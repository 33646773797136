const Vast = `
<VAST version="3.0">
    <Ad sequence="1">
        <InLine>
            <AdSystem>2.0</AdSystem>
            <AdTitle>ad-1</AdTitle>
            <Impression/>
            <Creatives>
                <Creative>
                    <Linear>
                        <Duration>00:00:25</Duration>
                        <MediaFiles>
                            <MediaFile delivery="progressive" type="video/mp4" width="1920" height="1080">
                                <![CDATA[https://ott-ssai-attempt-02-output.s3.us-west-2.amazonaws.com/ad1.mp4]]>
                            </MediaFile>
                        </MediaFiles>
                    </Linear>
                </Creative>
            </Creatives>
        </InLine>
    </Ad>
</VAST>
`;

export default Vast;
