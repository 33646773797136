import React, { useState, useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import TopBar from '../Components/VppComponet/TopBar';
import Container from '../Components/VppComponet/Container';
import Charts from '../Components/VppComponet/Charts';
import { Img } from '../ThemeProvider/style';
import { useNavigate } from 'react-router-dom';
import { addProjectId } from '../redux/projectSlice';
import { useDispatch } from "react-redux";
import { setShowLoader } from '../redux/loaderSlice';
import Loader from '../Components/CommonComponet/Loader';
import defaultThumbnail from '../assets/NoVideo.png'
import { generateThumbnailURL } from '../Routes/apiConfig';
import { awsApi } from '../Routes/Server';

const VppInventory = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const ComponentName = 'VPO Inventory';
  const [projectData, setProjectData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [filteredProjectData, setFilteredProjectData] = useState([]);
  const [totalVpoTotal, setTotalVpoTotal] = useState(0);
  const [filteredDataCount, setFilteredDataCount] = useState(0);
  const [pageType, setPageType] = useState('inventory')

  // Centralized filters in a single object
  const [filters, setFilters] = useState({
    country: '',
    projectType: [],
    genre: [],
    brandCategory: [],
    season: '',
    episode: '',
    searchText: ''
  });

  useEffect(() => {
    async function fetchInProgress() {
      dispatch(setShowLoader(true));
      try {
        let actionType = 'get-vpp-list';

        if (pageType === 'market') {
          actionType = 'get-vpp-list-market';
        }

        const res = await awsApi.post(
          '/postvideo',
          { action: actionType, ...filters }
        );
        if (res.data.statusCode === 200) {
          const dataItems = res.data.body;

          const filteredData = dataItems.filter((item) => item.type === 'Project');
          setProjectData(filteredData);
          setFilteredProjectData(filteredData);

          const totalVpoSum = filteredData.reduce((sum, item) => sum + parseInt(item.totalVpo, 10), 0);
          setTotalVpoTotal(totalVpoSum);
          setFilteredDataCount(filteredData.length);

          const transformedData = transformData(filteredData);

          // console.log("transformedData", transformedData);
          setTableData(transformedData);
        } else {
          console.error('Error fetching data:', res.data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);

      } finally {
        dispatch(setShowLoader(false));
      }
    }

    fetchInProgress();
  }, [filters, pageType]);



  const transformData = (data) => {

    const transformedData = data.map((item) => {
      return {
        id: item.id,
        name: item.title,
      };
    });

    return transformedData;
  };

  // console.log(filters)


  const handelNavigate = async (projectId) => {
    console.log(projectId, "projectId")
    dispatch(setShowLoader(true));
    try {
      navigate('/VppMain');
      dispatch(addProjectId(projectId));
    } catch (error) {
      console.error('Error navigating:', error);
    } finally {
      dispatch(setShowLoader(false));
    }
  };

  const handelNavigateTabel = async (row) => {
    const projectId = row.projectId
    console.log(projectId, "rowId")
    dispatch(setShowLoader(true));
    try {
      navigate('/VppMain');
      dispatch(addProjectId(projectId));
    } catch (error) {
      console.error('Error navigating:', error);
    } finally {
      dispatch(setShowLoader(false));
    }
  };


  if (filteredProjectData) {
    filteredProjectData.forEach((row, index) => {
      row._index = index + 1;
    });
  }

  

  const columns = [
    {
      field: 'index',
      headerName: 'S.No',
      width: 60,
      valueGetter: (params) => params.row._index,
    },
    {
      field: 'imgURL',
      headerName: 'Thumbnail & Title',
      width: 380,

      renderCell: (params) => {
        let videoId;
        if (params.row.video && params.row.video.length > 0) {
          videoId = params.row.video[0].videoId;
        } else {
          videoId = null;
        }
        const thumbnailURL = generateThumbnailURL(videoId);
        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Img src={thumbnailURL} alt="Project Title" onClick={() => { handelNavigate(params.row.id) }} />
            <Box sx={{ fontWeight: 'Bold', color: 'Black', cursor: "pointer" }} onClick={() => { handelNavigate(params.row.id) }}>{params.row.title}</Box>
          </Box>
        );
      },
    },

    { field: 'contentType', headerName: 'Type', width: 200 },
    {
      field: 'genres',
      headerName: 'Genre',
      width: 250,
      valueGetter: (params) => params.row.genres ? params.row.genres.join(', ') : '',
    },
    { field: 'totalVpo', headerName: 'No of Vpo', width: 180 },
    { field: 'releaseType', headerName: 'Release Type', width: 200 },
    { field: 'pValue', headerName: 'Estimated Value', width: 200 },
  ];



  return (
    <Grid container xs={12} mt={6}>
      <TopBar ComponentName={ComponentName} />
      <Grid container xs={12} variant='G1' mt={1} mb={2}>

        <Container
          totalVpoTotal={totalVpoTotal}
          filteredDataCount={filteredDataCount}
          tabledata={filteredProjectData}
          data={filteredProjectData}
          columns={columns}
          handelNavigateTabel={handelNavigateTabel}
          setFilters={setFilters}
          filters={filters}
          setPageType={setPageType}
          pageType={pageType}
        />
      </Grid>
      <Loader />
    </Grid>
  );
};

export default VppInventory;


