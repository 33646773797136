import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import TopBar from '../../Components/ProjectComponet/TopBar'
import Tabs from '../../Components/ProjectComponet/Tabs'
import Container from '../../Components/ProjectComponet/Container'
import { useDispatch, useSelector } from "react-redux";
import { setShowLoader } from '../../redux/loaderSlice';
import { awsApi } from '../../Routes/Server';


const ProjectList = () => {
  const dispatch = useDispatch();
  const ComponentName = 'Project'
  const accessToken = useSelector(state => state.login.accessToken);
  const [selectedType, setSelectedType] = useState('all');
  const [project, setProject] = useState([]);
  const [rows, setRows] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  console.log(accessToken)
  useEffect(() => {
    const fetchData = async () => {
      dispatch(setShowLoader(true));
      try {
        const response = await awsApi.post('/getprojects', { type: "Project" });
        if (response.data.statusCode === 200) {
          const jsonData = JSON.parse(response.data.body);
          const projectsData = jsonData.filter(item => item.type === "Project");
          setProject(projectsData);
          setRows(projectsData);
        }
      } catch (error) {
        console.error(error);
      } finally {
        dispatch(setShowLoader(false));
      }
    };

    fetchData();
  }, [dispatch]);

  const fetchDataForProjectUpload = async () => {
    dispatch(setShowLoader(true));
    try {
      const response = await awsApi.post('/getprojects', { type: "Project" })
      if (response.data.statusCode === 200) {
        const jsonData = JSON.parse(response.data.body);
        const projectsData = jsonData.filter(item => item.type === "Project");
        setProject(projectsData);
      }
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(setShowLoader(false));
    }
  };


  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value)
  };

  const filteredProjects = project.filter((item) => {
    const searchValue = searchQuery.toLowerCase();
    const rowData = [item.title, item.contentType, item.country, item.releaseType,
    item.averageViewPerMonth, item.genres.join(', '), item.releasePlatform.join(', '), item.totalVpo];

    return rowData.some((cell) => String(cell).toLowerCase().includes(searchValue));
  });



  return (
    <Grid container xs={12} mt={6} style={{ overflowX: "hidden" }}>
      <Grid xs={12} style={{ height: "82px" }}>
        <TopBar ComponentName={ComponentName} fetchDataForProjectUpload={fetchDataForProjectUpload} />
        <Tabs
          handleSearchChange={handleSearchChange}
          selectedType={selectedType}
          setSelectedType={setSelectedType}
          fetchDataForProjectUpload={fetchDataForProjectUpload}
        />

      </Grid>

      <Container selectedType={selectedType}
        setSelectedType={setSelectedType}
        project={filteredProjects}
        setProject={setProject}
        setRows={setRows}
        rows={rows}
      />


    </Grid>
  )
}

export default ProjectList
