import { AppBar, Button, Toolbar, Box, Grid } from '@mui/material'
import React from 'react'
import SearchBar from '../CampaignsComponet/SearchBar'
import { MdOutlineRefresh } from "react-icons/md";

const Tabs = ({ selectedType, setSelectedType, handleSearchChange, fetchDataForProjectUpload }) => {

    return (

        <AppBar position="static" color="transparent" elevation={0} variant='A1' style={{ width: '100%', marginBottom: "-15px" }}>
            <Toolbar variant="dense" >
                <Grid ml={-3} xs={8}>
                    <Button
                        variant='B3'
                        style={
                            selectedType === 'all'
                                ? { backgroundColor: '#000000', color: 'white' }
                                : {}
                        }
                        onClick={() => setSelectedType('all')} >
                        All
                    </Button>
                    <Button
                        variant='B2'
                        style={
                            selectedType === 'movie'
                                ? { backgroundColor: '#000000', color: 'white' }
                                : {}
                        }
                        onClick={() => setSelectedType('movie')}>
                        Movies
                    </Button>
                    <Button
                        variant='B2'
                        style={
                            selectedType === 'tv-show'
                                ? { backgroundColor: '#000000', color: 'white' }
                                : {}
                        }
                        onClick={() => setSelectedType('tv-show')}>
                        TV Show
                    </Button>
                    <Button
                        variant='B2'
                        style={
                            selectedType === 'web-series'
                                ? { backgroundColor: '#000000', color: 'white' }
                                : {}
                        }
                        onClick={() => setSelectedType('web-series')}>
                        Web Series
                    </Button>
                    <Button
                        variant='B2'
                        style={
                            selectedType === 'music-video'
                                ? { backgroundColor: '#000000', color: 'white' }
                                : {}
                        }
                        onClick={() => setSelectedType('music-video')}>
                        Music Video
                    </Button>
                    
                    <Button
                        variant='B2'
                        style={
                            selectedType === 'podcast'
                                ? { backgroundColor: '#000000', color: 'white' }
                                : {}
                        }
                        onClick={() => setSelectedType('podcast')}>
                        Podcast
                    </Button>
                    <Button
                        variant='B2'
                        style={
                            selectedType === 'you-tube'
                                ? { backgroundColor: '#000000', color: 'white' }
                                : {}
                        }
                        onClick={() => setSelectedType('you-tube')}>
                        You Tube
                    </Button>
                    <Button
                        variant='B2'
                        style={
                            selectedType === 'other'
                                ? { backgroundColor: '#000000', color: 'white' }
                                : {}
                        }
                        onClick={() => setSelectedType('other')}>
                        Other
                    </Button>
                </Grid>
                <Grid xs={4} ml={3.5} display='flex'>
                    <button style={{
                        height: "38px",
                        border: "1px solid #dbdfea",
                        width: '40px',
                        borderRadius: '8px',
                        background: 'white',
                        marginRight: '4px',
                    }}
                        onClick={fetchDataForProjectUpload}
                    ><MdOutlineRefresh /></button>
                  
                    <SearchBar onChange={handleSearchChange} />
                    
                    
                </Grid>
            </Toolbar>

        </AppBar >

    )
}

export default Tabs
