import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  pageType: "",
};

const PageTypeSlice = createSlice({
  name: "VppType",
  initialState,
  reducers: {
    addPageType: (state, action) => {
      state.pageType = action.payload;
    },
  },
});

export const { addPageType } = PageTypeSlice.actions;

export default PageTypeSlice.reducer;


