// import React, { useEffect, useState, useRef } from 'react';
// import Chart from 'chart.js/auto';
// import { awsApiCamp } from '../../Routes/Server';
// import { useDispatch } from "react-redux";
// import { setShowLoader } from '../../redux/loaderSlice';

// const CircleChart = () => {
//     const dispatch = useDispatch();
//     const [data, setData] = useState([]);
//     const chartRef = useRef(null);

//     useEffect(() => {
//         async function fetchInProgress() {
//             dispatch(setShowLoader(true));

//             try {
//                 const res = await awsApiCamp.post("/getcampaign");
//                 if (res.data.statusCode === 200) {
//                     const data = JSON.parse(res.data.body);
//                     setData(data.items);
//                     console.log(data.items);
//                 }
//             } catch (error) {
//                 console.error('Error fetching data:', error);
//             } finally {
//                 dispatch(setShowLoader(false));
//             }
//         }

//         fetchInProgress();
//     }, []);

//     useEffect(() => {
//         const getTypeCounts = (type) => {
//             return data.filter(item => item.type === type).length;
//         };

//         const xValues = ["Draft", "InProgress", "Active", "Rejected"];
//         const yValues = [
//             getTypeCounts("draft"),
//             getTypeCounts("in_progress"),
//             getTypeCounts("active"),
//             getTypeCounts("reject")
//         ];
//         const getRandomColor = () => {
//             const r = Math.floor(Math.random() * 256);
//             const g = Math.floor(Math.random() * 256);
//             const b = Math.floor(Math.random() * 256);
//             return `rgb(${r},${g},${b})`;
//         };

//         const barColors = Array.from({ length: 10 }, () => getRandomColor());

//         const ctx = document.getElementById('myChart').getContext('2d');

//         if (chartRef.current !== null) {
//             chartRef.current.destroy();
//         }

//         chartRef.current = new Chart(ctx, {
//             type: 'pie',
//             data: {
//                 labels: xValues,
//                 datasets: [{
//                     backgroundColor: barColors,
//                     data: yValues
//                 }]
//             },
//             options: {
//                 plugins: {
//                     title: {
//                         display: true,
//                     }
//                 }
//             }
//         });

//         return () => {
//             if (chartRef.current !== null) {
//                 chartRef.current.destroy();
//             }
//         };
//     }, [data]);


//     return <canvas id="myChart" className='myChartOne' style={{ width: '100%', maxWidth: '600px' ,minHeight: "268px"}} />;
// };

// export default CircleChart;

import React, { useEffect, useState, useRef } from 'react';
import * as echarts from 'echarts';
import { useDispatch } from "react-redux";
import { setShowLoader } from '../../redux/loaderSlice';

const CircleChart = ({ data }) => {
    const dispatch = useDispatch();
    const chartRef = useRef(null);


    useEffect(() => {
        const campaignInsight = data ? data.campaignInsight : {};
        
        const chartData = Object.keys(campaignInsight).map(key => ({
            value: campaignInsight[key],
            name: key.charAt(0).toUpperCase() + key.slice(1)
        }));

        const chartOptions = {
            tooltip: {
                trigger: 'item'
            },
            legend: {
                bottom: '5%',
                left: 'center'
            },
            series: [
                {
                    name: 'Campaigns',
                    type: 'pie',
                    radius: ['40%', '70%'],
                    avoidLabelOverlap: false,
                    itemStyle: {
                        borderRadius: 10,
                        borderColor: '#eff4fd',
                        borderWidth: 2
                    },
                    label: {
                        show: false,
                        position: 'center'
                    },
                    emphasis: {
                        label: {
                            show: true,
                            fontSize: 34,
                            fontWeight: 'bold'
                        }
                    },
                    labelLine: {
                        show: false
                    },
                    data: chartData
                }
            ]
        };

        let chartInstance = null;

        if (chartRef.current) {
            chartInstance = echarts.init(chartRef.current);
            chartInstance.setOption(chartOptions);
        }

        return () => {
            if (chartInstance) {
                chartInstance.dispose(); 
            }
        };
    }, [data]);

    return <div ref={chartRef}  style={{ width:"100%", height: "490px" }} 
    className='cchart'
    />;
};

export default CircleChart;
