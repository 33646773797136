
import { Box, Button, DialogActions, Grid, Slide } from '@mui/material';
import React, { useEffect, useState } from 'react';
import SearchBar from './SearchBar';
import CommonTable from '../CommonComponet/CommonTable';
import { Chip, IconButton, Dialog, DialogTitle, DialogContent } from '@mui/material';
import { IoMdArrowBack } from 'react-icons/io'
import { CloudDownloadOutlined, MoreHoriz, Delete } from '@mui/icons-material';
import { Img, Tabel } from './../../ThemeProvider/style';
import { DataGrid, } from '@mui/x-data-grid';
import { useDispatch } from "react-redux";
import { setShowLoader } from '../../redux/loaderSlice';
import { TfiMenuAlt } from 'react-icons/tfi';
import { BsGrid3X2GapFill } from 'react-icons/bs';
import NoData from '../CommonComponet/NoData';
import ProjectCard from '../CommonComponet/ProjectCard';
import VppPlayer from '../CommonComponet/VppPlayer';
import BasicTable, { PerPageView } from '../CommonComponet/BasicTable';
import { awsApiCamp } from '../../Routes/Server';
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu';
import CustomizedTables from '../DashboardComponet/MuiTabel';
import CampaignsCard from '../CommonComponet/CampaignsCard';
import { useNavigate } from 'react-router-dom';

const ActionMenu = ({ rowData, setRows, handleOpenDialog, setUpdateMessages }) => {
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState(null);
    const [approveDialogOpen, setApproveDialogOpen] = useState(false);
    const [commentInput, setCommentInput] = useState('');
    const [rejectMessage, setRejectMessage] = useState('');
    const [showRejectDialog, setShowRejectDialog] = useState(false);

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };


    const generateRandomText = () => {
        const randomText = Math.random().toString(36).substring(7);
        return randomText;
    };

    const handleUpdateMessages = () => {
        const randomText = generateRandomText();
        setUpdateMessages(randomText);
    };

    const handleRestore = async () => {
        dispatch(setShowLoader(true));
        try {
            if (rowData.rejectBy === 'Producer') {
                const updatedClipSet = rowData.clipSet.map(clip => {
                    return {
                        ...clip,
                    };
                });
                const updatedRowData = {
                    ...rowData,
                    creatorStatus: false,
                    clipSet: updatedClipSet,
                    status: "pending",
                    type: "in_progress",
                    rejectBy: "",
                    rejectMassage: "",
                };
                const res = await awsApiCamp.post('/postcampaign', {
                    ...updatedRowData,
                });
                if (res.data.statusCode === 200) {
                    handleUpdateMessages();

                }
            } else {
                setRejectMessage("You cannot restore this VPP frame as it has been rejected by the Brand.");
                setShowRejectDialog(true);
            }

        } catch (error) {
            console.error('Error rejecting data:', error);
        } finally {
            dispatch(setShowLoader(false));
        }
    };

    const handleDelete = () => {
        // console.log("this row is deleted")
    }

    return (
        <>
            {/* <IconButton onClick={handleMenuClick}>
                <MoreHoriz />
            </IconButton> */}
            <Menu menuButton={<IconButton><MoreHoriz /></IconButton>} transition align={'center'}>
                <MenuItem onClick={handleRestore}>Restore</MenuItem>
                <MenuItem onClick={handleDelete}>Delete</MenuItem>
            </Menu>
            <Dialog open={showRejectDialog} onClose={() => setShowRejectDialog(false)} >
                <DialogTitle >{"Restore Not Allowed."}</DialogTitle>
                <DialogContent >{rejectMessage}</DialogContent>
                <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
                    <Button onClick={() => setShowRejectDialog(false)} variant="outlined" color="success" sx={{ marginBottom: "7px" }}>
                        Close
                    </Button>

                </DialogActions>
            </Dialog>
        </>
    );
};

const Completed = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [searchText, setSearchText] = useState('');
    // const [apiData, setApiData] = useState([]);
    const [rows, setRows] = useState([]);
    const [lastEvaluatedKey, setLastEvaluatedKey] = useState(undefined);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [gridView, setGridView] = useState(false);
    const [listView, setListView] = useState(true);
    const [openPlayer, setOpenPlayer] = useState(false);
    const [selectedProject, setSelectedProject] = useState(null);
    const [updateMessages, setUpdateMessages] = useState('')
    const [rejectMessage, setRejectMessage] = useState('');
    const [showRejectDialog, setShowRejectDialog] = useState(false);
    const [selectedApproveRow, setSelectedApproveRow] = useState(null);
    const [actionValue, setAactionValue] = useState(1)

    useEffect(() => {
        async function fetchReject() {
            dispatch(setShowLoader(true));
            const res = await awsApiCamp.post("/getcampaign", { type: "reject", pageSize: 5, page: 1, lastEvaluatedKey: lastEvaluatedKey })
            if (res.data.statusCode === 200) {
                const data = JSON.parse(res.data.body);
                setRows(data.items);
                setLastEvaluatedKey(data.lastEvaluatedKey);
                // console.log(data);
            }
            dispatch(setShowLoader(false));
        }
        fetchReject();
    }, [updateMessages]);

    const handleSetRows = (updatedRows) => {
        setRows(updatedRows);
    };

    const handleOpenDialog = (rowData) => {
        setSelectedRow(rowData);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleDownload = (id) => {
        // console.log("Download project with ID:", id);
    };

    rows.forEach((row, index) => {
        row._index = index + 1;
    });

    const generateRandomText = () => {
        const randomText = Math.random().toString(36).substring(7);
        return randomText;
    };

    const handleUpdateMessages = () => {
        const randomText = generateRandomText();
        setUpdateMessages(randomText);
    };


    const getValue = (row) => row._index;

    const columns = [
        {
            field: 'index',
            headerName: 'S.No',
            width: 60,
            valueGetter: (params) => params.row._index,
        },
        {
            field: "projectTitle",
            headerName: "Title",
            width: 300,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {/* <Img src={params.row.thumbnail} alt="Project Title" onClick={() => handelOpenPlayer(params.row)} /> */}
                    <Box sx={{ fontWeight: 'bold', color: 'black' }}>{params.row.clipSet[0].projectTitle}</Box>
                </Box>
            ),
        },
        {
            field: "VppFreamNo", headerName: "Campaign No", width: 150,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ fontWeight: 'normal', color: 'black' }}>{params.row.clipSet[0].VppFreamNo}</Box>
                </Box>
            ),
        },
        {
            field: "catagory",
            headerName: "Media Type",
            width: 170,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ fontWeight: 'normal', color: 'black' }}>{params.row.clipSet[0].catagory}</Box>
                </Box>
            ),
        },
        {
            field: "brandCat",
            headerName: "Brand Catagory",
            width: 180,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ fontWeight: 'normal', color: 'black' }}> {params.row.paymentDetails?.userName}</Box>
                </Box>
            ),
        },
        {
            field: "status",
            headerName: "Status",
            width: 200,
            renderCell: (params) => {
                const rejectBy = params.row.rejectBy;
                let label = "Pending";
                let color = "secondary";

                if (rejectBy === "Producer") {
                    label = "Rejected by You";
                    color = "error";
                } else if (rejectBy === "Brand") {
                    label = "Rejected by Brand";
                    color = "error";
                }

                return (
                    <Chip
                        label={label}
                        color={color}
                        variant="outlined"
                        size="small"
                        sx={{ width: '160px', height: '30px' }}
                    />
                );
            },
        },

        { field: "rejectMassage", headerName: "Reject Massage", width: 300 },
        {
            field: 'id',
            headerName: 'VPP Campaign',
            description: 'This column has a value getter and is not sortable.',
            sortable: false,
            width: 130,
            renderCell: (params) => (
                <ActionMenu rowData={params.row} setRows={handleSetRows} handleOpenDialog={handleOpenDialog} setUpdateMessages={setUpdateMessages} />
            ),
        },
    ];

    const rowsPerPageOptions = [5, 10, 25];

    const handleSearchChange = (event) => {
        setSearchText(event.target.value)
    };

    const filteredData = rows.filter((item) => {
        const searchValue = searchText.toLowerCase();
        const rowData = [item.sno, item.projectTitle, item.completeDate, item.catagory];
        return rowData.some((cell) => String(cell).toLowerCase().includes(searchValue));
    });

    const handelOpenPlayer = (project) => {
        setSelectedProject(project);
        setOpenPlayer(true);
    };

    const decideColor = (row) => {
        const rejectBy = row.rejectBy;
        let label = "Pending";
        let color = "secondary";

        if (rejectBy === "Producer") {
            label = "Rejected by You";
            color = "error";
        } else if (rejectBy === "Brand") {
            label = "Rejected by Brand";
            color = "error";
        }
        return { label, color };
    }

    const handleRestore = async (row) => {
        dispatch(setShowLoader(true));
        try {
            if (row.rejectBy === 'Producer') {
                const updatedClipSet = row.clipSet.map(clip => {
                    return {
                        ...clip,
                    };
                });
                const updatedRowData = {
                    ...row,
                    creatorStatus: false,
                    clipSet: updatedClipSet,
                    status: "pending",
                    type: "in_progress",
                    rejectBy: "",
                    rejectMassage: "",
                };
                const res = await awsApiCamp.post('/postcampaign', {
                    ...updatedRowData,
                });
                if (res.data.statusCode === 200) {
                    handleUpdateMessages();
                }
            } else {
                setRejectMessage("You cannot restore this VPP frame as it has been rejected by the Brand.");
                setShowRejectDialog(true);
            }

        } catch (error) {
            console.error('Error rejecting data:', error);
        } finally {
            dispatch(setShowLoader(false));
        }
    };

    const handleDelete = () => {
        console.log("this row is deleted")
    }

    const handelBarndedPlayer = (project) => {
        setSelectedProject(project);
        setOpenPlayer(true);
        setAactionValue(3)
    };

    const handleComment = (row) => {
        const id = row.id;
        navigate(`/MessageBrand?id=${id}`);
    };


    return (
        <Grid xs={12}>
            <Grid xs={11.9} mt={1} display='flex' justifyContent='space-between' alignItems='center'>
                <Grid xs={2}>
                    <SearchBar onChange={handleSearchChange} />
                </Grid>
                <Grid xs={0.45}>
                    {listView === true ? (
                        <Button
                            variant="B5"
                            onClick={() => {
                                setGridView(true);
                                setListView(false);
                            }}
                        >
                            <BsGrid3X2GapFill />
                        </Button>
                    ) : (
                        <Button
                            variant="B5"
                            onClick={() => {
                                setGridView(false);
                                setListView(true);
                            }}
                        >
                            <TfiMenuAlt />
                        </Button>
                    )}
                </Grid>
            </Grid>

            {gridView && (
                <Grid xs={12} mt={3}>
                    <Grid xs={12} variant='G3'>
                        {filteredData.length === 0 ? (
                            <NoData />
                        ) : (
                            <Grid container item xs={12} spacing={3} style={{ margin: '0rem 1rem' }}>
                                {/* {gridView && */}
                                {filteredData.map((project) => (
                                    <CampaignsCard
                                        key={project.projectId}
                                        contentType={project.clipSet[0].category}
                                        projectTitle={project.clipSet[0].projectTitle}
                                        campaignId={project.clipSet[0].VppName}
                                        brandCatagory={project.clipSet[0].brandCat}
                                        dateOfCreation={project.currentTime}
                                        brandLogo={project.brandLogo?.value}
                                        comment={project.clipSet[0]}
                                        rejectMassage={project.rejectMassage}
                                        decideColor={() => decideColor(project)}
                                        handleComment={() => handleComment(project)}
                                        handelBarndedPlayer={()=> handelBarndedPlayer(project)}
                                        handleApproveClick={()=>handleRestore(project)}
                                        handleRejectDialog={()=>handleDelete(project)}
                                        price={project.clipSet[0].price}
                                        vppFreamNo={project.clipSet[0].VppFreamNo}
                                        thumbnail={project.clipSet[0].thumbnail}
                                        projectId={project.id}
                                        label={project.status.label}
                                        color={project.status.color}
                                        project={project}
                                        handelOpenPlayer={() => handelOpenPlayer(project)}
                                        viId={project.videoId}
                                        cardType={'reject'}
                                    />
                                ))
                                }
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            )}
            {listView && (
                <Grid xs={12} mt={3} mb={2}>
                    {filteredData.length === 0 ? (
                        <NoData />
                    ) : (
                        <>
                            {/* <DataGrid
                                rows={rows}
                                columns={columns}
                                disableRowSelectionOnClick
                                sx={{ color: '#526484', minHeight: "38.5vh" }}
                                initialState={{
                                    ...rows.initialState,
                                    pagination: { paginationModel: { pageSize: 5 } },
                                }}
                                pageSizeOptions={[5, 10, 25]}
                                disableColumnMenu
                            /> */}
                            <CustomizedTables
                                filterRow={filteredData}
                                columns={columns}
                                rowAttributes={[
                                    { name: "projectTitle", type: 'regular', child: "clipSet", newStyle: "pointer", fontWeight: "bold", TextDecoder: "underline", },
                                    { name: "VppFreamNo", type: 'regular', child: "clipSet" },
                                    { name: "category", type: 'regular', child: "clipSet" },
                                    { name: "brandCat", type: 'chip', child: "clipSet" },
                                    { name: "status", type: 'newChip', child: "clipSet", typeColorCallback: decideColor },
                                    { name: "rejectMassage", type: 'regular', },
                                    {
                                        name: "id",
                                        type: 'quikAction',
                                        child: "clipSet",
                                        actions: [
                                            { label: 'Restore', buttonType: 'Restore', buttonColor: "#00ff0070", action: handleRestore },
                                            { label: 'Delete', buttonType: 'Delete', buttonColor: "#ff000085", action: handleDelete },
                                        ],
                                    },
                                ]}
                            />
                        </>
                    )}
                </Grid>
            )}
            <VppPlayer
                setOpenPlayer={setOpenPlayer}
                openPlayer={openPlayer}
                videoData={filteredData}
                selectedProject={selectedProject}
            />
            <Dialog open={showRejectDialog} onClose={() => setShowRejectDialog(false)} >
                <DialogTitle >{"Restore Not Allowed."}</DialogTitle>
                <DialogContent >{rejectMessage}</DialogContent>
                <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
                    <Button onClick={() => setShowRejectDialog(false)} variant="outlined" color="success" sx={{ marginBottom: "7px" }}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>

    );
};

export default Completed;
