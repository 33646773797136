
// import React, { useRef, useState } from 'react';
// import { Table, TableBody, TableCell, Badge, TableHead, Chip, TableRow, Grid, Button, IconButton, Typography } from '@mui/material';
// import Checkbox from '@mui/material/Checkbox';
// import { BsPlayCircle } from 'react-icons/bs'
// import { HiOutlinePause } from 'react-icons/hi'
// import CustomTextfield from '../CommonComponet/CustomTextfield';
// import Dialog from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
// import DialogContent from '@mui/material/DialogContent';
// import DialogTitle from '@mui/material/DialogTitle';
// import { setShowLoader } from '../../redux/loaderSlice';
// import { useDispatch } from "react-redux";
// import { useEffect } from 'react';
// import { v4 as uuidv4 } from 'uuid';
// import Loader from '../CommonComponet/Loader';
// import { awsApi } from '../../Routes/Server';
// import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
// import { styled } from '@mui/material/styles';
// import { MdOutlineEdit } from "react-icons/md";
// import { RiSave3Fill } from "react-icons/ri";

// function parseTimeToSeconds(timeString) {
//     const [hours, minutes, seconds] = timeString.split(':').map(Number);
//     return hours * 3600 + minutes * 60 + seconds;
// }



// const StyledBadge = styled(Badge)(({ theme }) => ({
//     '& .MuiBadge-badge': {
//         right: -3,
//         top: 13,
//         border: `2px solid ${theme.palette.background.paper}`,
//         padding: '0 4px',
//     },
// }));

// const VppTabel = ({ clipsData, setClipData, selectedProjectType, projectTitle, brandCat, catagory, projectId, videoId, setUpdateMessages, invokeVideoDetails }) => {

//     const dispatch = useDispatch();
//     const videoRef = useRef(null);
//     const rowsPerPageOptions = [3, 4, 5];
//     const [page, setPage] = useState(0);
//     const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
//     const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
//     const [selectedRows, setSelectedRows] = useState([]);

//     const [selectedClip, setSelectedClip] = useState(
//         clipsData && clipsData.VppClips && clipsData.VppClips.length > 0
//             ? clipsData.VppClips[0].VppClipUrl
//             : ''
//     );

//     const generateRandomText = () => {
//         const randomText = Math.random().toString(36).substring(7);
//         return randomText;
//     };

//     const handleUpdateMessages = () => {
//         const randomText = generateRandomText();
//         setUpdateMessages(randomText);
//     };

//     console.log(clipsData)

//     const [currentPlayingIndex, setCurrentPlayingIndex] = useState(null);
//     const [showConfirmPopup, setShowConfirmPopup] = useState(false);
//     const [inputPrice, setInputPrice] = useState('');
//     const [videoID, setVideoID] = useState(clipsData.VideoId)

//     useEffect(() => {
//         setRowsPerPage(5)
//     }, [rowsPerPage]);


//     const handlePlayClick = (vppId, index) => {
//         let currentClipInfo = "";
//         clipsData.VppClips.forEach(element => {
//             if (element.VppId === vppId) {
//                 currentClipInfo = element;
//             }
//         });
//         const startTime = parseTimeToSeconds(currentClipInfo.startTime);
//         const endTime = parseTimeToSeconds(currentClipInfo.endTime);
//         videoRef.current.currentTime = startTime;
//         if (currentPlayingIndex === index) {
//             videoRef.current.pause();
//             setCurrentPlayingIndex(null);
//         } else {
//             videoRef.current.play();
//             setCurrentPlayingIndex(index);

//             const timeUpdateHandler = () => {
//                 if (videoRef.current.currentTime >= endTime) {
//                     videoRef.current.pause();
//                     setCurrentPlayingIndex(null);
//                 }
//             };
//             videoRef.current.addEventListener('timeupdate', timeUpdateHandler);
//             const endedHandler = () => {
//                 videoRef.current.removeEventListener('timeupdate', timeUpdateHandler);
//             };

//             const pauseHandler = () => {
//                 videoRef.current.removeEventListener('timeupdate', timeUpdateHandler);
//             };

//             videoRef.current.addEventListener('ended', endedHandler);
//             videoRef.current.addEventListener('pause', pauseHandler);
//         }
//     };


//     const handleCloseDialog = () => {
//         setShowConfirmPopup(false);
//         setShowEmptyInputWarning(false);
//     };

//     const handleBrandCheckboxChange = (vppId) => {
//         if (selectedRows.includes(vppId)) {
//             setSelectedRows(selectedRows.filter(id => id !== vppId));
//         } else {
//             setSelectedRows([...selectedRows, vppId]);
//         }
//     };

//     const fetchData = async () => {
//         dispatch(setShowLoader(true));
//         try {
//             const response = await invokeVideoDetails(selectedProjectType, projectId, videoId);
//             if (response.data.body && response.data.body) {
//                 setClipData(response.data.body);
//             } else {
//                 setClipData([]);
//             }
//             dispatch(setShowLoader(false));
//         } catch (error) {
//             // Handle errors if needed
//             console.error("Error fetching data:", error);
//         }
//     };

//     const [inputPrices, setInputPrices] = useState({});
//     const [emptyInputFields, setEmptyInputFields] = useState([]);
//     const [showEmptyInputWarning, setShowEmptyInputWarning] = useState(false);

//     const handleConfirmClick = async () => {
//         dispatch(setShowLoader(true));

//         try {
//             let allInputsFilled = true;

//             selectedRows.forEach(vppId => {
//                 if (!inputPrices[vppId] || inputPrices[vppId].trim() === '') {
//                     allInputsFilled = false;
//                 }
//             });
//             if (!allInputsFilled) {
//                 setShowEmptyInputWarning(true);
//                 dispatch(setShowLoader(false));
//                 return;
//             }
//             selectedRows.forEach(vppId => {
//                 const payload = {
//                     action: 'available-for-brand',
//                     projectId: projectId,
//                     videoId: videoId,
//                     clipSet: [{
//                         "VppId": vppId,
//                         price: inputPrices[vppId],
//                         status: 'checked',
//                         brandStatus: 'unChecked'
//                     }]
//                 };

//                 awsApi.post('/postvideo', payload)
//                     .then(response => {
//                         console.log(`Updated data for VPP Frame No ${vppId}:`, response.data);
//                         fetchData();
//                     })
//                     .catch(error => {
//                         console.error(`Error updating data for VPP Frame No ${vppId}:`, error);
//                     });
//             });

//             setShowConfirmPopup(false);
//             setSelectedRows([]);
//         } catch (error) {
//             console.error('An error occurred:', error);
//         }

//         dispatch(setShowLoader(false));
//     };

//     if (!clipsData || !clipsData.VppClips) {
//         return <div>Loading data...</div>;
//     }

//     const data = clipsData.VppClips;
//     const startIndex = page * rowsPerPage;
//     const endIndex = startIndex + rowsPerPage;
//     const pageData = data.slice(startIndex, endIndex);

//     const emptyRows = rowsPerPage - Math.min(rowsPerPage, pageData.length);

//     const totalPages = Math.ceil(data.length / rowsPerPage);
//     const pageNumbers = Array.from({ length: totalPages }, (_, index) => index + 1);

//     const handlePageClick = (pageNumber) => {
//         setPage(pageNumber);
//         setCurrentPlayingIndex(null);
//     };

//     const maxPagesToShow = 5;
//     const showEllipsis = totalPages > maxPagesToShow;

//     const renderPageNumbers = () => {
//         const pageNumbers = [];

//         if (!showEllipsis) {
//             // Show all pages if there are 5 or fewer pages
//             for (let i = 1; i <= totalPages; i++) {
//                 pageNumbers.push(i);
//             }
//         } else {
//             const visiblePages = [];

//             // Determine the range of visible pages
//             const startPage = Math.max(1, Math.min(page - 1, totalPages - maxPagesToShow + 1));
//             const endPage = Math.min(startPage + maxPagesToShow - 1, totalPages);

//             // Show the current page centered in the visible range
//             const centeredPage = Math.min(startPage + Math.floor((endPage - startPage) / 2), totalPages);

//             // Show ellipsis if the current page is not at the beginning
//             if (startPage > 1) {
//                 visiblePages.push(1);
//                 if (startPage > 2) {
//                     visiblePages.push(null); // Ellipsis
//                 }
//             }

//             // Show visible pages
//             for (let i = startPage; i <= endPage; i++) {
//                 visiblePages.push(i);
//             }

//             // Show ellipsis if the current page is not at the end
//             if (endPage < totalPages) {
//                 if (endPage < totalPages - 1) {
//                     visiblePages.push(null); // Ellipsis
//                 }
//                 visiblePages.push(totalPages);
//             }

//             pageNumbers.push(...visiblePages);
//         }

//         return pageNumbers.map((number, index) => (
//             <li key={index} className={`page-item ${page === number - 1 ? 'active' : ''}`}>
//                 {number === null ? (
//                     <span className="page-link">...</span>
//                 ) : (
//                     <a className="page-link" href="#" onClick={() => handlePageClick(number - 1)}>
//                         {number}
//                     </a>
//                 )}
//             </li>
//         ));
//     };
//     const calculateDurationInSeconds = (startTimeStr, endTimeStr) => {
//         const [startHour, startMinute, startSecond] = startTimeStr.split(':').map(Number);
//         const [endHour, endMinute, endSecond] = endTimeStr.split(':').map(Number);
//         const startTime = new Date(1970, 0, 1, startHour, startMinute, startSecond);
//         const endTime = new Date(1970, 0, 1, endHour, endMinute, endSecond);
//         if (isNaN(startTime) || isNaN(endTime)) {
//             return 'Invalid time format';
//         }
//         return (endTime - startTime) / 1000;
//     };

//     const NoDataFound = selectedRows.length === 0

//     return (
//         <>
//             <Grid xs={12} mt={1} variant="G2C" display='flex' style={{ height: '400px' }} mb={1}>
//                 <Grid xs={6} style={{ height: '400px', borderTopRightRadius: "0px", borderBottomRightRadius: "0px", borderRight: "1px solid #e0e0e0" }}>
//                     <Grid xs={12} sx={{ width: '100%' }}>
//                         <Grid sx={{ minHeight: "320px", maxHeight: "320px" }}>
//                             <Table variant="T1" size="small" aria-label="a dense table">
//                                 <TableHead sx={{ height: "55px" }}>
//                                     <TableRow >
//                                         <TableCell variant="TH1">VPP No</TableCell>
//                                         <TableCell variant="TH1">Brand Categories</TableCell>
//                                         <TableCell variant="TH1">&nbsp;Start Time  <br />  (HH:MM:SS) </TableCell>
//                                         <TableCell variant="TH1">Seconds</TableCell>
//                                         <TableCell variant="TH1">Price ($)</TableCell>
//                                         <TableCell variant="TH1">Play</TableCell>
//                                         <TableCell variant="TH1">Sale</TableCell>
//                                     </TableRow>
//                                 </TableHead>
//                                 <TableBody>
//                                     {pageData &&
//                                         pageData.map((clip, index) => (
//                                             <TableRow key={clip.VppId} sx={{ maxHeight: "30px" }}>
//                                                 <TableCell align='center'>{clip.VppId.substring(0, 3)}</TableCell>
//                                                 <TableCell align='center'>
//                                                     <Chip label={clip.brandCat} size="small" variant="outlined" />
//                                                 </TableCell>
//                                                 <TableCell align='center'>{clip.startTime}</TableCell>
//                                                 <TableCell align='center'>{calculateDurationInSeconds(clip.startTime, clip.endTime)}</TableCell>
//                                                 <TableCell align='center'>{clip.price !== '' ? clip.price : '-'} </TableCell>
//                                                 <TableCell align='center'>
//                                                     <IconButton
//                                                         variant="IB0"
//                                                         onClick={() => handlePlayClick(clip.VppId, index)}
//                                                     >
//                                                         {currentPlayingIndex === index ? <HiOutlinePause /> : <BsPlayCircle />}
//                                                     </IconButton>
//                                                 </TableCell>
//                                                 <TableCell align='center'>
//                                                     <Checkbox
//                                                         disabled={(clip.status && clip.status === "checked") ? true : false}
//                                                         checked={(clip.status && clip.status === "checked") ? true : selectedRows.includes(clip.VppId)}
//                                                         onChange={() => handleBrandCheckboxChange(clip.VppId)}
//                                                         inputProps={{ 'aria-label': 'controlled' }}
//                                                         color="success"
//                                                     />
//                                                 </TableCell>

//                                             </TableRow>
//                                         ))}
//                                 </TableBody>
//                             </Table>
//                         </Grid>

//                         <Grid variant="G22" xs={12}>
//                             <Grid xs={8} display="flex" justifyContent="space-between" >

//                                 <nav className="mt-3 ml-3">
//                                     <ul className="pagination">
//                                         <li className={`page-item ${page === 0 ? 'disabled' : ''}`}>
//                                             <a className="page-link" href="#" onClick={() => handlePageClick(page - 1)}>
//                                                 {"Previous"}
//                                             </a>
//                                         </li>
//                                         {renderPageNumbers()}
//                                         <li className={`page-item ${page === totalPages - 1 ? 'disabled' : ''}`}>
//                                             <a className="page-link" href="#" onClick={() => handlePageClick(page + 1)}>
//                                                 {"Next"}
//                                             </a>
//                                         </li>
//                                     </ul>
//                                 </nav>
//                             </Grid>
//                             <Grid xs={4} display='flex' justifyContent='center' alignItems='center'>
//                                 <Button
//                                     variant="B3C"
//                                     disabled={selectedRows.length === 0}
//                                     sx={{ background: NoDataFound ? "#00000066" : 'black' }}
//                                     onClick={() => setShowConfirmPopup(true)}
//                                 >
//                                     Available for Brands
//                                     <IconButton aria-label="cart">
//                                         <StyledBadge badgeContent={selectedRows.length > 0 && `${selectedRows.length}`} color="success" >
//                                             <ShoppingCartIcon style={{ color: "white", fontSize: "27px" }} />
//                                         </StyledBadge>
//                                     </IconButton>
//                                 </Button>

//                             </Grid>
//                         </Grid>
//                     </Grid>
//                 </Grid>
//                 <Grid xs={6} style={{ minHeight: '400px' }} alignItems="center" >
//                     <Grid xs={12} variant="G2" style={{ minHeight: '400px', borderTopLeftRadius: "0px", borderBottomLeftRadius: "0px", }} alignItems="center" justifyContent='center'>
//                         <video width="100%" height="100%" controls ref={videoRef} style={{ background: "black" }}>
//                             <source src={selectedClip} type="video/mp4" />
//                             Your browser does not support the video tag.
//                         </video>
//                     </Grid>
//                 </Grid>

//                 <Dialog
//                     open={showConfirmPopup}
//                     onClose={handleCloseDialog}
//                     aria-labelledby="alert-dialog-title"
//                     aria-describedby="alert-dialog-description"
//                 >
//                     <DialogTitle id="alert-dialog-title" >
//                         <Typography variant='P15' style={{ fontSize: '18px' }}>{"Please mention the price for Vpp in USD$"}</Typography>

//                     </DialogTitle>

//                     <DialogContent> 
//                         {selectedRows.map(vppId => (
//                             <Grid style={{ borderTop: "1px solid black", borderBottom: "1px solid black" }}>
//                                 <div key={vppId} style={{ display: 'flex', justifyContent: "space-between", alignItems: "center", marginTop: "5px", marginBottom: "9px" }}>
//                                     <Typography style={{ fontWeight: "bold", marginTop: "2px" }}>VPP No ({vppId.substring(0, 3)}) :-</Typography>

//                                     {/* <input type="number"

//                                         style={{ marginTop: "5px", width: "50%", borderRadius: "4px", height: "35px", outline: "none", }}
//                                         onChange={(e) => setInputPrice(e.target.value)}
//                                     /> */}
//                                     <input
//                                         type="number"
//                                         style={{ marginTop: "5px", width: "50%", borderRadius: "4px", height: "35px", outline: "none" }}
//                                         onChange={(e) => setInputPrices(prevState => ({ ...prevState, [vppId]: e.target.value }))}
//                                     />

//                                 </div>
//                             </Grid>
//                         ))}
//                         {showEmptyInputWarning && (
//                             <p style={{ color: 'red' }}>
//                                 Please fill in all input fields for VPP.
//                             </p>
//                         )}
//                     </DialogContent>

//                     <DialogActions>
//                         <Button onClick={() => handleCloseDialog()} variant="outlined" color="error">
//                             Close
//                         </Button>
//                         <Button onClick={handleConfirmClick} autoFocus variant="outlined" color="success">
//                             Agree
//                         </Button>
//                     </DialogActions>
//                 </Dialog>

//             </Grid>
//             <Loader />
//         </>
//     );
// };

// export default VppTabel;



import React, { useRef, useState } from 'react';
import { Table, TableBody, TableCell, Badge, TableHead, Tooltip, tooltipClasses, Chip, TableRow, Grid, Button, IconButton, Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { BsPlayCircle } from 'react-icons/bs'
import { HiOutlinePause } from 'react-icons/hi'
import CustomTextfield from '../CommonComponet/CustomTextfield';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { setShowLoader } from '../../redux/loaderSlice';
import { useDispatch } from "react-redux";
import { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Loader from '../CommonComponet/Loader';
import { awsApi } from '../../Routes/Server';
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { styled } from '@mui/material/styles';
import { MdOutlineEdit } from "react-icons/md";
import { RiSave3Fill } from "react-icons/ri";
import Preview from '../MraketPlace/Preview';
import { TbScanEye } from "react-icons/tb";

function parseTimeToSeconds(timeString) {
    const [hours, minutes, seconds] = timeString.split(':').map(Number);
    return hours * 3600 + minutes * 60 + seconds;
}



const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: -3,
        top: 13,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
    },
}));

const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        minHeight: "auto",
    },
});

const VppTabel = ({ clipsData, setClipData, selectedProjectType, projectTitle, brandCat, catagory, projectId, videoId, setUpdateMessages, invokeVideoDetails, pageType }) => {

    const dispatch = useDispatch();
    const videoRef = useRef(null);
    const rowsPerPageOptions = [3, 4, 5];
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
    const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [editIndex, setEditIndex] = useState(null);
    const [editedPrice, setEditedPrice] = useState('');
    const [previewDiloge, setPreviewDiloge] = useState(false)
    const [selectedClipData, setSelectedClipData] = useState(null);
    const handleEditClick = (index) => {
        setEditIndex(index);
        setEditedPrice(pageData[index]?.price || '');
    };

    const handleInputChange = (e) => {
        const updatedPrice = e.target.value;
        setEditedPrice(updatedPrice);
    };

    const [selectedClip, setSelectedClip] = useState(
        clipsData && clipsData.VppClips && clipsData.VppClips.length > 0
            ? clipsData.VppClips[0].VppClipUrl
            : ''
    );

    const generateRandomText = () => {
        const randomText = Math.random().toString(36).substring(7);
        return randomText;
    };

    const handleUpdateMessages = () => {
        const randomText = generateRandomText();
        setUpdateMessages(randomText);
    };

    console.log(clipsData)

    const [currentPlayingIndex, setCurrentPlayingIndex] = useState(null);
    const [showConfirmPopup, setShowConfirmPopup] = useState(false);
    const [inputPrice, setInputPrice] = useState('');
    const [videoID, setVideoID] = useState(clipsData.VideoId)
    const [newClipSet, setNewClipSet] = useState(null);


    useEffect(() => {
        const processClipData = () => {
            const startTimeMap = new Map();
            clipsData.VppClips.forEach(clip => {
                const { startTime, Duration, brandCat, VppId } = clip;
                if (startTimeMap.has(startTime)) {
                    const existingClip = startTimeMap.get(startTime);
                    if (existingClip.Duration < Duration) {
                        const updatedBrandCat = Array.isArray(existingClip.brandCat) ? existingClip.brandCat : [existingClip.brandCat];
                        if (!updatedBrandCat.includes(brandCat)) {
                            updatedBrandCat.push(brandCat);
                        }
                        existingClip.brandCat = updatedBrandCat;
                        existingClip.mergedId.push(VppId);
                        startTimeMap.set(startTime, existingClip);
                    } else {
                        const updatedBrandCat = Array.isArray(brandCat) ? brandCat : [brandCat];
                        existingClip.brandCat = Array.isArray(existingClip.brandCat) ? existingClip.brandCat.concat(updatedBrandCat) : updatedBrandCat;
                        existingClip.mergedId = existingClip.mergedId.concat([VppId]);
                        startTimeMap.set(startTime, existingClip);
                    }
                } else {
                    clip.mergedId = [VppId];
                    clip.brandCat = Array.isArray(brandCat) ? brandCat : [brandCat];
                    startTimeMap.set(startTime, clip);
                }
            });
            const processedClips = Array.from(startTimeMap.values());
            const updatedClipsData = { ...clipsData };
            updatedClipsData.VppClips = processedClips;

            console.log('updatedClipsData', updatedClipsData);
            setNewClipSet(updatedClipsData);
        };

        processClipData();
    }, [clipsData]);


    useEffect(() => {
        setRowsPerPage(5)
    }, [rowsPerPage]);


    const handlePlayClick = (vppId, index) => {
        let currentClipInfo = "";
        clipsData.VppClips.forEach(element => {
            if (element.VppId === vppId) {
                currentClipInfo = element;
            }
        });
        const startTime = parseTimeToSeconds(currentClipInfo.startTime);
        const endTime = parseTimeToSeconds(currentClipInfo.endTime);
        videoRef.current.currentTime = startTime;
        if (currentPlayingIndex === index) {
            videoRef.current.pause();
            setCurrentPlayingIndex(null);
        } else {
            videoRef.current.play();
            setCurrentPlayingIndex(index);

            const timeUpdateHandler = () => {
                if (videoRef.current.currentTime >= endTime) {
                    videoRef.current.pause();
                    setCurrentPlayingIndex(null);
                }
            };
            videoRef.current.addEventListener('timeupdate', timeUpdateHandler);
            const endedHandler = () => {
                videoRef.current.removeEventListener('timeupdate', timeUpdateHandler);
            };

            const pauseHandler = () => {
                videoRef.current.removeEventListener('timeupdate', timeUpdateHandler);
            };

            videoRef.current.addEventListener('ended', endedHandler);
            videoRef.current.addEventListener('pause', pauseHandler);
        }
    };

    const handleCloseDialog = () => {
        setShowConfirmPopup(false);
        setShowEmptyInputWarning(false);
    };

    const handleBrandCheckboxChange = (vppId) => {
        if (selectedRows.includes(vppId)) {
            setSelectedRows(selectedRows.filter(id => id !== vppId));
        } else {
            setSelectedRows([...selectedRows, vppId]);
        }
    };

    const fetchData = async () => {
        dispatch(setShowLoader(true));
        try {
            const response = await invokeVideoDetails(selectedProjectType, projectId, videoId);
            if (response.data.body && response.data.body) {
                setClipData(response.data.body);
            } else {
                setClipData([]);
            }
            dispatch(setShowLoader(false));
        } catch (error) {
            // Handle errors if needed
            console.error("Error fetching data:", error);
        }
    };

    const [inputPrices, setInputPrices] = useState({});
    const [emptyInputFields, setEmptyInputFields] = useState([]);
    const [showEmptyInputWarning, setShowEmptyInputWarning] = useState(false);

    const handleConfirmClick = async () => {
        dispatch(setShowLoader(true));

        try {
            let allInputsFilled = true;

            selectedRows.forEach(vppId => {
                if (!inputPrices[vppId] || inputPrices[vppId].trim() === '') {
                    allInputsFilled = false;
                }
            });
            if (!allInputsFilled) {
                setShowEmptyInputWarning(true);
                dispatch(setShowLoader(false));
                return;
            }
            selectedRows.forEach(vppId => {
                const payload = {
                    action: 'available-for-brand',
                    projectId: projectId,
                    videoId: videoId,
                    clipSet: [{
                        "VppId": vppId,
                        price: inputPrices[vppId],
                        status: 'checked',
                        brandStatus: 'unChecked'
                    }]
                };

                awsApi.post('/postvideo', payload)
                    .then(response => {
                        console.log(`Updated data for VPO Frame No ${vppId}:`, response.data);
                        fetchData();
                    })
                    .catch(error => {
                        console.error(`Error updating data for VPO Frame No ${vppId}:`, error);
                    });
            });

            setShowConfirmPopup(false);
            setSelectedRows([]);
        } catch (error) {
            console.error('An error occurred:', error);
        }

        dispatch(setShowLoader(false));
    };

    const handleSaveClick = (vppId) => {
        const updatedPrice = editedPrice;
        const payload = {
            action: 'available-for-brand',
            projectId: projectId,
            videoId: videoId,
            clipSet: [{
                VppId: vppId,
                price: updatedPrice
            }]
        };
        awsApi.post('/postvideo', payload)
            .then(response => {
                console.log(`Updated data for VPP Frame VPO ${vppId}:`, response.data);
                fetchData();
            })
            .catch(error => {
                console.error(`Error updating data for VPO Frame No ${vppId}:`, error);
            });
        setEditIndex(null);
    };




    if (!newClipSet || !newClipSet.VppClips) {
        return <div>Loading data...</div>;
    }

    const data = newClipSet.VppClips;
    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    const pageData = data.slice(startIndex, endIndex);

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, pageData.length);

    const totalPages = Math.ceil(data.length / rowsPerPage);
    const pageNumbers = Array.from({ length: totalPages }, (_, index) => index + 1);

    const handlePageClick = (pageNumber) => {
        setPage(pageNumber);
        setCurrentPlayingIndex(null);
    };

    const maxPagesToShow = 5;
    const showEllipsis = totalPages > maxPagesToShow;

    const renderPageNumbers = () => {
        const pageNumbers = [];
        if (!showEllipsis) {
            // Show all pages if there are 5 or fewer pages
            for (let i = 1; i <= totalPages; i++) {
                pageNumbers.push(i);
            }
        } else {
            const visiblePages = [];

            // Determine the range of visible pages
            const startPage = Math.max(1, Math.min(page - 1, totalPages - maxPagesToShow + 1));
            const endPage = Math.min(startPage + maxPagesToShow - 1, totalPages);

            // Show the current page centered in the visible range
            const centeredPage = Math.min(startPage + Math.floor((endPage - startPage) / 2), totalPages);

            // Show ellipsis if the current page is not at the beginning
            if (startPage > 1) {
                visiblePages.push(1);
                if (startPage > 2) {
                    visiblePages.push(null); // Ellipsis
                }
            }

            // Show visible pages
            for (let i = startPage; i <= endPage; i++) {
                visiblePages.push(i);
            }

            // Show ellipsis if the current page is not at the end
            if (endPage < totalPages) {
                if (endPage < totalPages - 1) {
                    visiblePages.push(null); // Ellipsis
                }
                visiblePages.push(totalPages);
            }

            pageNumbers.push(...visiblePages);
        }

        return pageNumbers.map((number, index) => (
            <li key={index} className={`page-item ${page === number - 1 ? 'active' : ''}`}>
                {number === null ? (
                    <span className="page-link">...</span>
                ) : (
                    <a className="page-link" href="#" onClick={() => handlePageClick(number - 1)}>
                        {number}
                    </a>
                )}
            </li>
        ));
    };

    const calculateDurationInSeconds = (startTimeStr, endTimeStr) => {
        const [startHour, startMinute, startSecond] = startTimeStr.split(':').map(Number);
        const [endHour, endMinute, endSecond] = endTimeStr.split(':').map(Number);
        const startTime = new Date(1970, 0, 1, startHour, startMinute, startSecond);
        const endTime = new Date(1970, 0, 1, endHour, endMinute, endSecond);
        if (isNaN(startTime) || isNaN(endTime)) {
            return 'Invalid time format';
        }
        return (endTime - startTime) / 1000;
    };

    const NoDataFound = selectedRows.length === 0

    const videoDatabru = {
        "VppId": "8CEvmo4Bs3-8W0Ym9m-P",
        "VppFreamNo": 0,
        "VppClipUrl": "https://vi-video-collector-01.s3.ap-south-1.amazonaws.com/27+-+33+seconds_anantadi.mp4",
        "startTime": "0:0:27",
        "endTime": "0:0:33",
        "status": "checked",
        "Duration": "16",
        "price": "2000",
        "projectTitle": "Chaand Baaliyan",
        "brandCat": "Food and Beverages",
        "associationStatus": "fea8f11e-8639-4b70-84f9-06bd996115b0",
        "videoId": "069b74b4-ecd9-4c55-9a41-9d2c3b160a68",
        "label": "Coffee",
        "labelInsight": {
            "Categories": [
                {
                    "Name": "Food and Beverage"
                }
            ],
            "Confidence": 99.92768859863281,
            "Parents": [
                {
                    "Name": "Beverage"
                }
            ],
            "Aliases": [],
            "Name": "Coffee"
        }
    };

    const videoDataCoke = {
        "VppId": "Qh8zmo4B45lqvD3FfhOY",
        "VppFreamNo": 3,
        "VppClipUrl": "https://vi-video-collector-01.s3.ap-south-1.amazonaws.com/47+-+55+seconds_anantadi.mp4",
        "startTime": "0:0:47",
        "endTime": "0:0:55",
        "status": "checked",
        "Duration": "16",
        "price": "3000",
        "projectTitle": "Chaand Baaliyan",
        "brandCat": "Signage and Billboard",
        "associationStatus": "eb27afc1-4ddf-425a-a224-4be732de3c03",
        "videoId": "069b74b4-ecd9-4c55-9a41-9d2c3b160a68",
        "label": "Coffee",
        "labelInsight": {
            "Categories": [
                {
                    "Name": "Food and Beverage"
                }
            ],
            "Confidence": 99.92768859863281,
            "Parents": [
                {
                    "Name": "Beverage"
                }
            ],
            "Aliases": [],
            "Name": "Coffee"
        }
    };

    const dummyData = {
        "VppId": "4B45lqvD3Fudyd",
        "VppFreamNo": 1,
        "VppClipUrl": "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
        "startTime": "0:1:24",
        "endTime": "0:1:26",
        "status": "checked",
        "Duration": "16",
        "price": "25",
        "projectTitle": "Gaadi Kaali",
        "brandCat": "Clothing and Fashion",
        "associationStatus": "",
        "videoId": "e5b6eecb-eedd-48d4-90dd-2afb5a38f297",
        "label": "Party",
        "labelInsight": {
            "Aliases": [],
            "Categories": [{
                "Name": "Events and Attractions"
            }],
            "Confidence": 92.23836517333984,
            "Name": "Party",
            "Parents": [{
                "Name": "Fun"
            }]
        }
    };

    const handlePreviewDialog = (clip) => {
        if (clip.videoId === videoDatabru.videoId && clip.VppId === videoDatabru.VppId) {
            setSelectedClipData(videoDatabru);
        } else if (clip.videoId === videoDataCoke.videoId && clip.VppId === videoDataCoke.VppId) {
            setSelectedClipData(videoDataCoke);
        } else {
            setSelectedClipData(dummyData);
        }
        setPreviewDiloge(true);
    };

    let tooltipContent = '';
    if (Array.isArray(brandCat) && brandCat.length > 1) {
        tooltipContent = brandCat.slice(1).join(', ');
    }

    // console.log(clipsData)

    console.log('newClipSet', newClipSet)
    console.log('selectedRows', selectedRows)

    return (
        <>
            <Grid xs={12} mt={1} variant="G2C" display='flex' style={{ height: '400px' }} mb={1}>
                <Grid xs={pageType === 'market' ? 7 : 6} style={{ height: '400px', borderTopRightRadius: "0px", borderBottomRightRadius: "0px", borderRight: "1px solid #e0e0e0" }}>
                    <Grid xs={12} sx={{ width: '100%' }}>
                        <Grid sx={{ minHeight: "320px", maxHeight: "320px" }}>
                            <Table variant="T1" size="small" aria-label="a dense table">
                                <TableHead sx={{ height: "55px" }}>
                                    <TableRow >
                                        <TableCell variant="TH1">VPO No</TableCell>
                                        <TableCell variant="TH1">Brand Categories</TableCell>
                                        <TableCell variant="TH1">&nbsp;Start Time  <br />  (HH:MM:SS) </TableCell>
                                        <TableCell variant="TH1">Seconds</TableCell>
                                        <TableCell variant="TH1">Price ($)</TableCell>
                                        <TableCell variant="TH1">Play</TableCell>
                                        <TableCell variant="TH1">Sale</TableCell>
                                        {pageType === 'market' ? (
                                            <TableCell variant="TH1">VPO Preview</TableCell>
                                        ) : null}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {pageData &&
                                        pageData.map((clip, index) => {
                                            const brandCat = clip.brandCat;
                                            const categoryCounts = {};
                                            if (Array.isArray(brandCat) && brandCat.length > 1) {
                                                for (let i = 1; i < brandCat.length; i++) {
                                                    const category = brandCat[i];
                                                    categoryCounts[category] = (categoryCounts[category] || 0) + 1;
                                                }
                                            }

                                            const tooltipContent = Object.entries(categoryCounts)
                                                .sort(([catA], [catB]) => {
                                                    if (catA === brandCat[0]) return -1;
                                                    if (catB === brandCat[0]) return 1;
                                                    return 0;
                                                })
                                                .map(([category, count]) => {
                                                    return count > 1 ? `${category}` : category;
                                                })
                                                .join(', ');


                                            return (
                                                <TableRow key={clip.VppId} sx={{ maxHeight: "30px", background: clip.status === "checked" ? "#cdcdcd" : "none" }}>
                                                    <TableCell align='center'>{clip.VppId.substring(0, 3)}</TableCell>
                                                    <TableCell align='center' className='newMax_css'>
                                                        <CustomWidthTooltip title={tooltipContent} arrow >
                                                            <Chip
                                                                // label={Array.isArray(brandCat) ? brandCat[0] : ''}
                                                                label={(Array.isArray(brandCat) ? brandCat[0] : '') + (brandCat.length > 1 ? ' +' : '')}
                                                                size="small"
                                                                variant="outlined"
                                                                style={{ cursor: 'pointer',fontSize:"10px" }}
                                                            />
                                                        </CustomWidthTooltip>
                                                    </TableCell>

                                                    <TableCell align='center'>{clip.startTime}</TableCell>
                                                    <TableCell align='center'>{calculateDurationInSeconds(clip.startTime, clip.endTime)}</TableCell>
                                                    {/* <TableCell align='center'>{clip.price !== '' ? clip.price : '-'} </TableCell> */}
                                                    <TableCell align='center'>
                                                        {editIndex === index ? (
                                                            <>
                                                                <input type='number' value={editedPrice} onChange={handleInputChange} style={{
                                                                    width: "60px", height: "25px",
                                                                    border: "1px solid #cccccc", borderRadius: "8px"
                                                                }} />
                                                                <RiSave3Fill
                                                                    onClick={() => handleSaveClick(clip.VppId)}
                                                                    style={{ cursor: 'pointer', marginLeft: "5px", fontSize: "16px", color: "green" }}
                                                                />
                                                            </>
                                                        ) : (
                                                            <>
                                                                <span>{clip.price !== '' ? clip.price : '-'}</span>
                                                                {/* {clip.associationStatus !== '' && pageType === 'inventory' && (

                                                                <MdOutlineEdit onClick={() => handleEditClick(index)}
                                                                    style={{
                                                                        cursor: 'pointer', marginLeft: "5px", fontSize: "16px", color: "green"
                                                                    }}
                                                                />

                                                            )} */}
                                                                {clip.price > 0 && clip.associationStatus === '' && pageType === 'inventory' && (
                                                                    <MdOutlineEdit
                                                                        onClick={() => handleEditClick(index)}
                                                                        style={{
                                                                            cursor: 'pointer', marginLeft: "5px", fontSize: "16px", color: "green"
                                                                        }}
                                                                    />
                                                                )}
                                                            </>
                                                        )}

                                                    </TableCell>
                                                    <TableCell align='center'>
                                                        <IconButton
                                                            variant="IB0"
                                                            onClick={() => handlePlayClick(clip.VppId, index)}
                                                        >
                                                            {currentPlayingIndex === index ? <HiOutlinePause /> : <BsPlayCircle />}
                                                        </IconButton>
                                                    </TableCell>
                                                    <TableCell align='center'>
                                                        <Checkbox
                                                            disabled={(clip.status && clip.status === "checked") ? true : false}
                                                            checked={(clip.status && clip.status === "checked") ? true : selectedRows.includes(clip.VppId)}
                                                            onChange={() => handleBrandCheckboxChange(clip.VppId)}
                                                            inputProps={{ 'aria-label': 'controlled' }}
                                                            color="success"
                                                        />
                                                    </TableCell>
                                                    {pageType === 'market' ? (
                                                        <TableCell>
                                                            <TbScanEye style={{ color: "black", fontSize: "24px", cursor: "pointer", marginLeft: "15px" }} onClick={() => handlePreviewDialog(clip)} />
                                                        </TableCell>
                                                    ) : null}
                                                </TableRow>
                                            )
                                        })}
                                </TableBody>
                            </Table>
                        </Grid>

                        <Grid variant="G22" xs={12}>
                            <Grid xs={8} display="flex" justifyContent="space-between" >
                                <nav className="mt-3 ml-3">
                                    <ul className="pagination">
                                        <li className={`page-item ${page === 0 ? 'disabled' : ''}`}>
                                            <a className="page-link" href="#" onClick={() => handlePageClick(page - 1)}>
                                                {"Previous"}
                                            </a>
                                        </li>
                                        {renderPageNumbers()}
                                        <li className={`page-item ${page === totalPages - 1 ? 'disabled' : ''}`}>
                                            <a className="page-link" href="#" onClick={() => handlePageClick(page + 1)}>
                                                {"Next"}
                                            </a>
                                        </li>
                                    </ul>
                                </nav>
                            </Grid>
                            <Grid xs={4} display='flex' justifyContent='center' alignItems='center'>
                                {pageType === 'inventory' && (
                                    <Button
                                        variant="B3C"
                                        disabled={selectedRows.length === 0}
                                        sx={{ background: NoDataFound ? "#00000066" : 'black' }}
                                        onClick={() => setShowConfirmPopup(true)}
                                    >
                                        Available for Marketplace
                                        <IconButton aria-label="cart">
                                            <StyledBadge badgeContent={selectedRows.length > 0 && `${selectedRows.length}`} color="success" >
                                                <ShoppingCartIcon style={{ color: "white", fontSize: "27px" }} />
                                            </StyledBadge>
                                        </IconButton>
                                    </Button>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid xs={pageType === 'market' ? 5 : 6} style={{ minHeight: '400px' }} alignItems="center" >
                    <Grid xs={12} variant="G2" style={{ minHeight: '400px', borderTopLeftRadius: "0px", borderBottomLeftRadius: "0px", }} alignItems="center" justifyContent='center'>
                        <video width="100%" height="100%" controls ref={videoRef} style={{ background: "black" }} >
                            <source src={selectedClip} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </Grid>
                </Grid>

                <Dialog
                    open={showConfirmPopup}
                    onClose={handleCloseDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title" >
                        <Typography variant='P15' style={{ fontSize: '18px' }}>{"Please mention the price for Vpo in USD$"}</Typography>

                    </DialogTitle>

                    <DialogContent>
                        {selectedRows.map(vppId => (
                            <Grid style={{ borderTop: "1px solid black", borderBottom: "1px solid black" }}>
                                <div key={vppId} style={{ display: 'flex', justifyContent: "space-between", alignItems: "center", marginTop: "5px", marginBottom: "9px" }}>
                                    <Typography style={{ fontWeight: "bold", marginTop: "2px" }}>VPO No ({vppId.substring(0, 3)}) :-</Typography>
                                    <input
                                        type="number"
                                        style={{ marginTop: "5px", width: "50%", borderRadius: "4px", height: "35px", outline: "none" }}
                                        onChange={(e) => setInputPrices(prevState => ({ ...prevState, [vppId]: e.target.value }))}
                                    />

                                </div>
                            </Grid>
                        ))}
                        {showEmptyInputWarning && (
                            <p style={{ color: 'red' }}>
                                Please fill in all input fields for VPO.
                            </p>
                        )}
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={() => handleCloseDialog()} variant="outlined" color="error">
                            Close
                        </Button>
                        <Button onClick={handleConfirmClick} autoFocus variant="outlined" color="success">
                            Agree
                        </Button>
                    </DialogActions>
                </Dialog>
                <Preview
                    setPreviewDiloge={setPreviewDiloge}
                    previewDiloge={previewDiloge}
                    clipData={selectedClipData}
                />
            </Grid>
            <Loader />
        </>
    );
};

export default VppTabel;
