
import React, { useEffect, useRef, useState } from 'react';
import { Grid, Button, Typography, Paper, Box, Card, Dialog, DialogActions, DialogContent, DialogTitle, } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import NoData from './../CommonComponet/NoData';
import { NoDataUpload } from '../../ThemeProvider/style';
import uplodIcon from '../../assets/uplodIcon.jpg'
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import Loader from '../CommonComponet/Loader'
import { setShowLoader } from '../../redux/loaderSlice';
import Campaigns from './../../Pages/Campaigns';
import { optimusApi } from '../../Routes/Server';
import download from 'downloadjs'; // You can use a library like download.js for downloading files
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import pdfMake from 'pdfmake/build/pdfmake';
import vfsFonts from 'pdfmake/build/vfs_fonts';
import jsPDF from 'jspdf';

function parseTimeToSeconds(timeString) {
    const [hours, minutes, seconds] = timeString.split(':').map(Number);
    return hours * 3600 + minutes * 60 + seconds;
}

const TabSwitchCreative = ({ link, startTime, type, uploadFunctionbrand, selectedProject, handleClose, label, handleGeneratePdf, handleUpdateMessages, handleComment, pageType }) => {

    const reportTemplateRef = useRef(null);
    const videoRef = useRef(null);
    const dispatch = useDispatch()

    const campaignId = selectedProject.id

    const [dialogOpen, setDialogOpen] = useState(false);

    pdfMake.vfs = vfsFonts.pdfMake.vfs;

    pdfMake.styles = {
        defaultStyle: {
            fontSize: 12,
            margin: [0, 0, 0, 0],
        },
    };

    useEffect(() => {
        if (link && startTime && videoRef.current) {
            const seconds = parseTimeToSeconds(startTime);
            videoRef.current.currentTime = seconds;
            videoRef.current.play();
        }
    }, [link, startTime]);

    console.log("link", link)
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const [videoDetails, setVideoDetails] = useState(null);
    const [updateVppVideo, setUpdateVppVideo] = useState(false)

    useEffect(() => {
        if (link && startTime && videoRef.current) {
            const seconds = parseTimeToSeconds(startTime);
            videoRef.current.currentTime = seconds;
            videoRef.current.play();
        }
    }, [link, startTime]);

    const onDrop = (acceptedFiles) => {
        const file = acceptedFiles[0];
        if (file && file.type.includes('video')) {
            setSelectedFile(file);
            setUploadSuccess(false);
        }
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: 'video/*',
        maxFiles: 1,
    });

    console.log('TabSwitchCreative', selectedProject)

    const uploadFunctionUnbrand = async (file) => {
        const formData = new FormData();
        formData.append('file', file);
        const localFileIndex = uuidv4();
        formData.append('uploadType', `campaign-${type}-asset-upload`);
        formData.append('id', campaignId);
        try {
            dispatch(setShowLoader(true));
            const response = await optimusApi.post('/upload-asset', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            const videoDetails = {
                videoId: localFileIndex,
                videoUrl: response.data.Location,
                s3Meta: response.data,
            };
            await handleUpdateMessages()
            setVideoDetails(videoDetails);
            setUpdateVppVideo(true)
            setUploadSuccess(true)
        } catch (error) {
            console.error('Upload error', error);
        } finally {
            dispatch(setShowLoader(false));
        }
    }

    const handleUpload = () => {
        dispatch(setShowLoader(true));
        if (selectedFile) {
            const uploadPromise = type === 'unbranded' ? uploadFunctionUnbrand(selectedFile) : uploadFunctionUnbrand(selectedFile);
            uploadPromise
                .then(() => {
                    setUploadSuccess(true);
                    setSelectedFile(null);
                    setUpdateVppVideo(true)
                    handleUpdateMessages()
                    
                })
                .catch((error) => {
                    console.error('Error uploading file:', error);
                });
        }
        dispatch(setShowLoader(false));
    };

    console.log('videoDetails', videoDetails)

    const handleDownloadVideo = () => {
        const videoFileName = `${selectedProject?.clipSet[0].projectTitle}_video.mp4`;
        download(link, videoFileName, 'video/mp4');
    };


    const handleSaveData = () => {
        const dataToSave = {
            'Project Title': selectedProject?.clipSet[0].projectTitle,
            'Start Time': selectedProject?.clipSet[0].startTime,
            'End Time': selectedProject?.clipSet[0].endTime,
            'Status': label,
            'Category': selectedProject?.clipSet[0].category,
        };

        const jsonData = JSON.stringify(dataToSave, null, 2);
        const jsonBlob = new Blob([jsonData], { type: 'application/json' });
        // Save JSON file
        FileSaver.saveAs(jsonBlob, `${selectedProject?.clipSet[0].projectTitle}_data.json`);

        handleGeneratePdf();
        // // Create PDF file
        // const formattedText = Object.entries(dataToSave)
        //     .map(([key, value]) => `${key}: ${value}`)
        //     .join('\n');
        // const pdfDoc = pdfMake.createPdf({
        //     content: [{ text: formattedText, style: 'defaultStyle' }],
        // });
        // pdfDoc.getBlob((blob) => {
        //     // Save PDF file
        //     FileSaver.saveAs(blob, `${selectedProject?.clipSet[0].projectTitle}_data.pdf`);
        // });

        // Create Excel file
        // const ws = XLSX.utils.json_to_sheet([dataToSave]);
        // const wb = XLSX.utils.book_new();
        // XLSX.utils.book_append_sheet(wb, ws, 'DataSheet');
        // // Fix: Use XLSX.write instead of XLSX.write
        // const excelBlob = XLSX.write(wb, { bookType: 'xlsx', mimeType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        // FileSaver.saveAs(new Blob([excelBlob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }), `${selectedProject?.clipSet[0].projectTitle}_data.xlsx`);

        const csvData = Object.entries(dataToSave)
            .map(([key, value]) => `${key}: ${value}`)
            .join('\n');
        const blob = new Blob([csvData], { type: 'text/csv' });
        const a = document.createElement('a');
        a.href = window.URL.createObjectURL(blob);
        a.download = `${selectedProject?.clipSet[0].projectTitle}_data.csv`;
        a.click();
        window.URL.revokeObjectURL(a.href);


    };



    if (type === 'original') {
        if (!link) {
            return (
                <Grid xs={12} variant="G2" style={{ minWidth: 650, minHeight: 335, maxHeight: 335, background: "white", borderRadius: '12px' }} alignItems="center" justifyContent='center'>
                    <NoData />
                </Grid>
            );
        }
        return (
            <>
                <Card sx={{ maxWidth: "100%", marginLeft: "10px", display: "flex", justifyContent: "center" }}>
                    <video width="100%" controls ref={videoRef} style={{ minWidth: 650, minHeight: 335, maxHeight: 335, background: "black", borderRadius: '12px' }}>
                        <source src={link} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </Card>
                <Grid variant="G13"
                    item
                    container
                    sx={{ height: "40px" }}
                >
                    <Button variant="B8" style={{ fontSize: "14px", marginRight: "10px" }} onClick={handleDownloadVideo}>
                        Download Video
                    </Button>
                    <Button variant="B8" style={{ fontSize: "15px", marginRight: "10px" }} onClick={handleSaveData}>
                        Save VPP Data
                    </Button>
                    <Button variant="B8" style={{ fontSize: "14px", marginRight: "10px" }} onClick={() => { handleComment(selectedProject) }}>Comment</Button>
                </Grid>

            </>

        );
    } else if (type === 'unbranded') {
        if (!link) {
            return (
                <Grid
                    xs={12}
                    variant="G2"
                    style={{ minHeight: '383px', background: 'white' }}
                    alignItems="center"
                    justifyContent="center"
                >
                    {uploadSuccess ? (
                        <Grid
                            xs={12}
                            style={{ background: 'white', border: "none" }}
                            alignItems="center"
                            justifyContent="center"
                            variant="G2"
                        >
                            <Typography variant="P15" >Thank you for uploading video!</Typography>
                        </Grid>
                    ) : (
                        <>
                            <Box>
                                <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <Paper
                                        elevation={1}
                                        sx={{
                                            border: "4px dashed #dfdfdf",
                                            cursor: "pointer",
                                            background: 'white',
                                            textAlign: 'center',
                                            width: "340px",
                                            height: "240px",
                                            p: 2,
                                        }}
                                    >
                                        {selectedFile ? (
                                            <>
                                                <video width='300px' height='150px' controls src={URL.createObjectURL(selectedFile)} style={{ marginBottom: '10px' }} />
                                                <Typography variant="P12">{selectedFile.name}</Typography>
                                            </>
                                        ) : (
                                            <Grid display='flex' flexDirection='column' xs={12} justifyContent='center' alignItems='center'>
                                                <NoDataUpload src={uplodIcon} alt="No Data" />
                                                <Typography variant='P12'>Drag & drop a video file here</Typography>
                                                <Typography variant=''>or click to select one</Typography>
                                            </Grid>
                                        )}
                                    </Paper>
                                </div>
                                <Box mt={3} textAlign="center">
                                    <Button variant="B10" onClick={handleUpload}>
                                        Upload Video
                                    </Button>
                                </Box>
                            </Box>
                            <Grid variant="G13"
                                item
                                container
                                sx={{ height: "55px" }}
                            >

                            </Grid>
                        </>
                    )}
                    <Loader />
                </Grid>
            );
        }
    } else if (type === 'branded') {
        if (!link) {
            return (
                <Grid
                    xs={12}
                    variant="G2"
                    style={{ minHeight: '383px', background: 'white' }}
                    alignItems="center"
                    justifyContent="center"
                >
                    {/* {pageType === 'newdraft' ? ( */}
                        <>
                            {uploadSuccess ? (
                                <Grid
                                    xs={12}
                                    style={{ background: 'white', border: "none" }}
                                    alignItems="center"
                                    justifyContent="center"
                                    variant="G2"
                                >
                                    <Typography variant="P15" >Thank you for uploading video!</Typography>
                                </Grid>
                            ) : (
                                <Box>
                                    <div {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        <Paper
                                            elevation={1}
                                            sx={{
                                                border: "4px dashed #dfdfdf",
                                                cursor: "pointer",
                                                background: 'white',
                                                textAlign: 'center',
                                                width: "340px",
                                                height: "240px",
                                                p: 2,
                                            }}
                                        >
                                            {selectedFile ? (
                                                <>
                                                    <video width='300px' height='150px' controls src={URL.createObjectURL(selectedFile)} style={{ marginBottom: '10px' }} />
                                                    <Typography variant="P12">{selectedFile.name}</Typography>
                                                </>
                                            ) : (
                                                <Grid display='flex' flexDirection='column' xs={12} justifyContent='center' alignItems='center'>
                                                    <NoDataUpload src={uplodIcon} alt="No Data" />
                                                    <Typography variant='P12'>Drag & drop a video file here</Typography>
                                                    <Typography variant=''>or click to select one</Typography>
                                                </Grid>
                                            )}

                                        </Paper>
                                    </div>
                                    <Box mt={3} textAlign="center">
                                        <Button variant="B10" onClick={handleUpload}>
                                            Upload Video
                                        </Button>
                                    </Box>
                                </Box>
                            )}
                        </>
                    {/* ) :
                        <NoData />
                    } */}



                    <Loader />
                </Grid>
            );
        }
    }

    return (
        <>
            <Card sx={{ maxWidth: "100%", marginLeft: "10px", display: "flex", justifyContent: "center" }}>
                <video width="100%" controls ref={videoRef} style={{ minWidth: 650, minHeight: 335, maxHeight: 335, background: "black", borderRadius: '12px' }}>
                    <source src={link} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </Card>
            {pageType === 'newdraft' && (
                <Grid variant="G13"
                    item
                    container
                    sx={{ height: "40px", }}
                >
                    <Button variant="B8"
                        sx={{ marginRight: '15px', fontSize: "14px", }} onClick={() => { setDialogOpen(true) }}>Re-Upload</Button>
                    <Button variant="B8" onClick={() => { handleComment(selectedProject) }} style={{ fontSize: "14px", }}>Comment</Button>
                    {/* <Button variant="B8" onClick={handleClose} >Cancel</Button> */}
                </Grid>
            )}

            <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} >
                <DialogTitle sx={{ display: "flex", justifyContent: "center" }}>
                    <Typography variant='CHead' style={{ fontSize: '18px' }}>{"Re-Upload Video"}</Typography>
                </DialogTitle>
                <DialogContent >
                    <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <Paper
                            elevation={1}
                            sx={{
                                border: "4px dashed #dfdfdf",
                                cursor: "pointer",
                                background: 'white',
                                textAlign: 'center',
                                width: "340px",
                                height: "240px",
                                p: 2,
                            }}
                        >
                            {selectedFile ? (
                                <>
                                    <video width='300px' height='150px' controls src={URL.createObjectURL(selectedFile)} style={{ marginBottom: '10px' }} />
                                    <Typography variant="P12">{selectedFile.name}</Typography>
                                </>
                            ) : (
                                <Grid display='flex' flexDirection='column' xs={12} justifyContent='center' alignItems='center'>
                                    <NoDataUpload src={uplodIcon} alt="No Data" />
                                    <Typography variant='P12'>Drag & drop a video file here</Typography>
                                    <Typography variant=''>or click to select one</Typography>
                                </Grid>
                            )}

                        </Paper>
                    </div>
                </DialogContent>
                <DialogActions sx={{ display: "flex", justifyContent: "center", marginBottom: '10px' }}>
                    <Box textAlign="center">
                        <Button variant="B10" onClick={handleUpload}>
                            Upload Video
                        </Button>
                    </Box>
                    <Button onClick={() => setDialogOpen(false)} variant="outlined" color="error" sx={{ marginLeft: '10px' }}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <Loader />
        </>

    );
};

export default TabSwitchCreative;
