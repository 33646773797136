import React, { useState } from 'react'
import { Chip, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea, CardActions, Grid, } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { addProjectId } from '../../redux/projectSlice';
import { useDispatch } from "react-redux";
import defaultThumbnail from '../../assets/NoVideo.png'
import { generateThumbnailURL } from '../../Routes/apiConfig';
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu';
import { IconButton } from '@mui/material';
import { BsThreeDotsVertical, BsFillFilePersonFill } from 'react-icons/bs';

const CampaignsCard = ({ contentType, projectTitle, campaignId, brandCatagory, dateOfCreation, liveDate, comment, cardType, brandLogo, project, rejectMassage,
    handleBrandClick, decideColor, handleComment, handelOpenPlayer, handelBarndedPlayer, handleRejectDialog, handleApproveClick,
    loger, viId }) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [anchorEl, setAnchorEl] = useState(null);

    const handleMenuClose = () => {
        setAnchorEl(null);
    };


    let videoId;

    const { label, color } = decideColor(project);

    const thumbnailURL = generateThumbnailURL(viId) || defaultThumbnail;

    const formatDate = (timestamp) => {
        const date = new Date(timestamp * 1000);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };


    return (
        <Card sx={{
            minWidth: 272, maxWidth: 272, margin: "1rem",
        }}>
            <CardActionArea sx={{
                '&:focus': {
                    outline: 'none',
                }
            }}>
                <CardMedia
                    component="img"
                    height="140"
                    image={thumbnailURL}
                    alt="green iguana"
                    onClick={() => { handelOpenPlayer(project) }}
                />
            </CardActionArea>
            <CardContent style={{ marginTop: "-5px", marginLeft: "-5px" }}>

                <Grid xs={12} display='flex' justifyContent='space-between' alignItems='center'>
                    {contentType && (
                        <Grid xs={11} display='flex' justifyContent='space-between' alignItems='center' mt={-0.5}>
                            <Typography variant='CHead' style={{ fontSize: "14px" }}>{contentType}</Typography>
                        </Grid>
                    )}
                    <Grid xs={1} mt={-0.5} ml={0.5}>
                        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose} align={'center'} menuButton={<IconButton><BsThreeDotsVertical size={18} /></IconButton>} transition>
                            {cardType === 'brand' && (
                                <>
                                    {project.clipSet.length > 0 && project.clipSet[0].branded ? (
                                        <>
                                            <MenuItem onClick={() => { handleApproveClick(project) }}>Approve</MenuItem>
                                            <MenuItem onClick={() => { handleRejectDialog(project) }}>Reject</MenuItem>
                                        </>
                                    ) : null}
                                </>
                            )}

                            {cardType === 'other' && (
                                <>
                                    <MenuItem onClick={() => { handleApproveClick(project) }}>Approve</MenuItem>
                                    <MenuItem onClick={() => { handleRejectDialog(project) }}>Reject</MenuItem>
                                </>
                            )}
                            {cardType === 'reject' && (
                                <>
                                    <MenuItem onClick={() => { handleApproveClick(project) }}>Restore</MenuItem>
                                    <MenuItem onClick={() => { handleRejectDialog(project) }}>Delete</MenuItem>
                                </>
                            )}

                            <MenuItem onClick={() => { handelBarndedPlayer(project) }}>Branded Scene</MenuItem>
                        </Menu>
                    </Grid>

                </Grid>


                {projectTitle && (
                    <Grid xs={12} display='flex' justifyContent='flex-start' alignItems='center' mt={0.2}>
                        <Typography variant='CHead' style={{ fontSize: "14px" }}>Project Title :&nbsp;</Typography>
                        <Grid variant='GV' style={{ fontSize: "14px" }}> {projectTitle} </Grid>
                    </Grid>
                )}
                {campaignId && (
                    <Grid xs={12} display='flex' justifyContent='flex-start' alignItems='center' mt={0.2}>
                        <Typography variant='CHead' style={{ fontSize: "14px" }}>Campaign Id :&nbsp;</Typography>
                        <Grid variant='GV' style={{ fontSize: "14px" }}> {campaignId} </Grid>
                    </Grid>
                )}
                {brandCatagory && (
                    <Grid xs={12} display='flex' justifyContent='flex-start' alignItems='center' mt={0.2}>
                        <Typography variant='CHead' style={{ fontSize: "14px" }}>Brand Catagory :&nbsp;</Typography>
                        <Grid variant='GV' style={{ fontSize: "14px" }}>{brandCatagory.length > 15 ? brandCatagory.substring(0, 15) + '...' : brandCatagory}</Grid>
                    </Grid>
                )}
                {rejectMassage && (
                    <Grid xs={12} display='flex' justifyContent='flex-start' alignItems='center' mt={0.2}>
                        <Typography variant='CHead' style={{ fontSize: "14px" }}>Starting Date :&nbsp;</Typography>
                        <Grid variant='GV' style={{ fontSize: "14px" }}>  {rejectMassage} </Grid>
                    </Grid>
                )}
                {dateOfCreation && (
                    <Grid xs={12} display='flex' justifyContent='flex-start' alignItems='center' mt={0.2}>
                        <Typography variant='CHead' style={{ fontSize: "14px" }}>Date Of Creation :&nbsp;</Typography>
                        <Grid variant='GV' style={{ fontSize: "14px" }}>{formatDate(dateOfCreation)}</Grid>
                    </Grid>
                )}

                {liveDate && (
                    <Grid xs={12} display='flex' justifyContent='flex-start' alignItems='center' mt={0.2}>
                        <Typography variant='CHead' style={{ fontSize: "14px" }}>Starting Date :&nbsp;</Typography>
                        <Grid variant='GV' style={{ fontSize: "14px" }}>  {liveDate} </Grid>
                    </Grid>
                )}
                <Grid xs={12} display='flex' justifyContent='flex-start' alignItems='center' mt={0.3}>
                    <Grid item container xs={7}>
                        <Grid item xs={12}>
                            <Typography variant='CHead' style={{ fontSize: "14px" }}>
                                {'Brand Creative'}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} mt={0.5}>
                            <div style={{ display: "flex", flexDirection: 'column', alignItems: 'flex-start' }} onClick={() => { handleBrandClick(project) }}>
                                <img
                                    src={brandLogo && brandLogo || 'https://www.ultimatesource.toys/wp-content/uploads/2013/11/dummy-image-landscape-1.jpg'}
                                    alt="Image"
                                    style={{ maxWidth: '100px', maxHeight: '40px', height: 'auto', width: 'auto', cursor: 'pointer' }}
                                />
                                {loger === 'brand' && (!brandLogo || !brandLogo) && (
                                    <span style={{ color: "blue", marginTop: "3px", cursor: 'pointer' }}>Creative Tag</span>
                                )}
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                {label && (
                    <Grid xs={12} display='flex' justifyContent='flex-start' alignItems='center' mt={0.2}>
                        <Typography variant='CHead' style={{ fontSize: "14px" }}>Status :&nbsp;</Typography>
                        <Grid variant='GV' style={{ fontSize: "14px" }}>
                            <Chip
                                label={label.length > 22 ? label.substring(0, 22) + '...' : label}
                                color={color}
                                variant="outlined"
                                size="small"
                                sx={{ minWidth: '100px', maxWidth: '180px', height: '25px', marginLeft: "3px" }}
                            />
                        </Grid>
                    </Grid>
                )}
                {comment && comment.commentsBrand && comment.commentsBrand.length > 0 && (
                    <Grid xs={12} display='flex' justifyContent='flex-start' alignItems='flex-start' mt={0.2}>
                        <Typography variant='CHead' style={{ fontSize: "14px" }}>Comment&nbsp;: &nbsp;</Typography>
                        <Grid variant='GV' style={{ fontSize: "14px", color: "blue", textDecoration: "underline", cursor: "pointer" }} onClick={() => { handleComment(project) }}>
                            {comment.commentsBrand[comment.commentsBrand.length - 1].meassage}
                        </Grid>
                    </Grid>
                )}
            </CardContent>
        </Card>


    )
}

export default CampaignsCard
