import * as React from "react";
import { Grid, Paper, Typography, Button, Collapse, Card, CardMedia } from "@mui/material";
import { IoFilterSharp } from "react-icons/io5";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { IoMdArrowBack } from 'react-icons/io'
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import TopBar from '../../Components/ProjectComponet/TopBar'
import SubContainer from "./SubContainer";
import { useDispatch } from "react-redux";
import { setShowLoader } from '../../redux/loaderSlice';
import { AiOutlineEdit } from 'react-icons/ai'
import { CgDetailsMore } from 'react-icons/cg'
import CustomTextfield from "../CommonComponet/CustomTextfield";
import EditMode from "./EditMode";
import defaultThumbnail from '../../assets/NoVideo.png'
import Loader from "../CommonComponet/Loader";
import { generateThumbnailURL } from "../../Routes/apiConfig";
import { MdArrowForwardIos } from 'react-icons/md'
import { awsApi } from "../../Routes/Server";
import { brandCategoryOptions } from "../CommonComponet/docsData";

const ViewProject = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const ComponentName = 'Project'
    const childComponentName = "View Project"

    const handleNavigate = () => {
        navigate('/ProjectList');
    };



    const [deatilsView, setDeatilsView] = useState(true);
    const [editView, setEditView] = useState(false);

    const projectById = useSelector(state => state.project.projectId);
    // console.log(projectById)

    const [project, setProject] = useState(null);
    const [videos, setVideos] = useState([]);

    useEffect(() => {
        if (projectById) {
            dispatch(setShowLoader(true));
            const fetchProjectData = async () => {
                try {
                    const response = await awsApi.post('/getprojects', { id: projectById });

                    if (response.data.statusCode === 200) {
                        const data = JSON.parse(response.data.body);
                        const projectData = data.Item;
                        setProject(projectData);
                        setVideos(projectData.video);
                    }
                } catch (error) {
                    console.error('Error fetching project data:', error);
                } finally {
                    dispatch(setShowLoader(false));
                }
            };
            fetchProjectData();
        }
    }, [projectById, setProject, dispatch]);

    const updatedData = () => {
        dispatch(setShowLoader(true));
        if (projectById) {
            const fetchProjectData = async () => {
                try {
                    const response = await awsApi.post('/getprojects', { id: projectById });
                    if (response.data.statusCode === 200) {
                        const data = JSON.parse(response.data.body);
                        const projectData = data.Item;
                        setProject(projectData);
                        setVideos(projectData.video);
                    }
                } catch (error) {
                    console.error('Error fetching project data:', error);
                } finally {
                    dispatch(setShowLoader(false));
                }
            };
            fetchProjectData();
        }
    }

    useEffect(() => {
        console.log();
        // console.log('dahsdfjasf', videos);
    }, [project, videos]);


    const videoId = (project && project.video[0].videoId) || defaultThumbnail;
    const thumbnailURL = generateThumbnailURL(videoId);
    console.log(project)

    const mapCategoryToLabel = (category) => {
        const foundCategory = brandCategoryOptions.find(option => option.value === category);
        return foundCategory ? foundCategory.label : 'N/A';
    };

    return (
        <Grid container xs={12} mt={7} sx={{ overflowX: "hidden" }}>
            <Grid xs={12} display='flex' justifyContent='space-between' alignItems='center'>

                <Grid xs={6} >
                    <Typography>
                        <Typography variant='P2' style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={handleNavigate}>{ComponentName}</Typography> <MdArrowForwardIos style={{ fontSize: '17px', marginTop: "-7px" }} />&nbsp;
                        <Typography variant='P2N' >{project && project.title}</Typography>
                    </Typography>
                </Grid>
                <Button variant='B1' onClick={() => { handleNavigate() }} style={{ fontSize: "15px", width: "80px" }}>Back</Button>

            </Grid>
            <Grid container xs={12} variant='G1' mt={3} >
                <Grid
                    container
                    item
                    xs={12}
                >
                    <Paper
                        variant="outlined"
                        style={{ width: "100%", boxShadow: "0px 1px 3px #364A6305" }}
                    >
                        <Grid
                            container
                            xs={12}
                            display='flex'

                        >
                            <Grid xs={3.5} display='flex' flexDirection='row' container sx={{ borderRight: "1px solid #DBDFEA" }}>
                                <Grid xs={12} sx={{ borderBottom: "1px solid #DBDFEA" }}>
                                    <Card sx={{ width: "94%", margin: "3%" }}>
                                        <CardMedia
                                            component="img"
                                            height="280"
                                            image={thumbnailURL}
                                            alt="green iguana"
                                        />
                                    </Card>
                                </Grid>
                                <Grid xs={12} >
                                    <Grid item xs={12} sx={{ margin: "15px", marginTop: "0px" }}>
                                        <Grid mt={2}>
                                            <Typography variant='CHead'>Project Title</Typography> : &nbsp;
                                            <Typography variant='GV'>{project && project.title}</Typography>
                                        </Grid>
                                        <Grid mt={2}>
                                            <Typography variant='CHead'>Platform</Typography> : &nbsp;
                                            <Typography variant='GV'>{project && project.releasePlatform && project.releasePlatform.length > 0 ? project.releasePlatform.join(', ') : 'N/A'}</Typography>
                                        </Grid>
                                        <Grid mt={2}>
                                            <Typography variant='CHead'>Type of Content</Typography> : &nbsp;
                                            <Typography variant='GV'>{project && project.contentType && (
                                                <>{project.contentType.charAt(0).toUpperCase() + project.contentType.slice(1)}</>
                                            )}</Typography>
                                        </Grid>
                                        <Grid container direction='row'>
                                            <Grid item xs={6}>
                                                <Grid variant='GH'>
                                                    <Typography variant='CHead'>Genre : &nbsp;</Typography>
                                                </Grid>
                                                <Grid variant='GV'>
                                                    {project && project.genres && project.genres.length > 0 ? project.genres.join(', ') : 'N/A'}
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={6}>

                                                <Grid variant='GH'>
                                                    <Typography variant='CHead'>Views : &nbsp;</Typography>
                                                </Grid>
                                                <Grid variant='GV'>
                                                    {project && project.averageViewPerMonth}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid container direction='row'>
                                            {/* <Grid item xs={6}>

                                                <Grid variant='GH'>
                                                    <Typography variant='CHead'> Predicted Audience</Typography>
                                                </Grid>
                                                <Grid variant='GV'>
                                                    {project && project.audience}
                                                </Grid>
                                            </Grid> */}
                                            <Grid item xs={12}>
                                                <Grid variant='GH'>
                                                    <Typography variant='CHead'>Brand Category : &nbsp;</Typography>
                                                </Grid>
                                                <Grid variant='GV'>
                                                    {project && project.brandCategory && project.brandCategory.length > 0 ?
                                                        project.brandCategory.map(category => mapCategoryToLabel(category)).join(', ')
                                                        : 'N/A'}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid xs={8.5} container>
                                <Grid xs={12} display='flex' flexDirection='column'>
                                    <Grid xs={12} variant='Head' display='flex' justifyContent='space-between' alignItems='center'>
                                        <Grid xs={6} variant='Head'>Project Details</Grid>
                                        {deatilsView === true ? (
                                            <Button
                                                variant="B5"
                                                onClick={() => {
                                                    setDeatilsView(false);
                                                    setEditView(true);
                                                }}
                                            >
                                                <AiOutlineEdit />
                                            </Button>
                                        ) : (
                                            <Button
                                                variant="B5"
                                                onClick={() => {
                                                    setDeatilsView(true);
                                                    setEditView(false);
                                                }}
                                            >
                                                <CgDetailsMore />
                                            </Button>
                                        )}
                                    </Grid>
                                    {deatilsView &&
                                        <>
                                            <Grid xs={12} variant='Table'>
                                                <Grid variant='body' xs={5.8} >
                                                    <Grid variant='GL' item xs={6} ml={2}>
                                                        Stage :
                                                    </Grid>
                                                    <Grid variant='GR' item xs={6}>
                                                        {project && project.stage}
                                                    </Grid>

                                                </Grid>
                                                <Grid xs={0.4}></Grid>
                                                <Grid variant='body' xs={5.8} >
                                                    <Grid variant='GL' item xs={6} ml={2}>
                                                        Episodes :
                                                    </Grid>
                                                    <Grid variant='GR' item xs={6}>
                                                        {videos.length}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid variant='Table' xs={12}>
                                                <Grid variant='body' xs={5.8} >
                                                    <Grid variant='GL' item xs={6} ml={2}>
                                                        Distribution Channel :
                                                    </Grid>
                                                    <Grid variant='GR' item xs={6}>
                                                        {project && project.distributionChannel}
                                                    </Grid>
                                                </Grid>
                                                <Grid xs={0.4}></Grid>
                                                <Grid variant='body' xs={5.8} >
                                                    <Grid variant='GL' item xs={6} ml={2}>
                                                        Country :
                                                    </Grid>
                                                    <Grid variant='GR' item xs={6}>
                                                        {project && project.country}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid variant='Table' xs={12}>
                                                <Grid variant='body' xs={5.8} >
                                                    <Grid variant='GL' item xs={6} ml={2}>
                                                        Trailer :
                                                    </Grid>
                                                    <Grid variant='GR' item xs={6} sx={{ cursor: 'pointer' }}>
                                                        <Link href={project && project.trailer} color="inherit">
                                                            {project && project.trailer}
                                                        </Link>
                                                    </Grid>

                                                </Grid>
                                                <Grid xs={0.4}></Grid>
                                                <Grid variant='body' xs={5.8} >
                                                    <Grid variant='GL' item xs={6} ml={2}>
                                                        Director :
                                                    </Grid>
                                                    <Grid variant='GR' item xs={6}>
                                                        {project && project.director}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid variant='Table' xs={12}>
                                                <Grid variant='body' xs={5.8} >
                                                    <Grid variant='GL' item xs={6} ml={2}>
                                                        Presentation :
                                                    </Grid>
                                                    <Grid variant='GR' item xs={6} sx={{ cursor: 'pointer' }}>
                                                        <Link href={project && project.presentation} color="inherit">
                                                            {project && project.presentation}
                                                        </Link>
                                                    </Grid>
                                                </Grid>
                                                <Grid xs={0.4}></Grid>
                                                <Grid variant='body' xs={5.8} >
                                                    <Grid variant='GL' item xs={6} ml={2}>
                                                        Producer :
                                                    </Grid>
                                                    <Grid variant='GR' item xs={6}>
                                                        {project && project.producer}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid variant='Table' xs={12}>
                                                <Grid variant='body' xs={5.8} >
                                                    <Grid variant='GL' item xs={6} ml={2} sx={{ cursor: 'pointer' }}>
                                                        Teaser :
                                                    </Grid>

                                                    <Grid variant='GR' item xs={6} sx={{ cursor: 'pointer' }}>
                                                        <Link href={project && project.teaser} color="inherit">
                                                            {project && project.teaser}
                                                        </Link>
                                                    </Grid>
                                                </Grid>
                                                <Grid xs={0.4}></Grid>
                                                <Grid variant='body' xs={5.8} >
                                                    <Grid variant='GL' item xs={6} ml={2}>
                                                        Casts :
                                                    </Grid>
                                                    <Grid variant='GR' item xs={6}>
                                                        {project && project.casts}
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                        </>
                                    }
                                    {editView &&
                                        <EditMode
                                            projectById={projectById}
                                            setDeatilsView={setDeatilsView}
                                            setEditView={setEditView}
                                            project={project}
                                            updatedData={updatedData}
                                        />
                                    }
                                    <Grid variant='Head' xs={12}>Assessment Details by (Admin/ Vfx Team)</Grid>
                                    <Grid variant='Table' xs={12}>
                                        <Grid variant='body' xs={5.8} >
                                            <Grid variant='GL' item xs={6} ml={2}>
                                                Embedded Inventory :
                                            </Grid>
                                            <Grid variant='GR' item xs={6}>
                                                {/* {project && project.stage} */}
                                            </Grid>
                                        </Grid>
                                        <Grid xs={0.4}></Grid>
                                        <Grid variant='body' xs={5.8} >
                                            <Grid variant='GL' item xs={6} ml={2}>
                                                Average Seconds :
                                            </Grid>
                                            <Grid variant='GR' item xs={6}>
                                                {/* {project && project.seasonCount} */}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid variant='Table' xs={12}>
                                        <Grid variant='body' xs={5.8} >
                                            <Grid variant='GL' item xs={6} ml={2}>
                                                Product Placement :
                                            </Grid>
                                            <Grid variant='GR' item xs={6}>
                                                {/* {project && project.title} */}
                                            </Grid>
                                        </Grid>
                                        <Grid xs={0.4}></Grid>
                                        <Grid variant='body' xs={5.8} >
                                            <Grid variant='GL' item xs={6} ml={2}>
                                                Signage Placement :
                                            </Grid>
                                            <Grid variant='GR' item xs={6}>
                                                {/* {project && project.episodeCount} */}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
            {videos && videos.length > 0 && (
                <SubContainer
                    name={project && project.title}
                    videos={videos}
                    project={project}
                    id={project && project.id}
                    genre={project && project.genres && project.genres.length > 0 ? project.genres.join(', ') : 'N/A'}
                    season={project && project.season}
                    projectTitle={project && project.title}
                    contentType={project && project.contentType}
                />
            )}
            <Loader />
        </Grid>
    )
}

export default ViewProject
