import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import { FiLogOut } from 'react-icons/fi';
import { GrLock } from 'react-icons/gr';
import { RxPerson } from 'react-icons/rx';
import ChangePassword from './ChangePassword';
import { useNavigate } from 'react-router-dom';
import { AvatarWrapper } from '../../ThemeProvider/style'
import { setShowLoader } from '../../redux/loaderSlice';
import { useDispatch } from 'react-redux';
import Loader from '../CommonComponet/Loader';
import { awsApiTwo } from '../../Routes/Server';


export default function AccountMenu(props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [openSet, setOpenSet] = useState(false);

    const handleClickOpen = () => {
        setOpenSet(true);
    };

    const handleClose = () => {
        props.setAnchorEl(null);
    };

    const handleNavigate = () => {
        navigate('/Profile');
    };

    const handleLogout = async () => {
        dispatch(setShowLoader(true));
        const user = JSON.parse(localStorage.getItem("data"));
        const accessToken = user.AccessToken;
        const headers = {
            "Content-Type": "application/json;charset=utf-8",
            authtoken: `${accessToken}`
        }
        try {
            let res = await awsApiTwo.get('/logout', { headers });
            if (res.data.statusCode === 200) {
                console.log(res.data.body);
                localStorage.removeItem('data');
                localStorage.removeItem('user');
                window.location.href = "/";
            }
        } catch (error) {
            console.log(error);
        } finally {
            dispatch(setShowLoader(false));
        }
    }


    return (
        <>
            <Menu
                anchorEl={props.anchorEl}
                id="account-menu"
                open={props.open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                {props.userData && (
                    <MenuItem variant='M1' onClick={handleClose}>
                        <AvatarWrapper>
                            <Avatar src={props.userData.imgUrl} sx={{ width: 38, height: 38 }}></Avatar>
                        </AvatarWrapper>
                        <div>
                            <Typography variant="body1" component="span" sx={{ fontWeight: 'bold', color: 'black' }}>
                                {props.userData.name}
                            </Typography>
                            <br />
                            <Typography variant="body1" component="span" sx={{ color: '#526484', fontSize: '14px' }}>
                                {props.userData.email}
                            </Typography>
                        </div>
                    </MenuItem>
                )}
                <MenuItem variant='M2' onClick={handleNavigate}>
                    <ListItemIcon>
                        <RxPerson fontSize="small" />
                    </ListItemIcon>
                    View Profile
                </MenuItem>
                <MenuItem variant='M2' onClick={handleClickOpen}>
                    <ListItemIcon>
                        <GrLock fontSize="small" style={{ color: '#8094AE' }} />
                    </ListItemIcon>
                    Change Password
                </MenuItem>
                <MenuItem variant='M2' sx={{ borderTop: '1px solid #e5e9f2' }} onClick={handleLogout}>
                    <ListItemIcon>
                        <FiLogOut fontSize="small" />
                    </ListItemIcon>
                    Sign out
                </MenuItem>
            </Menu>
            <ChangePassword setOpenSet={setOpenSet} openSet={openSet} />
            <Loader/>
        </>
    );
}
