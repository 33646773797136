import React, { useEffect, useState } from 'react'
import { Typography, Chip, Grid } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea, CardActions } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { addProjectId } from '../../redux/projectSlice';
import { useDispatch } from "react-redux";
import { VideoSettings } from '@mui/icons-material';
import defaultThumbnail from '../../assets/NoVideo.png'
import { generateThumbnailURL } from '../../Routes/apiConfig';


const ListCard = ({ project, category, title, description, thumbnail, contentId, seasonCount, episodeCount, time, id, genres, totalVpo, onClick, videoSubId, page, contentType }) => {

    const [types, setTypes] = useState(true)

    let videoId;

    if (videoSubId) {
        videoId = videoSubId;
    } else if (project?.video && project.video.length > 0) {

        videoId = project.video[0]?.videoId;
    } else {
        videoId = null;
    }
    useEffect(() => {
        if (page === "viewProject") {
            setTypes(false);
        }
    }, [page]);


    const checkProcessingStatus = () => {
        if (project?.video && project.video.length > 0) {
            const allStarted = project.video.every(video => video.processingStatus === 'STARTED');
            const allCompleted = project.video.every(video => video.processingStatus === 'COMPLETED');

            if (allStarted) {
                return (
                    <Chip variant='MC1' label="Processing" color="warning" />
                );
            } else if (allCompleted) {
                return (
                    <Chip variant='MC1' label="Analyzed" color="success" />
                );
            }
        }
        return null;
    };

    const thumbnailURL = generateThumbnailURL(videoId) || defaultThumbnail;

    const isSingleCameraNoMovement = project?.vppType === "single_camera_and_no_movement";

    return (
        <Card sx={{
            minWidth: 272, maxWidth: 272, margin: "1rem",
        }}>
            <CardActionArea sx={{
                '&:focus': {
                    outline: 'none',
                }

            }}  >
                <CardMedia
                    component="img"
                    height="140"
                    image={thumbnailURL}
                    alt="green iguana"
                    onClick={() => { onClick(contentId) }}
                />

            </CardActionArea>
            <CardContent>
                <Grid xs={12} display='flex' justifyContent='space-between' alignItems='center' mt={-0.5}>
                    <Grid xs={7}>
                        {/* <Typography
                            variant="P13"
                            color="#526484"
                            fontWeight={'500'}
                            
                        >
                            {category}
                        </Typography> */}
                        <Typography variant='CHead' > {category}</Typography>
                    </Grid>
                    <Grid xs={5} >
                        {checkProcessingStatus()}
                    </Grid>

                </Grid>
                <Grid xs={12} display='flex' justifyContent='flex-start' alignItems='center' mt={0.5}>
                    {types ? (
                        <Typography variant='CHead' style={{ fontSize: "15px" }}>Project Title :&nbsp;</Typography>
                    ) : (
                        <Typography variant='CHead' style={{ fontSize: "15px" }}>Video Title :&nbsp;</Typography>
                    )}


                    <Grid variant='GV'>
                        {title.length > 13 ? `${title.substring(0, 13)}...` : title}
                    </Grid>
                </Grid>
                {/* <Grid xs={12} display='flex' justifyContent='space-between' alignItems='center' mt={0.5}>
                    <Typography
                        variant="body2"
                        color="#526484"
                        fontWeight={'500'}
                    >
                        {description}
                    </Typography>
                </Grid> */}

                <Grid xs={12} display='flex' justifyContent='flex-start' alignItems='center' >
                    <Grid xs={3} >
                        <Typography variant='CHead' style={{ fontSize: "15px" }}>Genre :&nbsp;</Typography>
                    </Grid>
                    <Grid xs={9} style={{ textAlign: 'left', overflow: 'hidden' }} >
                        <div style={{
                            width: "100%", fontWeight: 'medium',
                            fontSize: '16px',
                            color: "#526484",
                            textAlign: 'left', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap',
                        }}>
                            {page === 'viewProject' ? (
                                genres
                            ) : (
                                genres && genres.length > 0 ? genres.join(', ') : 'N/A'
                            )}
                            {/* {genres && genres.length > 0 ? genres.join(', ') : 'N/A'} */}
                        </div>
                    </Grid>


                </Grid>



            </CardContent>
            <CardActions>


                <Grid container mt={-5} spacing={2} style={{ padding: '0.5rem' }}>
                    {/* {seasonCount !== undefined && (
                        <Grid item container xs={3.5}>
                            <Grid item xs={12}>
                                <Typography fontSize={'0.8rem'} fontWeight={'bold'}>
                                    {'Seasons'}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid item xs={12} mt={0.5}>
                                    <Typography style={{
                                        fontSize: '0.8rem',
                                        color: "#8094ae"
                                    }}>
                                        {seasonCount}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    )} */}
                    {!isSingleCameraNoMovement && episodeCount !== undefined && (
                        <Grid item container xs={6}>
                            <Grid item xs={12}>
                                <Typography fontSize={'0.8rem'} fontWeight={'bold'}>
                                    {'Videos'}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} mt={0.5}>
                                <Typography style={{
                                    fontSize: '1rem',
                                    color: "#8094ae"
                                }}>
                                    {episodeCount}
                                </Typography>
                            </Grid>
                        </Grid>
                    )}
                    {!isSingleCameraNoMovement && totalVpo !== undefined && (
                        <Grid item container xs={6}>
                            <Grid item xs={12}>
                                <Typography fontSize={'0.8rem'} fontWeight={'bold'}>
                                    {'No of VPP'}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} mt={0.5}>
                                <Typography style={{
                                    fontSize: '1rem',
                                    color: "#8094ae"
                                }}>
                                    {totalVpo}
                                </Typography>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </CardActions>

        </Card>

    )
}

export default ListCard
