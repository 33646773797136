import React, { useEffect, useRef, useState } from 'react';
import { Grid } from "@mui/material";

function parseTimeToSeconds(timeString) {
    const [hours, minutes, seconds] = timeString.split(':').map(Number);
    return hours * 3600 + minutes * 60 + seconds;
}

const VideoSec = ({ linkOne, likTwo, startTime, endTime, selectedProject }) => {
    const videoRef = useRef(null);
    const videoRefOne = useRef(null);
    const videoRefTwo = useRef(null);
    const [currentPlayingIndex, setCurrentPlayingIndex] = useState(null);

    useEffect(() => {
        if (linkOne && videoRefOne.current) {
            videoRefOne.current.play();
        }
    }, [linkOne]);

    return (
        <Grid xs={11.8} style={{ background: "#eff4fd", border: "1px solid #c8c8c8", borderRadius: "12px" }} display='flex' justifyContent='space-around' alignItems='center' mt={1}>
            <Grid xs={5.9} mt={1}>
                <video width='100%' controls ref={videoRefOne} style={{ minWidth: '100%', minHeight: 335, maxHeight: 335, background: "black", borderRadius: "12px" }} >
                    <source src={linkOne} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </Grid>
            <Grid xs={5.9} mt={1}>
               
                <video ref={videoRef} controls
                    style={{ minWidth: '100%', minHeight: 335, maxHeight: 335, background: "black", borderRadius: "12px" }}>
                    Your browser does not support the video tag.
                </video>
            </Grid>

        </Grid>
    )
}

export default VideoSec
