import React, { useState } from 'react'
import { Grid, Button } from '@mui/material'
import TabSwitchBrand from './TabSwitchBrand'

const PlayerTabBrand = ({ VppClipUrl, startTime, preApprovedUrl, brandedUrl, endTime, selectedProject, handleComment, handleUpdateMessages, type, handleRejectDialog, actionValue, handleApproveClick, pageType }) => {
    const [activeButton, setActiveButton] = useState(actionValue);

    const handleButtonClick = (buttonIndex) => {
        setActiveButton(buttonIndex);
    };


    return (
        <>
            <Grid xs={12} variant='G21-A'>
                <Button variant='Tab'
                    sx={{
                        marginLeft: '6px', borderLeft: '1px solid black',
                        color: activeButton === 1 ? 'white' : 'black',
                        background: activeButton === 1 ? '#657195' : 'white',
                    }} onClick={() => handleButtonClick(1)}
                >Original Scene</Button>

                {/* <Button variant='Tab'
                    sx={{
                        color: activeButton === 2 ? 'white' : 'black',
                        background: activeButton === 2 ? '#657195' : 'white',
                    }} onClick={() => handleButtonClick(2)}
                >Unbranded Scene</Button> */}

                <Button variant='Tab'
                    sx={{
                        color: activeButton === 3 ? 'white' : 'black',
                        background: activeButton === 3 ? '#657195' : 'white',
                    }} onClick={() => handleButtonClick(3)}
                >Branded Scene</Button>
            </Grid>
            <Grid xs={12} container mt={1}>
                {activeButton === 1 &&
                    <TabSwitchBrand link={VppClipUrl} startTime={startTime} endTime={endTime} selectedProject={selectedProject} handleComment={handleComment} activeButton={activeButton}
                        handleUpdateMessages={handleUpdateMessages} handleRejectDialog={handleRejectDialog}
                        handleApproveClick={handleApproveClick} type={type} pageType={pageType} />}
                {/* {activeButton === 2 &&
                    < TabSwitchBrand link={preApprovedUrl} startTime={startTime} endTime={endTime} selectedProject={selectedProject} handleComment={handleComment} activeButton={activeButton} 
                    handleUpdateMessages={handleUpdateMessages}/>} */}
                {activeButton === 3 &&
                    < TabSwitchBrand link={brandedUrl} startTime={startTime} endTime={endTime} selectedProject={selectedProject} handleComment={handleComment} activeButton={activeButton}
                        handleUpdateMessages={handleUpdateMessages} pageType={pageType} />}

            </Grid>
        </>
    )
}

export default PlayerTabBrand
