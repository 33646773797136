import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import { Box, Grid, Avatar } from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Link } from "react-router-dom";
import logo from '../assets/LogoTop.png'
import { useState } from "react";
// import routes from "../Routes/Routes";
import { useNavigate } from "react-router-dom";
import { MdMenuOpen } from 'react-icons/md'
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { AiOutlineBell } from 'react-icons/ai'
import { Details, Arrow, NameBar } from '../ThemeProvider/style'
import AccountMenu from "./ProfileComponet/AccountMenu";
import ProgressBar from "./CommonComponet/ProgressBar";
import { awsApiTwo, optimusApi } from "../Routes/Server";
import { setUserLoginData } from '../redux/userSlice';
import { addAppType } from '../redux/stateSlice'
import './sidebar.css'


const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
    overflowY: "hidden",
});


const closedMixin = (theme) => ({
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    overflowY: "hidden",

    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    boxShadow: " 0px 1px 0px #e0e0e0",
    transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,


        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",

    ...(open && {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme),
    }),
}));

export default function SideBar({ routes, setIsAuthenticated }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userId = useSelector(state => state.login.userId);
    const accessToken = useSelector((state) => state.login.accessToken);

    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const [isToggled, setIsToggled] = useState(false);
    const [buttonText, setButtonText] = useState('');
    const [buttonClass, setButtonClass] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await optimusApi.get('/update-data-source');

                if (response.status === 200) {
                    if (response.data.mode === 'live') {
                        setButtonText('Change to New User');
                        setButtonClass('off');
                        dispatch(addAppType('live'));
                    } else if (response.data.mode === 'demo') {
                        setButtonText('Change to Live');
                        setButtonClass('on');
                        dispatch(addAppType('demo'));
                    }
                } else {
                    console.error("Failed to retrieve data from API:", response.data.message);
                }
            } catch (error) {
                console.error("Error updating data source:", error);
            }
        };

        fetchData();
    }, [isToggled]);

    const fetchDataFromApi = async (mode) => {
        try {
            const headers = {
                "Content-Type": "application/json;charset=utf-8",
                authtoken: `${accessToken}`
            };
            const payload = { applicationMode: mode };
            const response = await optimusApi.post('/update-data-source', payload, { headers });
            if (response.status === 200) {
                const responseData = response.data;
                console.log("Data retrieved successfully:", responseData);
            } else {
                console.error("Failed to retrieve data from API:", response.data.message);
            }
        } catch (error) {
            console.error("Error fetching data from API:", error);
        }
    };




    const handleChange = () => {
        setIsToggled(!isToggled);
        const mode = isToggled ? 'live' : 'demo';
        fetchDataFromApi(mode);
    };




    useEffect(() => {
        const currentPath = window.location.pathname;
        const isVppMain = currentPath === "/VppMain";
        const isDashbord = currentPath === "/Dashboard";
        if (isVppMain) {
            setSelectedIndex(2);
        }
        if (isDashbord) {
            setSelectedIndex(0);
        }

    }, [window.location.pathname]);

    const [appTitle, setAppTitle] = useState(routes[0]?.name);

    console.log("Routes:", routes);

    const [open, setOpen] = React.useState(false);
    const [mouseHover, setMouseHover] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
        setMouseHover(false);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleMouseEnter = () => {
        if (!open && !mouseHover) {
            setOpen(true);
            setMouseHover(true);
        }
    };

    const handleMouseLeave = () => {
        if (mouseHover) {
            setMouseHover(false);
            setOpen(false);
        }
    };

    const handleItemClick = (index) => {
        if (index < routes?.length) {
            setAppTitle(routes[index]?.name);
            navigate(routes[index]?.path);
            setSelectedIndex(index);
        }
    };

    const [anchorEl, setAnchorEl] = useState(null);
    const openMenu = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const [userData, setUserData] = useState(null);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const headers = {
                    "Content-Type": "application/json;charset=utf-8",
                    authtoken: `${accessToken}`
                }
                const response = await awsApiTwo.get('/getuser', { headers });
                if (response.data.statusCode === 200) {
                    const user = JSON.parse(response.data.body);
                    const userData = user.UserAttributes.reduce((acc, attribute) => {
                        acc[attribute.Name] = attribute.Value;
                        return acc;
                    });
                    userData.userName = user.Username;
                    setUserData(userData)
                    dispatch(setUserLoginData(userData));
                    localStorage.setItem('userData', JSON.stringify(userData));
                }
            } catch (error) {
                console.error(error);
            }
        };
        fetchUserData();
    }, [accessToken, dispatch]);

    const [submenuOpenDelay, setSubmenuOpenDelay] = useState(false);

    const handleMouseEnterItem = (index) => {
        if (index < routes?.length) {
            setSelectedIndex(index);
            setSubmenuOpenDelay(false);
        }
    };

    const handleMouseLeaveItem = () => {
        setSubmenuOpenDelay(true);
        setTimeout(() => {
            if (submenuOpenDelay) {
                setSelectedIndex(-1);
            }
        }, 200);
    };

    const name = userData?.name;
    const imgUrl = userData?.imgUrl;
    const userState = userData?.Value;
    const capitalizedUserType = userState ? userState.charAt(0).toUpperCase() + userState.slice(1) : '';

    return (
        <Box sx={{ display: "flex" }}>
            <CssBaseline />
            <AppBar position="fixed" open={open} >
                <Toolbar sx={{ display: 'flex', justifyContent: "space-between" }}>
                    <Grid display='flex'>
                        <img src={logo} alt='' width='160' height='40'
                            style={{
                                marginLeft: "-15px",
                                marginRight: "15px",
                                ...(open && { display: "none" }),
                            }}
                        />
                        <IconButton
                            color="default"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            sx={{
                                marginRight: 5,
                                ...(open && { display: "none" }),
                                transform: "rotate(180deg)"
                            }}
                        >
                            <MdMenuOpen />
                        </IconButton>
                    </Grid>

                    <Details>
                        {/* <ProgressBar /> */}
                        <Grid >
                            {/* <div className="onoffswitch">
                                <input type="checkbox" name="onoffswitch" className="onoffswitch-checkbox" id="myonoffswitch" checked />
                                <label className="onoffswitch-label" htmlFor="myonoffswitch">
                                    <span className="onoffswitch-inner"></span>
                                    <span className="onoffswitch-switch"></span>
                                </label>
                            </div> */}
                            {/* <button onClick={handleChange} className={`toggle-button ${buttonClass}`}>
                                <span style={{marginLeft:"6px",marginRight:"6px"}}>{buttonText}</span>
                            </button> */}
                            {/* <button onClick={handleChange} className={`toggle-button ${isToggled ? 'on' : 'off'}`}>
                                {isToggled ? 'LIVE' : 'DEMO'}
                            </button> */}
                        </Grid>
                        <IconButton size="large">
                            <AiOutlineBell />
                        </IconButton>

                        <IconButton size="large" style={{ marginLeft: "-10px" }}
                            onClick={handleClick}
                            aria-controls={open ? 'account-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                        >
                            <Avatar src={imgUrl} sx={{ width: 35, height: 35 }}></Avatar>
                        </IconButton>

                        <NameBar>
                            <Typography variant="C8" style={{ textTransform: "capitalize" }}>
                                {capitalizedUserType}

                            </Typography>
                            <Typography variant="C9"> {name}</Typography>
                        </NameBar>
                    </Details>

                </Toolbar>

            </AppBar>
            <Drawer variant="permanent" open={open} sx={{ overflow: "hidden" }} 
            // onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}
            >
                <DrawerHeader>
                    <img src={logo} alt='' width='160' />
                    <IconButton onClick={handleDrawerClose}>
                        <MdMenuOpen />
                    </IconButton>
                </DrawerHeader>



                {/* <List>
                    {routes.map((route, index) => (
                        <React.Fragment key={index}>
                            <ListItem disablePadding sx={{ display: "block" }}>
                                <ListItemButton
                                    component={Link}
                                    to={route.path}
                                    onClick={() => handleItemClick(index)}
                                    onMouseEnter={() => handleMouseEnterItem(1)}
                                    sx={{
                                        minHeight: 44,
                                        justifyContent: open ? "initial" : "center",
                                        px: 2.5,
                                        backgroundColor: selectedIndex === index ? "#eff4fd" : "transparent",
                                        color: selectedIndex === index ? "Black" : "#526484",
                                    }}
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: open ? 2 : "auto",
                                            variant: "L1",
                                            color: selectedIndex === index ? "Black" : "#526484",
                                        }}
                                    >
                                        <Typography variant="P2">{route.icon}</Typography>
                                    </ListItemIcon>
                                    <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                                        <Typography variant="P1">{route.name}</Typography>
                                    </ListItemText>
                                </ListItemButton>
                            </ListItem>
                            {route.routes && selectedIndex === index && (
                                <List component="div" disablePadding sx={{ display: "block" }}>
                                    {route.routes.map((subRoute, subIndex) => (
                                        <ListItem
                                            key={subIndex}
                                            disablePadding
                                            sx={{ display: "block", pl: 1, ml: 2 }}
                                        >
                                            <ListItemButton
                                                component={Link}
                                                to={subRoute.path}
                                                sx={{
                                                    minHeight: "25px",
                                                    justifyContent: open ? "initial" : "center",
                                                    px: 2.5,
                                                }}
                                            >
                                                <Typography variant="P0">{subRoute.icon}</Typography>
                                                <ListItemText
                                                    sx={{
                                                        opacity: open ? 1 : 0,
                                                        marginLeft: "15px",
                                                    }}
                                                >
                                                    <Typography variant="P3">{subRoute.name}</Typography>
                                                </ListItemText>
                                            </ListItemButton>
                                        </ListItem>
                                    ))}
                                </List>
                            )}
                        </React.Fragment>
                    ))}
                </List> */}

                <List>
                    {routes.map((route, index) => (
                        <React.Fragment key={index}>
                            <ListItem disablePadding sx={{ display: "block" }}>
                                <ListItemButton
                                    component={Link}
                                    to={route.path}
                                    onClick={() => handleItemClick(index)}
                                    // onMouseEnter={() => handleMouseEnterItem(1)}
                                    sx={{
                                        minHeight: 44,
                                        justifyContent: open ? "initial" : "center",
                                        px: 2.5,
                                        backgroundColor: selectedIndex === index ? "#eff4fd" : "transparent",
                                        color: selectedIndex === index ? "Black" : "#526484",
                                    }}
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: open ? 2 : "auto",
                                            variant: "L1",
                                            color: selectedIndex === index ? "Black" : "#526484",
                                        }}
                                    >
                                        <Typography variant="P2">{route.icon}</Typography>
                                    </ListItemIcon>
                                    <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                                        <Typography variant="P1">{route.name}</Typography>
                                    </ListItemText>
                                </ListItemButton>
                            </ListItem>
                            {route.routes && (
                                <List component="div" disablePadding sx={{ display: "block" }}>
                                    {route.routes.map((subRoute, subIndex) => (
                                        <ListItem
                                            key={subIndex}
                                            disablePadding
                                            sx={{ display: "block", pl: 1, ml: 2 }}
                                        >
                                            <ListItemButton
                                                component={Link}
                                                to={subRoute.path}
                                                sx={{
                                                    minHeight: "25px",
                                                    justifyContent: open ? "initial" : "center",
                                                    px: 2.5,
                                                }}
                                            >
                                                <Typography variant="P0">{subRoute.icon}</Typography>
                                                <ListItemText
                                                    sx={{
                                                        opacity: open ? 1 : 0,
                                                        marginLeft: "15px",
                                                    }}
                                                >
                                                    <Typography variant="P3">{subRoute.name}</Typography>
                                                </ListItemText>
                                            </ListItemButton>
                                        </ListItem>
                                    ))}
                                </List>
                            )}
                        </React.Fragment>
                    ))}
                </List>



            </Drawer>
            <AccountMenu
                open={openMenu}
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                userData={userData}
                setIsAuthenticated={setIsAuthenticated}
            />
        </Box>
    );
}

