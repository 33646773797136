
import { Button, Grid, MenuItem, Typography, Popover, Tooltip, tooltipClasses, ClickAwayListener } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import MultiSelectDropdown from '../CommonComponet/MultiSelect';
import { styled } from '@mui/material/styles';
import Select from "react-select";
import {
    typeofContentOptions,
    genresOptions,
    brandCategoryOptions, ListCountry
} from '../CommonComponet/docsData';
import { FaMagnifyingGlassLocation } from "react-icons/fa6";
import { ImVideoCamera } from "react-icons/im";
import { BiSolidCategoryAlt } from "react-icons/bi";
import { BsPersonVideo3 } from "react-icons/bs";
import { GrFormClose } from "react-icons/gr";
import { TagIcon } from '../../ThemeProvider/style';




const customStyles = {
    option: (provided, state) => ({
        ...provided,
        color: 'black',
    }),
};

const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        minHeight: "auto",
    },
});

const SearchFilter = ({
    setSelectedCountry,
    setSelectedBrandCategory,
    setSelectedProjectType,
    setSelectedGenre,
    setSelectedProjectTitle,
    selectedProjectType,
    filters,
    setFilters
}) => {

    const [selectedOption, setSelectedOption] = useState('country');
    const [countryOpen, setCountryOpen] = useState(false);
    const [projectTypeOpen, setProjectTypeOpen] = useState(false);
    const [genreOpen, setGenreOpen] = useState(false);
    const [brandCategoryOpen, setBrandCategoryOpen] = useState(false);

    const handleTooltipClose = () => {
        setCountryOpen(false);
        setProjectTypeOpen(false);
        setGenreOpen(false);
        setBrandCategoryOpen(false);
    };

    const handleOptionClick = (option) => {
        handleTooltipClose();
        switch (option) {
            case 'country':
                setCountryOpen(true);
                break;
            case 'projectType':
                setProjectTypeOpen(true);
                break;
            case 'genre':
                setGenreOpen(true);
                break;
            case 'brandCategory':
                setBrandCategoryOpen(true);
                break;
            default:
                break;
        }

        setSelectedOption(option);
    };

    const handleCountryChange = (selectedOption) => {
        setFilters({ ...filters, country: selectedOption.value });
        handleOptionClick('country');
    };

    const handleProjectTypeChange = (selectedOptions) => {
        const selectedValues = selectedOptions.map((option) => option.value);
        setFilters({ ...filters, projectType: selectedValues });
        handleOptionClick('projectType');
    };

    const handleGenreChange = (selectedOptions) => {
        const selectedValues = selectedOptions.map((option) => option.value);
        setFilters({ ...filters, genre: selectedValues });
        handleOptionClick('genre');
    };
    const handleBrandCategoryChange = (selectedOptions) => {
        const selectedValues = selectedOptions.map((option) => option.value);
        setFilters({ ...filters, brandCategory: selectedValues });
        handleOptionClick('brandCategory');
    };

    const filterKeys = Object.keys(filters);

    const handleRemoveFilter = (key, value) => {
        const updatedFilters = { ...filters };

        if (Array.isArray(updatedFilters[key])) {
            updatedFilters[key] = updatedFilters[key].filter((item) => item !== value);
        } else {
            updatedFilters[key] = '';
        }

        setFilters(updatedFilters);
    };

    function getCountry() {
        return (
            <Select

                options={[
                    { value: 'all', label: 'All' },
                    ...ListCountry,
                ]}
                styles={customStyles}
                value={ListCountry.find((option) => option.value === filters.country) || null}
                onChange={handleCountryChange}
                placeholder={'Select Country'}
            />
        );
    }
    function getProjectType() {
        return (
            <Select
                closeMenuOnSelect={false}
                isMulti
                options={[
                    { value: 'all', label: 'All' },
                    ...typeofContentOptions,
                ]}
                value={
                    filters.projectType
                        ? filters.projectType.includes('all')
                            ? [{ value: 'all', label: 'All' }]
                            : filters.projectType.map((value) => ({
                                value,
                                label: value,
                            }))
                        : [{ value: 'all', label: 'All' }]
                }
                styles={customStyles}
                onChange={handleProjectTypeChange}
                placeholder={'Select Project Type'}
            />
        );
    }
    function getGenre() {
        return (
            <Select
                closeMenuOnSelect={false}
                isMulti
                options={[
                    { value: 'all', label: 'All' },
                    ...genresOptions,
                ]}
                value={
                    filters.genre
                        ? filters.genre.includes('all')
                            ? [{ value: 'all', label: 'All' }]
                            : filters.genre.map((value) => ({
                                value,
                                label: value,
                            }))
                        : [{ value: 'all', label: 'All' }]
                }
                styles={customStyles}
                onChange={handleGenreChange}
                placeholder={'Select Genre'}
            />
        );
    }
    function getBrandCategory() {
        return (
            <Select
                closeMenuOnSelect={false}
                isMulti
                options={[
                    { value: 'all', label: 'All' },
                    ...brandCategoryOptions,
                ]}
                value={
                    filters.brandCategory
                        ? filters.brandCategory.includes('all')
                            ? [{ value: 'all', label: 'All' }]
                            : filters.brandCategory.map((value) => ({
                                value,
                                label: value,
                            }))
                        : [{ value: 'all', label: 'All' }]
                }
                styles={customStyles}
                onChange={handleBrandCategoryChange}
                placeholder={'Select Brand Category'}
            />
        );
    }
    const country = getCountry();
    const projectType = getProjectType();
    const genre = getGenre();
    const brandCategory = getBrandCategory();

    return (

        <Grid display='flex' xs={11.76} ml={2} mt={1.5} variant='G3' item spacing={3} mb={2} flexDirection='column' >
            <ClickAwayListener onClickAway={handleTooltipClose}>
                <Grid xs={6} display='flex' justifyContent='space-between' mt={1} ml={1} mr={1} mb={0.2} className='newMax_css'>
                    <CustomWidthTooltip
                        // PopperProps={{
                        //     disablePortal: true,
                        // }}
                        onClose={handleTooltipClose}
                        open={countryOpen}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        title={country}
                        arrow
                    >
                        <Grid display='flex' justifyContent='center' alignItems='center' variant='FT' onClick={() => handleOptionClick('country')}>
                            <Typography variant={"P1"} style={{ color: selectedOption === 'country' ? "green" : "#344357" }} className='new_style_text'><FaMagnifyingGlassLocation /></Typography>
                            <Typography ml={1} variant={"P1"} style={{ color: selectedOption === 'country' ? "green" : "#344357" }} className='new_style_text'>Country</Typography>
                        </Grid>
                    </CustomWidthTooltip>


                    <CustomWidthTooltip
                        // PopperProps={{
                        //     disablePortal: true,
                        // }}
                        onClose={handleTooltipClose}
                        open={projectTypeOpen}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        title={projectType}
                        arrow
                    >
                        <Grid display='flex' justifyContent='center' alignItems='center' variant='FT' onClick={() => handleOptionClick('projectType')}>
                            <Typography variant={"P1"} style={{ color: selectedOption === 'projectType' ? "green" : "#344357" }} className='new_style_text'><ImVideoCamera /></Typography>
                            <Typography ml={1} variant={"P1"} style={{ color: selectedOption === 'projectType' ? "green" : "#344357" }} className='new_style_text'>Project Type</Typography>
                        </Grid>

                    </CustomWidthTooltip>
                    <CustomWidthTooltip
                        // PopperProps={{
                        //     disablePortal: true,
                        // }}
                        onClose={handleTooltipClose}
                        open={genreOpen}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        title={genre}
                        arrow
                    >
                        <Grid display='flex' justifyContent='center' alignItems='center' variant='FT' onClick={() => handleOptionClick('genre')}>
                            <Typography variant={"P1"} style={{ color: selectedOption === 'genre' ? "green" : "#344357" }} className='new_style_text'><BsPersonVideo3 /></Typography>
                            <Typography ml={1} variant={"P1"} style={{ color: selectedOption === 'genre' ? "green" : "#344357" }} className='new_style_text'>Genre</Typography>
                        </Grid>

                    </CustomWidthTooltip>

                    <CustomWidthTooltip
                        // PopperProps={{
                        //     disablePortal: true,
                        // }}
                        onClose={handleTooltipClose}
                        open={brandCategoryOpen}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        title={brandCategory}
                        arrow
                    >
                        <Grid display='flex' justifyContent='center' alignItems='center' variant='FT' onClick={() => handleOptionClick('brandCategory')}>
                            <Typography variant={"P1"} style={{ color: selectedOption === 'brandCategory' ? "green" : "#344357" }} className='new_style_text'><BiSolidCategoryAlt /></Typography>
                            <Typography ml={1} variant={"P1"} style={{ color: selectedOption === 'brandCategory' ? "green" : "#344357" }} className='new_style_text'>Brand Category</Typography>
                        </Grid>
                    </CustomWidthTooltip>

                </Grid>
            </ClickAwayListener>
            <Grid xs={12} display='flex' mt={1} ml={1} mr={1} mb={1} style={{
                background: "white",
                border: "1px solid #cccccc",
                borderRadius: '5px',
                height: "auto",
                minHeight: "81px",
                maxWidth: "100%",
                flexWrap: 'wrap',
            }}>
                {filterKeys.map((key) => (
                    (Array.isArray(filters[key]) && filters[key].length > 0) || (!Array.isArray(filters[key]) && filters[key])) && (
                        <Grid item key={key} mt={0.7} mb={0.7} display="flex" flexWrap='wrap'>
                            {Array.isArray(filters[key]) && filters[key].map((value) => (
                                <span style={{ display: 'inline', margin: "5px" }}>
                                    <span style={{ color: '#fff', backgroundColor: '#777777', padding: '.2em .6em .3em', borderTopLeftRadius: '.25em', borderBottomLeftRadius: '.25em' }}>
                                        {value}
                                    </span>
                                    <TagIcon key={value} onClick={() => handleRemoveFilter(key, value)}>
                                        x
                                    </TagIcon>
                                </span>
                            ))
                            }
                            {!Array.isArray(filters[key]) && (
                                <span style={{ display: 'inline', margin: "5px" }}>
                                    <span style={{ color: '#fff', backgroundColor: '#777777', padding: '.2em .6em .3em', borderTopLeftRadius: '.25em', borderBottomLeftRadius: '.25em' }}>
                                        {filters[key]}
                                    </span>
                                    <TagIcon onClick={() => handleRemoveFilter(key, filters[key])}>
                                        x
                                    </TagIcon>
                                </span>
                            )}
                        </Grid>
                    ))}
            </Grid>
        </Grid >

    );
};

export default SearchFilter;
