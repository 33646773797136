
import { Box, Button, DialogActions, Grid, Slide } from '@mui/material';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import SearchBar from './SearchBar';
import CommonTable from '../CommonComponet/CommonTable';
import { Chip, IconButton, Menu, MenuItem, Dialog, DialogTitle, DialogContent } from '@mui/material';
import { IoMdArrowBack } from 'react-icons/io'
import { CloudDownloadOutlined, MoreHoriz, Delete } from '@mui/icons-material';
import { Img, Tabel } from './../../ThemeProvider/style';
import { DataGrid, } from '@mui/x-data-grid';
import { useDispatch } from "react-redux";
import { setShowLoader } from '../../redux/loaderSlice';
import { TfiMenuAlt } from 'react-icons/tfi';
import { BsGrid3X2GapFill } from 'react-icons/bs';
import NoData from '../CommonComponet/NoData';
import ProjectCard from '../CommonComponet/ProjectCard';
import VppPlayer from '../CommonComponet/VppPlayer';
import BasicTable, { PerPageView } from '../CommonComponet/BasicTable';
import { awsApiCamp } from '../../Routes/Server';
import CustomizedTables from '../DashboardComponet/MuiTabel';

const ActionMenu = ({ rowData, setRows, handleOpenDialog }) => {
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState(null);
    const [approveDialogOpen, setApproveDialogOpen] = useState(false);
    const [rejectMessage, setRejectMessage] = useState('');
    const [showRejectDialog, setShowRejectDialog] = useState(false);

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleApproveClick = () => {
        handleMenuClose();
        setApproveDialogOpen(true);
    };

    const handleRestore = async () => {
        dispatch(setShowLoader(true));
        try {
            if (rowData.rejectBy === 'Brand') {
                const updatedClipSet = rowData.clipSet.map(clip => {
                    return {
                        ...clip,
                        status: {
                            ...clip.status,
                            label: 'Review',
                        },
                    };
                });
                const updatedRowData = {
                    ...rowData,
                    creatorStatus: false,
                    clipSet: updatedClipSet,
                    status: "pending",
                    type: "in_progress",
                    rejectBy: "",
                    rejectMassage: "",
                };
                const res = await awsApiCamp.post('/postcampaign', {
                    ...updatedRowData,
                });
                if (res.data.statusCode === 200) {
                    // console.log('Data rejected successfully');

                }
            } else {
                setRejectMessage("You cannot restore this VPP frame as it has been rejected by the Producer.");
                setShowRejectDialog(true);
            }

        } catch (error) {
            // console.error('Error rejecting data:', error);
        } finally {
            dispatch(setShowLoader(false));
        }
    };

    const handleDelete = () => {
        // console.log("this row is deleted")
    }

    return (
        <>
            <IconButton onClick={handleMenuClick}>
                <MoreHoriz />
            </IconButton>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                <MenuItem onClick={handleRestore}>Restore</MenuItem>
                <MenuItem onClick={handleDelete}>Delete</MenuItem>
            </Menu>
            <Dialog open={showRejectDialog} onClose={() => setShowRejectDialog(false)} >
                <DialogTitle >{"Restore Not Allowed."}</DialogTitle>
                <DialogContent >{rejectMessage}</DialogContent>
                <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
                    <Button onClick={() => setShowRejectDialog(false)} variant="outlined" color="success" sx={{ marginBottom: "7px" }}>
                        Close
                    </Button>

                </DialogActions>
            </Dialog>
        </>
    );
};

const Completed = () => {
    const [searchText, setSearchText] = useState('');
    const [lastEvaluatedKey, setLastEvaluatedKey] = useState(undefined);
    const [rows, setRows] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);

    useEffect(() => {
        async function fetchReject() {
            const res = await awsApiCamp.post("/getcampaign", { type: "reject", pageSize: 5, page: 1, lastEvaluatedKey: lastEvaluatedKey })
            if (res.data.statusCode === 200) {
                const data = JSON.parse(res.data.body);
                // setApiData(data);
                setRows(data.items);
                setLastEvaluatedKey(data.lastEvaluatedKey);
                // console.log(data, "Rejected");
            }
        }
        fetchReject();
    }, []);

    // useEffect(() => {
    //     axios
    //         .get('http://localhost:3006/getMarketReject')
    //         .then((response) => {
    //             setApiData(response.data);
    //             setRows(response.data);
    //         })
    //         .catch((error) => {
    //             console.error('Error fetching API data:', error);
    //         });
    // }, []);


    const handleSetRows = (updatedRows) => {
        setRows(updatedRows);
    };

    const handleOpenDialog = (rowData) => {
        setSelectedRow(rowData);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleDownload = (id) => {
        // console.log("Download project with ID:", id);
    };

    rows.forEach((row, index) => {
        row._index = index + 1;
    });

    const getValue = (row) => row._index;

    const columns = [
        {
            field: 'index',
            headerName: 'S.No',
            width: 100,
            valueGetter: (params) => params.row._index,
        },
        {
            field: "projectTitle",
            headerName: "Title",
            width: 300,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {/* <Img src={params.row.thumbnail} alt="Project Title" onClick={() => handelOpenPlayer(params.row)} /> */}
                    <Box sx={{ fontWeight: 'bold', color: 'black' }}>{params.row.clipSet[0].projectTitle}</Box>
                </Box>
            ),
        },
        {
            field: "VppFreamNo", headerName: "Campaign No", width: 180,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ fontWeight: 'normal', color: 'black' }}>{params.row.clipSet[0].VppFreamNo}</Box>
                </Box>
            ),
        },
        {
            field: "catagory",
            headerName: "Media Type",
            width: 250,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ fontWeight: 'normal', color: 'black' }}>{params.row.clipSet[0].catagory}</Box>
                </Box>
            ),
        },
        {
            field: "status",
            headerName: "Status",
            width: 200,
            renderCell: (params) => {
                const rejectBy = params.row.rejectBy;
                let label = "Pending";
                let color = "secondary";

                if (rejectBy === "Producer") {
                    label = "Rejected by Producer";
                    color = "error";
                } else if (rejectBy === "Brand") {
                    label = "Rejected by You";
                    color = "error";
                }

                return (
                    <Chip
                        label={label}
                        color={color}
                        variant="outlined"
                        size="small"
                        sx={{ width: '160px', height: '30px' }}
                    />
                );
            },
        },
        { field: "rejectMassage", headerName: "Reject Massage", width: 320 },
        {
            field: 'id',
            headerName: 'Action',
            description: 'This column has a value getter and is not sortable.',
            sortable: false,
            width: 160,
            renderCell: (params) => (
                <ActionMenu rowData={params.row} setRows={handleSetRows} handleOpenDialog={handleOpenDialog} />
            ),
        },
    ];

    const rowsPerPageOptions = [5, 10, 25];

    const handleSearchChange = (event) => {
        setSearchText(event.target.value)
    };

    const filteredData = rows.filter((item) => {
        const searchValue = searchText.toLowerCase();
        const rowData = [item.sno, item.projectTitle, item.completeDate, item.catagory];
        return rowData.some((cell) => String(cell).toLowerCase().includes(searchValue));
    });

    const decideColor = (row) => {
        const rejectBy = row.rejectBy;
        let label = "Pending";
        let color = "secondary";

        if (rejectBy === "Producer") {
            label = "Rejected by Producer";
            color = "error";
        } else if (rejectBy === "Brand") {
            label = "Rejected by Brand";
            color = "error";
        }
        return { label, color };
    }

    return (
        <Grid xs={12}>
            <Grid xs={2} mt={1}>
                <SearchBar onChange={handleSearchChange} />
            </Grid>

            <Grid xs={12} mt={3} mb={2}>
                {filteredData.length === 0 ? (
                    <NoData />
                ) : (
                    // <DataGrid
                    //     rows={filteredData}
                    //     columns={columns}
                    //     disableRowSelectionOnClick
                    //     sx={{ color: '#526484', minHeight:"38.5vh" }}
                    //     initialState={{
                    //         ...filteredData.initialState,
                    //         pagination: { paginationModel: { pageSize: 5 } },
                    //     }}
                    //     pageSizeOptions={[5, 10, 25]} disableColumnMenu
                    // />
                    <CustomizedTables
                        filterRow={rows}
                        columns={columns}
                        rowAttributes={[
                            { name: "projectTitle", type: 'regular', child: "clipSet", newStyle: "pointer", TextDecoder: "underline", fontWeight: "bold" },
                            { name: "VppFreamNo", type: 'regular', child: "clipSet" },
                            { name: "category", type: 'regular', child: "clipSet" },
                            { name: "brandCat", type: 'chip', child: "clipSet" },
                            { name: "status", type: 'newChip', child: "clipSet", typeColorCallback: decideColor },
                            { name: "rejectMassage", type: 'regular' }
                        ]}
                    />
                )}
                {/* <Tabel>
                    <DataGrid
                        rows={filteredData}
                        columns={columns}
                        disableRowSelectionOnClick
                        sx={{ color: '#526484' }}
                        initialState={{
                            ...filteredData.initialState,
                            pagination: { paginationModel: { pageSize: 5 } },
                        }}
                        pageSizeOptions={[5, 10, 25]}
                    />
                </Tabel> */}
            </Grid>

        </Grid>

    );
};

export default Completed;
