import axios from 'axios';
import { useSelector } from 'react-redux';

const AWS_API_BASE_URL = 'https://kyppp28oxb.execute-api.ap-south-1.amazonaws.com/dev';
const OPTIMUS_API_BASE_URL = 'https://dev.optimus.anantadi.com/api';
const AWS_TWO_API_BASE_URL = 'https://aqi6lu6dh2.execute-api.ap-south-1.amazonaws.com/dev';
const AWS_CAMP_API_BASE_URL = 'https://egboar4bdj.execute-api.ap-south-1.amazonaws.com/dev';

// https://dev.optimus.anantadi.com/api 
// https://dev.optimus.anantadi.com/api
// http://localhost:3009/api

const useApplicationType = () => {
    const applicationState = useSelector(state => state.Application.appType);
    return applicationState;
};

const awsApi = axios.create({
    baseURL: AWS_API_BASE_URL,
    timeout: 9000,
    // headers: {
    //     'Authorization': `Bearer ${accessToken}`,
    //     'Content-Type': 'application/json',
    // },
});

const optimusApi = axios.create({
    baseURL: OPTIMUS_API_BASE_URL,
    timeout: 3500000,
});

const awsApiTwo = axios.create({
    baseURL: AWS_TWO_API_BASE_URL,
    timeout: 9000,
});

const awsApiCamp = axios.create({
    baseURL: AWS_CAMP_API_BASE_URL,
    timeout: 9000,
});

export { awsApi, optimusApi, awsApiTwo, awsApiCamp };


// import axios from 'axios';
// import { useSelector } from 'react-redux';

// const AWS_API_BASE_URL = 'https://kyppp28oxb.execute-api.ap-south-1.amazonaws.com/dev';
// const OPTIMUS_API_BASE_URL = 'https://dev.optimus.anantadi.com/api';
// const AWS_TWO_API_BASE_URL = 'https://aqi6lu6dh2.execute-api.ap-south-1.amazonaws.com/dev';
// const AWS_CAMP_API_BASE_URL = 'https://egboar4bdj.execute-api.ap-south-1.amazonaws.com/dev';

// // const baseAxios = axios.create();

// baseAxios.interceptors.request.use((config) => {
//     const accessToken = useSelector(state => state.login.accessToken);
//     console.log('Request Headers:', config.headers);
//     if (config.headers['includeAuthHeader'] !== false && accessToken) {
//         config.headers['Authorization'] = `Bearer ${accessToken}`;
//     }
//     config.headers['Content-Type'] = 'application/json';
//     return config;
// });


// const awsApi = axios.create({
//     baseURL: AWS_API_BASE_URL,
//     timeout: 5000,
//     headers: {
//         'Authorization': `Bearer ${accessToken}`,
//         'Content-Type': 'application/json',
//     },
// });

// const optimusApi = axios.create({
//     baseURL: OPTIMUS_API_BASE_URL,
//     timeout: 250000,
//     headers: {
//         'Authorization': `Bearer ${accessToken}`,
//         'Content-Type': 'application/json',
//     },
// });

// const awsApiTwo = axios.create({
//     baseURL: AWS_TWO_API_BASE_URL,
//     timeout: 5000,
//     // headers: {
//     //     'includeAuthHeader': false,
//     // },
// });

// const awsApiCamp = axios.create({
//     baseURL: AWS_CAMP_API_BASE_URL,
//     timeout: 5000,
//     headers: {
//         'Authorization': `Bearer ${accessToken}`,
//         'Content-Type': 'application/json',
//     },
// });

// export { awsApi, optimusApi, awsApiTwo, awsApiCamp };
