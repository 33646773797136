import { Grid, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { RiUploadCloud2Fill } from 'react-icons/ri';
import axios from 'axios';
import { useDispatch } from "react-redux";
import { setShowLoader } from '../../redux/loaderSlice';
import { BrandLogo, UplaodButton } from '../../ThemeProvider/style';
import { v4 as uuidv4 } from 'uuid';
import { optimusApi, awsApi } from '../../Routes/Server';
import { useSelector } from 'react-redux';
import GallaryDetails from './GallaryDetails';
import brandIdsData from './brandId.json';
import { awsApiTwo } from "../../Routes/Server";


const Container = () => {
    const dispatch = useDispatch();

    const [vppData, setVppData] = useState([]);
    const [brandIds, setBrandIds] = useState([]);
    const [selectedBrandId, setSelectedBrandId] = useState(brandIdsData[0]?.brandid || null);
    const [selectedBrandName, setSelectedBrandName] = useState(brandIdsData[0]?.name || '')


    // Add this line to log the value of brandIdsData
    console.log("selectedBrandId:", selectedBrandId);


    useEffect(() => {
        const fetchBrandIds = async () => {
            try {
                const response = await awsApiTwo.post('/signup',
                    {

                        action: 'get-brand-user-list',

                    }
                );
                const parsedData = JSON.parse(response.data.body);
                const brandIdsFromApi = parsedData;
                setBrandIds(brandIdsFromApi);
            } catch (error) {
                console.error('Error fetching brand IDs:', error);
            }
        };
        fetchBrandIds();
    }, [])

    const handleBrandClick = (brandId, brandName) => {
        setSelectedBrandId(brandId);
        setSelectedBrandName(brandName);

    };

    console.log(brandIds)

    return (
        <Grid container xs={12} variant="G19">
            <Grid item xs={2.5} style={{ border: '1px solid #dbdfea', borderRadius: '8px' }}>
                <Grid container xs={12} variant="G20">
                    <Grid item xs={12} variant="G26" sx={{background:'black' }}>
                        <Typography variant="P21" mt={2} mb={2} style={{color:'white'}}>
                            Brands
                        </Typography>
                    </Grid>
                    <Grid item xs={12} variant="G20">
                        {brandIds.map((brand) => (
                            <Grid
                                key={brand.id}
                                item
                                xs={11.8}
                                ml={0.5}
                                mr={0.5}
                                mt={1}
                                variant="G27"
                                onClick={() => handleBrandClick(brand.id, brand.name)}
                                style={{
                                    cursor: 'pointer',
                                    backgroundColor: selectedBrandId === brand.id ? 'lightgray' : 'inherit',
                                }}
                            >
                                <Typography variant="P21" mt={2} mb={2} style={{ fontSize: "13px" }}>
                                    {brand.name}
                                </Typography>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={9.5} style={{ border: '1px solid #dbdfea', borderRadius: '8px' }}>
                <Grid container xs={12} variant="G20">
                    <Grid item xs={12} variant="G26" sx={{background:'black' }}>
                        <Typography variant="P21" mt={2} mb={2} style={{color:'white'}}>
                            {selectedBrandName || 'Select a brand'}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} variant="G20" >
                        <GallaryDetails selectedBrandId={selectedBrandId} />
                    </Grid>
                </Grid>
            </Grid>


        </Grid>
    );
};

export default Container;


