
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch } from "react-redux";
import SearchBar from './SearchBar';
import { Chip } from '@mui/material';
import { Tabel } from './../../ThemeProvider/style';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { IconButton } from '@mui/material';
import { MoreHoriz } from '@mui/icons-material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Accordion, AccordionSummary, Typography, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Popover, List, ListItem, ListItemButton, ListItemText, Button } from "@mui/material";
import CommentDialog from '../CommonComponet/CommentDialog';
import { setShowLoader } from '../../redux/loaderSlice';
import RejectDialog from '../CommonComponet/RejectDialog';
import { v4 as uuidv4 } from 'uuid';
import { TfiMenuAlt } from 'react-icons/tfi';
import { BsGrid3X2GapFill } from 'react-icons/bs';
import VppPlayer from '../CommonComponet/VppPlayer';
import NoData from '../CommonComponet/NoData';
import ProjectCard from '../CommonComponet/ProjectCard';
import CreativeVppUpload from '../CommonComponet/CreativeVppUpload';
import { awsApiCamp } from '../../Routes/Server';
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { addvppId } from '../../redux/vppIdSlice';
import CustomizedTables from '../DashboardComponet/MuiTabel';
import Loader from '../CommonComponet/Loader';
import CampaignsCard from '../CommonComponet/CampaignsCard';
import TrackDilogVfx from './TrackDilogVfx';


const ActionMenu = ({ rowData, setRows, handleOpenDialog, userData }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState(null);
    const [commentInput, setCommentInput] = useState('');
    const [commentDialogOpen, setCommentDialogOpen] = useState(false);

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleComment = () => {
        handleMenuClose();
        setCommentDialogOpen(true);
    };

    const handleCommentSubmit = async () => {
        dispatch(setShowLoader(true));
        if (rowData && rowData.clipSet && rowData.clipSet.length > 0) {
            const firstClip = rowData.clipSet[0];
            if (firstClip && firstClip.VppId) {
                navigate('/MessageCreative');
                console.log(firstClip.VppId)
                dispatch(addvppId(firstClip.VppId));
            } else {
                console.error('VppId not found in the first clip of rowData.');
            }
        } else {
            console.error('Invalid rowData structure or clipSet is empty.');
        }
        dispatch(setShowLoader(false));
    };


    return (
        <>
            <Menu menuButton={<IconButton><MoreHoriz /></IconButton>} transition align={'center'}>
                <MenuItem onClick={handleCommentSubmit}>Comment</MenuItem>
            </Menu>

            <CommentDialog
                open={commentDialogOpen}
                onClose={() => setCommentDialogOpen(false)}
                onCommentSubmit={handleCommentSubmit}
                commentInput={commentInput}
                setCommentInput={setCommentInput}
            />

        </>
    );
};


const InProgressCreative = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const userData = useSelector((state) => state.user.userLoginData);
    const [searchText, setSearchText] = useState('');
    const [rows, setRows] = useState([]);
    const [lastEvaluatedKey, setLastEvaluatedKey] = useState(undefined);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [gridView, setGridView] = useState(false);
    const [listView, setListView] = useState(true);
    const [openPlayer, setOpenPlayer] = useState(false);
    const [selectedProject, setSelectedProject] = useState(null);
    const [updateMessages, setUpdateMessages] = useState('')
    const [selectedApproveRow, setSelectedApproveRow] = useState(null);
    const [brandDialogOpen, setBrandDialogOpen] = useState(false);
    const [actionValue, setAactionValue] = useState(1)
    const [trackCamp, setTrackCamp] = useState(false);

    const generateRandomText = () => {
        const randomText = Math.random().toString(36).substring(7);
        return randomText;
    };

    const handleUpdateMessages = () => {
        const randomText = generateRandomText();
        setUpdateMessages(randomText);
    };

    useEffect(() => {
        async function fetchInProgress() {
            dispatch(setShowLoader(true));

            try {
                const res = await awsApiCamp.post("/getcampaign", {
                    pageSize: 10,
                    type: "in_progress",
                    page: 1,
                    lastEvaluatedKey: lastEvaluatedKey,
                });
                if (res.data.statusCode === 200) {
                    const data = JSON.parse(res.data.body);
                    setRows(data.items);
                    setLastEvaluatedKey(data.lastEvaluatedKey);
                    if (selectedProject && selectedProject.id) {
                        const updatedProject = data.items.find(item => item.id === selectedProject.id);
                        if (updatedProject) {
                            setSelectedProject(updatedProject);
                        }
                    }
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                dispatch(setShowLoader(false));
            }
        }

        fetchInProgress();
    }, [updateMessages]);


    const handleSetRows = (updatedRows) => {
        setRows(updatedRows);
    };



    const handleOpenDialog = (rowData) => {
        setSelectedRow(rowData);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleDownload = (id) => {
        // console.log("Download project with ID:", id);
    };
    const handelTrack = (project) => {
        setSelectedProject(project);
        setTrackCamp(true);

    };
    rows.forEach((row, index) => {
        row._index = index + 1;
    });

    const getValue = (row) => row._index;

    const columns = [
        {
            field: 'index',
            headerName: 'S.No',
            width: 100,
            valueGetter: (params) => params.row._index,
        },
        {
            field: "projectTitle",
            headerName: "Title",
            width: 300,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {/* <Img src={params.row.imgURL} alt="Project Title" /> */}
                    <Box sx={{ fontWeight: 'bold', color: 'black', cursor: "pointer" }} onClick={() => handelOpenPlayer(params.row)}>{params.row.clipSet[0].projectTitle}</Box>
                </Box>
            ),
        },
        {
            field: "VppId", headerName: "Campaign Id", width: 150,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ fontWeight: 'normal', color: 'black' }}>{params.row.clipSet[0].VppFreamNo}</Box>
                </Box>
            ),
        },
        {
            field: "catagory",
            headerName: "Media Type",
            width: 250,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ fontWeight: 'normal', color: 'black' }}>{params.row.clipSet[0].catagory}</Box>
                </Box>
            ),
        }, {
            field: "videoId", headerName: "Branded Sense", width: 150,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ fontWeight: 'normal', color: 'black' }}>{params.row.clipSet[0].VppFreamNo}</Box>
                </Box>
            ),
        }, {
            field: "currentTime", headerName: "Date Of Creation", width: 150,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ fontWeight: 'normal', color: 'black' }}>{params.row.clipSet[0].currentTime}</Box>
                </Box>
            ),
        },
        {
            field: "brandCat", headerName: "Brand Catagory", width: 150,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ fontWeight: 'normal', color: 'black' }}>{params.row.clipSet[0].VppFreamNo}</Box>
                </Box>
            ),
        },
        {
            field: "value", headerName: "Brand Creative", width: 150,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ fontWeight: 'normal', color: 'black' }}>{params.row.clipSet[0].VppFreamNo}</Box>
                </Box>
            ),
        },
        {
            field: "userName",
            headerName: "Brand Name",
            width: 175,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ fontWeight: 'normal', color: 'black' }}> {params.row.paymentDetails?.userName || 'N/A'}</Box>
                </Box>
            ),
        },
        {
            field: "status",
            headerName: "Status",
            width: 200,
            renderCell: (params) => {
                const brandStatus = params.row.brandStatus;
                const creatorStatus = params.row.creatorStatus;

                let label = params.row.status.charAt(0).toUpperCase() + params.row.status.slice(1);
                let color = "secondary";

                if (brandStatus && creatorStatus) {
                    label = label.charAt(0).toUpperCase() + label.slice(1);
                    color = "success";
                } else if (!brandStatus && creatorStatus) {
                    label = "Your Approval Pending";
                    color = "warning";
                } else if (brandStatus && !creatorStatus) {
                    label = "Producer Approval Pending";
                    color = "warning";
                }
                return (
                    <Chip
                        label={label}
                        color={color}
                        variant="outlined"
                        size="small"
                        sx={{ width: '180px', height: '30px' }}
                    />
                );
            },
        },
        {
            field: "comment", headerName: "Comment", width: 320,
        },
        // {
        //     field: "track", headerName: "Track", width: 300,
        // },
        // {
        //     field: 'id',
        //     headerName: 'Action',
        //     description: 'This column has a value getter and is not sortable.',
        //     sortable: false,
        //     width: 160,
        //     renderCell: (params) => (
        //         <ActionMenu rowData={params.row} setRows={handleSetRows} handleOpenDialog={handleOpenDialog} userData={userData} />
        //     ),
        // },
    ];

    const rowsPerPageOptions = [5, 10, 25];

    const handleSearchChange = (event) => {
        setSearchText(event.target.value)
    };

    const filteredData = rows
        .filter((item) => {
            const searchValue = searchText.toLowerCase();
            const rowData = [item.clipSet[0].VppId, item.clipSet[0].projectTitle, item.clipSet[0].category, item.clipSet[0].brandCat];
            return rowData.some((cell) => String(cell).toLowerCase().includes(searchValue));
        })
        .sort((a, b) => {
            const timestampA = parseInt(a.currentTime, 10);
            const timestampB = parseInt(b.currentTime, 10);
            return timestampB - timestampA; // Sort in descending order (newest first)
        });

    const handelOpenPlayer = (project) => {
        setSelectedProject(project);
        setOpenPlayer(true);
        setAactionValue(1)
    };

    const handelBarndedPlayer = (project) => {
        setSelectedProject(project);
        setOpenPlayer(true);
        setAactionValue(3)
    };

    const decideColor = (row) => {
        const brandStatus = row.brandStatus;
        const creatorStatus = row.creatorStatus;
        let label = row.status.charAt(0).toUpperCase() + row.status.slice(1);
        let color = "secondary";
        if (brandStatus && creatorStatus) {
            label = label.charAt(0).toUpperCase() + label.slice(1);
            color = "success";
        } else if (!brandStatus && creatorStatus) {
            label = "Brand Approval Pending";
            color = "warning";
        } else if (brandStatus && !creatorStatus) {
            label = "Waiting for Producer's approval";
            color = "warning";
        }

        return { label, color };
    }

    const handleBrandClick = (row) => {
        setSelectedApproveRow(row);
        setBrandDialogOpen(true);
    }

    const handleComment = (row) => {
        const id = row.id;
        navigate(`/MessageCreative?id=${id}`);
    };

    console.log('in progress cretive', selectedProject)
    return (
        <Grid xs={12}>
            <Grid xs={12} mt={1} display='flex' justifyContent='space-between' alignItems='center'>
                <Grid xs={2}>
                    <SearchBar onChange={handleSearchChange} />
                </Grid>
                <Grid xs={0.45}>
                    {listView === true ? (
                        <Button
                            variant="B5"
                            onClick={() => {
                                setGridView(true);
                                setListView(false);
                            }}
                        >
                            <BsGrid3X2GapFill />
                        </Button>
                    ) : (
                        <Button
                            variant="B5"
                            onClick={() => {
                                setGridView(false);
                                setListView(true);
                            }}
                        >
                            <TfiMenuAlt />
                        </Button>
                    )}
                </Grid>
            </Grid>

            {gridView && (
                <Grid xs={12} mt={3}>
                    <Grid xs={12} variant='G3'>
                        {filteredData.length === 0 ? (
                            <NoData />
                        ) : (
                            <Grid container item xs={12} spacing={3} style={{ margin: '0rem 1rem' }}>
                                {filteredData.map((project) => (
                                    <CampaignsCard
                                        key={project.projectId}
                                        contentType={project.clipSet[0].category}
                                        projectTitle={project.clipSet[0].projectTitle}
                                        campaignId={project.clipSet[0].VppName}
                                        brandCatagory={project.clipSet[0].brandCat}
                                        dateOfCreation={project.currentTime}
                                        brandLogo={project.brandLogo?.value}
                                        comment={project.clipSet[0]}
                                        handleBrandClick={() => handleBrandClick(project)}
                                        decideColor={() => decideColor(project)}
                                        handleComment={() => handleComment(project)}
                                        handelBarndedPlayer={() => handelBarndedPlayer(project)}
                                        price={project.clipSet[0].price}
                                        vppFreamNo={project.clipSet[0].VppFreamNo}
                                        thumbnail={project.clipSet[0].thumbnail}
                                        projectId={project.id}
                                        label={project.status.label}
                                        color={project.status.color}
                                        project={project}
                                        handelOpenPlayer={() => handelOpenPlayer(project)}
                                        viId={project.videoId}
                                    />
                                ))
                                }
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            )}
            {listView && (
                <Grid xs={12} mt={3} mb={2}>
                    {filteredData.length === 0 ? (
                        <NoData />
                    ) : (
                        <>
                            {/* <DataGrid
                                rows={filteredData}
                                columns={columns}
                                disableRowSelectionOnClick
                                sx={{ color: '#526484', minHeight: "38.5vh" }}
                                initialState={{
                                    ...rows.initialState,
                                    pagination: { paginationModel: { pageSize: 5 } },
                                }}
                                pageSizeOptions={[5, 10, 25]} disableColumnMenu
                            /> */}
                            <CustomizedTables
                                filterRow={filteredData}
                                columns={columns}
                                rowAttributes={[
                                    { name: "projectTitle", type: 'regular', child: "clipSet", newStyle: "pointer", fontWeight: "bold", TextDecoder: "underline", action: handelOpenPlayer },
                                    { name: "VppName", type: 'regular', child: "clipSet", action: handelOpenPlayer },
                                    { name: "category", type: 'regular', child: "clipSet", action: handelOpenPlayer },
                                    { name: "videoId", type: 'thumbnail', action: handelBarndedPlayer },
                                    { name: "currentTime", type: 'regular', action: handelOpenPlayer, valueType: 'date' },
                                    { name: "brandCat", type: 'chip', child: "clipSet", action: handelOpenPlayer },
                                    { name: "value", type: 'image', objectChild: "brandLogo", action: handleBrandClick },
                                    { name: "userName", type: 'regular', objectChild: "paymentDetails", action: handelOpenPlayer },
                                    { name: "status", type: 'newChip', child: "clipSet", typeColorCallback: decideColor , action: handelTrack },
                                    {
                                        name: "comment", type: 'regular', child: "clipSet", objectArray: "commentsProducer", newStyle: "pointer",
                                        color: "#0f87ff", TextDecoder: "underline", action: handleComment
                                    },
                                    // { name: "status", type: 'track', action: handelTrack },

                                ]}
                            />
                        </>
                    )}
                </Grid>
            )}
            <CreativeVppUpload
                setOpenPlayer={setOpenPlayer}
                openPlayer={openPlayer}
                videoData={filteredData}
                selectedProject={selectedProject}
                handleUpdateMessages={handleUpdateMessages}
                handleComment={handleComment}
                actionValue={actionValue}
            />  
            <TrackDilogVfx
            setTrackCamp={setTrackCamp}
            trackCamp={trackCamp}
            selectedProject={selectedProject}
            type={"producer"}
            track={'draft'}
        />
            <Dialog
                fullWidth={true}
                maxWidth='xs'
                open={brandDialogOpen} onClose={() => setBrandDialogOpen(false)}
            >
                <DialogTitle style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant='h6'>Brand Logo</Typography>
                </DialogTitle>
                <DialogContent sx={{ display: "flex", justifyContent: 'center', alignItems: 'center' }}>
                    <img src={selectedApproveRow?.brandLogo?.value || 'https://www.ultimatesource.toys/wp-content/uploads/2013/11/dummy-image-landscape-1.jpg'} style={{
                        width: '300px',
                        height: '300px',
                        marginTop: '10px',
                        borderRadius: "8px",
                    }} />
                </DialogContent>
                <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
                    <Button onClick={() => setBrandDialogOpen(false)} variant="outlined" color="error" sx={{ marginBottom: "7px" }}>
                        Close
                    </Button>
                </DialogActions>
                <Loader />
            </Dialog>
        </Grid>

    );
};

export default InProgressCreative;
