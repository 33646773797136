import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Grid, InputAdornment } from '@mui/material';
import CustomTextfield from '../CommonComponet/CustomTextfield';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { setShowLoader } from '../../redux/loaderSlice';
import { useDispatch } from 'react-redux';
import Loader from '../CommonComponet/Loader';
import { awsApiTwo } from '../../Routes/Server';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other} fontWeight="bold" style={{ fontFamily: 'DM Sans' }}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};



const ChangePassword = (props) => {
    const dispatch = useDispatch();
    const { openSet, setOpenSet } = props;
    const handleClose = () => {
        setOpenSet(false);
    };

    const [username, setusername] = useState('');
    const [password, setpassword] = useState('');
    const [confirmPassword, setconfirmPassword] = useState('');
    const [otp, setOtp] = useState(false)
    const [otpValue, setOtpValue] = useState('');

    const [showPassword, setShowPassword] = useState(false);

    const handleToggleShowPassword = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };

    const handlePasswordChangeOld = (event) => {
        setusername(event.target.value);
    };
    const handlePasswordChangeNew = (event) => {
        setpassword(event.target.value);
    };
    const handlePasswordChangeConfirm = (event) => {
        setconfirmPassword(event.target.value);
    };

    const handleOtpSubmit = async () => {
        dispatch(setShowLoader(true));
        try {
            let res = await awsApiTwo.post('/confirmpasswordreset', { username: username, newPassword: password, otp: otpValue });
            console.log(res.data);
            if (res.data.statusCode === 200) {
                console.log(res.data.body);
                setOtp(false);
                setOpenSet(false);
            }
        } catch (error) {
            console.log(error);
        } finally {
            dispatch(setShowLoader(false));
        }
    };

    const handleSubmit = async () => {
        dispatch(setShowLoader(true));
        if (password !== confirmPassword) {
            dispatch(setShowLoader(false));
            alert("New Password doesn't match with Confirm Password");
        } else {
            try {
                let res = await awsApiTwo.post('/forgotpassword', { username: username });
                console.log(res.data);
                if (res.data.statusCode === 200) {
                    console.log(res.data.body);
                    setOtp(true);
                }
            } catch (error) {
                console.log(error);
            } finally {
                dispatch(setShowLoader(false));
            }
        }
    };


    return (
        <div>
            <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={openSet}>
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {otp ? "Enter OTP" : "Change Password"}
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    {otp ? <Grid item xs={12} container spacing={1}>
                        <Grid item xs={12}>
                            <CustomTextfield
                                size="small"
                                id="otp-input"
                                placeholder="Enter OTP"
                                fullWidth
                                variant="outlined"
                                type={'text'}
                                value={otpValue}
                                onChange={(e) => { setOtpValue(e.target.value) }}
                            />
                        </Grid>
                        <Grid variant='G16' item container>
                            <Button variant="B9" onClick={handleSubmit}>Resend OTP</Button>
                            <Button variant="B9" onClick={handleOtpSubmit}>
                                Submit
                            </Button>
                            <Button variant="B9" onClick={handleClose}>
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                        :
                        <Grid item xs={12} container spacing={1}>
                            <Grid item container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle1" fontWeight="bold" color="#344357" sx={{ fontSize: '15px', marginTop: '10px' }}>
                                        Your Username
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <CustomTextfield
                                        size="small"
                                        id="old-password-input"
                                        placeholder="Enter your username"
                                        fullWidth
                                        variant="outlined"
                                        type={'text'}
                                        value={username}
                                        onChange={handlePasswordChangeOld}
                                    
                                    />
                                </Grid>
                            </Grid>

                            <Grid item container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle1" fontWeight="bold" color="#344357" sx={{ fontSize: '15px', marginTop: '10px' }}>
                                        New Password*
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <CustomTextfield
                                        size="small"
                                        id="new-password-input"
                                        placeholder="Enter new password"
                                        fullWidth
                                        variant="outlined"
                                        type={showPassword ? 'text' : 'password'}
                                        value={password}
                                        onChange={handlePasswordChangeNew}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton onClick={handleToggleShowPassword}>
                                                        {showPassword ? <VisibilityOff fontSize="small" sx={{ height: '100%' }} /> : <Visibility fontSize="small" sx={{ height: '100%' }} />}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                            </Grid>

                            <Grid item container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle1" fontWeight="bold" color="#344357" sx={{ fontSize: '15px', marginTop: '10px' }}>
                                        Confirm New Password*
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <CustomTextfield
                                        size="small"
                                        id="confirm-password-input"
                                        placeholder="Confirm new password"
                                        fullWidth
                                        variant="outlined"
                                        type={showPassword ? 'text' : 'password'}
                                        value={confirmPassword}
                                        onChange={handlePasswordChangeConfirm}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton onClick={handleToggleShowPassword}>
                                                        {showPassword ? <VisibilityOff fontSize="small" sx={{ height: '100%' }} /> : <Visibility fontSize="small" sx={{ height: '100%' }} />}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                            </Grid>

                            <Grid variant='G16' item container>
                                <Button variant="B9" onClick={handleSubmit}>
                                    Submit
                                </Button>
                                <Button variant="B9" onClick={handleClose}>
                                    Cancel
                                </Button>
                            </Grid>
                        </Grid>
                    }
                </DialogContent>
            </BootstrapDialog>
            <Loader />
        </div>
    );
}

export default ChangePassword;