import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import SearchBar from '../CampaignsComponet/SearchBar';
import { useDispatch } from "react-redux";
import { setShowLoader } from '../../redux/loaderSlice';
import { Chip } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { MiniTabel } from './../../ThemeProvider/style';
import NoData from '../CommonComponet/NoData';
import dummyDataone from '../../JSON/dummyDataone.json'
import { BsFiletypeCsv } from 'react-icons/bs'
import { AiOutlineFilter } from 'react-icons/ai'
import { AiOutlineCloseCircle } from "react-icons/ai";
import Filters from '../CommonComponet/Filters';
import CustomizedTables from './MuiTabel';
import { Badge } from '@mui/material';

const TopProject = ({pageType ,data}) => {

    const dispatch = useDispatch();
    const [rows, setRows] = useState(data);
    const [filteredData, setFilteredData] = useState(data?.topInsight?.projectSet || []);

    const columns = [
        {
            field: 'index',
            headerName: 'S.No',
            width: 100,
            valueGetter: (params) => params.row._index,
            headerClassName: 'custom-header-class',
            sortable: false
        },
        {
            field: "projectTitle",
            headerName: " Project Title",
            width: 250,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ fontWeight: 'bold', color: 'black' }}>{params.row.ads}</Box>
                </Box>
            ),
            headerClassName: 'custom-header-class', sortable: false
        },
        { field: "durationVPO", headerName: "VPO Duration", width: 150, headerClassName: 'custom-header-class', sortable: false, },
        { field: "totalRevenue", headerName: "Total Revenue", sortable: false, width: 150, headerClassName: 'custom-header-class', },
        { field: "contentType", headerName: "Media Type", sortable: false, width: 150, headerClassName: 'custom-header-class', },
        { field: "vpoCount", headerName: "No of VPO", sortable: false, width: 150, headerClassName: 'custom-header-class', },
    ];

    const handelViewAll = () => {
        console.log('daghs')
    };

    return (
        <Grid mt={1.5} xs={12} variant='G3' display='flex' flexDirection='column' justifyContent='center' alignItems='flex-start'>
            <Grid xs={12} display='flex' ml={2} >
                <Typography variant='C1' style={{ fontSize: "16px" }}>{pageType === 'producer' ? 'Total 5 Projects' : 'Top 5 Projects'}</Typography>
            </Grid>
            <Grid xs={12} sx={{ border: '1px solod black', width: "96.4%" }} mt={1} mb={1} variant='G18' ml={2}>
                {filteredData.length === 0 ? (
                    <NoData />
                ) : (
                    <CustomizedTables
                        filterRow={filteredData}
                        columns={columns}
                        pagination="need"
                        rowAttributes={[
                            { name: "projectTitle", type: 'regular' },
                            { name: "durationVPO", type: 'regular' },
                            { name: "totalRevenue", type: 'regular' },
                            { name: "contentType", type: 'regular' },
                            { name: "vpoCount", type: 'regular' },
                        ]} />

                )}
            </Grid>
        </Grid>
    )
}

export default TopProject

