import React from 'react'
import { generateThumbnailURL } from '../../Routes/apiConfig';
import defaultThumbnail from '../../assets/NoVideo.png'
import CloseIcon from '@mui/icons-material/Close';
import { Card, CardMedia, Grid, Box, Paper, DialogActions, Divider } from "@mui/material";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';

const TrackDilogVfx = (props) => {
    const { selectedProject, type, track, setTrackCamp, trackCamp } = props;

    if (!selectedProject) {
        return null;
    }

    const url = selectedProject?.videoId;
    const thumbnailURL = generateThumbnailURL(url) || defaultThumbnail;

    return (
        <Dialog
            fullWidth={true}
            maxWidth='xs'
            open={trackCamp} onClose={() => setTrackCamp(false)}
        >
            <DialogTitle style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: "1px solid #e0e0e0" }}>
                {/* <Typography variant='h6'>Track Campaign</Typography> */}
                <figure>
                    <img src={thumbnailURL} alt="" />
                    <figcaption>
                        <Typography variant='CHead' style={{ fontSize: "17px" }}>Campaign Tracking Details</Typography>
                        <Typography variant='CHead'>VPO Number</Typography>
                        <Typography variant='CHead'># {`${selectedProject?.clipSet[0].projectTitle.split(' ')[0]}_${selectedProject?.clipSet[0].VppId.substring(0, 3)}`}</Typography>
                    </figcaption>
                </figure>
            </DialogTitle>

            <DialogContent sx={{ display: "flex", flexDirection: "column" }}>
                <Grid xs={12} mt={1} display='flex' justifyContent='center' alignItems='center' sx={{ background: "#eff4fd", borderRadius: "12px", minHeight: "65px" }}>
                    <Typography variant='GV' ml={1.5}>
                        Note:- The VPO campaign <Typography variant='GV' sx={{ fontWeight: "bold" }}>"{`${selectedProject?.clipSet[0].projectTitle.split(' ')[0]}_${selectedProject?.clipSet[0].VppId.substring(0, 3)}`}"</Typography> has been bought by <Typography variant='GV' sx={{ fontWeight: "bold" }}>"{selectedProject?.paymentDetails.userName}"</Typography> from marketplace
                    </Typography>
                </Grid>
                <Box sx={{ maxWidth: '100%' }}>
                    <Stepper orientation="vertical" >

                        <Step >
                            <StepLabel>
                                <Typography variant='CHead' style={{ color: "#49b51f" }}>Campaign Created</Typography>
                            </StepLabel>
                            <StepContent>
                                <Typography></Typography>
                            </StepContent>
                        </Step>

                        <Step >
                            <StepLabel>
                                {selectedProject?.brandLogo ? (
                                    <Typography variant='CHead' style={{ color: "#49b51f" }}>
                                        Advertiser has uploaded designs
                                    </Typography>
                                ) : (
                                    <Typography variant='CHead' style={{ color: "#ef964c" }}>
                                        Waiting for Advertiser to upload designs
                                    </Typography>
                                )}

                            </StepLabel>
                            <StepContent>
                                <Typography></Typography>
                            </StepContent>
                        </Step>

                        <Step >
                            <StepLabel>
                                <Typography variant='CHead' ></Typography>
                                {selectedProject?.clipSet[0].branded ? (
                                    <Typography variant='CHead' style={{ color: "#49b51f" }}>
                                        "VPP Branded Scenes" Ready for Approval
                                    </Typography>
                                ) : (
                                    <Typography variant='CHead' style={{ color: "#ef964c" }}>
                                        Waiting for "VPP Branded Scenes" by you
                                    </Typography>
                                )}
                            </StepLabel>
                            <StepContent>
                                <Typography></Typography>
                            </StepContent>
                        </Step>

                        <Step >
                            <StepLabel>
                                {selectedProject?.brandStatus === true ? (
                                    <Typography variant='CHead' style={{ color: "#49b51f" }}>
                                        Approved by Advertiser
                                    </Typography>
                                ) : (
                                    <Typography variant='CHead' style={{ color: "#ef964c" }}>
                                        Waiting for Advertiser's approval
                                    </Typography>
                                )}
                            </StepLabel>
                            <StepContent>
                                <Typography></Typography>
                            </StepContent>
                        </Step>

                        <Step >
                            <StepLabel>
                                {selectedProject?.creatorStatus === true ? (
                                    <Typography variant='CHead' style={{ color: "#49b51f" }}>
                                        Approved by Producer
                                    </Typography>
                                ) : (
                                    <Typography variant='CHead' style={{ color: "#ef964c" }}>
                                        Waiting for Producer's approval 
                                    </Typography>
                                )}
                            </StepLabel>
                            <StepContent>
                                <Typography></Typography>
                            </StepContent>
                        </Step>

                        <Step >
                            <StepLabel>
                                {selectedProject?.brandStatus === true && selectedProject?.creatorStatus === true ? (
                                    <Typography variant='CHead' style={{ color: "#49b51f" }}>
                                        Campaign is ready
                                    </Typography>
                                ) : (
                                    <Typography variant='CHead' style={{ color: "#ef964c" }}>
                                        Campaign is ready
                                    </Typography>
                                )}
                            </StepLabel>
                            <StepContent>
                                <Typography></Typography>
                            </StepContent>
                        </Step>

                    </Stepper>
                </Box>

            </DialogContent>
            <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
                <Button onClick={() => setTrackCamp(false)} variant="outlined" color="error" sx={{ marginBottom: "7px" }}>
                    Close
                </Button>
            </DialogActions>

        </Dialog>
    )
}

export default TrackDilogVfx