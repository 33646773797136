
import { Grid, Card, Button, Chip, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import NoData from '../CommonComponet/NoData';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch } from "react-redux";
import { awsApiCamp } from '../../Routes/Server';
import { setShowLoader } from '../../redux/loaderSlice';
import Loader from '../CommonComponet/Loader'

const Details = ({ selectedVpp, handleUpdateMessages }) => {
  const dispatch = useDispatch();
  const [selectedScene, setSelectedScene] = useState('Original');
  const [isOriginalSceneVisible, setIsOriginalSceneVisible] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogOpenReject, setDialogOpenReject] = useState(false);

  const brandStatus = selectedVpp?.brandStatus;
  const creatorStatus = selectedVpp?.creatorStatus;
  const producerUnbranded = selectedVpp?.clipSet[0].brandUnbrandedScene
  const producerBranded = selectedVpp?.clipSet[0].brandBrandedScene
  const [chipLabel, setChipLabel] = useState('');
  const [chipColor, setChipColor] = useState('');

  useEffect(() => {
    const videoElements = document.querySelectorAll('video');
    videoElements.forEach((video) => {
      if (video.paused === false) {
        video.pause();
      }
    });
    const currentVideo = document.getElementById(`video-${selectedScene}`);
    if (currentVideo) {
      currentVideo.play();
    }
  }, [selectedScene]);


  const handleSceneChange = (scene) => {
    setSelectedScene(scene);
    setIsOriginalSceneVisible(scene === 'Original');
  };

  useEffect(() => {
    if (selectedScene === 'preApproved') {
      const { label, color } = decideColor();
      setChipLabel(label);
      setChipColor(color);
    } else if (selectedScene === 'newEmbedded') {
      const { label, color } = decideColorBrand();
      setChipColor(color);
      setChipLabel(label);
    }
  }, [selectedScene, selectedVpp]);

  const decideColor = () => {
    const clipSet = selectedVpp?.clipSet?.[0];
    if (!clipSet || !clipSet.unbrandedStatus) {
      console.error("ClipSet or unbrandedStatus is undefined or null");
      return { label: "Undefined", color: "error" };
    }
    const brandStatus = clipSet.brandUnbrandedScene;
    const creatorStatus = clipSet.producerUnbrandedScene;
    let label = clipSet.unbrandedStatus.charAt(0).toUpperCase() + clipSet.unbrandedStatus.slice(1);
    let color = "secondary";

    if (brandStatus && creatorStatus) {
      label = "Approved";
      color = "success";
    } else if (!brandStatus && creatorStatus) {
      label = "Your Approval Pending";
      color = "warning";
    } else if (brandStatus && !creatorStatus) {
      label = "Producer Approval Pending";
      color = "warning";
    } else if (!brandStatus && !creatorStatus) {
      label = "Both Approvel Pending";
      color = "secondary";
    }
    return { label, color };
  };

  const decideColorBrand = () => {
    const clipSet = selectedVpp?.clipSet?.[0];
    if (!clipSet || !clipSet.brandedStatus) {
      console.error("ClipSet or unbrandedStatus is undefined or null");
      return { label: "Undefined", color: "error" };
    }
    const brandStatus = clipSet.brandBrandedScene;
    const creatorStatus = clipSet.producerBrandedScene;
    let label = clipSet.brandedStatus.charAt(0).toUpperCase() + clipSet.brandedStatus.slice(1);
    let color = "secondary";

    if (brandStatus && creatorStatus) {
      label = "Approved";
      color = "success";
    } else if (!brandStatus && creatorStatus) {
      label = "Your Approval Pending";
      color = "warning";
    } else if (brandStatus && !creatorStatus) {
      label = "Producer Approval Pending";
      color = "warning";
    } else if (!brandStatus && !creatorStatus) {
      label = "Both Approvel Pending";
      color = "secondary";
    }
    return { label, color };
  };

  const handleApprove = async () => {
    dispatch(setShowLoader(true));
    console.log('test 1')
    try {
      let updatedData;
      if (selectedVpp && selectedScene === 'preApproved') {
        console.log('test 2 a')
        if (selectedVpp.clipSet[0].producerUnbrandedScene === true) {
          updatedData = {
            ...selectedVpp,
            clipSet: selectedVpp.clipSet.map(clip => ({
              ...clip,
              brandUnbrandedScene: true,
              unbrandedStatus: 'approved'
            })),
          };
        } else {
          console.log('test 2 b')
          updatedData = {
            ...selectedVpp,
            clipSet: selectedVpp.clipSet.map(clip => ({
              ...clip,
              brandUnbrandedScene: true,
            })),
          };
        }
      } else {
        if (selectedVpp.clipSet[0].producerBrandedScene === true) {
          console.log('test c')
          updatedData = {
            ...selectedVpp,
            clipSet: selectedVpp.clipSet.map(clip => ({
              ...clip,
              brandBrandedScene: true,
              brandedStatus: 'approved'
            })),
          };
        } else {
          console.log('test d')
          updatedData = {
            ...selectedVpp,
            clipSet: selectedVpp.clipSet.map(clip => ({
              ...clip,
              brandBrandedScene: true,
            })),
          };
        }
      }
      const res = await awsApiCamp.post('/postcampaign', {
        ...updatedData,
      });
      console.log('test 3')
      if (res.data.statusCode === 200) {
        setDialogOpen(false);
        console.log('test 4')
        handleUpdateMessages();
        console.log('test 5')
      }
    } catch (error) {
      console.error('Error approving data:', error);
    } finally {
      dispatch(setShowLoader(false));
    }
    console.log('test 6')
  };

  const handleReject = async () => {
    dispatch(setShowLoader(true));
    try {
      let updatedData;
      if (selectedVpp && selectedScene === 'preApproved') {
        updatedData = {
          ...selectedVpp,
          clipSet: selectedVpp.clipSet.map(clip => ({
            ...clip,
            brandUnbrandedScene: false,
            unbrandedStatus: 'rejected'
          })),
        };
      } else {
        updatedData = {
          ...selectedVpp,
          clipSet: selectedVpp.clipSet.map(clip => ({
            ...clip,
            brandBrandedScene: false,
            brandedStatus: 'rejected'
          })),
        };
      }
      const res = await awsApiCamp.post('/postcampaign', {
        ...updatedData,
      });
      console.log('After Axios request', res);
      if (res.data.statusCode === 200) {
        handleUpdateMessages()
        setDialogOpenReject(false);
      }
    } catch (error) {
      console.error('Error rejecting data:', error);
    } finally {
      dispatch(setShowLoader(false));
    }
  };

  console.log(selectedVpp)

  return (
    <Grid xs={12} container>
      <Grid item xs={12} container variant="G26">
        <Grid
          item
          xs={6}
          variant="G26"
          onClick={() => handleSceneChange('Original')}
          style={{
            cursor: 'pointer',
            backgroundColor: selectedScene === 'Original' ? 'black' : 'transparent',
          }}
        >
          <Typography variant="P21" mb={2} mt={2} sx={{ color: selectedScene === 'Original' ? 'white' : 'black' }}>
            Original
          </Typography>
        </Grid>
        {/* <Grid
          item
          xs={4}
          variant="G26"
          onClick={() => handleSceneChange('preApproved')}
          style={{
            cursor: 'pointer',
            backgroundColor: selectedScene === 'preApproved' ? 'black' : 'transparent',
          }}
        >
          <Typography variant="P21" mb={2} mt={2} sx={{ color: selectedScene === 'preApproved' ? 'white' : 'black' }}>
            Pre Approved
          </Typography>
        </Grid> */}
        <Grid
          item
          xs={6}
          variant="G26"
          onClick={() => handleSceneChange('newEmbedded')}
          style={{
            cursor: 'pointer',
            backgroundColor: selectedScene === 'newEmbedded' ? 'black' : 'transparent',
          }}
        >
          <Typography variant="P21" mb={2} mt={2} sx={{ color: selectedScene === 'newEmbedded' ? 'white' : 'black' }}>
            Branded Scene
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <div
          style={{
            width: "100%",
            height: "100%",
            display: isOriginalSceneVisible ? 'block' : 'none',
            opacity: selectedScene === 'Original' ? 1 : 0,
            transition: 'opacity 0.3s ease',
          }}
        >
          {selectedVpp?.clipSet && selectedVpp.clipSet.length > 0 ? (
            <video key={selectedVpp.clipSet[0].VppClipUrl} controls width="100%" style={{ minheight: "57vh", maxHeight: "70vh", background: "black" }}>
              <source src={selectedVpp.clipSet[0].VppClipUrl} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          ) : (
            <img src="https://www.rrcampus.com/images/no-video.jpg" alt="No Video Available" width="100%" style={{ marginTop: '8px' }} />
          )}

        </div>
        <div
          style={{
            display: isOriginalSceneVisible ? 'none' : selectedScene === 'preApproved' ? 'block' : 'none',
            opacity: selectedScene === 'preApproved' ? 1 : 0,
            transition: 'opacity 0.3s ease',
          }}
        >
          {/* {selectedVpp?.clipSet && selectedVpp.clipSet.length > 0 ? ( */}
          {selectedVpp?.clipSet && selectedVpp.clipSet.length > 0 && selectedVpp.clipSet[0]?.unbranded ? (
            <video id="video-Original" key={selectedVpp.clipSet[0].unbranded?.Location} controls width="100%" style={{ minheight: "57vh", maxHeight: "70vh", background: "black" }}>
              <source src={selectedVpp.clipSet[0].unbranded?.Location} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          ) : (
            <img src="https://www.rrcampus.com/images/no-video.jpg" alt="No Video Available" width="100%" />
          )}
        </div>
        <div
          style={{
            display: isOriginalSceneVisible ? 'none' : selectedScene === 'newEmbedded' ? 'block' : 'none',
            opacity: selectedScene === 'newEmbedded' ? 1 : 0,
            transition: 'opacity 0.3s ease',
          }}
        >
          {selectedVpp?.clipSet && selectedVpp.clipSet.length > 0 && selectedVpp.clipSet[0]?.branded ? (
            <video key={selectedVpp.clipSet[0]?.branded?.Location} controls width="100%" style={{ minheight: "57vh", maxHeight: "70vh", background: "black" }}>
              <source src={selectedVpp.clipSet[0]?.branded?.Location} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          ) : (
            <img src="https://www.rrcampus.com/images/no-video.jpg" alt="No Video Available" width="100%" />
          )}
        </div>
      </Grid>
      {/* <Grid mt={0.5} mb={1.5} display='flex' alignItems='center' justifyContent='flex-end' xs={12}>
        {selectedScene === 'preApproved' && selectedVpp?.clipSet && selectedVpp.clipSet[0]?.unbrandedStatus !== 'rejected' && selectedVpp.clipSet[0]?.unbranded && (
          <Chip label={chipLabel} color={chipColor || "default"} variant="outlined" style={{ marginRight: "13px" }} />
        )}
        {selectedScene === 'newEmbedded' && selectedVpp?.clipSet && selectedVpp.clipSet[0]?.brandedStatus !== 'rejected' && selectedVpp.clipSet[0]?.branded && (
          <Chip label={chipLabel} color={chipColor || "default"} variant="outlined" style={{ marginRight: "13px" }} />
        )}
        {selectedVpp?.clipSet && selectedVpp.clipSet[0]?.unbranded && selectedScene === 'preApproved' && !producerUnbranded && (
          <>
            {selectedVpp?.clipSet[0]?.unbrandedStatus !== 'rejected' ? (
              <>
                <Button variant="B2S" sx={{ marginRight: '15px' }} onClick={() => setDialogOpen(true)}>Approve Unbranded Scene</Button>
                <Button variant="B2S" sx={{ marginRight: '10px' }} onClick={() => setDialogOpenReject(true)}>Reject Unbranded Scene</Button>
              </>
            ) : (
              <Chip label={'Rejected'} color='error' variant="outlined" style={{ marginRight: "13px" }} />
            )}
          </>
        )}
        {selectedVpp?.clipSet && selectedVpp.clipSet[0]?.unbranded && selectedScene === 'newEmbedded' && !producerBranded && (
          <>
            {selectedVpp?.clipSet[0]?.brandedStatus !== 'rejected' ? (
              <>
                <Button variant="B2S" sx={{ marginRight: '15px' }} onClick={() => setDialogOpen(true)}>Approve Branded Scene</Button>
                <Button variant="B2S" sx={{ marginRight: '10px' }} onClick={() => setDialogOpenReject(true)}>Reject Branded Scene</Button>
              </>
            ) : (
              <Chip label={'Rejected'} color='error' variant="outlined" style={{ marginRight: "13px" }} />
            )}
          </>
        )}
      </Grid> */}
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} >
        <DialogTitle >{"Approve campaign"}</DialogTitle>
        <DialogContent >{"Are You Sure You want to approve Unbranded Scene."}</DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
          <Button onClick={() => setDialogOpen(false)} variant="outlined" color="error">
            Cancel
          </Button>
          <Button onClick={handleApprove} autoFocus variant="outlined" color="success">
            Approve
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={dialogOpenReject} onClose={() => setDialogOpenReject(false)} >
        <DialogTitle >{"Approve campaign"}</DialogTitle>
        <DialogContent >{"Are You Sure You want to Reject Unbranded Scene."}</DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
          <Button onClick={() => setDialogOpenReject(false)} variant="outlined" color="error">
            Cancel
          </Button>
          <Button onClick={handleReject} autoFocus variant="outlined" color="success">
            Reject
          </Button>
        </DialogActions>
      </Dialog>
      <Loader />
    </Grid>
  );
};

export default Details;
