import Dashboard from "../Pages/Dashboard";
import ProjectList from "../Pages/Project/ProjectList";
import DraftList from "../Pages/Project/DraftList";
import VppInventory from "../Pages/VppInventory";
import Message from "../Pages/Message";
import ViewProject from '../Components/ProjectComponet/ViewProject'
import Campaigns from "../Pages/Campaigns";
import VppMain from "../Components/VppComponet/VppMain";
import Earnings from "../Pages/Earnings";
import { TbPlayerTrackNextFilled } from 'react-icons/tb'
import { GoProject } from 'react-icons/go'
import { SiWechat } from 'react-icons/si'
import { FaWallet } from 'react-icons/fa'
import { ImBook } from 'react-icons/im'
import { LuListEnd } from 'react-icons/lu'
import { RiPlayList2Line } from 'react-icons/ri'
import { HiSquaresPlus } from 'react-icons/hi2'
import Profile from '../Components/ProfileComponet/Profile'
import { IoCameraReverse } from "react-icons/io5";
import SingleCameraProducer from "../Pages/SingleCameraProducer";
import SSAI from '../Pages/SSAI'
import AdSetup from "../Pages/AdSetup";
import { RiAdvertisementLine } from "react-icons/ri";
import { LuServerCrash } from "react-icons/lu";

const routes = [
    {
        name: "Dashboard",
        path: "/Dashboard",
        component: Dashboard,
        icon: <HiSquaresPlus />
    },

    {
        name: "Projects",
        path: "/ProjectList",
        component: ProjectList,
        icon: <GoProject />,
        routes: [
            {
                name: "Draft List",
                path: "/DraftList",
                component: DraftList,
                icon: <LuListEnd />
            }
        ]
    },
    {
        name: "VPO AI (Beta)",
        path: "/VppInventory",
        component: VppInventory,
        icon: <TbPlayerTrackNextFilled />
    },
    // {
    //     name: "Single Camera",
    //     path: "/SingleCameraProducer",
    //     component: SingleCameraProducer,
    //     icon: <IoCameraReverse />
    // },
    {
        name: "Campaigns",
        path: "/Campaigns",
        component: Campaigns,
        icon: <ImBook />
    },
    // {
    //     name: "Earnings",
    //     path: "/Earnings",
    //     component: Earnings,
    //     icon: <FaWallet />
    // },
    {
        name: "Ad Setup",
        path: "/AdSetup",
        component: AdSetup,
        icon: <RiAdvertisementLine />
    },
    {
        name: "Message",
        path: "/Message",
        component: Message,
        icon: <SiWechat />
    },
    
    // {
    //     name: "SSAI",
    //     path: "/SSAI",
    //     component: SSAI,
    //     icon: <LuServerCrash />
    // },
    {
        path: "/ViewProject",
        name: "",
        component: ViewProject,
    },
    {
        path: "/VppMain",
        name: "",
        component: VppMain,
    },
    {
        path: "/DraftList",
        name: "",
        component: DraftList,
    },
    {
        path: "/Profile",
        name: "",
        component: Profile,
    },
    {
        path: "/ProjectList",
        component: ProjectList,
    },
];

export default routes;
