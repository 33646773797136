import React, { useState } from 'react'
import Bar from '../../Components/CommonComponet/Bar'
import { Button, Grid, Snackbar,IconButton  } from '@mui/material'
import StepOne from '../AddProject/StepOne';
import CloseIcon from '@mui/icons-material/Close';

const TopBar = ({ ComponentName, fetchDataForProjectUpload, fetchDraftProjects }) => {

    const [openDialog, setOpenDialog] = useState(false);

    const handleClickOpen = () => {
        setOpenDialog(true);
    };

    const handleClose = () => {
        setOpenDialog(false);
    };

    const [openSankbar, setOpenSankbar] = useState(false);
    const [sankbarMessage, setSankbarMessage] = useState('')


    const handleCloseSankbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSankbar(false);
    };

    const action = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleCloseSankbar}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    console.log('sankbarMessage', sankbarMessage)
    console.log('openSankbarSate',openSankbar)

    return (
        <Grid xs={12} display='flex' justifyContent='space-between' alignItems='center'>
            <Grid xs={6}>
                <Bar ComponentName={ComponentName} />
            </Grid>
            <Button variant='B1' onClick={() => { handleClickOpen() }}>New Project</Button>
            <StepOne open={openDialog} onClose={handleClose} fetchDataForProjectUpload={fetchDataForProjectUpload} fetchDraftProjects={fetchDraftProjects}
                setSankbarMessage={setSankbarMessage}
                setOpenSankbar={setOpenSankbar}
            />
            <Snackbar
                open={openSankbar}
                autoHideDuration={6000}
                onClose={handleCloseSankbar}
                message={sankbarMessage}
                action={action}
            />
        </Grid>
    )
}

export default TopBar
