import CloseIcon from '@mui/icons-material/Close';
import { Grid } from "@mui/material";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import CustomTextfield from './../CommonComponet/CustomTextfield';
import SelectField from '../CommonComponet/SelectFiels';
import { useState } from 'react'
import MultiVideoUpload from './MultiVideoUpload'
import { useDispatch } from "react-redux";
import { setShowLoader } from '../../redux/loaderSlice';
import { v4 as uuidv4 } from 'uuid';
import Loader from '../CommonComponet/Loader'
import Tooltip from '@mui/material/Tooltip';
import { MultiSelect } from 'primereact/multiselect';
import Select from "react-select";
import { SelectNew } from '../../ThemeProvider/style';
import {
    VPPTypeOptions, releasePlatform, averageViewPerMonthOptions, newTypeofContentOptions,
    releaseTypeOptions, typeofContentOptions, genresOptions, seasonOptions, ListCountry, brandCategoryOptions
} from '../CommonComponet/docsData'
import { awsApi } from '../../Routes/Server';

export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));



export function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;
    return (
        <DialogTitle sx={{ m: 0, p: 1 }} {...other}>
            <Typography variant='P15' style={{ fontSize: '18px' }} ml={1}>{children}</Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 6,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

const StepOne = ({ open, onClose,
    fetchDataForProjectUpload,
    fetchDraftProjects, setOpenDialog,
    rowData, setSankbarMessage,
    setOpenSankbar }) => {
    const dispatch = useDispatch();

    const [confirmModal, setConfirmModal] = useState(false);
    const [projectId, setProjectID] = useState('');
    const [videoDialog, setVideoDialog] = useState(true);
    const [selectedContentType, setSelectedContentType] = useState('');
    const [projectTitle, setProjectTitle] = useState('');
    const [selectedVppType, setSelectedVppType] = useState('');

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const handleClickOpen = (projectId) => {
        setVideoDialog(true);
        setFormData(formDataInitialState);
        setProjectID(projectId);
        setSelectedContentType(formData.contentType);
        setProjectTitle(formData.title);
        setSelectedVppType(formData.vppType)
    };

    const [formErrors, setFormErrors] = useState({});
    const [backData, setBackData] = useState()

    const timestamp = Math.floor(new Date().getTime() / 1000);

    const formDataInitialState = {
        id: rowData?.id || backData?.id || uuidv4(),
        title: rowData?.title || backData?.title || '',
        country: rowData?.country || backData?.country || '',
        vppType: 'update',
        contentType: rowData?.contentType || backData?.contentType || '',
        releasePlatform: rowData?.releasePlatform || backData?.releasePlatform || [],
        releaseType: rowData?.releaseType || backData?.releaseType || '',
        genres: rowData?.genres || backData?.genres || [],
        brandCategory: rowData?.brandCategory || backData?.brandCategory || [],
        averageViewPerMonth: rowData?.averageViewPerMonth || backData?.averageViewPerMonth || '',
        season: rowData?.season || backData?.season || '',
        vppPrice: rowData?.vppPrice || backData?.vppPrice || '',
        timestamp: timestamp,
    };

    const [formData, setFormData] = useState(formDataInitialState);

    const handleReleasePlatformChange = (selectedOptions) => {
        const selectedValues = selectedOptions.map((option) => option.value);
        setFormData({
            ...formData,
            releasePlatform: selectedValues,
        });
        setFormErrors((prevErrors) => ({
            ...prevErrors,
            releasePlatform: '',
        }));
    };

    const handleGenresChange = (selectedOptions) => {
        const selectedValues = selectedOptions.map((option) => option.value);
        setFormData({
            ...formData,
            genres: selectedValues,
        });
        setFormErrors((prevErrors) => ({
            ...prevErrors,
            genres: '',
        }));
    };

    const handleBrandCategoryChange = (selectedOptions) => {
        const selectedValues = selectedOptions.map((option) => option.value);
        setFormData({
            ...formData,
            brandCategory: selectedValues,
        });
        setFormErrors((prevErrors) => ({
            ...prevErrors,
            brandCategory: '',
        }));
    };

    const handleSelectChange = (fieldName, selectedOption) => {
        setFormData({
            ...formData,
            [fieldName]: selectedOption.value,
        });
        setFormErrors((prevErrors) => ({
            ...prevErrors,
            [fieldName]: '',
        }));
    };

    const validateForm = () => {
        const errors = {};

        if (formData.title.trim() === '') {
            errors.title = '* Please enter a title';
        } else {
            delete errors.title;
        }


        if (formData.country.trim() === '') {
            errors.country = '* Please select a country';
        } else {
            delete errors.country;
        }

        // if (formData.vppType.trim() === '') {
        //     errors.vppType = '* Please select a VPO type';
        // } else {
        //     delete errors.vppType;
        // }

        if (formData.contentType.trim() === '') {
            errors.contentType = '* Please select a content type';
        } else {
            delete errors.contentType;
        }


        if (formData.releasePlatform.length === 0) {
            errors.releasePlatform = '* Please select at least one release platform';
        } else {
            delete errors.releasePlatform;
        }


        if (formData.releaseType.trim() === '') {
            errors.releaseType = '* Please select a release type';
        } else {
            delete errors.releaseType;
        }

        if (formData.genres.length === 0) {
            errors.genres = '* Please select at least one genre';
        } else {
            delete errors.genres;
        }
        // if (formData.brandCategory.length === 0) {
        //     errors.brandCategory = '* Please select at least one Brand Category';
        // } else {
        //     delete errors.brandCategory;
        // }

        if (formData.averageViewPerMonth.trim() === '') {
            errors.averageViewPerMonth = '* Please enter the average views per month';
        } else {
            delete errors.averageViewPerMonth;
        }

        if (validTypes.includes(formData.contentType)) {
            if (formData.season === 0) {
                errors.season = '* Please select at least one season';
            }
        }

        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
        setFormErrors((prevErrors) => ({
            ...prevErrors,
            [name]: '',
        }));
    };



    const handleDraftSubmit = async () => {
        const isFormValid = validateForm();
        if (!isFormValid) {
            return;
        }
        dispatch(setShowLoader(true));
        const requestBody = {
            ...formData,
            type: 'draft',
            video: [],
            projectId: formData.id,
        };
        try {
            const res = await awsApi.post('/postproject', requestBody);
            if (res.data.statusCode === 200) {
                onClose();
            }
        } catch (err) {
            console.log(err, "error");
        } finally {
            dispatch(setShowLoader(false));
            fetchDraftProjects()
        }
    };

    const handleSubmit = async () => {
        const isFormValid = validateForm();
        if (!isFormValid) {
            return;
        }
        dispatch(setShowLoader(true));
        const requestBody = {
            ...formData,
            type: 'draft',
            video: [],
            projectId: formData.id
        };

        try {
            const res = await awsApi.post('/postproject', requestBody);
            // console.log(res.data, "post Project");
            if (res.data.statusCode === 200) {
                const projectId = requestBody.id;
                setProjectID(projectId);
                handleClickOpen(projectId);
                setVideoDialog(false);
                // fetchDraftProjects()
            }
        } catch (err) {
            console.log(err, "error");
        } finally {
            dispatch(setShowLoader(false));
        }
    };



    const validTypes = ['tv-show', 'web-series'];

    const resetForm = () => {
        setFormData(formDataInitialState);
        setFormErrors({});
    };

    React.useEffect(() => {
        if (!open) {
            resetForm();
        }
    }, [open]);

    const tooltipContent = (
        <div >
            <div>*Pre Approved VPO- Scenes shortlisted or pre-approved by creator team. Upload only scenes not the entire project videos for marketplace.</div>
            <div>**New VPP – Upload full videos to find VPO with AI analysis</div>
        </div>
    );

    const [widthStyle, setWidthStyle] = useState('70vw');
    console.log("formData", formData)

    React.useEffect(() => {
        function handleResize() {
            const windowWidth = window.innerWidth;
            if (windowWidth < 1400) {
                setWidthStyle('80vw');
            } else if (windowWidth < 1320) {
                setWidthStyle('88vw');
            } else if (windowWidth < 1230) {
                setWidthStyle('97vw');
            } else {
                setWidthStyle('70vw');
            }
        }
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    const coloseDilogeBox = () => {
        setConfirmModal(false);
        setVideoDialog(true);
        onClose()
        setBackData()
    }

    return (
        <BootstrapDialog
            onClose={coloseDilogeBox}
            aria-labelledby="customized-dialog-title"
            open={open}
            maxWidth="xxl"
        >
            <BootstrapDialogTitle id="customized-dialog-title" onClose={coloseDilogeBox} >
                {videoDialog ? 'Project Details' : 'Add Videos'}
            </BootstrapDialogTitle>
            <DialogContent dividers sx={{ width: widthStyle, padding: "24px !important" }} >
                {videoDialog ?
                    <>

                        <Grid container spacing={3} display='flex' justifyContent='flex-start' alignContent='center'>

                            <Grid
                                item
                                xs={4}
                                container
                                sx={{ height: '95px' }}
                                mb={1}
                            >
                                <Grid
                                    item
                                    xs={12}
                                >
                                    <Typography
                                        variant="P22"
                                    >
                                        {"Project Title*"}
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={12} mt={0.4}
                                >
                                    <CustomTextfield
                                        name="title"
                                        size="small"
                                        placeholder="Enter Title"
                                        fullWidth
                                        variant="outlined"
                                        value={formData.title}
                                        onChange={handleChange}
                                        className={formErrors.title ? 'error' : ''}
                                    />
                                    {formErrors.title && <Typography variant='P23' className="error-message">{formErrors.title}</Typography>}
                                </Grid>
                            </Grid>
                            {/* Country */}
                            <Grid
                                item
                                xs={4}
                                container
                                sx={{ height: '95px' }} mb={1}
                            >
                                <Grid
                                    item
                                    xs={12}
                                >
                                    <Typography
                                        variant="P22"
                                    >
                                        {"Country*"}
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={12} mt={0.4}
                                >
                                    <Select
                                        className={formErrors.country ? 'error' : ''}
                                        options={ListCountry}
                                        value={ListCountry.find((option) => option.value === formData.country)}
                                        onChange={(selectedOption) => handleSelectChange('country', selectedOption)}
                                    />
                                    {formErrors.country && <Typography variant='P23' className="error-message">{formErrors.country}</Typography>}
                                </Grid>


                            </Grid>
                            {/* <Grid
                                item
                                xs={4}
                                container
                                sx={{ height: '95px' }} mb={1}
                            >
                                <Grid
                                    item
                                    xs={12}
                                >
                                    <Tooltip title={tooltipContent}>
                                        <Typography
                                            variant="P22"
                                        >
                                            {"Available VPO Type* (i)"}
                                        </Typography>
                                    </Tooltip>
                                </Grid>
                                <Grid
                                    item
                                    xs={12} mt={0.4}
                                >

                                    <Select
                                        className={formErrors.vppType ? 'error' : ''}
                                        options={VPPTypeOptions}
                                        value={VPPTypeOptions.find((option) => option.value === formData.vppType)}
                                        onChange={(selectedOption) => handleSelectChange('vppType', selectedOption)}
                                    />
                                    {formErrors.vppType && <Typography variant='P23' className="error-message">{formErrors.vppType}</Typography>}
                                </Grid>
                            </Grid> */}

                            <Grid
                                item
                                xs={4}
                                container
                                sx={{ height: '95px' }} mb={1}
                            >
                                <Grid
                                    item
                                    xs={12}
                                >
                                    <Typography
                                        variant="P22"
                                    >
                                        {"Type of Content*"}
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={12} mt={0.4}
                                >
                                    {formData.vppType === 'single_camera_and_no_movement' ? (
                                        <Select
                                            className={formErrors.contentType ? 'error' : ''}
                                            options={newTypeofContentOptions}
                                            value={newTypeofContentOptions.find((option) => option.value === formData.contentType)}
                                            onChange={(selectedOption) => handleSelectChange('contentType', selectedOption)}
                                        />
                                    ) : (
                                        <Select
                                            className={formErrors.contentType ? 'error' : ''}
                                            options={typeofContentOptions}
                                            value={typeofContentOptions.find((option) => option.value === formData.contentType)}
                                            onChange={(selectedOption) => handleSelectChange('contentType', selectedOption)}
                                        />
                                    )}
                                    {formErrors.contentType && <Typography variant='P23' className="error-message">{formErrors.contentType}</Typography>}
                                </Grid>
                            </Grid>

                            {validTypes.includes(formData.contentType) && <Grid
                                item
                                xs={4}
                                container
                                sx={{ height: '95px' }} mb={1}
                            >
                                <Grid
                                    item
                                    xs={12}
                                >
                                    <Typography
                                        variant="P22"
                                    >
                                        {"Season Number (No)​*"}
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={12} mt={0.4}
                                >
                                    <Select
                                        className={formErrors.season ? 'error' : ''}
                                        options={seasonOptions}
                                        value={seasonOptions.find((option) => option.value === formData.season)}
                                        onChange={(selectedOption) => handleSelectChange('season', selectedOption)}
                                        menuPlacement="bottom"
                                    />
                                    {formErrors.season && <Typography variant='P23' className="error-message">{formErrors.season}</Typography>}

                                </Grid>
                            </Grid>}

                            <Grid
                                item
                                xs={4}
                                container
                                sx={{ height: '95px' }} mb={1}
                            >
                                <Grid
                                    item
                                    xs={12}
                                >
                                    <Typography
                                        variant="P22"
                                    >
                                        {"Release Platform*"}
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={12} mt={0.4}
                                >
                                    <Select
                                        closeMenuOnSelect={false}
                                        isMulti
                                        options={releasePlatform}
                                        value={formData.releasePlatform ? releasePlatform.filter((option) =>
                                            formData.releasePlatform.includes(option.value)
                                        ) : []}
                                        onChange={handleReleasePlatformChange}
                                        className={formErrors.releasePlatform ? 'error' : ''}
                                    />
                                    {formErrors.releasePlatform && <Typography variant='P23' className="error-message">{formErrors.releasePlatform}</Typography>}
                                </Grid>
                            </Grid>

                            <Grid
                                item
                                xs={4}
                                container
                                sx={{ height: '95px' }} mb={1}
                            >
                                <Grid
                                    item
                                    xs={12}
                                >
                                    <Typography
                                        variant="P22"
                                    >
                                        {"Release Type*"}
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={12} mt={0.4}
                                >
                                    <Select
                                        className={formErrors.releaseType ? 'error' : ''}
                                        options={releaseTypeOptions}
                                        value={releaseTypeOptions.find((option) => option.value === formData.releaseType)}
                                        onChange={(selectedOption) => handleSelectChange('releaseType', selectedOption)}
                                    />
                                    {formErrors.releaseType && <Typography variant='P23' className="error-message">{formErrors.releaseType}</Typography>}
                                </Grid>
                            </Grid>
                            {/* Genre */}
                            <Grid
                                item
                                xs={4}
                                container
                                sx={{ height: '95px' }} mb={1}
                            >
                                <Grid
                                    item
                                    xs={12}
                                >
                                    <Typography
                                        variant="P22"
                                    >
                                        {"Genre*"}
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={12} mt={0.4}
                                >
                                    <Select
                                        closeMenuOnSelect={false}
                                        isMulti
                                        options={genresOptions}
                                        value={formData.genres ? genresOptions.filter((option) =>
                                            formData.genres.includes(option.value)
                                        ) : []}
                                        onChange={handleGenresChange}
                                        className={formErrors.genres ? 'error' : ''}

                                        menuPlacement="auto"
                                    />
                                    {formErrors.genres && <Typography variant='P23' className="error-message">{formErrors.genres}</Typography>}
                                </Grid>
                            </Grid>
                            <Grid
                                item
                                xs={4}
                                container
                                sx={{ height: '95px' }} mb={1}
                            >
                                <Grid
                                    item
                                    xs={12}
                                >
                                    <Typography
                                        variant="P22"
                                    >
                                        {"Average View per Month*"}
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={12} mt={0.4}
                                >
                                    <Select
                                        className={formErrors.averageViewPerMonth ? 'error' : ''}
                                        options={averageViewPerMonthOptions}
                                        value={averageViewPerMonthOptions.find((option) => option.value === formData.averageViewPerMonth)}
                                        onChange={(selectedOption) => handleSelectChange('averageViewPerMonth', selectedOption)}
                                        menuPlacement="auto"
                                    />
                                    {formErrors.averageViewPerMonth && <Typography variant='P23' className="error-message">{formErrors.averageViewPerMonth}</Typography>}
                                </Grid>
                            </Grid>
                            {(formData.vppType === 'single_camera_and_no_movement' || formData.vppType === 'pre_approved_vpp') && (
                                // {formData.vppType === 'single_camera_and_no_movement' && (
                                <Grid
                                    item
                                    xs={4}
                                    container
                                    sx={{ height: '95px' }}
                                    mb={1}
                                >
                                    <Grid
                                        item
                                        xs={12}
                                    >
                                        <Typography
                                            variant="P22"
                                        >
                                            {"VPO Price*"}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12} mt={0.4}
                                    >
                                        <CustomTextfield
                                            name="vppPrice"
                                            size="small"
                                            placeholder="Enter Price"
                                            fullWidth
                                            variant="outlined"
                                            value={formData.vppPrice}
                                            onChange={handleChange}
                                            className={formErrors.vppPrice ? 'error' : ''}
                                        />
                                        {formErrors.vppPrice && <Typography variant='P23' className="error-message">{formErrors.vppPrice}</Typography>}
                                    </Grid>
                                </Grid>
                            )}

                            {/* {formData.vppType === 'pre_approved_vpp' && (
                                <Grid
                                    item
                                    xs={12}
                                    container
                                    sx={{ height: '95px' }}
                                    mb={3}
                                    mt={-2}
                                >
                                    <Grid
                                        item
                                        xs={12}
                                    >
                                        <Typography
                                            variant="P22"
                                        >
                                            {"Brand Categorie*"}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12} mt={0.4}
                                    >
                                        <Select
                                            closeMenuOnSelect={false}
                                            isMulti
                                            options={formData.brandCategory && formData.brandCategory.includes('all')
                                                ? [{ value: 'all', label: 'All' }]
                                                : [{ value: 'all', label: 'All' }, ...brandCategoryOptions]}
                                            value={
                                                formData.brandCategory
                                                    ? formData.brandCategory.includes('all')
                                                        ? [{ value: 'all', label: 'All' }]
                                                        : formData.brandCategory.map((value) => ({
                                                            value,
                                                            label: value,
                                                        }))
                                                    : null
                                            }
                                            onChange={handleBrandCategoryChange}
                                            className={formErrors.brandCategory ? 'error' : ''}
                                            menuPlacement="auto"
                                        />

                                        {formErrors.brandCategory && <Typography variant='P23' className="error-message">{formErrors.brandCategory}</Typography>}
                                    </Grid>
                                </Grid>
                            )} */}
                        </Grid>

                        <Grid display='flex' alignContent='center' justifyContent='center' mt={4} mb={2}>
                            <Grid
                                item
                            >
                                <Button variant='B4' onClick={handleDraftSubmit}>Save as Draft
                                </Button>
                            </Grid>
                            <Grid
                                item
                                ml={2}
                            >
                                <Button variant='B4' onClick={handleSubmit}>Next
                                </Button>
                            </Grid>

                        </Grid>
                    </> :
                    <MultiVideoUpload setVideoDialog={setVideoDialog} onClose={onClose} projectId={projectId} contentType={selectedContentType} projectTitle={projectTitle}
                        fetchDraftProjects={fetchDraftProjects} 
                        fetchDataForProjectUpload={fetchDataForProjectUpload} confirmModal={confirmModal} setConfirmModal={setConfirmModal} backData={backData} setFormData={setFormData}
                        rowData={rowData} setSankbarMessage={setSankbarMessage}
                        setOpenSankbar={setOpenSankbar}
                        // selectedVppType={selectedVppType}
                    />
                }
            </DialogContent>

            <Loader />
        </BootstrapDialog >


    );
};

export default StepOne;
