import { createSlice } from '@reduxjs/toolkit';

const storedAccessToken = localStorage.getItem('accessToken'); 
const initialState = {
  accessToken: storedAccessToken || '', 
};

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setAccessToken: (state, action) => {
      // console.log('setAccessToken action payload:', action.payload);
      state.accessToken = action.payload; 
    },
  },
});

export const { setAccessToken } = loginSlice.actions;

export default loginSlice.reducer;


