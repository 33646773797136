
import React, { useEffect, useState, useRef } from 'react';
import * as echarts from 'echarts';
import { useDispatch } from "react-redux";
import { setShowLoader } from '../../redux/loaderSlice';
import { Grid } from '@mui/material'

const GraphChart = ({ pageType, data }) => {

    const chartRef = useRef(null);
    const dispatch = useDispatch();

    console.log(data)

    useEffect(() => {
        const chart = echarts.init(chartRef.current);

        if (!data || !data.revenueInsight || !data.revenueInsight.stackedData) {
            return;
        }

        const { revenueInsight } = data;
        const stackedData = revenueInsight.stackedData;
        const { underReviewMsg, revenueBookedMsg } = revenueInsight;

        const xValues = stackedData.map(item => item.displayName);

        const draftRowData = stackedData.map(item => parseInt(item.underReview));
        const settledRowData = stackedData.map(item => parseInt(item.revenueBooked));

        const onHoldLabel = (pageType === 'producer') ? `Under review (${underReviewMsg})` : `Under review (${revenueBookedMsg})`;
        const settledLabel = (pageType === 'producer') ? `Revenue booked (${revenueBookedMsg})` : `Bought (${revenueBookedMsg})`;


        const series = [
            {
                data: draftRowData,
                type: 'bar',
                stack: 'a',
                name: onHoldLabel,
                label: {
                    show: true,
                    font: "10px"
                },
            },
            {
                data: settledRowData,
                type: 'bar',
                stack: 'a',
                name: settledLabel,
                label: {
                    show: true,
                    font: "10px",
                },
            },
        ];

        const option = {
            xAxis: {
                type: 'category',
                data: xValues,
            },
            tooltip: {
                trigger: 'item',
            },
            legend: {
                selectedMode: true,
                bottom: '1%',
            },
            yAxis: {
                type: 'value',
                axisLabel: {
                    formatter: function (value) {
                        if (value >= 1000) {
                            return (value / 1000).toFixed(1) + 'K';
                        } else {
                            return value;
                        }
                    }
                }
            },
            series: series,
        };

        chart.setOption(option);

        return () => {
            chart.dispose();
        };

    }, [data, pageType]);


    return <div ref={chartRef} style={{ width:"100%", height: "490px"  }} 
    className='cchart'
    />;
};

export default GraphChart;
