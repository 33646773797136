import { useState } from "react";
import { Grid, Paper, Button, Typography, Toolbar, styled, AppBar } from "@mui/material";

import Logo from '../assets/Logo.png';
import { GiFrankensteinCreature } from "react-icons/gi"
import { IoPricetagsOutline } from "react-icons/io5"
import { useNavigate } from 'react-router-dom';
import { Media } from '../ThemeProvider/style'

const Circle = styled(Grid)(({ isSelected }) => ({
    border: "1px solid #dbdfea",
    width: "35%",
    height: "9rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "15rem",
    fontSize: "38px",
    color: isSelected ? "white" : "#526484",
    background: isSelected ? "black" : "none",
    cursor: "pointer",
}));



const SelectionPage = (props) => {
    const navigate = useNavigate();
    

    const [selectedCircle, setSelectedCircle] = useState(null);

    const handleCircleClick = (circleId) => {
        setSelectedCircle(circleId);
    };

    const handleNavigate = () => {
        navigate('/');
    };

    const handleContinue = () => {
        if (selectedCircle === 'Creator' || selectedCircle === 'Brand') {
            // navigate('/Login', { state: { selectedCircle } });
        } else {
            alert("Please select Login Type before continuing")
            // console.error('Please select a circle before continuing.');
        }
    };

    return (
        <Grid >
            <Grid variant='Login'  xs={12} sm={8} md={6} lg={4}  justifyContent="center"
                alignItems="center"
                style={{ minHeight: "100vh" }}>

                <AppBar position="static" color="transparent" variant="A3" elevation={0} >
                    <Toolbar variant="dense" >
                        <Typography  >
                            <Media src={Logo} alt="" />
                        </Typography>
                    </Toolbar>
                </AppBar>

                <Paper
                    variant={"outlined"}
                    sx={{
                        border: "1px solid #DBDFEA",
                        width: "30rem",
                        padding: "2rem",
                    }}
                >
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid
                            item
                            xs={12}
                        >
                            <Typography
                                variant={"h5"}
                                fontSize={"1.3rem"}
                                textAlign={"center"}
                                fontWeight={"bold"}
                                fontFamily={"DM Sans"}
                            >
                                {"Get Started & Join Now !"}
                            </Typography>
                            <Typography
                                variant={"subtitle2"}
                                textAlign={"center"}
                                color={"#526484"}
                                mt={1}
                            >
                                {"Product Placement Made Easy."}
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            mt={3}
                            sx={{ display: 'flex', justifyContent: 'space-around' }}
                        >
                            <Circle
                                isSelected={selectedCircle === 'Creator'}
                                onClick={() => handleCircleClick('Creator')}
                            >
                                <GiFrankensteinCreature />
                            </Circle>
                            <Circle
                                isSelected={selectedCircle === 'Brand'}
                                onClick={() => handleCircleClick('Brand')}
                            >
                                <IoPricetagsOutline />
                            </Circle>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sx={{ display: 'flex', justifyContent: 'space-around' }}
                        >
                            <Typography variant="C11" mr={0.6}>I'M A CREATOR</Typography>
                            <Typography variant="C11" mr={0.6}>I'M A BRAND</Typography>
                        </Grid>

                        <Grid
                            item
                            xs={12}
                        >
                            <Button
                                fullWidth
                                type="submit"
                                variant="B6"
                                color="primary"
                                size="large"
                                onClick={handleContinue}
                            >
                                Continue
                            </Button>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                        >
                            <Typography
                                variant={"subtitle2"}
                                textAlign={"center"}
                                color={"#526484"}
                            >
                                {"Already have an account?"}
                                <span style={{ fontWeight: "bold", color: "black", cursor: "pointer" }} onClick={handleNavigate}>{" Sign In"}</span>
                            </Typography>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
};

export default SelectionPage;
