
import { Grid, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { RiUploadCloud2Fill } from 'react-icons/ri';
import axios from 'axios';
import { useDispatch } from "react-redux";
import { setShowLoader } from '../../redux/loaderSlice';
import { BrandLogo, UplaodButton } from '../../ThemeProvider/style';
import { optimusApi, awsApi } from '../../Routes/Server';
import { useSelector } from 'react-redux';
import NoData from '../CommonComponet/NoData'
import { RiDownloadCloudFill } from 'react-icons/ri'

const GallaryDetails = ({ selectedBrandId }) => {
    const dispatch = useDispatch();
    const [images, setImages] = useState([]);
    const [hoverStates, setHoverStates] = useState(Array(images.length).fill(false));

    // Function to update the hover state for a specific image
    const handleMouseEnter = (index) => {
        setHoverStates((prevStates) => {
            const newStates = [...prevStates];
            newStates[index] = true;
            return newStates;
        });
    };

    const handleMouseLeave = (index) => {
        setHoverStates((prevStates) => {
            const newStates = [...prevStates];
            newStates[index] = false;
            return newStates;
        });
    };

    const handleImageUpload = async (files) => {
        console.log(files)
        const formData = new FormData();
        const brandId = selectedBrandId;
        for (let i = 0; i < files.length; i++) {
            formData.append('file', files[i]);
        }
        formData.append('brandId', brandId);
        try {
            dispatch(setShowLoader(true));
            const response = await optimusApi.post('/upload-brand-asset', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            console.log('Upload response:', response.data);
            setImages(response.data);
            setTimeout(fetchImages, 100);
            dispatch(setShowLoader(false));

        } catch (error) {
            console.error('Error uploading images:', error);
            dispatch(setShowLoader(false));
        }
    };

    const fetchImages = async () => {
        try {
            dispatch(setShowLoader(true));
            const payload = {
                assetAction: 'get-asset',
                brandId: selectedBrandId,
            };
            const response = await awsApi.post('/postproject', payload, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            const responseBody = JSON.parse(response.data.body);
            if (Array.isArray(responseBody)) {
                setImages(responseBody);
            } else {
                console.error('Invalid response format. Expected an array.');
            }
            console.log('Raw Response:', responseBody);
            dispatch(setShowLoader(false));

        } catch (error) {
            console.error('Error fetching images:', error);
            dispatch(setShowLoader(false));
        }
    };

    useEffect(() => {
        setImages([]);
        fetchImages();
    }, [selectedBrandId, dispatch]);

    const handleDownloadClick = (imageLocation) => {
        const downloadLink = document.createElement('a');
        downloadLink.href = imageLocation;
        downloadLink.download = `image_${Date.now()}.jpg`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };


    return (
        <Grid container >
            {/* <Grid item xs={2.5} variant='G24' >
                <UplaodButton
                    // color="success"
                    component="label"
                    style={{ marginBottom: 10, marginTop:10}}
                >
                    <RiUploadCloud2Fill style={{ fontSize: "108px" }} />
                    <input
                        type="file"
                        style={{ display: 'none' }}
                        multiple
                        onChange={(e) => handleImageUpload(e.target.files)}
                    />
                </UplaodButton>
                <Typography variant='P15' >Upload Image</Typography>
            </Grid> */}
            {/* {images && images.length > 0 ? (
                images.map((image, index) => (
                    <Grid item xs={2} key={index} variant='G24'>
                        {image.mimeType === 'application/json' ? (
                            <div>JSON Thumbnail</div>
                        ) : (
                            <BrandLogo src={image.Location} alt={`Image ${index}`} />
                        )}
                    </Grid>
                ))
            ) : (
             <NoData />
            )} */}
            {images && images.length > 0 ? (
                images.map((image, index) => (
                    <Grid
                        item
                        xs={2}
                        key={index}
                        variant="G24"
                        style={{ position: 'relative' }}
                    >
                        <div
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                background: 'rgba(0, 0, 0, 0.5)',
                                opacity: hoverStates[index] ? 1 : 0,
                                transition: 'opacity 0.3s',
                            }}
                            onMouseEnter={() => handleMouseEnter(index)}
                            onMouseLeave={() => handleMouseLeave(index)}
                        >
                            <RiDownloadCloudFill
                                style={{
                                    color: 'white',
                                    fontSize: 38,
                                    cursor: 'pointer',
                                }}
                                onClick={() => handleDownloadClick(image.Location)}
                            />
                        </div>
                        {image.mimeType === 'application/json' ? (
                            <div>JSON Thumbnail</div>
                        ) : (
                            <BrandLogo src={image.Location}
                                alt={`Image ${index}`}
                                style={{ width: '100%', height: '100%' }} />
                        )}
                        <Typography variant='CHead' style={{ marginTop: '4px', marginBottom: '4px' }}>{image.assetName.split('.')[0]}</Typography>

                    </Grid>
                ))
            ) : (
                <NoData />
            )}

        </Grid>
    );
};

export default GallaryDetails;


