import React from 'react'
import { Typography, Grid, Button } from '@mui/material'
import { useNavigate } from "react-router-dom";
import Container from '../Components/MessageComponentCreative/Container'

const MessageCreative = () => {
  const navigate = useNavigate();
  const ComponentName = 'Message'

  const handleNavigate = () => {
    navigate('/CampaignsCreative');
  };

  return (
    <Grid container xs={12} mt={7} >
      <Grid xs={12} display='flex' justifyContent='space-between' alignItems='center' >
        <Grid xs={6}>
          <Grid xs={6} >
            <Typography variant='P2' >Message</Typography>
          </Grid>
        </Grid>
        <Button variant='B1' onClick={() => { handleNavigate() }} style={{ fontSize: "15px", width: "80px" }}>Back</Button>
      </Grid>

      <Grid container xs={12} variant='G1' mt={3} >
        <Grid xs={12} ml={1} mt={1} mb={1} mr={1}>
          <Container />
        </Grid>
      </Grid>

    </Grid>
  )
}

export default MessageCreative
