import React from 'react'
import { Grid, Typography } from '@mui/material'
import { IoWalletSharp } from "react-icons/io5";
import { MdCampaign } from "react-icons/md";
import { TbPlayerTrackNextFilled } from "react-icons/tb";
import { AiOutlineProject } from "react-icons/ai";

const TabsSection = ({ pageType, data, currencySymbol }) => {

    console.log(data)

    return (
        <Grid xs={12} ml={2} display='flex' justifyContent='space-between' alignItems='center' spacing={2} mt={3}>
            <Grid xs={2} variant='G3' display='flex' justifyContent='space-between' alignItems='center'>
                <Grid flexDirection='column' display='flex' ml={2} mt={2} mb={2} xs={8} justifyContent='center' alignItems='flex-start'>
                    <Typography variant='C2' style={{ color: "black" }}>
                        {data && data.topNumberInsight ? (pageType === 'producer' ? `${currencySymbol}${data.topNumberInsight.potentialValue}`
                            : `${currencySymbol}${data.topNumberInsight.potentialValue}`) : 'No data available'}
                    </Typography>
                    <Typography variant='C1' style={{ fontSize: "12px" }}>{pageType === 'producer' ? 'Total Potential Value' : 'Total VPO Booked'}</Typography>
                </Grid>
                <Grid display='flex' ml={2} mt={2} mb={2} xs={4} justifyContent='flex-start' alignItems='center'>
                    <IoWalletSharp style={{ height: "70%", width: "70%", color: "black" }} />
                </Grid>
            </Grid>
            <Grid xs={2} variant='G3' display='flex' justifyContent='space-between' alignItems='center'>
                <Grid flexDirection='column' display='flex' ml={2} mt={2} mb={2} xs={8} justifyContent='center' alignItems='flex-start'>
                    <Typography variant='C2' style={{ color: "black" }}>
                        {data && data.topNumberInsight ? (pageType === 'producer' ? `${currencySymbol}${data.topNumberInsight.revenueGenerated}`
                            : `${currencySymbol}${data.topNumberInsight.revenueGenerated}`) : 'No data available'}
                    </Typography>
                    <Typography variant='C1' style={{ fontSize: "12px" }}>{pageType === 'producer' ? 'Revenue Generated' : 'Total Spend'}</Typography>
                </Grid>
                <Grid display='flex' ml={2} mt={2} mb={2} xs={4} justifyContent='flex-start' alignItems='center'>
                    <IoWalletSharp style={{ height: "70%", width: "70%", color: "black" }} />
                </Grid>
            </Grid>
            <Grid xs={2} variant='G3' display='flex' justifyContent='space-between' alignItems='center'>
                <Grid flexDirection='column' display='flex' ml={2} mt={2} mb={2} xs={8} justifyContent='center' alignItems='flex-start'>
                    <Typography variant='C2' style={{ color: "black" }}>
                        {data && data.topNumberInsight ? (pageType === 'producer' ? `${data.topNumberInsight.campaignCount}`
                            : `${data.topNumberInsight.campaignCount}`) : 'No data available'}
                    </Typography>
                    <Typography variant='C1' style={{ fontSize: "12px" }}>{pageType === 'producer' ? 'Campaigns' : 'Campaigns'}</Typography>
                </Grid>
                <Grid display='flex' ml={2} mt={2} mb={2} xs={4} justifyContent='flex-start' alignItems='center'>
                    <MdCampaign style={{ height: "70%", width: "70%", color: "black" }} />
                </Grid>
            </Grid>
            <Grid xs={2} variant='G3' display='flex' justifyContent='space-between' alignItems='center'>
                <Grid flexDirection='column' display='flex' ml={2} mt={2} mb={2} xs={8} justifyContent='center' alignItems='flex-start'>
                    <Typography variant='C2' style={{ color: "black" }}>
                        {data && data.topNumberInsight ? (pageType === 'producer' ? `${data.topNumberInsight.videoInProject}`
                            : `${data.topNumberInsight.videoInProject}`) : 'No data available'}
                    </Typography>
                    <Typography variant='C1' style={{ fontSize: "12px" }}>{pageType === 'producer' ? 'Pieces of Content' : 'Pieces of Content'}</Typography>
                </Grid>
                <Grid display='flex' ml={2} mt={2} mb={2} xs={4} justifyContent='flex-start' alignItems='center'>
                    <AiOutlineProject style={{ height: "70%", width: "70%", color: "black" }} />
                </Grid>
            </Grid>
            <Grid xs={2} variant='G3' display='flex' justifyContent='space-between' alignItems='center' mr={2}>
                <Grid flexDirection='column' display='flex' ml={2} mt={2} mb={2} xs={8} justifyContent='center' alignItems='flex-start'>
                    <Typography variant='C2' style={{ color: "black" }}>
                        {data && data.topNumberInsight ? (pageType === 'producer' ? `${data.topNumberInsight.vpoCount}`
                            : `${data.topNumberInsight.vpoCount}`) : 'No data available'}
                    </Typography>
                    <Typography variant='C1' style={{ fontSize: "12px" }}>{pageType === 'producer' ? 'VPO Opportunities' : 'VPO Opportunities'}</Typography>
                </Grid>
                <Grid display='flex' ml={2} mt={2} mb={2} xs={4} justifyContent='flex-start' alignItems='center'>
                    <TbPlayerTrackNextFilled style={{ height: "70%", width: "70%", color: "black" }} />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default TabsSection