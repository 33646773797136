import React from 'react';
import { useState } from 'react';
import { styled } from '@mui/system';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { generateThumbnailURL } from '../../Routes/apiConfig';
import defaultThumbnail from '../../assets/NoVideo.png'
import { Chip } from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import { GrStatusInfo } from "react-icons/gr";

const StyledTable = styled(Table)`
  background-color: #fff;
  padding: 16px;
  minHeight: '400px'
`;

const StyledTableHead = styled(TableHead)`
  background-color: #000;
  color: white;
`;

const StyledTableRow = styled(TableRow)`
  height: 50px;

  &:nth-of-type(odd) {
    background-color:#f4f7fd;
  }

  &:last-child td,
  &:last-child th {
    border: 0;
  }

  &:hover {
    background-color: #e0ebff;
    cursor: pointer;
  }
`;

const StyledTableCell = styled(TableCell)`
  padding: 12px 16px;
`;

const CustomizedTables = ({ filterRow, columns, rowAttributes, pagination }) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const renderArray = (row, attributeName) => {
        const arrayValue = row[attributeName];
        return Array.isArray(arrayValue) ? arrayValue.join(', ') : '';
    };

    const formatDate = (timestamp) => {
        const date = new Date(timestamp * 1000);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    const hasBrandedObject = (objects) => {
        return objects.some(obj => obj.branded !== undefined);
    };

    return (
        <TableContainer component={Paper} style={{ minHeight: '295px' }}>
            <StyledTable>
                <StyledTableHead>
                    <TableRow>
                        {columns.map((column) => (
                            <StyledTableCell key={column.field} align="center" style={{ color: "white" }}>{column.headerName}</StyledTableCell>
                        ))}
                    </TableRow>
                </StyledTableHead>
                <TableBody>
                    {(rowsPerPage > 0
                        ? filterRow.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        : filterRow
                    ).map((row, index) => (
                        <StyledTableRow key={row.name}>
                            <StyledTableCell component="th" scope="row" align="center" >
                                {index + 1}
                            </StyledTableCell>
                            {rowAttributes.map((singleAttributeName, index) => (
                                <StyledTableCell align="center" key={index} onClick={() => singleAttributeName.action && singleAttributeName.action(row)}
                                    style={{
                                        cursor: singleAttributeName.newStyle && singleAttributeName.newStyle,
                                        fontWeight: singleAttributeName.fontWeight && singleAttributeName.fontWeight,
                                        color: singleAttributeName.color && singleAttributeName.color,
                                        textDecoration: singleAttributeName.TextDecoder && singleAttributeName.TextDecoder,
                                    }}
                                >{singleAttributeName.type === 'quikAction' && singleAttributeName.actions ? (
                                    <div>
                                        {singleAttributeName.actions.map((action, actionIndex) => (
                                            <button
                                                key={actionIndex}
                                                onClick={() => action.action(row)}
                                                style={{ marginLeft: "5px", width: "80px", height: "25px", borderRadius: "15px", border: "1px solid #bdbdbd", background: action.buttonColor, marginTop: "5px" }}
                                                disabled={
                                                    action.bType === 'darft' &&
                                                    (!row.clipSet || !row.clipSet[0] || !row.clipSet[0].branded)
                                                }
                                            >
                                                {action.label}
                                            </button>
                                        ))}
                                    </div>
                                ) : singleAttributeName.type === 'image' ? (
                                    <div style={{ display: "flex", flexDirection: 'column', alignItems: 'center' }}>
                                        <img
                                            src={row[singleAttributeName.objectChild] && row[singleAttributeName.objectChild][singleAttributeName.name] || 'https://www.ultimatesource.toys/wp-content/uploads/2013/11/dummy-image-landscape-1.jpg'}
                                            alt="Image"
                                            style={{ maxWidth: '100px', maxHeight: '40px', height: 'auto', width: 'auto', cursor: 'pointer' }}
                                        />
                                        {singleAttributeName.loger === 'brand' && (!row[singleAttributeName.objectChild] || !row[singleAttributeName.objectChild][singleAttributeName.name]) && (
                                            <span style={{ color: "blue", marginTop: "3px", cursor: 'pointer' }}>Creative Tag</span>
                                        )}
                                    </div>
                                ) : singleAttributeName.type === 'thumbnail' ? (
                                    <img
                                        src={
                                            row[singleAttributeName.name]
                                                ? generateThumbnailURL(row[singleAttributeName.name])
                                                : defaultThumbnail
                                        }
                                        alt="Image"
                                        style={{ maxWidth: '100px', maxHeight: '40px', height: 'auto', width: 'auto', cursor: 'pointer' }}
                                    />
                                ) : singleAttributeName.type === 'track' ? (
                                    <GrStatusInfo style={{fontSize:"23px"}}/>
                                ) : singleAttributeName.name === 'currentTime' && singleAttributeName.valueType === 'date' ? (
                                    <span>{formatDate(row[singleAttributeName.name])}</span>
                                ) : (
                                    singleAttributeName.type === 'chip' || singleAttributeName.type === 'newChip' ? (
                                        <Chip
                                            label={singleAttributeName.type === 'chip'
                                                ? row[singleAttributeName.name] || (row[singleAttributeName.child] && row[singleAttributeName.child].length > 0 && row[singleAttributeName.child][0][singleAttributeName.name])
                                                : singleAttributeName.typeColorCallback
                                                    ? singleAttributeName.typeColorCallback(row).label
                                                    : ""}
                                            color={singleAttributeName.type === 'chip'
                                                ? singleAttributeName.color
                                                : singleAttributeName.typeColorCallback
                                                    ? singleAttributeName.typeColorCallback(row).color
                                                    : ""}
                                            variant="outlined"
                                            sx={{ width: singleAttributeName.type === 'newChip' ? '180px' : undefined, height: '30px' }}
                                        />
                                    ) : (
                                        singleAttributeName.child ? (
                                            singleAttributeName.name === "comment" ? (
                                                row[singleAttributeName.child] &&
                                                    row[singleAttributeName.child].length > 0 &&
                                                    row[singleAttributeName.child][0][singleAttributeName.objectArray] &&
                                                    row[singleAttributeName.child][0][singleAttributeName.objectArray].length > 0 ? (
                                                    row[singleAttributeName.child][0][singleAttributeName.objectArray][
                                                        row[singleAttributeName.child][0][singleAttributeName.objectArray].length - 1
                                                    ].meassage
                                                ) : (
                                                    "No comments"
                                                )
                                            ) : (
                                                row[singleAttributeName.child] &&
                                                row[singleAttributeName.child].length > 0 &&
                                                row[singleAttributeName.child][0][singleAttributeName.name]
                                            )
                                        ) : (
                                            singleAttributeName.objectChild ? (
                                                row[singleAttributeName.objectChild] && row[singleAttributeName.objectChild][singleAttributeName.name]
                                            ) : (
                                                singleAttributeName.dataType === 'arraySet' ?
                                                    (row[singleAttributeName.name] ? row[singleAttributeName.name].join(', ') : '') :
                                                    row[singleAttributeName.name]
                                            )
                                        )
                                    )
                                )}
                                </StyledTableCell>
                            ))}
                        </StyledTableRow>
                    ))}
                    {filterRow.length < 5 &&
                        Array.from({ length: 5 - filterRow.length }, (_, i) => (
                            <StyledTableRow key={`empty-${i}`}>

                            </StyledTableRow>
                        ))}
                </TableBody>
            </StyledTable>
            {/* <div className='newMax_css'>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={filterRow.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </div> */}
            {pagination === 'need' ? (
                null
            ) : <div className="newMax_css">
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={filterRow.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </div>}
        </TableContainer>
    );
};

export default CustomizedTables;
