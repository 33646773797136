import React, { useEffect, useRef, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Card, CardMedia, Grid } from "@mui/material";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { addProjectId } from '../../redux/projectSlice';
import { useDispatch } from "react-redux";
import defaultThumbnail from '../../assets/NoVideo.png'
import Chip from '@mui/material/Chip';
import { generateThumbnailURL } from '../../Routes/apiConfig';
import { awsApiCamp } from '../../Routes/Server';
import { setShowLoader } from '../../redux/loaderSlice';
import { LuCopy } from "react-icons/lu";
import VideoSection from '../CampaignsComponet/VideoSection';
import Documents from './Documents';
import Tooltip from '@mui/material/Tooltip';
import CustomTextfield from '../CommonComponet/CustomTextfield';
import VideoSec from './VideoSec';
import ReactPlayer from 'react-player';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
        <DialogTitle sx={{ m: 0, p: 0.5 }} {...other} fontWeight="bold" style={{ fontFamily: "DM Sans" }}>
            <Typography variant='CHead' ml={2}>{children}</Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 2,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};



const GoLive = (props) => {
    const { openPlayer, setOpenPlayer, selectedProject, handleApproveClick, handleRejectDialog, type, handleComment, handleUpdateMessages, pageType, actionValue } = props;
    const videoRef = useRef(null);
    const videoRefOne = useRef(null);
    const videoRefTwo = useRef(null);
    const [currentPlayingIndex, setCurrentPlayingIndex] = useState(null);

    const [activeButton, setActiveButton] = useState(1);

    const handleButtonClick = (buttonIndex) => {
        setActiveButton(buttonIndex);
    };



    const dispatch = useDispatch();

    const [copied, setCopied] = useState(false);

    const [textareaValue, setTextareaValue] = useState('https://for-ott-ssai-input.s3.ap-south-1.amazonaws.com/brandwise/orig/HLS/Chaand+Baaliya+(Original+Video).m3u8');
    const [inputUrl, setInputUrl] = useState('')
    const [hlsUrl, setHlsUrl] = useState('')
    const [outputUrl, setOutputUrl] = useState("")

    const handleInputUrlPre = () => {
        setInputUrl('https://for-ott-ssai-input.s3.ap-south-1.amazonaws.com/brandwise/orig/HLS/Chaand+Baaliya+(Original+Video).m3u8')
    }

    useEffect(() => {
        if (inputUrl && videoRefOne.current) {
            videoRefOne.current.load();
            videoRefOne.current.play();
        }
    }, [inputUrl]);

    const handleGetOutputUrl = () => {
        setTimeout(() => {
            if (
                selectedProject?.projectId === "d66957a2-c0ac-4a3a-9e46-f51522ff5be0" &&
                selectedProject?.clipSet.length > 0 &&
                selectedProject?.clipSet[0].VppId === "Qh8zmo4B45lqvD3FfhOY"
            ) {
                setHlsUrl("https://bbf6e6ff0ae34fe68aead98b444f634e.mediatailor.ap-south-1.amazonaws.com/v1/master/1df3dbe0224927238811a63954bed111bd6bbbc8/ColaAutomatedCampaign/chandmain.m3u8");
            } else if (
                selectedProject?.projectId === "d66957a2-c0ac-4a3a-9e46-f51522ff5be0" &&
                selectedProject?.clipSet.length > 0 &&
                selectedProject?.clipSet[0].VppId === "8CEvmo4Bs3-8W0Ym9m-P"
            ) {
                setHlsUrl("https://0c6e62ba76dd47199734b3b73f05b2ef.mediatailor.ap-south-1.amazonaws.com/v1/master/1df3dbe0224927238811a63954bed111bd6bbbc8/BruAutomatedCampaign/chandmain.m3u8");
            } else if (
                selectedProject?.projectId === "cfb2a701-7de8-4387-b2ec-fbbe831800a1" &&
                selectedProject?.clipSet.length > 0 &&
                selectedProject?.clipSet[0].VppId === "Qh8zmo4B45lqvD3Ffhuio"
            ) {
                setHlsUrl("https://60c1dead822b4c63b71b280ba6d1d999.mediatailor.ap-south-1.amazonaws.com/v1/master/1df3dbe0224927238811a63954bed111bd6bbbc8/SwiggyAutomatedCampaign/chandmain.m3u8");
            } else {
                setHlsUrl("https://for-ott-ssai-input.s3.ap-south-1.amazonaws.com/1_st_april_2024/HLS/chandmain.m3u8");
            }
        }, 1000);
    };


    const handlePreviewTwo = () => {
        setOutputUrl(hlsUrl)
    };


    let textToCopy;

    if (
        selectedProject?.projectId === "d66957a2-c0ac-4a3a-9e46-f51522ff5be0" &&
        selectedProject?.clipSet.length > 0 &&
        selectedProject?.clipSet[0].VppId === "Qh8zmo4B45lqvD3FfhOY"
    ) {
        textToCopy = "https://bbf6e6ff0ae34fe68aead98b444f634e.mediatailor.ap-south-1.amazonaws.com/v1/master/1df3dbe0224927238811a63954bed111bd6bbbc8/ColaAutomatedCampaign/chandmain.m3u8";
    } else if (
        selectedProject?.projectId === "d66957a2-c0ac-4a3a-9e46-f51522ff5be0" &&
        selectedProject?.clipSet.length > 0 &&
        selectedProject?.clipSet[0].VppId === "8CEvmo4Bs3-8W0Ym9m-P"
    ) {
        textToCopy = "https://0c6e62ba76dd47199734b3b73f05b2ef.mediatailor.ap-south-1.amazonaws.com/v1/master/1df3dbe0224927238811a63954bed111bd6bbbc8/BruAutomatedCampaign/chandmain.m3u8";
    } else if (
        selectedProject?.projectId === "cfb2a701-7de8-4387-b2ec-fbbe831800a1" &&
        selectedProject?.clipSet.length > 0 &&
        selectedProject?.clipSet[0].VppId === "Qh8zmo4B45lqvD3Ffhuio"
    ) {
        textToCopy = "https://60c1dead822b4c63b71b280ba6d1d999.mediatailor.ap-south-1.amazonaws.com/v1/master/1df3dbe0224927238811a63954bed111bd6bbbc8/SwiggyAutomatedCampaign/chandmain.m3u8";
    } else {
        textToCopy = "https://for-ott-ssai-input.s3.ap-south-1.amazonaws.com/1_st_april_2024/HLS/chandmain.m3u8";
    }


    const handleCopy = () => {
        navigator.clipboard.writeText(textToCopy);
        setCopied(true);
    };

    const [genrate, setGenrate] = useState(false);

    if (!selectedProject) {
        return null;
    }

    const getStatusLabelAndColor = () => {
        const brandStatus = selectedProject?.brandStatus;
        const creatorStatus = selectedProject?.creatorStatus;
        const status = selectedProject?.status;

        let label = status ? status.charAt(0).toUpperCase() + status.slice(1) : '';
        let color = "secondary";

        if (brandStatus !== undefined && creatorStatus !== undefined && status !== undefined) {
            if (brandStatus && creatorStatus) {
                label = 'STARTING';
                color = "success";
            } else if (!brandStatus && creatorStatus) {
                label = "Brand Approval Pending";
                color = "warning";
            } else if (brandStatus && !creatorStatus) {
                label = "Your Approval Pending";
                color = "warning";
            }
        }

        return { label, color };
    };

    const handleClose = () => {
        setOpenPlayer(false);
        setInputUrl('')
        setHlsUrl('')
        setOutputUrl('')
    };

    const calculateTimeDifference = (startTime, endTime) => {
        const parseTime = (time) => {
            const [hours, minutes, seconds] = time.split(':').map(Number);
            return hours * 3600 + minutes * 60 + seconds;
        };
        const startSeconds = parseTime(startTime);
        const endSeconds = parseTime(endTime);
        return endSeconds - startSeconds;
    };


    const startTime = selectedProject?.clipSet[0].startTime;
    const endTime = selectedProject?.clipSet[0].endTime;

    const differenceInSeconds = calculateTimeDifference(startTime, endTime);


    // console.log('selectedProject', selectedProject)
    const brandStatus = selectedProject?.brandStatus;
    const creatorStatus = selectedProject?.creatorStatus;

    console.log(brandStatus)
    console.log(creatorStatus)
    const url = selectedProject?.videoId;
    const thumbnailURL = generateThumbnailURL(url) || defaultThumbnail;
    const { label, color } = getStatusLabelAndColor();



    console.log('vpp player SP', selectedProject)



    const tooltipContent = (
        <div >
            <div>text required.</div>
        </div>
    );

    console.log(textToCopy)

    return (
        <div>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={openPlayer}
                maxWidth="xl"
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose} >
                    {/* {selectedProject?.clipSet[0].projectTitle} */}
                    {selectedProject?.clipSet[0].projectTitle && `${selectedProject.clipSet[0].projectTitle}-${selectedProject.clipSet[0].VppName.split('_')[1]}-${selectedProject.brandLogo.label.split('.')[0]}`}
                </BootstrapDialogTitle>
                <DialogContent dividers >
                    <Grid xs={12} variant='G21-A' mb={2}>

                        <Button variant='Tab'
                            sx={{
                                marginLeft: '6px', borderLeft: '1px solid black',
                                color: activeButton === 1 ? 'white' : 'black',
                                background: activeButton === 1 ? 'black' : 'white',
                            }} onClick={() => handleButtonClick(1)}
                        >Details</Button>



                        <Button variant='Tab'
                            sx={{
                                color: activeButton === 2 ? 'white' : 'black',
                                background: activeButton === 2 ? 'black' : 'white',
                            }} onClick={() => handleButtonClick(2)}
                        >Artifacts

                        </Button>

                        <Button variant='Tab'
                            sx={{
                                color: activeButton === 3 ? 'white' : 'black',
                                background: activeButton === 3 ? 'black' : 'white',
                            }} onClick={() => handleButtonClick(3)}
                        >HLS Preview
                        </Button>


                    </Grid>

                    {activeButton === 1 &&
                        <Grid container spacing={2} sx={{ minWidth: 1200, maxWidth: 1200 }} display='flex' flexDirection='column' ml={1}>
                            <VideoSection
                                linkOne={selectedProject?.clipSet[0].VppClipUrl}
                                likTwo={selectedProject.clipSet && selectedProject.clipSet[0] && selectedProject.clipSet[0]?.branded?.Location}
                                startTime={selectedProject?.clipSet[0].startTime}
                                endTime={selectedProject?.clipSet[0].endTime}
                            />

                            <Grid xs={11.8} style={{ minHeight: "140px", borderRadius: "12px", background: "#eff4fd", border: "1px solid #c8c8c8" }} mt={2}>
                                <Grid xs={12} display='flex' alignItems='center' justifyContent='space-between' style={{ background: "" }}>
                                    <Grid xs={3} >
                                        <Card sx={{ minWidth: 220, marginTop: "0px", borderRadius: "12px" }}>
                                            <CardMedia
                                                component="img"
                                                height="140"
                                                image={thumbnailURL}
                                                alt="thumb"
                                            />
                                        </Card>
                                    </Grid>
                                    <Grid xs={4} >
                                        <Grid item xs={12} sx={{ margin: "15px", marginTop: "0px" }}>
                                            <Grid mt={1}>
                                                <Typography variant='CHead'>Project Title</Typography> - &nbsp;
                                                <Typography variant='GV'>{selectedProject?.clipSet[0].projectTitle}</Typography>
                                            </Grid>
                                            {pageType !== 'draft' && (
                                                <Grid mt={1}>
                                                    <Typography variant='CHead'>Status</Typography> - &nbsp;
                                                    <Typography variant='GV'><Chip
                                                        label={label}
                                                        color={color}
                                                        variant="outlined"
                                                        size="small"
                                                        sx={{ width: '180px', height: '25px' }}
                                                    /></Typography>
                                                </Grid>
                                            )}
                                            <Grid container direction='row'>
                                                <Grid item xs={6}>
                                                    <Grid variant='GH'>
                                                        <Typography variant='CHead'>Start Time</Typography>
                                                    </Grid>
                                                    <Grid variant='GV'>
                                                        {selectedProject?.clipSet[0].startTime}
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Grid variant='GH'>
                                                        <Typography variant='CHead'> End Time</Typography>
                                                    </Grid>
                                                    <Grid variant='GV'>
                                                        {selectedProject?.clipSet[0].endTime}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid xs={4} >
                                        <Grid >
                                            <Typography variant='CHead'>Catagory</Typography> - &nbsp;
                                            <Typography variant='GV'>{selectedProject?.clipSet[0].category}</Typography>
                                        </Grid>
                                        <Grid mt={1}>
                                            <Typography variant='CHead'>Stage</Typography> - &nbsp;
                                            <Typography variant='GV'></Typography>
                                        </Grid>
                                        <Grid mt={1}>
                                            <Typography variant='CHead'>Embedded Ad Inventory Seconds</Typography> - &nbsp;
                                            <Typography variant='GV'>{differenceInSeconds}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {!genrate &&
                                (
                                    <Grid xs={11.8} display='flex' justifyContent='center' alignItems='center' style={{ minHeight: "80px" }}>
                                        <Button variant="contained" color="success" onClick={() => { setGenrate(true) }}>Generate Live URL</Button>
                                    </Grid>
                                )
                            }

                            {genrate &&
                                (<Grid xs={11.8} style={{ background: "#eff4fd", overflow: 'hidden', border: "1px solid #c8c8c8", minHeight: "80px", borderRadius: "12px" }} mt={1}>
                                    <Grid xs={12} display='flex' flexDirection='column'>
                                        <Grid xs={12} style={{ height: "35px", background: "#2f2f2f" }} display='flex' justifyContent='space-between' alignItems='center' >
                                            <Grid>
                                                <Typography onClick={handleCopy} style={{ color: "#a4a4a4", marginLeft: "7px" }} >Go Live Link</Typography>
                                            </Grid>
                                            <Grid>
                                                <Typography onClick={handleCopy} style={{ color: "#a4a4a4", marginRight: "17px", cursor: "pointer" }} ><LuCopy style={{ marginRight: "4px" }} />Copy</Typography>
                                                {copied && <span style={{ color: "green", marginLeft: "5px" }}>Copied!</span>}
                                            </Grid>
                                        </Grid>

                                        <Grid xs={12} style={{ height: "45px" }} container justify="flex-start" alignItems="center">
                                            <Typography ml={1} mt={1} style={{ wordWrap: 'break-word' }}>{textToCopy}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>)
                            }

                        </Grid>
                    }

                    {activeButton === 2 &&
                        <Documents
                            selectedProject={selectedProject}
                        />
                    }

                    {activeButton === 3 &&
                        <Grid container spacing={2} sx={{ minWidth: 1200, maxWidth: 1200 }} display='flex' flexDirection='column' ml={1}>
                            <Grid xs={11.8} display='flex' mt={2}>
                                <Grid xs={4.5} display='flex' flexDirection='column' justifyContent='center' alignItems='center' sx={{ minHeight: "150px" }}>
                                    <Grid xs={12} display='flex' flexDirection='column' sx={{ background: "#eff4fd", overflow: 'hidden', border: "1px solid #c8c8c8", minHeight: "80px", minWidth: "100%", borderRadius: "12px" }}>
                                        <Grid xs={12} sx={{ maxHeight: "30px", background: "#2f2f2f" }} display='flex' justifyContent='space-between' alignItems='center' >
                                            <Grid xs={7} >
                                                <Typography onClick={handleCopy} style={{ color: "#a4a4a4", marginLeft: "7px" }} >Input Url</Typography>
                                            </Grid>
                                            <Grid xs={5} display='flex' justifyContent='flex-end'>
                                                <Typography onClick={handleCopy} style={{ color: "#a4a4a4", marginRight: "17px", cursor: "pointer" }} ><LuCopy style={{ marginRight: "4px" }} />Copy</Typography>
                                                {copied && <span style={{ color: "green", marginLeft: "5px" }}>Copied!</span>}
                                            </Grid>
                                        </Grid>
                                        <Grid xs={12} style={{ height: "45px" }} container justify="flex-start" alignItems='flex-start'>
                                            <textarea
                                                name="textareaValue"
                                                style={{ minWidth: "100%", minHeight: "150px", maxWidth: "100%", maxHeight: "150px", borderRadius: "8px", border: 'none', background: "#eff4fd", }}
                                                rows="4"
                                                cols="50"
                                                placeholder="Enter Input URL..."
                                                value={textareaValue}
                                                onChange={(e) => setTextareaValue(e.target.value)}
                                            ></textarea>
                                        </Grid>
                                    </Grid>
                                    <Button variant='B2S' sx={{ marginTop: "10px" }} onClick={handleInputUrlPre}>Preview</Button>
                                </Grid>
                                <Grid xs={3} display='flex' justifyContent='center' alignItems='center' sx={{ minHeight: "150px" }}>
                                    <Button variant='B2S' style={{ marginTop: "-20px" }} onClick={handleGetOutputUrl}>Get Output URL</Button>
                                </Grid>
                                <Grid xs={4.55} display='flex' flexDirection='column' justifyContent='center' alignItems='center' sx={{ minHeight: "150px" }}>
                                    <Grid xs={12} display='flex' flexDirection='column' sx={{ background: "#eff4fd", overflow: 'hidden', border: "1px solid #c8c8c8", minHeight: "80px", minWidth: "100%", borderRadius: "12px" }}>
                                        <Grid xs={12} sx={{ maxHeight: "30px", background: "#2f2f2f" }} display='flex' justifyContent='space-between' alignItems='center' >
                                            <Grid xs={7} >
                                                <Typography onClick={handleCopy} style={{ color: "#a4a4a4", marginLeft: "7px" }} >Go Output Link</Typography>
                                            </Grid>
                                            <Grid xs={5} display='flex' justifyContent='flex-end'>
                                                <Typography onClick={handleCopy} style={{ color: "#a4a4a4", marginRight: "17px", cursor: "pointer" }} ><LuCopy style={{ marginRight: "4px" }} />Copy</Typography>
                                                {copied && <span style={{ color: "green", marginLeft: "5px" }}>Copied!</span>}
                                            </Grid>
                                        </Grid>

                                        <Grid xs={12} style={{ height: "45px" }} container justify="flex-start" alignItems='flex-start'>
                                            <Typography ml={1} mt={1} style={{ wordWrap: 'break-word' }}>{hlsUrl}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Button variant='B2S' sx={{ marginTop: "10px" }} onClick={handlePreviewTwo}>Preview</Button>
                                </Grid>
                            </Grid>
                            <Grid xs={12} display='flex' >
                                <Grid xs={11.8} style={{ background: "#eff4fd", border: "1px solid #c8c8c8", borderRadius: "12px" }} display='flex' justifyContent='space-around' alignItems='center' mt={1}>
                                    <Grid xs={5.9}>
                                        
                                        <ReactPlayer url={inputUrl} playing controls style={{ minWidth: "100%", maxWidth: '100%', minHeight: 335, maxHeight: 335, background: "black", borderRadius: "12px" }} />
                                    </Grid>

                                    <Grid xs={5.9} >
                                      
                                        <ReactPlayer url={outputUrl} playing controls style={{ minWidth: "100%", maxWidth: '100%', minHeight: 335, maxHeight: 335, background: "black", borderRadius: "12px" }} />
                                    </Grid>

                                </Grid>
                            </Grid>
                        </Grid>
                    }
                </DialogContent>
            </BootstrapDialog>
        </div >
    );
}

export default GoLive;
