const VastVp = `
<VAST version="3.0">
    <Ad sequence="1">
        <InLine>
            <AdSystem>2.0</AdSystem>
            <AdTitle>ad-1</AdTitle>
            <Impression/>
            <Creatives>
                <Creative>
                    <Linear>
                        <Duration>00:00:06</Duration>
                        <MediaFiles>
                            <MediaFile delivery="progressive" type="video/mp4" width="1920" height="1080">
                                <![CDATA[https://for-ott-ssai-input.s3.ap-south-1.amazonaws.com/1_st_april_2024/advertise/ad_swiggy.mp4]]>
                            </MediaFile>
                        </MediaFiles>
                    </Linear>
                </Creative>
            </Creatives>
        </InLine>
    </Ad>
</VAST>
`;

export default VastVp;
