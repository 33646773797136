// import { Grid, Card, Button, Chip } from '@mui/material';
// import React, { useEffect, useRef, useState } from 'react';
// import NoData from '../CommonComponet/NoData';
// import Dialog from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
// import DialogContent from '@mui/material/DialogContent';
// import DialogTitle from '@mui/material/DialogTitle';
// import { useDispatch } from "react-redux";
// import { awsApiCamp, awsApi } from '../../Routes/Server';
// import { setShowLoader } from '../../redux/loaderSlice';
// import { useSelector } from "react-redux";
// import Select from "react-select";

// function parseTimeToSeconds(timeString) {
//     const [hours, minutes, seconds] = timeString.split(':').map(Number);
//     return hours * 3600 + minutes * 60 + seconds;
// }

// const TabSwitchBrand = ({ link, startTime, type, endTime, selectedProject, handleComment, activeButton, handleUpdateMessages, handleRejectDialog, handleApproveClick }) => {
//     const videoRef = useRef(null);
//     const dispatch = useDispatch();
//     const userData = useSelector((state) => state.user.userLoginData);

//     const [currentPlayingIndex, setCurrentPlayingIndex] = useState(null);
//     const [dialogOpen, setDialogOpen] = useState(false);
//     const [dialogOpenReject, setDialogOpenReject] = useState(false);
//     const [brandDetails, SetBrandDetails] = useState([])
//     const [chipLabel, setChipLabel] = useState('');
//     const [chipColor, setChipColor] = useState('');
//     const [selectedBrand, setSelectedBrand] = useState(null);

//     useEffect(() => {
//         if (link && startTime && endTime && videoRef.current) {
//             const startTimeInSeconds = parseTimeToSeconds(startTime);
//             const endTimeInSeconds = parseTimeToSeconds(endTime);
//             const timeUpdateHandler = () => {
//                 if (videoRef.current && videoRef.current.currentTime >= endTimeInSeconds) {
//                     videoRef.current.pause();
//                     setCurrentPlayingIndex(null);
//                 }
//             };
//             const endedHandler = () => {
//                 if (videoRef.current) {
//                     videoRef.current.removeEventListener('timeupdate', timeUpdateHandler);
//                 }
//             };
//             const pauseHandler = () => {
//                 if (videoRef.current) {
//                     videoRef.current.removeEventListener('timeupdate', timeUpdateHandler);
//                 }
//             };
//             videoRef.current.addEventListener('timeupdate', timeUpdateHandler);
//             videoRef.current.addEventListener('ended', endedHandler);
//             videoRef.current.addEventListener('pause', pauseHandler);
//             videoRef.current.currentTime = startTimeInSeconds;

//             if (currentPlayingIndex === null) {
//                 videoRef.current.play();
//                 setCurrentPlayingIndex(1);
//             } else {
//                 videoRef.current.pause();
//                 setCurrentPlayingIndex(null);
//             }
//         }
//     }, [link]);

//     const brandStatus = selectedProject?.brandStatus;
//     const creatorStatus = selectedProject?.creatorStatus;
//     const producerUnbranded = selectedProject?.clipSet[0].brandUnbrandedScene
//     const producerBranded = selectedProject?.clipSet[0].brandBrandedScene

//     useEffect(() => {
//         if (activeButton === 2) {
//             const { label, color } = decideColor();
//             setChipLabel(label);
//             setChipColor(color);
//         } else if (activeButton === 3) {
//             const { label, color } = decideColorBrand();
//             setChipColor(color);
//             setChipLabel(label);
//         }
//     }, [activeButton, selectedProject]);

//     const fetchImages = async () => {
//         try {
//             dispatch(setShowLoader(true));
//             const payload = {
//                 assetAction: 'get-asset',
//                 brandId: userData.sub,
//             };
//             const response = await awsApi.post('/postproject', payload, {
//                 headers: {
//                     'Content-Type': 'application/json',
//                 },
//             });
//             const responseBody = JSON.parse(response.data.body);
//             if (Array.isArray(responseBody)) {
//                 SetBrandDetails(responseBody);
//             } else {
//                 console.error('Invalid response format. Expected an array.');
//             }
//             console.log('Raw Response:', responseBody);
//             dispatch(setShowLoader(false));

//         } catch (error) {
//             console.error('Error fetching images:', error);
//             dispatch(setShowLoader(false));
//         }
//     };

//     useEffect(() => {
//         fetchImages();
//     }, []);

//     const decideColor = () => {
//         const clipSet = selectedProject?.clipSet?.[0];
//         if (!clipSet || !clipSet.unbrandedStatus) {
//             console.error("ClipSet or unbrandedStatus is undefined or null");
//             return { label: "Undefined", color: "error" };
//         }
//         const brandStatus = clipSet.brandUnbrandedScene;
//         const creatorStatus = clipSet.producerUnbrandedScene;
//         let label = clipSet.unbrandedStatus.charAt(0).toUpperCase() + clipSet.unbrandedStatus.slice(1);
//         let color = "secondary";

//         if (brandStatus && creatorStatus) {
//             label = "Approved";
//             color = "success";
//         } else if (!brandStatus && creatorStatus) {
//             label = "Your Approval Pending";
//             color = "warning";
//         } else if (brandStatus && !creatorStatus) {
//             label = "Producer Approval Pending";
//             color = "warning";
//         } else if (!brandStatus && !creatorStatus) {
//             label = "Both Approvel Pending";
//             color = "secondary";
//         }
//         console.log(label, color)
//         return { label, color };
//     };
//     const decideColorBrand = () => {
//         const clipSet = selectedProject?.clipSet?.[0];
//         if (!clipSet || !clipSet.brandedStatus) {
//             console.error("ClipSet or unbrandedStatus is undefined or null");
//             return { label: "Undefined", color: "error" };
//         }
//         const brandStatus = clipSet.brandBrandedScene;
//         const creatorStatus = clipSet.producerBrandedScene;
//         let label = clipSet.brandedStatus.charAt(0).toUpperCase() + clipSet.brandedStatus.slice(1);
//         let color = "secondary";

//         if (brandStatus && creatorStatus) {
//             label = "Approved";
//             color = "success";
//         } else if (!brandStatus && creatorStatus) {
//             label = "Your Approval Pending";
//             color = "warning";
//         } else if (brandStatus && !creatorStatus) {
//             label = "Producer Approval Pending";
//             color = "warning";
//         } else if (!brandStatus && !creatorStatus) {
//             label = "Both Approvel Pending";
//             color = "secondary";
//         }
//         console.log(label, color)

//         return { label, color };
//     };

//     const handleApprove = async () => {
//         dispatch(setShowLoader(true));
//         console.log('test 1')
//         try {
//             let updatedData;
//             if (selectedProject && activeButton === 2) {
//                 console.log('test 2 a')
//                 if (selectedProject.clipSet[0].producerUnbrandedScene === true) {
//                     updatedData = {
//                         ...selectedProject,
//                         clipSet: selectedProject.clipSet.map(clip => ({
//                             ...clip,
//                             brandUnbrandedScene: true,
//                             unbrandedStatus: 'approved'
//                         })),
//                     };
//                 } else {
//                     console.log('test 2 b')
//                     updatedData = {
//                         ...selectedProject,
//                         clipSet: selectedProject.clipSet.map(clip => ({
//                             ...clip,
//                             brandUnbrandedScene: true,
//                         })),
//                     };
//                 }
//             } else {
//                 if (selectedProject.clipSet[0].producerBrandedScene === true) {
//                     console.log('test c')
//                     updatedData = {
//                         ...selectedProject,
//                         clipSet: selectedProject.clipSet.map(clip => ({
//                             ...clip,
//                             brandBrandedScene: true,
//                             brandedStatus: 'approved'
//                         })),
//                     };
//                 } else {
//                     console.log('test d')
//                     updatedData = {
//                         ...selectedProject,
//                         clipSet: selectedProject.clipSet.map(clip => ({
//                             ...clip,
//                             brandBrandedScene: true,
//                         })),
//                     };
//                 }
//             }
//             const res = await awsApiCamp.post('/postcampaign', {
//                 ...updatedData,
//             });
//             console.log('test 3')
//             if (res.data.statusCode === 200) {
//                 setDialogOpen(false);
//                 console.log('test 4')
//                 handleUpdateMessages();
//                 console.log('test 5')
//             }
//         } catch (error) {
//             console.error('Error approving data:', error);
//         } finally {
//             dispatch(setShowLoader(false));
//         }
//         console.log('test 6')
//     };

//     const handleReject = async () => {
//         dispatch(setShowLoader(true));
//         try {
//             let updatedData;
//             if (selectedProject && activeButton === 2) {
//                 updatedData = {
//                     ...selectedProject,
//                     clipSet: selectedProject.clipSet.map(clip => ({
//                         ...clip,
//                         brandUnbrandedScene: false,
//                         unbrandedStatus: 'rejected'
//                     })),
//                 };
//             } else {
//                 updatedData = {
//                     ...selectedProject,
//                     clipSet: selectedProject.clipSet.map(clip => ({
//                         ...clip,
//                         brandBrandedScene: false,
//                         brandedStatus: 'rejected'
//                     })),
//                 };
//             }
//             const res = await awsApiCamp.post('/postcampaign', {
//                 ...updatedData,
//             });
//             console.log('After Axios request', res);
//             if (res.data.statusCode === 200) {
//                 handleUpdateMessages()
//                 setDialogOpenReject(false);
//             }
//         } catch (error) {
//             console.error('Error rejecting data:', error);
//         } finally {
//             dispatch(setShowLoader(false));
//         }
//     };

//     const customStyles = {
//         control: (provided) => ({
//             ...provided,
//             width: 250,

//         }),
//     };

//     const handleBrandChange = (selectedOption) => {
//         console.log(selectedOption)
//         setSelectedBrand(selectedOption);
//     };

//     const handelImgSubmit = async () => {
//         dispatch(setShowLoader(true));
//         if (selectedBrand !== null) {
//             try {
//                 let updatedData = {
//                     ...selectedProject,
//                     brandLogo: selectedBrand
//                 };

//                 const res = await awsApiCamp.post('/postcampaign', {
//                     ...updatedData,
//                 });

//                 if (res.data.statusCode === 200) {
//                     handleUpdateMessages();
//                 } else {
//                     console.log('error')
//                 }
//             } catch (error) {
//                 console.error('Error rejecting data:', error);
//             } finally {
//                 dispatch(setShowLoader(false));
//             }
//         }
//     };


//     const shouldRenderSection = () => {
//         const brandLogoExists = selectedProject?.clipSet?.[0]?.brandLogo;

//         return !brandLogoExists;
//     };




//     if (!link) {
//         return (
//             <Grid xs={12} variant="G2" style={{ minWidth: 650, minHeight: 335, maxHeight: 335, background: "white", borderRadius: '12px' }} alignItems="center" justifyContent='center'>
//                 <NoData />
//             </Grid>
//         );
//     }


//     return (
//         <Grid xs={12}>
//             <Card sx={{ maxWidth: "100%", display: "flex", justifyContent: "center" }}>
//                 <video width='100%' controls ref={videoRef} style={{ minWidth: 650, minHeight: 335, maxHeight: 335, background: "black", borderRadius: '12px' }}>
//                     <source src={link} type="video/mp4" />
//                     Your browser does not support the video tag.
//                 </video>

//             </Card>
//             <Grid variant="G13" xs={12}
//                 item
//                 container
//                 sx={{ height: "40px" }} justifyContent='space-between'>

//                 <Grid>
//                     {activeButton === 1 && shouldRenderSection() && (
//                         <Grid display='flex' justifyContent='flex-start' alignItems='center'>
//                             <Select
//                                 options={brandDetails.map((brand) => ({ value: brand.Location, label: brand.assetName, Key: brand.Key }))}
//                                 onChange={handleBrandChange}
//                                 styles={customStyles}
//                                 menuPlacement="top"
//                             />
//                             <Button variant="B2S" sx={{ background: "green", color: 'black', height: "40px", marginRight: '5px' }} onClick={handelImgSubmit}
//                                 disabled={selectedBrand === null}>Submit</Button>
//                         </Grid>

//                     )}
//                 </Grid>
//                 <Grid>
//                     {activeButton === 2 && selectedProject?.clipSet[0]?.unbrandedStatus !== 'rejected' && (
//                         <Chip label={chipLabel} color={chipColor || "default"} variant="outlined" />
//                     )}
//                     {activeButton === 3 && selectedProject?.clipSet[0]?.brandedStatus !== 'rejected' && (
//                         <Chip label={chipLabel} color={chipColor || "default"} variant="outlined" />
//                     )}
//                     {activeButton === 2 && !producerUnbranded && (
//                         <>
//                             {selectedProject?.clipSet[0]?.unbrandedStatus !== 'rejected' ? (
//                                 <>
//                                     <Button variant="B2S" sx={{ marginRight: '1px' }} onClick={() => setDialogOpen(true)}>Approve Unbranded Scene</Button>
//                                     <Button variant="B2S" sx={{ marginRight: '1px' }} onClick={() => setDialogOpenReject(true)}>Reject Unbranded Scene</Button>
//                                 </>
//                             ) : (
//                                 <Chip label={'Rejected'} color='error' variant="outlined" />
//                             )}
//                         </>
//                     )}
//                     {activeButton === 3 && !producerBranded && (
//                         <>
//                             {selectedProject?.clipSet[0]?.brandedStatus !== 'rejected' ? (
//                                 <>
//                                     <Button variant="B2S" sx={{ marginRight: '1px' }} onClick={() => setDialogOpen(true)}>Approve Branded Scene</Button>
//                                     <Button variant="B2S" sx={{ marginRight: '1px' }} onClick={() => setDialogOpenReject(true)}>Reject Branded Scene</Button>
//                                 </>
//                             ) : (
//                                 <Chip label={'Rejected'} color='error' variant="outlined" />
//                             )}
//                         </>
//                     )}

//                     {activeButton !== 2 && activeButton !== 3 && type === "brand" && !brandStatus && (
//                         <>
//                             <Button variant="B2S" sx={{ marginRight: '1px' }} onClick={() => handleApproveClick(selectedProject)}>Approve VPP</Button>
//                             <Button variant="B2S" sx={{ marginRight: '1px' }} onClick={() => handleRejectDialog(selectedProject)}>Reject VPP</Button>
//                         </>
//                     )}
//                 </Grid>


//                 {/* <Button variant="B2S" onClick={() => { handleComment(selectedProject) }}>Comment</Button> */}
//             </Grid>
//             <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} >
//                 <DialogTitle >{"Approve campaign"}</DialogTitle>
//                 <DialogContent >{"Are You Sure You want to approve Unbranded Scene."}</DialogContent>
//                 <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
//                     <Button onClick={() => setDialogOpen(false)} variant="outlined" color="error">
//                         Cancel
//                     </Button>
//                     <Button onClick={handleApprove} autoFocus variant="outlined" color="success">
//                         Approve
//                     </Button>
//                 </DialogActions>
//             </Dialog>
//             <Dialog open={dialogOpenReject} onClose={() => setDialogOpenReject(false)} >
//                 <DialogTitle >{"Approve campaign"}</DialogTitle>
//                 <DialogContent >{"Are You Sure You want to Reject Unbranded Scene."}</DialogContent>
//                 <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
//                     <Button onClick={() => setDialogOpenReject(false)} variant="outlined" color="error">
//                         Cancel
//                     </Button>
//                     <Button onClick={handleReject} autoFocus variant="outlined" color="success">
//                         Reject
//                     </Button>
//                 </DialogActions>
//             </Dialog>
//         </Grid>
//     );
// }

// export default TabSwitchBrand;



import { Grid, Card, Button, Chip } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import NoData from '../CommonComponet/NoData';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch } from "react-redux";
import { awsApiCamp, awsApi } from '../../Routes/Server';
import { setShowLoader } from '../../redux/loaderSlice';
import { useSelector } from "react-redux";
import Select from "react-select";

function parseTimeToSeconds(timeString) {
    const [hours, minutes, seconds] = timeString.split(':').map(Number);
    return hours * 3600 + minutes * 60 + seconds;
}

const TabSwitchBrand = ({ link, startTime, type, endTime, selectedProject, handleComment, activeButton, handleUpdateMessages, handleRejectDialog, handleApproveClick, pageType }) => {
    const videoRef = useRef(null);
    const dispatch = useDispatch();
    const userData = useSelector((state) => state.user.userLoginData);

    const [currentPlayingIndex, setCurrentPlayingIndex] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogOpenReject, setDialogOpenReject] = useState(false);
    const [brandDetails, SetBrandDetails] = useState([])
    const [chipLabel, setChipLabel] = useState('');
    const [chipColor, setChipColor] = useState('');
    const [selectedBrand, setSelectedBrand] = useState(null);

    useEffect(() => {
        if (link && startTime && endTime && videoRef.current) {
            const startTimeInSeconds = parseTimeToSeconds(startTime);
            const endTimeInSeconds = parseTimeToSeconds(endTime);
            const timeUpdateHandler = () => {
                if (videoRef.current && videoRef.current.currentTime >= endTimeInSeconds) {
                    videoRef.current.pause();
                    setCurrentPlayingIndex(null);
                }
            };
            const endedHandler = () => {
                if (videoRef.current) {
                    videoRef.current.removeEventListener('timeupdate', timeUpdateHandler);
                }
            };
            const pauseHandler = () => {
                if (videoRef.current) {
                    videoRef.current.removeEventListener('timeupdate', timeUpdateHandler);
                }
            };
            videoRef.current.addEventListener('timeupdate', timeUpdateHandler);
            videoRef.current.addEventListener('ended', endedHandler);
            videoRef.current.addEventListener('pause', pauseHandler);
            videoRef.current.currentTime = startTimeInSeconds;

            if (currentPlayingIndex === null) {
                videoRef.current.play();
                setCurrentPlayingIndex(1);
            } else {
                videoRef.current.pause();
                setCurrentPlayingIndex(null);
            }
        }
    }, [link]);

    const fetchImages = async () => {
        try {
            dispatch(setShowLoader(true));
            const payload = {
                assetAction: 'get-asset',
                brandId: userData.sub,
            };
            const response = await awsApi.post('/postproject', payload, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            const responseBody = JSON.parse(response.data.body);
            if (Array.isArray(responseBody)) {
                SetBrandDetails(responseBody);
            } else {
                console.error('Invalid response format. Expected an array.');
            }
            console.log('Raw Response:', responseBody);
            dispatch(setShowLoader(false));

        } catch (error) {
            console.error('Error fetching images:', error);
            dispatch(setShowLoader(false));
        }
    };

    useEffect(() => {
        fetchImages();
    }, []);


    // const customStyles = {
    //     control: (provided) => ({
    //         ...provided,
    //         width: 200,

    //     }),
    // };

    // const handleBrandChange = (selectedOption) => {
    //     console.log(selectedOption)
    //     setSelectedBrand(selectedOption);
    // };

    // const handelImgSubmit = async () => {
    //     dispatch(setShowLoader(true));
    //     if (selectedBrand !== null) {
    //         try {
    //             let updatedData = {
    //                 ...selectedProject,
    //                 brandLogo: selectedBrand
    //             };

    //             const res = await awsApiCamp.post('/postcampaign', {
    //                 ...updatedData,
    //             });

    //             if (res.data.statusCode === 200) {
    //                 handleUpdateMessages();
    //             } else {
    //                 console.log('error')
    //             }
    //         } catch (error) {
    //             console.error('Error rejecting data:', error);
    //         } finally {
    //             dispatch(setShowLoader(false));
    //         }
    //     }
    // };


    // const shouldRenderSection = () => {
    //     const brandLogoExists = selectedProject?.clipSet?.[0]?.brandLogo;

    //     return !brandLogoExists;
    // };




    if (!link) {
        return (
            <Grid xs={12} variant="G2" style={{ minWidth: 650, minHeight: 335, maxHeight: 335, background: "white", borderRadius: '12px' }} alignItems="center" justifyContent='center'>
                <NoData />
            </Grid>
        );
    }


    // const CustomOption = ({ innerProps, label, data }) => (
    //     <div {...innerProps} style={{ cursor: "pointer", border: '1px solid black', marginTop: '5px', }}>
    //         <img
    //             src={data.value}
    //             alt={label}
    //             style={{ marginRight: '8px', width: '35px', height: '35px' }}
    //         />
    //         {label}
    //     </div>
    // );

    // const options = brandDetails.map((brand) => ({
    //     value: brand.Location,
    //     label: brand.assetName,
    //     Key: brand.Key,
    // }));

    // const defaultOption = options.find(
    //     (option) => option.value === selectedProject.brandLogo.value
    // );
    // console.log(defaultOption)

    return (
        <Grid xs={12}>
            <Card sx={{ maxWidth: "100%", display: "flex", justifyContent: "center" }}>
                <video width='100%' controls controlsList="nodownload" ref={videoRef} controlsList="nodownload" style={{ minWidth: 650, minHeight: 335, maxHeight: 335, background: "black", borderRadius: '12px' }}>
                    <source src={link} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>

            </Card>
            {/* {pageType === 'draft' && (
                <Grid variant="G13" xs={12}
                    item
                    container
                    sx={{ height: "40px" }} justifyContent='space-between'>

                    <Grid>
                        {activeButton === 1 && shouldRenderSection() && (
                            <Grid display='flex' justifyContent='flex-start' alignItems='center'>
                                <Select
                                    options={options}
                                    onChange={handleBrandChange}
                                    components={{ Option: CustomOption }}
                                    styles={customStyles}
                                    menuPlacement="top"
                                    value={selectedProject?.brandLogo.value}
                                />
                                <Button variant="B2S" sx={{ background: "green", color: 'black', height: "40px", marginRight: '5px' }} onClick={handelImgSubmit}
                                    disabled={selectedBrand === null}>Submit</Button>
                            </Grid>

                        )}
                    </Grid>
                    <Grid>
                        <>
                            <Button variant="B2S" sx={{ marginRight: '1px' }} onClick={() => handleApproveClick(selectedProject)}>Approve Campaign</Button>
                            <Button variant="B2S" sx={{ marginRight: '1px' }} onClick={() => handleRejectDialog(selectedProject)}>Reject Campaign</Button>
                        </>
                    </Grid>
                    <Button variant="B2S" onClick={() => { handleComment(selectedProject) }}>Comment</Button>
                </Grid>
            )} */}

        </Grid>
    );
}

export default TabSwitchBrand;



