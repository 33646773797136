import React, { useState, useRef, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { Grid, Typography, IconButton} from '@mui/material';
import CustomTextfield from './../CommonComponet/CustomTextfield';
import { BsFillSendFill } from 'react-icons/bs'
import { useSelector } from 'react-redux';
import { setShowLoader } from '../../redux/loaderSlice';
import { awsApiCamp } from '../../Routes/Server';
import { v4 as uuidv4 } from 'uuid';

const CommentBrand = ({ selectedVpp, currentUser, setNewMessage, newMessage ,setUpdateMessages ,onUpdateSelectedVpp,setSelectedVpp ,handleUpdateMessages}) => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user.userLoginData);
  const commentsContainerRef = useRef(null);
  const comments = selectedVpp?.clipSet[0]?.commentsBrand || [];

  const formatTime = (time) => {
    const date = new Date(time);
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  };

  const handleSend = async () => {
    if (newMessage.trim() !== '') {
      dispatch(setShowLoader(true));
      try {
        const currentUnixTimestamp = Math.floor(new Date().getTime() / 1000);
        const messageId = uuidv4();
        const updatedClipSet = selectedVpp.clipSet.map(clip => {
          return {
            ...clip,
            commentsBrand: [
              ...(clip.commentsBrand || []),
              {
                meassage: newMessage,
                time: new Date().toISOString(),
                senderType: "creative",
                senderId: userData.sub,
                senderName: userData.name,
                meassageId: messageId,
                options: "",
                timeStamp: currentUnixTimestamp,
                rechiverType: "brand",
              },
            ],
          };
        });
        const updatedselectedVpp = {
          ...selectedVpp,
          clipSet: updatedClipSet,
        };
        const res = await awsApiCamp.post('/postcampaign', {
          ...updatedselectedVpp,
        });
        setNewMessage('')
        handleUpdateMessages()
        if (res.data.statusCode === 200) {
          onUpdateSelectedVpp(updatedselectedVpp);
          console.log((JSON.parse(res.data.body)), 'Comment submitted successfully');
          setSelectedVpp(updatedselectedVpp);
        }

      } catch (error) {
        console.error('Error submitting comment:', error);
      } finally {
        dispatch(setShowLoader(false));
      }
    }
  };

  useEffect(() => {
    if (commentsContainerRef.current) {
      commentsContainerRef.current.scrollTop = commentsContainerRef.current.scrollHeight;
    }
  }, [comments]);

  let windowHeight;

  if (window.innerWidth < 1150) {
      windowHeight = "98vh";
  } else {
      windowHeight = window.innerHeight - 315;
  }


  return (
    <Grid container direction="column" >
       <Grid item xs={12} variant="G26" sx={{ background: 'black' }}>
        <Typography variant="P21" mt={2} mb={2} style={{ color: 'white' }}>
          Brand Comment
        </Typography>
      </Grid>
      <Grid xs={12} style={{ flex: 1, overflowY: 'auto', marginBottom: '10px', maxHeight: windowHeight, minHeight: windowHeight }} ref={commentsContainerRef}>
        <Grid item mt={1.5} ml={1.5} mr={1.5} >
          {/* Display Messages */}
          {comments.map((comment, index) => (
            <div
              key={index}
              style={{
                display: 'flex',
                justifyContent: comment.senderType === currentUser ? 'flex-end' : 'flex-start',
                marginBottom: '10px',
              }}
            >
              {/* {comment.brand !== currentUser && <Avatar style={{ marginRight: '5px' }}>C</Avatar>} */}
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: comment.brand === currentUser ? 'flex-end' : 'flex-start' }}>
                <Typography
                  variant="body1"
                  color={comment.senderType === currentUser ? 'textPrimary' : 'textSecondary'}
                  style={{
                   
                    backgroundColor:
                      comment.meassage === 'New branded Video Has been Uploaded' ||
                        comment.meassage === 'New unbranded Video Has been Uploaded'
                        ? '#ff000040'
                        : comment.senderType === currentUser
                          ? '#DCF8C6'
                          : '#EAEAEA',
                    fontWeight: comment.meassage === 'New branded Video Has been Uploaded' ||
                      comment.meassage === 'New unbranded Video Has been Uploaded'
                      ? '600'
                      : comment.senderType === currentUser
                        ? '400'
                        : '400',
                        color:"black",
                    padding: '8px',
                    borderRadius: '8px',
                    maxWidth: '250px',
                    // minWidth: '70%',
                    fontSize: "14px",
                    fontFamily: "DM Sans",
                    wordWrap: 'break-word',
                    textAlign: comment.senderType === currentUser ? 'right' : 'left',
                  }}
                >
                  {comment.meassage || comment.meassage} {/* Use 'message' or 'creative' property based on availability */}
                </Typography>
                <Typography variant="caption" color="textSecondary" style={{ alignSelf: comment.senderType === currentUser ? 'flex-end' : 'flex-start' }}>
                  {/* {formatTime(comment.time)} */}
                  {formatTime(comment.timeStamp)}
                </Typography>
              </div>
            </div>
          ))}
        </Grid>
      </Grid>

      <Grid xs={12} display="flex">
        {/* Input field to send new messages */}

        <CustomTextfield
          size={"small"}
          id={"user-email-input"}
          placeholder={"Type your message..."}
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              handleSend();
            }
          }}
          fullWidth
          style={{ border: 'none' }}
        />
        <IconButton variant='IB1' onClick={handleSend}><BsFillSendFill /></IconButton>

      </Grid>
    </Grid>
  );
};

export default CommentBrand;


