import * as React from "react";
import { Grid, Paper, Typography, Button, Collapse, Card, CardMedia, Chip, IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";
import { IoFilterSharp } from "react-icons/io5";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { IoMdArrowBack } from 'react-icons/io'
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import TopBar from '../../Components/ProjectComponet/TopBar'
import SubContainer from "./SubContainer";
import SearchBar from "../CampaignsComponet/SearchBar";
import CommonTable from "../CommonComponet/CommonTable";
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { Img, Tabel } from './../../ThemeProvider/style';
import Box from '@mui/material/Box';
import { CloudDownloadOutlined, MoreHoriz, Delete } from '@mui/icons-material';
import StepOne from "../AddProject/StepOne";
import { useDispatch } from "react-redux";
import { setShowLoader } from '../../redux/loaderSlice';
import defaultThumbnail from '../../assets/NoVideo.png'
import Loader from "../CommonComponet/Loader";
import { awsApi } from "../../Routes/Server";
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import NoData from "../CommonComponet/NoData";
import CustomizedTables from "../DashboardComponet/MuiTabel";

const ActionMenu = ({ rowData, setRows, handleOpenDialog }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleDelete = async (id) => {
        try {
            const res = await awsApi.post('/deleteproject', { projectId: id })
            if (res.data.statusCode === 200) {
                // console.log(res.data.body);
                setRows((prevRows) => prevRows.filter((row) => row.id !== id));
            }
        } catch (err) {
            console.log(err, "error")
        }
    };

    const handleEdit = (rowData) => {
        handleOpenDialog(rowData); // Pass the rowData to handleOpenDialog
        handleMenuClose();
    };


    return (
        <>
            {/* <IconButton onClick={handleMenuClick}>
                <MoreHoriz />
            </IconButton> */}
            {/* <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                <MenuItem onClick={() => handleDelete(rowData.id)}>Delete</MenuItem>
                <MenuItem onClick={() => handleEdit(rowData)}>Edit</MenuItem>
            </Menu> */}
            <Menu menuButton={<IconButton><MoreHoriz /></IconButton>} transition align={'center'}>
                <MenuItem onClick={() => handleDelete(rowData.id)}>Delete</MenuItem>
                <MenuItem onClick={() => handleEdit(rowData)}>Edit</MenuItem>
            </Menu>
        </>
    );
};

const DraftListCon = ({ rows, setRows, fetchDraftProjects }) => {
    const dispatch = useDispatch();
    const [searchText, setSearchText] = useState('');

    const [openDialog, setOpenDialog] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [isDataGridLoaded, setIsDataGridLoaded] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [selectedRowDetails, setSelectedRowDetails] = useState(null);

    const handleClickDelete = (row) => {
        setOpen(true);
        setSelectedRowDetails(row)
    };

    const handleClose = () => {
        setOpen(false);
    };

    const largeMaxHeight = 10000;

    useEffect(() => {
        setIsDataGridLoaded(true);
    }, []);



    const handleSetRows = (updatedRows) => {
        setRows(updatedRows);
    };

    const handleOpenDialog = (rowData) => {
        setSelectedRow(rowData);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleDownload = (id) => {
        // console.log("Download project with ID:", id);
    };

    rows.forEach((row, index) => {
        row._index = index + 1;
    });

    const getValue = (row) => row._index;

    // const thumbnailUrl = thumbnail || defaultThumbnail;

    const columns = [
        {
            field: 'index',
            headerName: 'S.No',
            width: 60,
            valueGetter: (params) => params.row._index,
        },
        // {
        //     field: "imgURL",
        //     headerName: "Thumbnail",
        //     width: 160,
        //     renderCell: (params) => (
        //         <Box sx={{ display: 'flex', alignItems: 'center' }}>
        //             <Img src={params.row.imgURL || defaultThumbnail} alt="Project Title" />

        //         </Box>
        //     ),
        // },
        { field: "title", headerName: "Title", width: 330 },
        { field: "contentType", headerName: "Content Type", width: 175 },
        { field: "country", headerName: "Country", width: 175 },
        {
            field: "releasePlatform",
            headerName: "Distribution Channel",
            width: 180,
        },

        {
            field: 'id',
            headerName: 'Action',
            description: 'This column has a value getter and is not sortable.',
            sortable: false,
            width: 150,
            renderCell: (params) => (
                <ActionMenu rowData={params.row} setRows={handleSetRows} handleOpenDialog={handleOpenDialog} />
            ),
        },
    ];

    const rowsPerPageOptions = [5, 10, 25];

    const handleSearchChange = (event) => {
        setSearchText(event.target.value)
    };

    const filteredData = rows.filter((item) => {
        const searchValue = searchText.toLowerCase();
        const rowData = [item.sno, item.imgURL, item.title, item.releasePlatform];
        return rowData.some((cell) => String(cell).toLowerCase().includes(searchValue));
    });

    const handleDelete = async (row) => {
        const id = row.id
        try {
            const res = await awsApi.post('/deleteproject', { projectId: id })
            if (res.data.statusCode === 200) {
                // console.log(res.data.body);
                setRows((prevRows) => prevRows.filter((row) => row.id !== id)); 
                handleClose()
                await fetchDraftProjects()
            }
           
        } catch (err) {
            console.log(err, "error")
        }

    };

    const handleEdit = (rowData) => {
        handleOpenDialog(rowData); // Pass the rowData to handleOpenDialog
    };



    return (
        <Grid container xs={12} >
            <Grid container xs={12} variant='G1' mt={3} >
                <Grid xs={2} mt={3} ml={2} >
                    <SearchBar onChange={handleSearchChange} />
                </Grid>
                <Grid xs={11.7} mt={3} ml={2}>
                    {filteredData.length === 0 ? (
                        <NoData />
                    ) : (
                        <>
                            <CustomizedTables
                                filterRow={filteredData}
                                columns={columns}
                                rowAttributes={[
                                    { name: "title", type: 'regular', newStyle: "pointer", fontWeight: "bold", },
                                    { name: "contentType", type: 'regular' },
                                    { name: "country", type: 'regular' },
                                    { name: "releasePlatform", type: 'regular', dataType: "arraySet" },
                                    {
                                        name: "id",
                                        type: 'quikAction',
                                        child: "clipSet",
                                        actions: [
                                            { label: 'Edit', buttonType: 'Edit', buttonColor: "#00ff0070", action: handleEdit },
                                            { label: 'Delete', buttonType: 'Delete', buttonColor: "#ff000085", action: handleClickDelete },

                                        ],
                                    },
                                ]}
                            />
                        </>
                    )}
                </Grid>
            </Grid>
            {selectedRow && (
                <StepOne open={openDialog} onClose={handleCloseDialog} rowData={selectedRow}
                    fetchDraftProjects={fetchDraftProjects}
                />
            )}
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" style={{fontSize:"18px"}}>
                    {"Are you sure you want to delete this draft permanently."}
                </DialogTitle>
                <DialogActions>
                    <Button onClick={handleClose} autoFocus color="success">Disagree</Button>
                    <Button onClick={() => handleDelete(selectedRowDetails)} color="error">
                        Agree
                    </Button>
                </DialogActions>
            </Dialog>
            <Loader />
        </Grid>
    );
};

export default DraftListCon;
