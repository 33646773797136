const SignalProcessingVp = `
<SignalProcessingNotification xmlns="urn:cablelabs:iptvservices:esam:xsd:signal:1" 
    xmlns:sig="urn:cablelabs:md:xsd:signaling:3.0" 
    xmlns:common="urn:cablelabs:iptvservices:esam:xsd:common:1" 
    xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
<ResponseSignal acquisitionPointIdentity="AD_INSERTION_POINT" acquisitionSignalID="1" signalPointID="1" action="create">
    <sig:NPTPoint nptPoint="27.000" />
    <sig:SCTE35PointDescriptor spliceCommandType="06">
        <sig:SegmentationDescriptorInfo segmentEventId="1" segmentTypeId="52"/>
    </sig:SCTE35PointDescriptor>
</ResponseSignal>
<ResponseSignal acquisitionPointIdentity="AD_INSERTION_POINT" acquisitionSignalID="1" signalPointID="2" action="create">
    <sig:NPTPoint nptPoint="33.000" />
    <sig:SCTE35PointDescriptor spliceCommandType="06">
        <sig:SegmentationDescriptorInfo segmentEventId="2" segmentTypeId="52"/>
    </sig:SCTE35PointDescriptor>
</ResponseSignal>
</SignalProcessingNotification>
`;

export default SignalProcessingVp;
