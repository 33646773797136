import React from 'react'
import Bar from '../../Components/CommonComponet/Bar'
import { Grid } from '@mui/material'

const TopBar = ({ ComponentName }) => {
    return (
        <Grid xs={12} display='flex' justifyContent='space-between' alignItems='center'>
            <Grid xs={6}>
                <Bar
                    ComponentName={ComponentName}
                />
            </Grid>

        </Grid>
    )
}

export default TopBar