import { Grid, Typography ,Button} from '@mui/material'
import React from 'react'
import TopBar from '../MraketPlace/TopBar'
import SelctionVpp from '../VpoMarket/SelctionVpp'
import Bar from '../CommonComponet/Bar'
import { MdArrowForwardIos } from 'react-icons/md'
import { useNavigate } from 'react-router-dom';


const VpoMarketView = () => {
    
    const navigate = useNavigate();

    const ComponentName = 'VPO Market'
    const childComponentName = "VPO Main"

    const handleNavigate = () => {
        navigate('/VpoMarket');
    };


    return (
        <Grid container xs={12} mt={7}>
            {/* <TopBar ComponentName={ComponentName} /> */}
            <Grid xs={12} display='flex' justifyContent='space-between' alignItems='center'>
                <Grid xs={6} >
                    <Typography>
                        <Typography variant='P2' style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={handleNavigate}>{ComponentName}</Typography> <MdArrowForwardIos style={{ fontSize: '17px', marginTop: "-7px" }} />&nbsp;
                        <Typography variant='P2N' >{childComponentName}</Typography>
                    </Typography>
                </Grid>
                <Button variant='B1' onClick={() => { handleNavigate() }} style={{ fontSize: "15px", width: "80px" ,height:"35px",marginTop:'5px'}} >Back</Button>
            </Grid>
            <Grid container xs={12} variant='G1' mt={3} >
                <SelctionVpp />
            </Grid>
        </Grid>
    )
}

export default VpoMarketView
