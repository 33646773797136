import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Image {
    categoryId: number;
    label: string;
    videoId: string;
    InsightType: string;
}

interface SelectedImagesState {
    selectedImages: Image[];
}

const initialState: SelectedImagesState = {
    selectedImages: [],
};

const selectedImagesSlice = createSlice({
    name: 'selectedImages',
    initialState,
    reducers: {
        selectImage: (state, action: PayloadAction<Image>) => {
            state.selectedImages.push(action.payload);
        },
        deselectImage: (state, action: PayloadAction<string>) => {
            state.selectedImages = state.selectedImages.filter(
                (image) => image.videoId !== action.payload
            );
        },
    },
});

export const { selectImage, deselectImage } = selectedImagesSlice.actions;

export default selectedImagesSlice.reducer;
