import React, { useState } from 'react'
import { Grid, Button } from '@mui/material'
import TabSwitchCreative from './TabSwitchCreative'
const VTwo = ''
const VThree = ''

const PlayerTabCreative = ({ VppClipUrl, startTime, uploadFunctionbrand,actionValue, uploadFunctionUnbrand, selectedProject, handleClose, label, handleGeneratePdf, handleUpdateMessages, handleComment ,pageType}) => {
    const [activeButton, setActiveButton] = useState(actionValue);

    const handleButtonClick = (buttonIndex) => {
        setActiveButton(buttonIndex);
    };

    console.log('PlayerTabCreative', selectedProject)


    return (
        <>
            <Grid xs={12} variant='G21-A'>
                <Button variant='Tab'
                    sx={{
                        marginLeft: '6px', borderLeft: '1px solid black',
                        color: activeButton === 1 ? 'white' : 'black',
                        background: activeButton === 1 ? '#657195' : 'white',
                    }} onClick={() => handleButtonClick(1)}
                >Original Scene</Button>

                {/* <Button variant='Tab'
                    sx={{
                        color: activeButton === 2 ? 'white' : 'black',
                        background: activeButton === 2 ? '#657195' : 'white',
                    }} onClick={() => handleButtonClick(2)}
                >Unbranded Scene</Button> */}

                <Button variant='Tab'
                    sx={{
                        color: activeButton === 3 ? 'white' : 'black',
                        background: activeButton === 3 ? '#657195' : 'white',
                    }} onClick={() => handleButtonClick(3)}
                >Branded Scene</Button>
            </Grid>
            <Grid xs={12} container mt={1}>
                {activeButton === 1 && <TabSwitchCreative handleClose={handleClose} link={VppClipUrl} startTime={startTime} type={"original"} selectedProject={selectedProject}
                    label={label} handleGeneratePdf={handleGeneratePdf} handleUpdateMessages={handleUpdateMessages} handleComment={handleComment} pageType={pageType}/>}
                {/* {activeButton === 2 && < TabSwitchCreative handleClose={handleClose} handleComment={handleComment}
                    link={selectedProject.clipSet && selectedProject.clipSet[0] && selectedProject.clipSet[0]?.unbranded?.Location}
                    type={"unbranded"} selectedProject={selectedProject} label={label} handleGeneratePdf={handleGeneratePdf} handleUpdateMessages={handleUpdateMessages} />} */}
                {activeButton === 3 && < TabSwitchCreative handleClose={handleClose} handleComment={handleComment}
                    link={selectedProject.clipSet && selectedProject.clipSet[0] && selectedProject.clipSet[0]?.branded?.Location}
                    type={"branded"} selectedProject={selectedProject} label={label} handleGeneratePdf={handleGeneratePdf} handleUpdateMessages={handleUpdateMessages} pageType={pageType}/>}

            </Grid>


        </>
    )
}

export default PlayerTabCreative
