import { Grid } from '@mui/material';
import React from 'react'

import TopBar from '../Components/MraketPlace/TopBar';
import Container from '../Components/GallaryComponet/Container';

const Gallary = () => {
  const ComponentName = 'Gallery';


  return (
    <Grid container xs={12} mt={7}>
      <TopBar ComponentName={ComponentName} />
      <Grid container xs={12} variant='G1' mt={3}>
        <Container />
      </Grid>
    </Grid>
  )
}

export default Gallary
