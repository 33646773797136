import { configureStore } from '@reduxjs/toolkit';
import loginSlice from './loginSlice';
import projectSlice from './projectSlice';
import videoSlice from './videoSlice';
import selectedImagesSlice from './SelectedImagesSlice';
import videoIdSlice from './videoIdSlice';
import loaderSlice from './loaderSlice';
import analysisSlice from './analysisSlice';
import userReducer from './userSlice';
import vppIdSlice from './vppIdSlice'
import PageTypeSlice from './vppTypeSlice'
import AppTypeSlice from './stateSlice'

const store = configureStore({
  reducer: {
    login: loginSlice,
    videoId: videoIdSlice,
    analysis: analysisSlice,
    project: projectSlice,
    video: videoSlice,
    selectedImages: selectedImagesSlice,
    loader: loaderSlice,
    user: userReducer,
    vpp: vppIdSlice,
    VppType: PageTypeSlice,
    Application: AppTypeSlice,
  },
});

export default store;

