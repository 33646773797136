// ManifestCondition.jsx

const ManifestConditionDataVp = `
<ManifestConfirmConditionNotification xmlns="http://www.cablelabs.com/namespaces/metadata/xsd/confirmation/2">
    <ManifestResponse acquisitionPointIdentity="AD_INSERTION_POINT" acquisitionSignalID="1" duration="PT20.000S" dataPassThrough="true">
        <SegmentModify>
            <FirstSegment>
                <Tag value="#EXT-X-CUE-OUT:27.000"/>
            </FirstSegment>
            <LastSegment>
                <Tag value="#EXT-X-CUE-IN" locality="after" adapt="true"/>
            </LastSegment>
        </SegmentModify>
    </ManifestResponse>
</ManifestConfirmConditionNotification>
`;

export default ManifestConditionDataVp;
