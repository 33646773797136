
import {
    Grid, MenuItem,
    //  Select 
} from "@mui/material";
import Typography from '@mui/material/Typography';
import * as React from 'react';
import VppTabel from './VppTabel';
import { useEffect, useLayoutEffect, useState } from 'react';
import { setShowLoader } from '../../redux/loaderSlice';
import { useDispatch } from "react-redux";
import NoData from "../CommonComponet/NoData";
import { useSelector } from "react-redux";
import Loader from "../CommonComponet/Loader";
import { awsApi } from "../../Routes/Server";
import { brandCategoryOptions, typeofContentOptions, vppTypeOption } from "../CommonComponet/docsData";
import Select from "react-select";
import NewNoData from "./NewNoData";

const SelctionVpp = () => {
    const dispatch = useDispatch();

    const projectById = useSelector(state => state.project.projectId);
    const pageType = useSelector(state => state.VppType.pageType);
    const [clickedProject, setClickedProject] = useState(null);
    console.log('pageType', pageType)

    const [filters, setFilters] = useState({
        brandCategory: ['all'],
        vppType: ['both']
    });

    const parsedProject = localStorage.getItem('clickedProject');
    const storedProject = JSON.parse(parsedProject);

    console.log(filters)

    const [projectTypeXs, setProjectTypeXs] = useState('12');
    const [projectNameXs, setProjectNameXs] = useState('0');
    const [episodeXs, setEpisodeXs] = useState('0');
    const [selectedProjectType, setSelectedProjectType] = useState(null);
    const [selectedProjectType1, setSelectedProjectType1] = useState(null);
    const [projectNames, setProjectNames] = useState([]);
    const [projectStoredNames, setProjectStoredNames] = useState([]);
    const [selectedProjectName, setSelectedProjectName] = useState('');
    const [seasonsData, setSeasonsData] = useState([]);
    const [selectedSeason, setSelectedSeason] = useState('');
    const [selectedEpisode, setSelectedEpisode] = useState('');
    const [episodeOptions, setEpisodeOptions] = useState([]);
    const [clipSet, setClipSet] = useState([]);
    const [selectedVideoIdSlice, setSelectedVideoIdSlice] = useState("")
    const [selectedProjectTitle, setSelectedProjectTitle] = useState('');
    const [selectedEpisodeNo, setSelectedEpisodeNo] = useState('');
    const [newD, setNewD] = useState(false)
    const [updateMessages, setUpdateMessages] = useState('')
    const [dummyTesting, setDummyTesting] = useState("")



    // useEffect(() => {
    //     dispatch(setShowLoader(true));
    //     if (selectedProjectType) {
    //         handleProjectTypeChange({ target: { value: selectedProjectType } });
    //     }
    //     console.log('Use 4')
    //     dispatch(setShowLoader(false));
    //     console.log('Use 04')
    // }, [selectedProjectType]);

    //console.log(storedProject)



    const handleProjectTypeChange = async (event) => {
        const newSelectedProjectType = event?.target?.value || event?.value;
        setSelectedProjectType(newSelectedProjectType);
        setClipSet([])
        if (newSelectedProjectType) {
            console.log("pep 1 : -newSelectedProjectType ", newSelectedProjectType)

            const fetchData = async () => {
                try {
                    let actionType = 'get-vpp-list';

                    if (pageType === 'market') {
                        actionType = 'get-vpp-list-market';
                    }

                    dispatch(setShowLoader(true));
                    const response = await awsApi.post(
                        "/postvideo",
                        {
                            action: actionType,
                            country: "",
                            projectType: [newSelectedProjectType],
                            genre: "",
                            season: "",
                            episode: ""
                        }
                    );
                    setProjectTypeXs('5.9');
                    setProjectNameXs('5.9');
                    setEpisodeXs('0');
                    const projectTitles = response.data.body
                    console.log("pep 2 : -projectTitles ", projectTitles)
                    let tempProjectName = projectTitles.map(project => ({ value: project.projectId, label: project.title, key: project.projectId }));
                    setProjectNames(tempProjectName);
                    console.log("pep 3 : -tempProjectName ", tempProjectName)
                    setProjectStoredNames(projectTitles)
                    if (newD === false) {
                        setNewD(true)
                    }
                    dispatch(setShowLoader(false));
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
                dispatch(setShowLoader(false));

            };
            await fetchData();
        }
    };

    //    console.log('project Names', projectNames)

    const invokeVideoDetails = async (selectedProjectType, newSelectedProjectName, firstVideoId) => {
        let actionType = 'get-vpp-detail';

        if (pageType === 'market') {
            actionType = 'get-vpp-detail-market';
        }
        const response = await awsApi.post(
            "/postvideo",
            {
                action: actionType,
                projectType: [selectedProjectType],
                brandCategory: filters.brandCategory,
                vppType: filters.vppType,
                season: "",
                episode: "",
                projectId: newSelectedProjectName,
                videoId: firstVideoId,
            }
        );
        return response
    }



    const handleProjectNameChangeUpdated = async (event, sProjectType = "") => {
        const newSelectedProjectName = event?.target?.value || event?.value;
        setSelectedProjectName(newSelectedProjectName);
        console.log(selectedProjectType)
        let selectedProjectTypeLocal = selectedProjectType ? selectedProjectType : sProjectType;
        if (selectedProjectTypeLocal === 'tv-show' || selectedProjectTypeLocal === 'web-series') {
            dispatch(setShowLoader(true));
            const selectedProject = projectStoredNames.find((singleProj) => newSelectedProjectName === singleProj.projectId)
            setSelectedProjectTitle(selectedProject ? selectedProject.title : '');
            setProjectTypeXs('3.9');
            setProjectNameXs('3.9');
            setEpisodeXs('3.9');
            if (selectedProject) {
                const episodeOptions = selectedProject.video.map(video => ({
                    value: video.videoId,
                    label: video.episodeNo,
                    key: video.videoId
                }));
                setEpisodeOptions(episodeOptions);
                setSelectedEpisodeNo(selectedProject.video[0].videoId);
            } else {
                console.log('Selected project not found');
            }
            dispatch(setShowLoader(false));
        } else {
            console.log('test start :-', projectStoredNames)
            dispatch(setShowLoader(true));
            const selectedProject = projectStoredNames.find((singleProj) => newSelectedProjectName === singleProj.projectId)
            console.log("test 1 : -selectedProject ", selectedProject)
            let firstVideoId = "";
            console.log("test 2 : -firstVideoId ", firstVideoId)
            if (selectedProject && selectedProject.video && selectedProject.video.length > 0) {

                firstVideoId = selectedProject.video[0].videoId;
                setSelectedVideoIdSlice(firstVideoId);
                console.log("test 3 : -firstVideoId ", firstVideoId)
                setSelectedEpisodeNo(selectedProject.video[0].episodeNo);
            } else {
                setSelectedVideoIdSlice("");
                console.log("test 3 : -Failed ")
            }
            console.log("test 4 : -firstVideoId ", firstVideoId)
            setSelectedProjectTitle(selectedProject ? selectedProject.title : '');
            console.log(selectedProjectTypeLocal, newSelectedProjectName, firstVideoId)

            console.log("test 5 : -selectedProjectTypeLocal, newSelectedProjectName, firstVideoId ", selectedProjectTypeLocal, newSelectedProjectName, firstVideoId)
            const response = await invokeVideoDetails(selectedProjectTypeLocal, newSelectedProjectName, firstVideoId);
            if (response.data.body && response.data.body) {
                setClipSet(response.data.body);
                console.log("Response Data Body: ", JSON.stringify(response.data.body));
            } else {
                setClipSet([]);
            }
            setProjectTypeXs('5.9');
            setProjectNameXs('5.9');
            setEpisodeXs('0');
            dispatch(setShowLoader(false));
            setDummyTesting(newSelectedProjectName)
        }
    }



    const handleEpisodeChange = async (event) => {
        const selectedEpisode = event?.target?.value || event?.value;
        setSelectedEpisodeNo(selectedEpisode)
        try {
            dispatch(setShowLoader(true));
            let actionType = 'get-vpp-detail';

            if (pageType === 'market') {
                actionType = 'get-vpp-detail-market';
            }

            const response = await awsApi.post(
                "/postvideo",
                {
                    action: actionType,
                    projectType: selectedProjectType,
                    season: selectedSeason,
                    episode: selectedEpisode,
                    projectId: selectedProjectName,
                    videoId: selectedEpisode
                }
            );
            if (response.data.body && response.data.body) {
                setClipSet(response.data.body);
                setSelectedVideoIdSlice(selectedEpisode);
                // console.log(response.data)
            } else {
                setClipSet([]);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            dispatch(setShowLoader(false));
        }
    };


    useEffect(() => {
        if (storedProject.contentType && storedProject.id) {
            handleProjectTypeChange({ target: { value: storedProject.contentType } });
            setSelectedProjectName(storedProject.id);
        }
    }, []);

    useEffect(() => {
        if (newD === true) {
            handleProjectNameChangeUpdated({ target: { value: storedProject.id } }, storedProject.contentType);
        }
    }, [newD]);

    useEffect(() => {
        if (selectedProjectName && storedProject.video && storedProject.video.length > 0) {
            handleEpisodeChange({ target: { value: storedProject.video[0].videoId } });
            setSelectedEpisode(storedProject.video[0].videoId);
        }
    }, [selectedProjectName]);

    // useEffect(() => {
    //     dispatch(setShowLoader(true));
    //     if (selectedEpisode) {
    //         handleEpisodeChange({ target: { value: selectedEpisode } });
    //     }
    //     dispatch(setShowLoader(false));
    // }, [selectedEpisode]);


    // useEffect(() => {
    //     if (selectedProjectType && storedProject.id) {
    //         setSelectedProjectName(storedProject.id);
    //     }
    // }, [selectedProjectType, storedProject]);

    // useEffect(() => {
    //     if (selectedProjectName && storedProject.video && storedProject.video.length > 0) {
    //         setSelectedEpisode(storedProject.video[0].videoId);
    //     }
    // }, [selectedProjectName]);

    // useEffect(() => {
    //     dispatch(setShowLoader(true));
    //     if (selectedProjectType) {
    //         handleProjectTypeChange({ target: { value: selectedProjectType } });
    //     }
    //     dispatch(setShowLoader(false));
    // }, [selectedProjectType]);


    // console.log(selectedProjectName)



    // useEffect(() => {
    //     dispatch(setShowLoader(true));
    //     if (selectedEpisode) {
    //         handleEpisodeChange({ target: { value: selectedEpisode } });
    //     }
    //     dispatch(setShowLoader(false));
    // }, [selectedEpisode]);

    // const ProjectOption = projectNames.map(option => ({ value: option.id, label: option.title }));
    // const EpisodeOptions = episodeOptions.map(option => ({ value: option.videoId, label: option.episodeNo }));

    // const selectedProjectOption = ProjectOption.find(option => option.value === selectedProjectName);
    const selctPName = projectStoredNames.find((singleProj) => selectedProjectName === singleProj.projectId)

    const handleBrandCategoryChange = (selectedOptions) => {
        const selectedValues = selectedOptions.map((option) => option.value);
        setFilters({ ...filters, brandCategory: selectedValues });
    };



    const handleVppTypeChange = (selectedOptions) => {
        const selectedValues = selectedOptions.map((option) => option.value);
        setFilters({ ...filters, vppType: selectedValues });
    };

    useEffect(() => {
        const fetchData = async () => {
            dispatch(setShowLoader(true));
            try {
                const response = await invokeVideoDetails(selectedProjectType, selectedProjectName, selectedVideoIdSlice);
                if (response.data.body && response.data.body) {
                    setClipSet(response.data.body);
                } else {
                    setClipSet([]);
                }
                dispatch(setShowLoader(false));
            } catch (error) {
                // Handle errors if needed
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, [filters]);

    const getLabelByValue = (value) => {
        const option = typeofContentOptions.find((option) => option.value === value);
        return option ? option.label : value; // Return the label if found, otherwise return the original value
    };

    // const newSet = projectNames.find(option => option.value === selectedProjectName)
    // console.log(newSet)

    const jsonData = {
        "id": "869ca2f3-73ba-4bd5-a168-3fb91a7c3334",
        "projectId": "cfb2a701-7de8-4387-b2ec-fbbe831800a1",
        "VideoId": "da92257a-4ab2-44a0-a5cd-335c6473f361",
        "VppClips": [
            {
                "VppId": "NB4EF44B45lqvD3F09oWe",
                "VppFreamNo": 0,
                "VppClipUrl": "https://vi-video-collector-01.s3.ap-south-1.amazonaws.com/d66957a2-c0ac-4a3a-9e46-f51522ff5be0_%23_069b74b4-ecd9-4c55-9a41-9d2c3b160a68_%23_Chaand+Baaliyan.mp4",
                "startTime": "0:0:27",
                "endTime": "0:0:33",
                "status": "",
                "Duration": "6",
                "price": "3000",
                "projectTitle": "Badshah - Sajna",
                "brandCat": "Clothing and Fashion",
                "associationStatus": "",
                "status":"checked",
                "videoId": "c5c24356-1972-488a-93e6-9329bda98cde",
                "label": "Party",
                "labelInsight": {
                    "Aliases": [],
                    "Categories": [
                        {
                            "Name": "Events and Attractions"
                        }
                    ],
                    "Confidence": 95.12347412109375,
                    "Name": "Party",
                    "Parents": [
                        {
                            "Name": "Fun"
                        }
                    ]
                }
            }
        ]
    };

    useEffect(() => {
        if (dummyTesting === jsonData.projectId) {
            setClipSet(jsonData);
        }
    }, [dummyTesting]);

    console.log("clipSet", clipSet)

    return (
        <Grid container xs={12}>
            <Grid container xs={11.7} mt={1} ml={2}>
                <Grid xs={12} variant='G2' style={{ maxHeight: "40px" }}>
                    <Typography variant='P15' ml={2} style={{ fontSize: "18px" }}>
                        {/* {selectedProjectType &&
                            `${selectedProjectType.charAt(0).toUpperCase() + selectedProjectType.slice(1)} - `}

                        {selectedProjectTitle &&
                            `${selectedProjectTitle} - `}  

                        {selectedProjectType === 'tv-show' || selectedProjectType === 'web-series' ?
                            `Episode (${selectedEpisodeNo}) - ` : ''}

                        VPP */}

                        {getLabelByValue(selectedProjectType)} - {selctPName && selctPName.title && <>{selctPName.title}</>} - VPO
                    </Typography>
                </Grid>
                <Grid xs={12} display='flex' spacing={2} justifyContent='space-between' mt={1}>
                    <Grid xs={projectTypeXs} variant='G2'>
                        <Grid item xs={11.5} container ml={0.2} spacing={1} >
                            <Grid item xs={12} >
                                <Typography variant="P14" >
                                    {"Project Type -"}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} mt={-1} >
                                <Select
                                    options={typeofContentOptions}
                                    value={typeofContentOptions.find((option) => option.value === selectedProjectType)}
                                    onChange={handleProjectTypeChange}
                                    placeholder={'Select Project Type'}
                                />
                                {/* <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    fullWidth
                                    sx={{ maxHeight: '35px' }}
                                    value={selectedProjectType}
                                    onChange={handleProjectTypeChange}
                                >

                                    <MenuItem value="">Select Project Type</MenuItem>
                                    <MenuItem value="tv-show">Tv-Show</MenuItem>
                                    <MenuItem value="web-series">Web-Series</MenuItem>
                                    <MenuItem value="movie">Movie</MenuItem>
                                    <MenuItem value="music-video">Music-Video</MenuItem>
                                    <MenuItem value="other">Other</MenuItem>
                                </Select> */}
                            </Grid>
                        </Grid>
                    </Grid>
                    {selectedProjectType && (
                        <Grid xs={projectNameXs} variant='G2'>
                            <Grid item xs={11.5} container ml={0.2} spacing={1} >
                                <Grid item xs={12} >
                                    <Typography
                                        variant="P14"
                                    >
                                        {"Project  -"}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} mt={-1} >
                                    <Select
                                        options={projectNames}
                                        value={projectNames.find(option => option.value === selectedProjectName) || null}
                                        onChange={handleProjectNameChangeUpdated}
                                        placeholder={'Select Project Name'}
                                    />

                                    {/* <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        fullWidth
                                        sx={{ maxHeight: '35px' }}
                                        value={selectedProjectName}
                                        onChange={handleProjectNameChange}
                                    >
                                        {projectNames.map(project => (
                                            <MenuItem key={project} value={project.id}>
                                                {project.title}
                                            </MenuItem>
                                        ))}
                                    </Select>  */}
                                </Grid>

                            </Grid>
                        </Grid>
                    )}
                    {selectedProjectType && (
                        (selectedProjectType === 'tv-show' || selectedProjectType === 'web-series') ? (
                            <Grid xs={episodeXs} variant='G2' >
                                <Grid item xs={11.5} container ml={0.2} spacing={1} >
                                    <Grid item xs={12} >
                                        <Typography
                                            variant="P14"
                                        >
                                            {"Episode  -"}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} mt={-1} >
                                        <Select
                                            options={episodeOptions}
                                            // value={episodeOptions.find((option) => option.value === selectedEpisode)}
                                            value={episodeOptions.find(option => option.value === selectedEpisodeNo)}
                                            onChange={handleEpisodeChange}
                                            placeholder={'Select Episode'}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        ) : null
                    )}



                </Grid>
                <Grid xs={12} display='flex' spacing={2} justifyContent='space-between' mt={1}>
                    <Grid xs={7.8} variant='G2'>
                        <Grid item xs={11.5} container ml={0.2} spacing={1} >
                            <Grid item xs={12} >
                                <Typography
                                    variant="P14"
                                >
                                    {"Possible brand Categories -"}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} mt={-1} >
                                <Select
                                    closeMenuOnSelect={false}
                                    isMulti
                                    options={
                                        filters.brandCategory && filters.brandCategory.includes('all')
                                            ? [{ value: 'all', label: 'All' }]
                                            : [{ value: 'all', label: 'All' }, ...brandCategoryOptions]
                                    }
                                    value={
                                        filters.brandCategory
                                            ? filters.brandCategory.includes('all')
                                                ? [{ value: 'all', label: 'All' }]
                                                : filters.brandCategory.map((value) => ({
                                                    value,
                                                    label: value,
                                                }))
                                            : [{ value: 'all', label: 'All' }]
                                    }
                                    onChange={handleBrandCategoryChange}
                                    placeholder={'Select Brand Category'}
                                />


                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid xs={4} variant='G2'>
                        <Grid item xs={11.5} container ml={0.2} spacing={1} >
                            <Grid item xs={12} >
                                <Typography
                                    variant="P14"
                                >
                                    {"VPO Type  -"}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} mt={-1} >
                                <Select
                                    closeMenuOnSelect={false}
                                    isMulti
                                    options={
                                        filters.vppType && filters.vppType.includes('both')
                                            ? [{ value: 'both', label: 'Both' }]
                                            : [{ value: 'both', label: 'Both' }, ...vppTypeOption]
                                    }
                                    value={
                                        filters.vppType
                                            ? filters.vppType.includes('both')
                                                ? [{ value: 'both', label: 'Both' }]
                                                : filters.vppType.map((value) => ({
                                                    value,
                                                    label: value,
                                                }))
                                            : [{ value: 'both', label: 'Both' }]
                                    }
                                    onChange={handleVppTypeChange}
                                    placeholder={'Select Brand Category'}
                                />

                            </Grid>

                        </Grid>
                    </Grid>

                </Grid>
                {/* {clipSet && clipSet.VppClips && clipSet.VppClips.length >= 1 ? (
                    <VppTabel clipsData={clipSet}  setClipData={setClipSet} invokeVideoDetails={invokeVideoDetails} setUpdateMessages={setUpdateMessages} 
                    selectedProjectType={selectedProjectType}
                    brandCat={'Food and Beverages'} projectId={selectedProjectName} videoId={selectedVideoIdSlice} />
                ) : (
                    <NoData />
                )} */}
                {clipSet && clipSet.VppClips && clipSet.VppClips.length >= 1 ? (
                    <VppTabel
                        clipsData={clipSet}
                        setClipData={setClipSet}
                        invokeVideoDetails={invokeVideoDetails}
                        setUpdateMessages={setUpdateMessages}
                        selectedProjectType={selectedProjectType}
                        brandCat={'Food and Beverages'}
                        projectId={selectedProjectName}
                        videoId={selectedVideoIdSlice}
                        pageType={pageType}
                    />
                ) : <NewNoData state={"Sale"} />}

            </Grid>
            <Loader />
        </Grid >
    )
}



export default SelctionVpp


