import React, { useState } from 'react'
import Bar from '../../Components/CommonComponet/Bar'
import Select from "react-select";
import { Button, Grid, Typography } from '@mui/material'
import { dashboard } from '../CommonComponet/docsData';

const TopBar = ({ ComponentName, filters, setFilters }) => {


    const handleFilterByDate = (selectedOption) => {
        setFilters({ ...filters, filterByDate: selectedOption.value });
    };


    return (
        <Grid xs={12} display='flex' justifyContent='space-between' alignItems='center'>
            <Grid xs={6}>
                <Bar
                    ComponentName={ComponentName}
                />
            </Grid>
            <Grid display='flex' alignItems='center'>
                <Typography variant='CHead' style={{ fontSize: "15px", cursor: 'pointer' }} mr={1}>Filter &nbsp;: &nbsp;</Typography>
                <Grid >
                    <Select
                        options={[
                            ...dashboard,
                        ]}
                        value={dashboard.find((option) => option.value === filters.filterByDate) || null}
                        onChange={handleFilterByDate}
                        placeholder={'Select Filter'}
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default TopBar
