import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
    name: 'user',
    initialState: { userLoginData: {} },
    reducers: {
        setUserLoginData: (state, action) => {
            state.userLoginData = action.payload;
        },
    },
});

export const { setUserLoginData } = userSlice.actions;
export default userSlice.reducer;
