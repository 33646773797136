import React, { useEffect, useRef, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Card, CardMedia, Grid } from "@mui/material";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { addProjectId } from '../../redux/projectSlice';
import { useDispatch } from "react-redux";
import { brandCategoryOptions } from '../CommonComponet/docsData';
import Select from "react-select";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
        <DialogTitle sx={{ m: 0, p: 0.5 }} {...other} fontWeight="bold" style={{ fontFamily: "DM Sans" }}>
            <Typography variant='CHead' ml={2}>{children}</Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 2,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};



const Preview = (props) => {
    const { previewDiloge, setPreviewDiloge, clipData } = props;
    const dispatch = useDispatch();

    const handleClose = () => {
        setPreviewDiloge(false);
    };

    const [brandCategory, setBrandCategory] = useState(null);
    // const [barandValue ,setBrandValue] =useState('')

    useEffect(() => {
        if (clipData) {
            const matchingOption = brandCategoryOptions.find(option => option.label === clipData.brandCat);
            if (matchingOption) {
                setBrandCategory(matchingOption);
                // setBrandValue(matchingOption)
            }
        }
    }, [clipData]);

    const handleBrandCategoryChange = (selectedOption) => {
        setBrandCategory(selectedOption);
    };

    console.log(clipData)

    return (
        <div>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={previewDiloge}
                maxWidth="md"
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose} >
                VPO Preview Scenes {clipData && clipData.brandCat && `(${clipData.brandCat})`}
                </BootstrapDialogTitle>
                <DialogContent dividers >
                    <Grid container spacing={2} sx={{ minWidth: 800, maxWidth: 800 }} display='flex' flexDirection='column' ml={1}>
                        <Grid xs={12} mt={2}>
                            {/* <Select
                                options={brandCategoryOptions}
                                value={brandCategory}
                                onChange={handleBrandCategoryChange}
                                placeholder={'Select Brand Category'}
                                isClearable
                                isSearchable
                                isDisabled={true}
                            /> */}
                            {/* <Grid xs={11.10} display='flex' justifyContent='flex-start' alignItems='center' sx={{height:"38px",border:"1px solid green"}}>
                                <Typography variant='CHead'>{barandValue}</Typography>
                            </Grid> */}
                        </Grid>
                        <Grid xs={12} mt={2}>
                            <video controls style={{ borderRadius: "7px", width: "100%", height: "450px" }} autoPlay>
                                <source src={clipData && clipData.VppClipUrl} type="video/mp4" />
                            </video>
                        </Grid>
                    </Grid>

                </DialogContent>
            </BootstrapDialog>
        </div>
    );
}

export default Preview;
