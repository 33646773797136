import React, { useEffect, useState, useRef } from 'react';
import { awsApiCamp } from '../Routes/Server';
import { useDispatch } from "react-redux";
import { setShowLoader } from '../redux/loaderSlice';
import { Grid } from '@mui/material'
import TopBar from '../Components/DashboardComponetBrand/TopBar'
import PurchasedVpp from '../Components/DashboardComponetBrand/PurchasedVpp'
import CampaignsSectionBrand from '../Components/DashboardComponetBrand/CampaignsSectionBrand'
import LiveVpp from '../Components/DashboardComponetBrand/LiveVpp'
import ChartsBrand from '../Components/DashboardComponetBrand/ChartsBrand'
import TabsSection from '../Components/DashboardComponet/TabsSection'
import TopCamp from '../Components/DashboardComponet/TopCamp';
import TopProject from '../Components/DashboardComponet/TopProject';
import time from '../JSON/time.json'

const DashboardBrand = () => {
  const dispatch = useDispatch();
  const ComponentName = 'Dashboard'

  const [data, setData] = useState(time);
  const [filters, setFilters] = useState({
    filterByDate: '1month',
  });

  // useEffect(() => {
  //   async function fetchInProgress() {
  //     dispatch(setShowLoader(true));
  //     try {
  //       const res = await awsApiCamp.post("/getcampaign");
  //       if (res.data.statusCode === 200) {
  //         const data = JSON.parse(res.data.body);
  //         setData(data.items);
  //         console.log(data.items);
  //       }
  //     } catch (error) {
  //       console.error('Error fetching data:', error);
  //     } finally {
  //       dispatch(setShowLoader(false));
  //     }
  //   }
  //   fetchInProgress();
  // }, []);

  const currencySymbol = data.currencyCode === 'USD' ? '$' : '';

  return (
    <Grid container xs={12} mt={7}>
      <TopBar ComponentName={ComponentName} setFilters={setFilters} filters={filters} />
      <Grid container xs={12} variant='G1' mt={1} >
        <TabsSection pageType={'brand'} data={data} currencySymbol={currencySymbol} />
        <ChartsBrand data={data} currencySymbol={currencySymbol} pageType={'brand'}/>
        <Grid xs={11.77} ml={2} display='flex' justifyContent='space-between' alignItems='center' spacing={2} mt={2.5} mb={3}>
          <Grid xs={5.9} variant='G3' display='flex'>
            <TopCamp pageType={'brand'} currencySymbol={currencySymbol} data={data} />
          </Grid>
          <Grid xs={5.9} variant='G3' display='flex' >
            <TopProject pageType={'brand'} currencySymbol={currencySymbol} data={data} />
          </Grid>
        </Grid>
      </Grid>

    </Grid>
  )
}

export default DashboardBrand
