import React, { useState, useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import TopBar from '../Components/VppComponet/TopBar';
import Container from '../Components/MraketPlace/Container';
import { Img } from '../ThemeProvider/style';
import { useNavigate } from 'react-router-dom';
import { addProjectId } from '../redux/projectSlice';
import { useDispatch } from "react-redux";
import { setShowLoader } from '../redux/loaderSlice';
import Loader from '../Components/CommonComponet/Loader';
import defaultThumbnail from '../assets/NoVideo.png'
import { awsApi } from '../Routes/Server';

const MarketPlace = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const ComponentName = 'MarketPlace';
  // const [projectData, setProjectData] = useState([]);
  // const [tableData, setTableData] = useState([]);
  const [filteredProjectData, setFilteredProjectData] = useState([]);
  const [totalVpoTotal, setTotalVpoTotal] = useState(0);
  const [filteredDataCount, setFilteredDataCount] = useState(0);
  const [searchText, setSearchText] = useState('')

  // Centralized filters in a single object
  const [filters, setFilters] = useState({
    country: '',
    projectType: [],
    genre: [],
    brandCategory: [],
    season: '',
    episode: '',
    searchText: ''
  });


  useEffect(() => {
    async function fetchInProgress() {
      dispatch(setShowLoader(true));
      try {
        const res = await awsApi.post(
          '/postvideo',
          { action: 'get-vpp-list-market', ...filters }
          // { action: 'get-vpp-list', ...filters }
        );
        if (res.data.statusCode === 200) {
          const dataItems = res.data.body;

          const filteredData = dataItems.filter((item) => item.type === 'Project');
          setFilteredProjectData(filteredData);

          const totalVpoSum = filteredData.reduce((sum, item) => sum + parseInt(item.totalVpo, 10), 0);
          setTotalVpoTotal(totalVpoSum);
          setFilteredDataCount(filteredData.length);
          const transformedData = transformData(filteredData);
          // console.log("transformedData", transformedData);
          // setTableData(transformedData);
        } else {
          console.error('Error fetching data:', res.data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        dispatch(setShowLoader(false));
      }
    }

    fetchInProgress();
  }, [filters, dispatch]);


  const transformData = (data) => {

    const transformedData = data.map((item) => {
      return {
        id: item.id,
        name: item.title,
      };
    });

    return transformedData;
  };

  // console.log(filteredProjectData)

  const handelNavigate = async (projectId) => {
    dispatch(setShowLoader(true));
    try {
      navigate('/VppMainBrand');
      dispatch(addProjectId(projectId));
    } catch (error) {
      console.error('Error navigating:', error);
    } finally {
      dispatch(setShowLoader(false));
    }
  };

  const handelNavigateTabel = async (row) => {
    const projectId = row.projectId
    console.log(projectId, "rowId")
    dispatch(setShowLoader(true));
    try {
      navigate('/VppMainBrand');
      dispatch(addProjectId(projectId));
    } catch (error) {
      console.error('Error navigating:', error);
    } finally {
      dispatch(setShowLoader(false));
    }
  };

  if (filteredProjectData) {
    filteredProjectData.forEach((row, index) => {
      row._index = index + 1;
    });
  }



  const columns = [
    {
      field: 'index',
      headerName: 'S.No',
      width: 60,
      valueGetter: (params) => params.row._index,
    },
    {
      field: 'imgURL',
      headerName: 'Thumbnail & Title',
      width: 380,
      renderCell: (params) => (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Img src={params.row.imgURL || defaultThumbnail} alt="Project Thumbnail" onClick={() => { handelNavigate(params.row.id) }} sx={{ cursor: "pointer" }} />
          <Box sx={{ fontWeight: 'Bold', color: 'Black', cursor: "pointer" }} onClick={() => { handelNavigate(params.row.id) }}>{params.row.title}</Box>
        </Box>
      ),
    },

    { field: 'contentType', headerName: 'Type', width: 200 },
    {
      field: 'genres',
      headerName: 'Genre',
      width: 250,
      valueGetter: (params) => params.row.genres ? params.row.genres.join(', ') : '',
    },
    { field: 'releaseType', headerName: 'Release Type', width: 200 },
    { field: 'totalVpo', headerName: 'No of Vpo', width: 180 },
  ];

  return (
    <Grid container xs={12} mt={7}>
      <TopBar ComponentName={ComponentName} />
      <Grid container xs={12} variant='G1' mt={3}>
        <Container
          totalVpoTotal={totalVpoTotal}
          filteredDataCount={filteredDataCount}
          tabledata={filteredProjectData}
          data={filteredProjectData}
          columns={columns}
          handelNavigateTabel={handelNavigateTabel}
          setFilters={setFilters}
          filters={filters}
          setSearchText={setSearchText}
          searchText={searchText}
        />
      </Grid>
      <Loader />
    </Grid>
  );
};

export default MarketPlace;
