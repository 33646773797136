import CloseIcon from '@mui/icons-material/Close';
import { Card, CardMedia, Grid } from "@mui/material";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { addProjectId } from '../../redux/projectSlice';
import { useDispatch } from "react-redux";
import PlayerTabBrand from './PlayerTabBrand';
import defaultThumbnail from '../../assets/NoVideo.png'
import Chip from '@mui/material/Chip';
import { generateThumbnailURL } from '../../Routes/apiConfig';
import { awsApiCamp, awsApi } from '../../Routes/Server';
import { setShowLoader } from '../../redux/loaderSlice';
import { useSelector } from "react-redux";
import Select from "react-select";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
        <DialogTitle sx={{ m: 0, p: 0.5 }} {...other} fontWeight="bold" style={{ fontFamily: "DM Sans" }}>
            <Typography variant='CHead' ml={2}>{children}</Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 2,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};



const VppPlayerBrand = (props) => {
    const { openPlayer, setOpenPlayer, selectedProject, handleApproveClick, handleRejectDialog, type, handleComment, handleUpdateMessages, pageType, actionValue, disableState } = props;

    const dispatch = useDispatch();
    const userData = useSelector((state) => state.user.userLoginData);

    const [brandDetails, SetBrandDetails] = useState([])
    const [selectedBrand, setSelectedBrand] = useState(null);

    const fetchImages = async () => {
        try {
            dispatch(setShowLoader(true));
            const payload = {
                assetAction: 'get-asset',
                brandId: userData.sub,
            };
            const response = await awsApi.post('/postproject', payload, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            const responseBody = JSON.parse(response.data.body);
            if (Array.isArray(responseBody)) {
                SetBrandDetails(responseBody);
            } else {
                console.error('Invalid response format. Expected an array.');
            }
            console.log('Raw Response:', responseBody);
            dispatch(setShowLoader(false));

        } catch (error) {
            console.error('Error fetching images:', error);
            dispatch(setShowLoader(false));
        }
    };

    useEffect(() => {
        fetchImages();
    }, []);

    const customStyles = {
        control: (provided) => ({
            ...provided,
            width: 200,

        }),
    };

    const getStatusLabelAndColor = () => {
        const brandStatus = selectedProject?.brandStatus;
        const creatorStatus = selectedProject?.creatorStatus;
        const status = selectedProject?.status;

        let label = status.charAt(0).toUpperCase() + status.slice(1);
        let color = "secondary";

        if (brandStatus && creatorStatus) {
            label = 'STARTING';
            color = "success";
        } else if (!brandStatus && creatorStatus) {
            label = "Brand Approval Pending";
            color = "warning";
        } else if (brandStatus && !creatorStatus) {
            label = "Producer Approval Pending";
            color = "warning";
        }

        return { label, color };
    };

    const handleClose = () => {
        setOpenPlayer(false);
    };

    if (!selectedProject) {
        return null;
    }

    const handleBrandChange = (selectedOption) => {
        console.log(selectedOption)
        setSelectedBrand(selectedOption);
    };

    // console.log('selectedProject', selectedProject)
    const brandStatus = selectedProject?.brandStatus;
    const creatorStatus = selectedProject?.creatorStatus;

    console.log(brandStatus)
    console.log(creatorStatus)
    const url = selectedProject?.videoId;
    const thumbnailURL = generateThumbnailURL(url) || defaultThumbnail;
    const { label, color } = getStatusLabelAndColor();

    const calculateTimeDifference = (startTime, endTime) => {
        const parseTime = (time) => {
            const [hours, minutes, seconds] = time.split(':').map(Number);
            return hours * 3600 + minutes * 60 + seconds;
        };
        const startSeconds = parseTime(startTime);
        const endSeconds = parseTime(endTime);
        return endSeconds - startSeconds;
    };

    const startTime = selectedProject?.clipSet[0].startTime;
    const endTime = selectedProject?.clipSet[0].endTime;

    const differenceInSeconds = calculateTimeDifference(startTime, endTime);

    const handelImgSubmit = async () => {
        dispatch(setShowLoader(true));
        if (selectedBrand !== null) {
            try {
                let updatedData = {
                    ...selectedProject,
                    brandLogo: selectedBrand
                };

                const res = await awsApiCamp.post('/postcampaign', {
                    ...updatedData,
                });

                if (res.data.statusCode === 200) {
                    handleUpdateMessages();
                } else {
                    console.log('error')
                }
            } catch (error) {
                console.error('Error rejecting data:', error);
            } finally {
                dispatch(setShowLoader(false));
            }
        }
    };


    const CustomOption = ({ innerProps, label, data }) => (
        <div {...innerProps} style={{ cursor: "pointer", border: '1px solid black', marginTop: '5px', }}>
            <img
                src={data.value}
                alt={label}
                style={{ marginRight: '8px', width: '35px', height: '35px' }}
            />
            {label}
        </div>
    );

    const options = brandDetails.map((brand) => ({
        value: brand.Location,
        label: brand.assetName,
        Key: brand.Key,
    }));

    const defaultOption = options.find(
        (option) => option.value === selectedProject?.brandLogo?.value
    );

    console.log(defaultOption)

    return (
        <div>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={openPlayer}
                maxWidth="lg"
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose} >
                    {selectedProject?.clipSet[0].projectTitle}
                </BootstrapDialogTitle>
                <DialogContent dividers >
                    <Grid container spacing={2} sx={{ minWidth: 1000, maxWidth: 1000 }}>
                        <Grid variant="G12" item xs={4} ml={-2}>
                            <Grid item xs={12} sx={{ borderBottom: "1px solid #dbdfea" }}>
                                <Card sx={{ minWidth: 282, margin: "15px", marginTop: "0px" }}>
                                    <CardMedia
                                        component="img"
                                        height="160"
                                        image={thumbnailURL}
                                        alt="thumb"
                                    />
                                </Card>
                            </Grid>
                            <Grid xs={12} >
                                <Grid item xs={12} sx={{ margin: "15px", marginTop: "0px" }}>
                                    <Grid mt={1}>
                                        <Typography variant='CHead'>Project Title</Typography> - &nbsp;
                                        <Typography variant='GV'>{selectedProject?.clipSet[0].projectTitle}</Typography>
                                    </Grid>
                                    <Grid mt={1}>
                                        <Typography variant='CHead'>Status</Typography> - &nbsp;
                                        <Typography variant='GV'><Chip
                                            label={label}
                                            color={color}
                                            variant="outlined"
                                            size="small"
                                            sx={{ width: '180px', height: '25px' }}
                                        /></Typography>
                                    </Grid>
                                    <Grid container direction='row'>
                                        <Grid item xs={6}>
                                            <Grid variant='GH'>
                                                <Typography variant='CHead'>Start Time</Typography>
                                            </Grid>
                                            <Grid variant='GV'>
                                                {selectedProject?.clipSet[0].startTime}
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={6}>

                                            <Grid variant='GH'>
                                                <Typography variant='CHead'> End Time</Typography>
                                            </Grid>
                                            <Grid variant='GV'>
                                                {selectedProject?.clipSet[0].endTime}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid mt={1}>
                                        <Typography variant='CHead'>Catagory</Typography> - &nbsp;
                                        <Typography variant='GV'>{selectedProject?.clipSet[0].category}</Typography>
                                    </Grid>
                                    <Grid mt={1}>
                                        <Typography variant='CHead'>Stage</Typography> - &nbsp;
                                        <Typography variant='GV'></Typography>
                                    </Grid>
                                    <Grid mt={1}>
                                        <Typography variant='CHead'>Embedded Ad Inventory Seconds</Typography> - &nbsp;
                                        <Typography variant='GV'>{differenceInSeconds}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={8} >
                            <Grid item xs={12} >
                                <PlayerTabBrand
                                    VppClipUrl={selectedProject?.clipSet[0].VppClipUrl}
                                    startTime={selectedProject?.clipSet[0].startTime}
                                    endTime={selectedProject?.clipSet[0].endTime}
                                    preApprovedUrl={selectedProject.clipSet && selectedProject.clipSet[0] && selectedProject.clipSet[0]?.unbranded?.Location}
                                    brandedUrl={selectedProject.clipSet && selectedProject.clipSet[0] && selectedProject.clipSet[0]?.branded?.Location}
                                    selectedProject={selectedProject}
                                    handleComment={handleComment}
                                    handleUpdateMessages={handleUpdateMessages}
                                    handleRejectDialog={handleRejectDialog}
                                    handleApproveClick={handleApproveClick}
                                    type={type}
                                    pageType={pageType}
                                    actionValue={actionValue}
                                />

                            </Grid>
                            {pageType === 'draft' && (
                                <Grid variant="G13" xs={12}
                                    item
                                    container
                                    sx={{ height: "40px" }} justifyContent='space-between'>
                                    <Grid>
                                        <Grid display='flex' justifyContent='flex-start' alignItems='center'>
                                            <Select
                                                options={options}
                                                onChange={handleBrandChange}
                                                components={{ Option: CustomOption }}
                                                styles={customStyles}
                                                menuPlacement="top"
                                                // value={selectedBrand?.brandLogo?.value || defaultOption}
                                                value={selectedBrand ? selectedBrand?.brandLogo?.value : defaultOption}
                                            />
                                            <Button variant="B2S" sx={{ height: "40px", marginRight: '5px' }} onClick={handelImgSubmit}
                                                disabled={selectedBrand === null}>Submit</Button>
                                        </Grid>
                                    </Grid>
                                    <Grid>
                                        <>
                                            <Button variant="B2S" sx={{ marginRight: '1px' }} onClick={() => handleApproveClick(selectedProject)} disabled={
                                                disableState === 'draft' &&
                                                (!selectedProject.clipSet || !selectedProject.clipSet[0] || !selectedProject.clipSet[0].branded)
                                            }>
                                                Approve Campaign
                                            </Button>
                                            <Button variant="B2S" sx={{ marginRight: '1px' }} onClick={() => handleRejectDialog(selectedProject)} disabled={
                                                disableState === 'draft' &&
                                                (!selectedProject.clipSet || !selectedProject.clipSet[0] || !selectedProject.clipSet[0].branded)
                                            }>
                                                Reject Campaign
                                            </Button>
                                        </>
                                    </Grid>
                                    {/* <Button variant="B2S" onClick={() => { handleComment(selectedProject) }}>Comment</Button> */}
                                </Grid>
                            )}
                        </Grid>
                    </Grid>

                </DialogContent>
            </BootstrapDialog>
        </div>
    );
}

export default VppPlayerBrand;
