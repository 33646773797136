import React, { useState, useRef, useEffect } from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Box, Grid } from "@mui/material";
import CustomTextfield from "../CommonComponet/CustomTextfield"
import { RiVideoUploadFill } from 'react-icons/ri';
import { AiOutlinePlus } from 'react-icons/ai';
import { AiOutlineMinus } from 'react-icons/ai';
import LinearProgress from '@mui/material/LinearProgress';
import DialogContent from '@mui/material/DialogContent';
import Stack from '@mui/material/Stack';
import { ImageContainer, Input, Action, Label, UploadButton, } from '../../ThemeProvider/style'
import { useDispatch } from "react-redux";
import { setShowLoader } from '../../redux/loaderSlice';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { v4 as uuidv4 } from 'uuid';
import Loader from '../CommonComponet/Loader'
import { optimusApi, awsApi } from '../../Routes/Server';
import Select from "react-select";
import { secData, hourData, mintData } from '../CommonComponet/TimePick';
import Switch from "react-switch";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { brandCategoryOptions } from '../CommonComponet/docsData';

// const BootstrapDialog = styled(Dialog)(({ theme }) => ({
//     '& .MuiDialogContent-root': {
//         padding: theme.spacing(2),
//     },
//     '& .MuiDialogActions-root': {
//         padding: theme.spacing(1),
//     },
// }));

// function BootstrapDialogTitle(props) {
//     const { children, onClose, ...other } = props;

//     return (
//         <DialogTitle sx={{ m: 0, p: 1 }} {...other} fontWeight="bold" style={{ fontFamily: "DM Sans" }} >
//             {children}
//             {onClose ? (
//                 <IconButton
//                     aria-label="close"
//                     onClick={onClose}
//                     sx={{
//                         position: 'absolute',
//                         right: 8,
//                         top: 8,
//                         color: (theme) => theme.palette.grey[500],
//                     }}
//                 >
//                     <CloseIcon />
//                 </IconButton>
//             ) : null}
//         </DialogTitle>
//     );
// }





// export default function MultiVideoUpload(props) {
//     const { onClose, setVideoDialog, projectId, contentType, selectedVppType, projectTitle, fetchDataForProjectUpload, fetchDraftProjects, confirmModal, setConfirmModal, backData, setFormData, rowData } = props;

//     // console.log(projectId, "projectId");

//     let initialValue;

//     if (contentType === "tv-show" || contentType === "web-series") {
//         initialValue = [
//             { episodeNo: '', episodeTitle: '', file: null },
//         ];
//     } else {
//         initialValue = [
//             { file: null, videoTitle: '' },
//         ];
//     }

//     const [isDefault, setIsDefault] = useState(true);

//     const dispatch = useDispatch()


//     const [analyseModal, setAnalyseModal] = useState(false);
//     const Link = "https://global.discourse-cdn.com/business4/uploads/prezi/original/2X/a/aaaff5d5dec03e32799015d424b4a5db51c9eeff.png"

//     const [inputFields, setInputFields] = useState(initialValue);
//     const [activeRow, setActiveRow] = useState(null);
//     const [videoTitle, setVideoTitle] = useState('');
//     const [uploadProgress, setUploadProgress] = useState(inputFields.map(() => 0));
//     const [uploadSuccess, setUploadSuccess] = useState(Array(inputFields.length).fill(false));
//     const [analyzeClicked, setAnalyzeClicked] = useState(false);


//     // console.log(uploadProgress, 'input');


//     const handleAddFields = () => {
//         setInputFields([...inputFields, { episodeNo: '', episodeTitle: '', file: null, videoTitle: '' }]);
//         setUploadProgress([...uploadProgress, 0]);
//     };

//     const handleRemoveFields = (index) => {
//         const updatedFields = [...inputFields];
//         updatedFields.splice(index, 1);
//         setInputFields(updatedFields);
//         const progress = [...uploadProgress];
//         progress.splice(index, 1);
//         setUploadProgress(progress);

//     };

//     const handleChangeInput = (index, event) => {
//         const { name, value } = event.target;
//         const updatedFields = [...inputFields];
//         updatedFields[index] = { ...updatedFields[index], [name]: value };
//         setInputFields(updatedFields);
//     };

//     const handleSelectChange = (index, selectedOption) => {
//         const values = [...inputFields];
//         values[index].episodeNo = selectedOption.value;
//         setInputFields(values);
//     };

//     const handleFileChange = (event) => {
//         const file = event.target.files && event.target.files[0];

//         if (file) {
//             setUploadProgress(0);
//             setVideoTitle('');
//         } else {
//             event.target.value = null;
//         }
//     };

//     const fileInputRef = useRef(null);

//     const [selectedVideo, setSelectedVideo] = useState(null);
//     const [selectedRow, setSelectedRow] = useState(null);


//     const handleFileSelect = async (event, index) => {
//         const file = event.target.files[0];
//         setSelectedVideo(file);
//         setSelectedRow(index);
//         await uploadVideoToBackend(file, index);
//         startUpload(file, index);
//     };

//     const startUpload = (file, index) => {
//         const interval = setInterval(() => {
//             setUploadProgress((prevProgress) => {
//                 const updatedProgress = [...prevProgress];
//                 updatedProgress[index] = Math.min(updatedProgress[index] + 10, 100);
//                 if (updatedProgress[index] >= 100) {
//                     clearInterval(interval);
//                 }
//                 return [...updatedProgress];
//             });
//         }, 500);
//     };

//     // console.log("This is contentType which has came from stepOne =>", contentType, "and this is title=>", projectTitle)

//     const [snackbarOpen, setSnackbarOpen] = useState(false);
//     const [snackbarMessage, setSnackbarMessage] = useState('');

//     const handleShowSnackbar = () => {
//         const snackbarContent = `${contentType} - ${projectTitle}`;
//         setSnackbarMessage(snackbarContent);
//         setSnackbarOpen(true);
//     };

//     const [buttonView, setButtonView] = useState(false)

//     // console.log("input fields line 170 ", inputFields)



//     const uploadVideoToBackend = async (file, fileIndex) => {
//         // console.log('file', file)
//         const formData = new FormData();
//         formData.append('file', file);
//         let localInputFields = inputFields;
//         const localFileIndex = uuidv4();
//         console.log('fileIndex', fileIndex);
//         console.log('file', file);
//         formData.append('videoId', localFileIndex);
//         formData.append('projectId', projectId);
//         dispatch(setShowLoader(true));
//         // console.log('formData', formData)
//         if (file && file.name) {
//             const fileName = file.name;
//             localInputFields[fileIndex].videoTitle = fileName;
//             localInputFields[fileIndex].episodeTitle = fileName;
//             localInputFields[fileIndex].episodeNo = fileIndex + 1;

//             try {
//                 const response = await optimusApi.post('/uploadv1', formData, {
//                     headers: {
//                         'Content-Type': 'multipart/form-data',
//                     },
//                 });
//                 if (inputFields && inputFields[fileIndex]) {
//                     localInputFields[fileIndex].VideoUrl = response.data.Location;
//                     localInputFields[fileIndex].s3Meta = response.data;
//                     localInputFields[fileIndex].videoId = localFileIndex

//                     const fileName = file.name;
//                     localInputFields[fileIndex].episodeTitle = fileName;
//                 }
//                 const updatedUploadSuccess = [...uploadSuccess];
//                 updatedUploadSuccess[fileIndex] = true;
//                 setUploadSuccess(updatedUploadSuccess);
//                 setInputFields(localInputFields);
//                 // console.log('Upload successful', response.data);
//                 setButtonView(true)
//             } catch (error) {

//                 console.error('Upload error', error);
//             } finally {
//                 dispatch(setShowLoader(false));
//             }
//         }

//     }

//     const handleAnalize = async () => {
//         dispatch(setShowLoader(true));
//         let fields = inputFields;
//         let fieldsWithS3Meta = inputFields.filter(field => field.s3Meta);

//         if (fieldsWithS3Meta.length === 0) {
//             // console.log("No fields with s3Meta to send to the backend.");
//             dispatch(setShowLoader(false));
//             return;
//         }
//         try {
//             const res = await awsApi.post('/postvideo', { projectId: projectId, videos: fieldsWithS3Meta });
//             // console.log(res.data, "post Video");
//             if (res.data.statusCode === 200) {
//                 console.log(res.data.body);
//                 setVideoDialog(true);
//                 setAnalyseModal(false);
//                 onClose();
//                 fetchDataForProjectUpload();
//                 fetchDraftProjects();
//                 handleShowSnackbar();

//             }
//         } catch (err) {
//             console.log(err, "error");
//         } finally {
//             dispatch(setShowLoader(false));
//         }
//     };

//     // const handleRemoveVideo = (index) => {
//     //     if (index === 0) {
//     //         const updatedUploadProgress = [...uploadProgress];
//     //         updatedUploadProgress[0] = 0;
//     //         setUploadProgress(updatedUploadProgress);
//     //         setVideoTitle('');
//     //         const updatedInputFields = [...inputFields];
//     //         updatedInputFields[0] = { episodeNo: '', episodeTitle: '', file: null, videoTitle: '' };
//     //         setInputFields(updatedInputFields);
//     //     }
//     // };

//     console.log("rowData" ,rowData)
//     console.log("backData" ,backData)


//     // const handleBackButtonClick = () => {
//     //     // Update the form data with values from backData
//     //     setFormData({
//     //         id: rowData?.id || backData?.id || uuidv4(),
//     //         title: rowData?.title || backData?.title || '',
//     //         country: rowData?.country || backData?.country || '',
//     //         vppType: rowData?.vppType || backData?.vppType || '',
//     //         contentType: rowData?.contentType || backData?.contentType || '',
//     //         releasePlatform: rowData?.releasePlatform || backData?.releasePlatform || [],
//     //         releaseType: rowData?.releaseType || backData?.releaseType || '',
//     //         genres: rowData?.genres || backData?.genres || [],
//     //         averageViewPerMonth: rowData?.averageViewPerMonth || backData?.averageViewPerMonth || '',
//     //         season: rowData?.season || backData?.season || '',
//     //     });

//     //     setVideoDialog(true);
//     // };
// ;
//     const handleBackButtonClick = () => {
//         setFormData({
//             id: backData?.id ,
//             title: backData?.title ,
//             country:  backData?.country ,
//             vppType:  backData?.vppType ,
//             contentType: backData?.contentType ,
//             releasePlatform:  backData?.releasePlatform ,
//             releaseType:  backData?.releaseType ,
//             genres:  backData?.genres ,
//             averageViewPerMonth:  backData?.averageViewPerMonth ,
//             season:  backData?.season ,
//         });
//         setVideoDialog(true);
//     };


//     const handleRemoveVideo = (index) => {

//         const updatedUploadProgress = [...uploadProgress];
//         updatedUploadProgress[index] = 0;
//         const updatedUploadSuccess = [...uploadSuccess];
//         updatedUploadSuccess[index] = false;
//         const updatedInputFields = [...inputFields];
//         updatedInputFields[index] = { episodeNo: '', episodeTitle: '', file: null, videoTitle: '' };
//         const fileInput = fileInputRef.current;
//         if (fileInput) {
//             fileInput.value = '';
//         }
//         setUploadProgress(updatedUploadProgress);
//         setUploadSuccess(updatedUploadSuccess);
//         setInputFields(updatedInputFields);
//         setVideoTitle('');
//     };

//     console.log(uploadSuccess)



//     const handleAnalyzeClick = () => {
//         if (contentType === "tv-show" || contentType === "web-series") {
//             for (const inputField of inputFields) {
//                 if (inputField.episodeNo === '' || inputField.episodeNo === undefined) {
//                     setAnalyzeClicked(true);
//                     return;
//                 }
//             }
//             setAnalyseModal(true);
//             setIsDefault(false);
//             setAnalyzeClicked(false);
//         } else {
//             setAnalyseModal(true);
//             setIsDefault(false);
//         }
//     }


//     console.log('inputFields', inputFields)

//     return (
//         <>
//             {analyseModal &&

//                 <DialogContent sx={{ width: '100%' }} >
//                     <Typography variant="P22">Are you sure you want to submit for Ai Analysis?</Typography>
//                     <Grid item container display='flex' alignContent='center' justifyContent='center' mt={4} mb={2}>
//                         <Button variant="B1" onClick={() => { handleAnalize() }} sx={{ marginRight: "7px" }}>Submit</Button>
//                         <Button variant="B1" onClick={() => { setAnalyseModal(false); setIsDefault(true); }} sx={{ marginLeft: "7px" }}>Cancel</Button>
//                     </Grid>
//                 </DialogContent>

//             }

//             {confirmModal &&

//                 <DialogContent sx={{ width: '100%' }} >
//                     <Typography variant="P22">Are you sure you want to close?</Typography>
//                     <Grid item container display='flex' alignContent='center' justifyContent='center' mt={4} mb={2}>
//                         <Button variant="B1" onClick={() => { setConfirmModal(false); setVideoDialog(true); onClose() }} sx={{ marginRight: "7px" }}>Yes</Button>
//                         <Button variant="B1" onClick={() => { setIsDefault(true); setConfirmModal(false) }} sx={{ marginLeft: "7px" }}>No</Button>
//                     </Grid>
//                 </DialogContent>

//             }
//             {isDefault && (
//                 <div>
//                     <Grid
//                         item
//                         xs={12}
//                         container
//                         spacing={1}

//                     >
//                         <Grid item xs={12} spacing={1} mr={2} container>
//                             {uploadProgress[0] === 100 &&
//                                 <><Grid item xs={12} mb={0.6}>
//                                     <Typography variant="P22">
//                                         {"Video Thumbnail"}
//                                     </Typography>
//                                 </Grid>
//                                     <Stack direction="row" spacing={2} alignItems="center">
//                                         {/* {uploadProgress.map((upload, index) => {
//                                             return (upload === 100 &&
//                                                 <ImageContainer>
//                                                     <img src={Link} alt="Your Image" width="70px" height="70px" style={{ borderRadius: '10px', marginLeft: "10px" }} />
//                                                 </ImageContainer>

//                                             )
//                                         })} */}
//                                         {/* {uploadProgress.map((upload, index) => {
//                                             if (upload === 100) {
//                                                 return (
//                                                     {
//                                                         inputFields.map((field, index) => (
//                                                             <Grid display='flex' flexDirection='column' alignItems='center'>
//                                                                 <Grid>
//                                                                     <ImageContainer>
//                                                                         <img src={Link} alt="Your Image" width="70px" height="70px" style={{ borderRadius: '10px', marginLeft: "10px" }} />
//                                                                         {index === 0 && (
//                                                                     <IconButton
//                                                                         style={{
//                                                                             position: 'absolute', top: '-3px', right: '-3px', background: 'red',
//                                                                             height: "15px", width: '15px', border: "1px solid black"
//                                                                         }}
//                                                                         onClick={() => handleRemoveVideo(index)}
//                                                                     >
//                                                                         <CloseIcon style={{ color: 'white' }} />
//                                                                     </IconButton>
//                                                                 )}
//                                                                     </ImageContainer>
//                                                                 </Grid>

//                                                                 <Typography key={index} ml={1.4} mt={0.5} variant='C11' color={'#344357'}>
//                                                                     {field.episodeNo}
//                                                                 </Typography>

//                                                                 <Typography ml={1.4} mt={0.5} variant='C11' color={'#344357'}>{inputFields[0].episodeNo}</Typography>
//                                                             </Grid>

//                                                         ))
//                                                     }

//                                                 );
//                                             }
//                                         })} */}
//                                         {uploadProgress.map((upload, index) => {
//                                             if (upload === 100) {
//                                                 const correspondingFields = inputFields.filter((field, fieldIndex) => fieldIndex === index);

//                                                 return correspondingFields.map((field, fieldIndex) => (
//                                                     <Grid key={fieldIndex} display='flex' flexDirection='column' alignItems='center'>
//                                                         <Grid>
//                                                             <ImageContainer>
//                                                                 <img src={Link} alt="Your Image" width="70px" height="70px" style={{ borderRadius: '10px', marginLeft: "10px" }} />
//                                                             </ImageContainer>
//                                                         </Grid>

//                                                         <Typography ml={1.4} mt={0.5} variant='C11' color={'#344357'}>
//                                                             {field.episodeNo}
//                                                         </Typography>
//                                                     </Grid>
//                                                 ));
//                                             }
//                                         })}

//                                     </Stack>
//                                 </>}
//                         </Grid>
//                     </Grid>
//                     <Grid item xs={12} container spacing={1} mt={2}>
//                         {contentType === "tv-show" || contentType === "web-series" ? (
//                             <>
//                                 <Grid item xs={1.4} container spacing={2} style={{ height: '55px' }}>
//                                     <Grid item xs={12}>
//                                         <Typography variant="P22">
//                                             {"*Episode No."}
//                                         </Typography>
//                                     </Grid>
//                                 </Grid>
//                                 <Grid item xs={6} container spacing={2} ml={0.5}>
//                                     <Grid item xs={12}>
//                                         <Typography variant="P22">
//                                             {"Episode Title"}
//                                         </Typography>
//                                     </Grid>
//                                 </Grid>
//                             </>
//                         ) : (
//                             <Grid item xs={8.8} container spacing={2}>
//                                 <Grid item xs={12}>
//                                     <Typography variant="P22">
//                                         {"Video Title"}
//                                     </Typography>
//                                 </Grid>
//                             </Grid>
//                         )}
//                         <Grid item xs={3} container spacing={2}>
//                             <Grid item xs={12}>
//                                 <Typography variant="P22">
//                                     {"Upload Video"}
//                                 </Typography>
//                             </Grid>
//                         </Grid>
//                     </Grid>
//                     {inputFields.map((inputField, index) => {

//                         let color = "#bfbfbf";
//                         if (uploadSuccess[index]) {
//                             color = "#4caf50";
//                         }
//                         return (
//                             <React.Fragment key={index}>
//                                 <Grid item xs={12} container spacing={1} mt={1} >

//                                     {contentType === "tv-show" || contentType === "web-series" ? (
//                                         <>
//                                             <Grid item xs={1.4} container spacing={2} style={{ height: '55px' }}>
//                                                 {/* <Grid item xs={12}>
//                                                     <Typography variant="P22">
//                                                         {"*Episode No."}
//                                                     </Typography>
//                                                 </Grid> */}
//                                                 <Grid item xs={12}>
//                                                     <CustomTextfield
//                                                         size="small"
//                                                         placeholder="Enter number"
//                                                         fullWidth
//                                                         variant="outlined"
//                                                         name="episodeNo"
//                                                         value={inputField.episodeNo}
//                                                         onChange={(event) => handleChangeInput(index, event)}
//                                                         required
//                                                     />
//                                                     {/* <Select
//                                                         options={episodeNoOptions}
//                                                         value={episodeNoOptions.find((option) => option.value === inputField.episodeNo)}
//                                                         onChange={(selectedOption) => handleSelectChange(index, selectedOption)}
//                                                     /> */}
//                                                     {analyzeClicked && !inputField.episodeTitle && <Typography variant='P23' className="error-message" >Episode No is required.</Typography>}

//                                                 </Grid>
//                                             </Grid>
//                                             <Grid item xs={6} container spacing={2} ml={0.5}>
//                                                 {/* <Grid item xs={12}>
//                                                     <Typography variant="P22">
//                                                         {"Episode Title"}
//                                                     </Typography>
//                                                 </Grid> */}
//                                                 <Grid item xs={12}>
//                                                     <CustomTextfield
//                                                         size="small"
//                                                         placeholder="Enter title"
//                                                         fullWidth
//                                                         variant="outlined"
//                                                         name="episodeTitle"
//                                                         value={inputField.episodeTitle}
//                                                         onChange={(event) => handleChangeInput(index, event)}
//                                                     />
//                                                 </Grid>
//                                             </Grid>
//                                         </>
//                                     ) : (
//                                         <Grid item xs={8.8} container spacing={2}>
//                                             {/* <Grid item xs={12}>
//                                                 <Typography variant="P22">
//                                                     {"Video Title"}
//                                                 </Typography>
//                                             </Grid> */}
//                                             <Grid item xs={12}>
//                                                 <CustomTextfield
//                                                     size="small"
//                                                     placeholder="Enter number"
//                                                     fullWidth
//                                                     variant="outlined"
//                                                     name="videoTitle"
//                                                     value={inputField.videoTitle}
//                                                     onChange={(event) => handleChangeInput(index, event)}
//                                                 />

//                                             </Grid>
//                                         </Grid>

//                                     )}
//                                     <Grid item xs={3} container spacing={2}>
//                                         {/* <Grid item xs={12}>
//                                             <Typography variant="P22">
//                                                 {"Upload Video"}
//                                             </Typography>
//                                         </Grid> */}
//                                         <Grid item xs={12}>
//                                             <div  >
//                                                 <>
//                                                     <Input
//                                                         accept="video/*"
//                                                         id={`video-upload-${index}`}
//                                                         multiple={false}
//                                                         type="file"
//                                                         onChange={(event) => handleFileSelect(event, index)}
//                                                         ref={fileInputRef}
//                                                         style={{ display: 'none' }}
//                                                         disabled={uploadSuccess[index]}
//                                                     />

//                                                     <Label htmlFor={`video-upload-${index}`} style={{ margin: '0px', backgroundColor: color }}>
//                                                         <UploadButton
//                                                             as="span"
//                                                             startIcon={<RiVideoUploadFill style={{ fontSize: '23px', marginTop: '5px' }} />}
//                                                             disabled={uploadSuccess[index]}
//                                                         >
//                                                             {uploadSuccess[index] ? 'Uploaded' : 'Upload'}
//                                                         </UploadButton>

//                                                     </Label>
//                                                 </>
//                                             </div>
//                                         </Grid>
//                                     </Grid>
//                                     {contentType === "tv-show" || contentType === "web-series" ? (
//                                         <Grid item xs={1} container mr={1}>
//                                             <Grid item xs={12} >
//                                                 {index === (inputFields.length - 1) ? (
//                                                     <div style={{ display: "flex", width: "110px" }}>
//                                                         <Action style={{ background: "Black" }} disabled={uploadProgress[uploadProgress.length - 1] === 0} onClick={handleAddFields}>
//                                                             <AiOutlinePlus />
//                                                         </Action>
//                                                         <Action style={{ background: "#E85347" }} onClick={() => { handleRemoveVideo(index); if (index > 0) handleRemoveFields(index); }}>
//                                                             <AiOutlineMinus />
//                                                         </Action>
//                                                     </div>
//                                                 ) : (
//                                                     <Action style={{ background: "#E85347" }} onClick={() => { handleRemoveVideo(index) }}>
//                                                         <AiOutlineMinus />
//                                                     </Action>
//                                                 )}
//                                             </Grid>
//                                         </Grid>
//                                     ) : (
//                                         null
//                                     )}


//                                 </Grid >
//                             </React.Fragment >
//                         )
//                     })}
//                     <Grid item container display='flex' alignContent='center' justifyContent='center' mt={4} mb={2}>
//                         <Button variant="B1" onClick={() => { handleAnalyzeClick() }} sx={{ marginRight: "7px" }}>Analyze</Button>
//                         <Button variant="B1" onClick={() => { handleBackButtonClick() }} sx={{ marginLeft: "7px" }}>Back</Button>
//                     </Grid>

//                     <Snackbar
//                         open={snackbarOpen}
//                         autoHideDuration={6000}
//                         onClose={() => setSnackbarOpen(false)}
//                         anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
//                     >
//                         <MuiAlert
//                             elevation={6}
//                             variant="filled"
//                             onClose={() => setSnackbarOpen(false)}
//                             severity="warning"
//                             sx={{ color: '#fff', backgroundColor: '#ff9800' }}
//                         >
//                             {snackbarMessage}
//                         </MuiAlert>
//                     </Snackbar>
//                 </div >
//             )}
//             <Loader />
//         </>
//     );
// }


export default function MultiVideoUpload(props) {
    const { onClose, setVideoDialog, projectId, setSankbarMessage,
        setOpenSankbar, contentType, projectTitle, fetchDataForProjectUpload, fetchDraftProjects, confirmModal, setConfirmModal, backData, setFormData, rowData } = props;

    // console.log(projectId, "projectId");

    // let initialValue;

    // const [selectedVppType, setSelectedVppType] = useState('Ai-Analyze');

    // if (contentType === "tv-show" || contentType === "web-series") {
    //     initialValue = [
    //         {
    //             episodeNo: '', episodeTitle: '', file: null,
    //             ...(selectedVppType === 'Manual-Analyze' && {
    //                 rows: [
    //                     {
    //                         id: 1,
    //                         startTime: { hour: '00', minute: '00', second: '00' },
    //                         endTime: { endHour: '00', endMinute: '00', endSecond: '00' },
    //                         brandCategory: '',
    //                         vppPrice: ''
    //                     }
    //                 ]
    //             })
    //         },
    //     ];
    // } else {
    //     initialValue = [
    //         {
    //             file: null, videoTitle: '',
    //             ...(selectedVppType === 'Manual-Analyze' && {
    //                 rows: [
    //                     {
    //                         id: 1,
    //                         startTime: { hour: '00', minute: '00', second: '00' },
    //                         endTime: { endHour: '00', endMinute: '00', endSecond: '00' },
    //                         brandCategory: '',
    //                         vppPrice: ''
    //                     }
    //                 ]
    //             })
    //         },
    //     ];
    // }


    let initialValue;

    const [selectedVppType, setSelectedVppType] = useState('new_vpp');
    const [inputFields, setInputFields] = useState([]);

    // Initialize initialValue based on the initial value of selectedVppType
    if (contentType === "tv-show" || contentType === "web-series") {
        initialValue = [
            {
                episodeNo: '', episodeTitle: '', file: null,
                ...(selectedVppType === 'pre_approved_vpp' && {
                    rows: [
                        {
                            id: uuidv4(),
                            startTime: { hour: '00', minute: '00', second: '00' },
                            endTime: { endHour: '00', endMinute: '00', endSecond: '00' },
                            brandCategory: '',
                            vppPrice: ''
                        }
                    ]
                })
            },
        ];
    } else {
        initialValue = [
            {
                file: null, videoTitle: '',
                ...(selectedVppType === 'pre_approved_vpp' && {
                    rows: [
                        {
                            id: uuidv4(),
                            startTime: { hour: '00', minute: '00', second: '00' },
                            endTime: { endHour: '00', endMinute: '00', endSecond: '00' },
                            brandCategory: '',
                            vppPrice: ''
                        }
                    ]
                })
            },
        ];
    }

    // Update inputFields only when selectedVppType changes
    useEffect(() => {
        setInputFields(initialValue);
    }, [selectedVppType, contentType]);



    const [isDefault, setIsDefault] = useState(true);

    const dispatch = useDispatch()

    const inputFileRef = useRef(null);


    const [analyseModal, setAnalyseModal] = useState(false);
    const Link = "https://global.discourse-cdn.com/business4/uploads/prezi/original/2X/a/aaaff5d5dec03e32799015d424b4a5db51c9eeff.png"

    // const [inputFields, setInputFields] = useState(initialValue);
    const [activeRow, setActiveRow] = useState(null);
    const [videoTitle, setVideoTitle] = useState('');
    const [uploadProgress, setUploadProgress] = useState(inputFields.map(() => 0));
    const [uploadSuccess, setUploadSuccess] = useState(Array(inputFields.length).fill(false));
    const [analyzeClicked, setAnalyzeClicked] = useState(false);

    const [fileInputKey, setFileInputKey] = useState(0);
    const [isUploaded, setIsUploaded] = useState(false);
    const [showUpload, setShowUpload] = useState(false);

    const [hour, setHour] = useState('');
    const [minute, setMinute] = useState('');
    const [second, setSecond] = useState('');



    const handleChange = (event) => {
        setSelectedVppType(event.target.value);
    };


    console.log('hsfdshj', selectedVppType)


    const [endHour, setEndHour] = useState('');
    const [endMinute, setEndMinute] = useState('');
    const [endSecond, setEndSecond] = useState('');

    const combinedEndTime = `${endHour}:${endMinute}:${endSecond}`;

    console.log(combinedEndTime)

    function getCurrentTime() {
        const now = new Date();
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const seconds = String(now.getSeconds()).padStart(2, '0');
        return `${hours}:${minutes}:${seconds}`;
    }

    const handleAddFields = () => {
        setInputFields([...inputFields, {
            episodeNo: '', episodeTitle: '', file: null, videoTitle: '', rows: [
                {
                    id: 1,
                    startTime: { hour: '00', minute: '00', second: '00' },
                    endTime: { endHour: '00', endMinute: '00', endSecond: '00' },
                    brandCategory: '',
                    vppPrice: ''
                }
            ]
        }]);
        setUploadProgress([...uploadProgress, 0]);
        setFileInputKey((prevKey) => prevKey + 1);

    };

    const handleRemoveFields = (index) => {
        const updatedFields = [...inputFields];
        updatedFields.splice(index, 1);
        setInputFields(updatedFields);
        const progress = [...uploadProgress];
        progress.splice(index, 1);
        setUploadProgress(progress);

    };

    const handleChangeInput = (index, event) => {
        const { name, value } = event.target;
        const updatedFields = [...inputFields];
        updatedFields[index] = { ...updatedFields[index], [name]: value };
        setInputFields(updatedFields);
    };

    const handleSelectChange = (index, selectedOption) => {
        const values = [...inputFields];
        values[index].episodeNo = selectedOption.value;
        setInputFields(values);
    };

    const handleFileChange = (event) => {
        const file = event.target.files && event.target.files[0];

        if (file) {
            setUploadProgress(0);
            setVideoTitle('');

        } else {
            event.target.value = null;
        }
        setFileInputKey((prevKey) => prevKey + 1);
    };

    const fileInputRef = useRef(null);

    const [selectedVideo, setSelectedVideo] = useState(null);
    const [selectedRow, setSelectedRow] = useState(null);
    const [sourceFiel, setSourceFiel] = useState();

    const handleFileSelect = async (event, index) => {
        const file = event.target.files[0];
        setSelectedVideo(file);
        setSelectedRow(index);
        await uploadVideoToBackend(file, index);
        startUpload(file, index);
    };

    const startUpload = (file, index) => {
        const interval = setInterval(() => {
            setUploadProgress((prevProgress) => {
                const updatedProgress = [...prevProgress];
                updatedProgress[index] = Math.min(updatedProgress[index] + 10, 100);
                if (updatedProgress[index] >= 100) {
                    clearInterval(interval);
                }
                return [...updatedProgress];
            });
        }, 500);
    };

    // console.log("This is contentType which has came from stepOne =>", contentType, "and this is title=>", projectTitle)

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    const handleShowSnackbar = () => {
        const snackbarContent = `${contentType} - ${projectTitle}`;
        setSnackbarMessage(snackbarContent);
        setSnackbarOpen(true);
    };

    const [buttonView, setButtonView] = useState(false)

    // console.log("input fields line 170 ", inputFields)
    const handleSourceSelect = async (event) => {
        const file = event.target.files[0];
        const formData = new FormData();
        formData.append('file', file);
        const localFileIndex = uuidv4();
        formData.append('videoId', localFileIndex);
        formData.append('projectId', projectId);
        if (file) {
            try {
                const response = await optimusApi.post('/uploadv1', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                if (response.status === 200) {
                    console.log('Upload successful', response.data);
                    setSourceFiel(response.data)
                    setIsUploaded(true);
                }
            } catch (error) {
                console.error('Upload error', error);
            } finally {
                dispatch(setShowLoader(false));
            }
        }
    };


    const uploadVideoToBackend = async (file, fileIndex) => {
        // console.log('file', file)
        const formData = new FormData();
        formData.append('file', file);
        let localInputFields = inputFields;
        const localFileIndex = uuidv4();
        console.log('fileIndex', fileIndex);
        console.log('file', file);
        formData.append('videoId', localFileIndex);
        formData.append('projectId', projectId);
        formData.append('vppType', selectedVppType);
        dispatch(setShowLoader(true));
        // console.log('formData', formData)
        if (file && file.name) {
            const fileName = file.name;
            localInputFields[fileIndex].videoTitle = fileName;
            localInputFields[fileIndex].episodeTitle = fileName;
            localInputFields[fileIndex].episodeNo = fileIndex + 1;

            try {
                const response = await optimusApi.post('/uploadv1', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                if (inputFields && inputFields[fileIndex]) {
                    localInputFields[fileIndex].VideoUrl = response.data.Location;
                    localInputFields[fileIndex].s3Meta = response.data;
                    localInputFields[fileIndex].videoId = localFileIndex

                    const fileName = file.name;
                    localInputFields[fileIndex].episodeTitle = fileName;
                }
                const updatedUploadSuccess = [...uploadSuccess];
                updatedUploadSuccess[fileIndex] = true;
                setUploadSuccess(updatedUploadSuccess);
                setInputFields(localInputFields);
                // console.log('Upload successful', response.data);
                setButtonView(true)
            } catch (error) {

                console.error('Upload error', error);
            } finally {
                dispatch(setShowLoader(false));
            }
        }

    }

    const handleBackButtonClick = () => {
        setFormData({
            id: rowData?.id,
            title: rowData?.title,
            country: rowData?.country,
            vppType: rowData?.vppType,
            contentType: rowData?.contentType,
            releasePlatform: rowData?.releasePlatform,
            releaseType: rowData?.releaseType,
            genres: rowData?.genres,
            averageViewPerMonth: rowData?.averageViewPerMonth,
            season: rowData?.season,
        });
        setVideoDialog(true);
    };




    // const handleRemoveVideo = (index) => {
    //     const updatedUploadProgress = [...uploadProgress];
    //     updatedUploadProgress[index] = 0;
    //     const updatedUploadSuccess = [...uploadSuccess];
    //     updatedUploadSuccess[index] = false;
    //     const updatedInputFields = [...inputFields];
    //     updatedInputFields[index] = { episodeNo: '', episodeTitle: '', file: null, videoTitle: '' };
    // const fileInput = fileInputRef.current;
    // if (fileInput) {
    //     fileInput.value = null;
    //     fileInput.removeAttribute('disabled');
    // }
    //     setUploadProgress(updatedUploadProgress);
    //     setUploadSuccess(updatedUploadSuccess);
    //     setInputFields(updatedInputFields);
    //     setVideoTitle('');
    // };



    const handleRemoveVideo = (index) => {
        const updatedUploadProgress = [...uploadProgress];
        updatedUploadProgress[index] = 0;
        const updatedUploadSuccess = [...uploadSuccess];
        updatedUploadSuccess[index] = false;
        const updatedInputFields = [...inputFields];
        updatedInputFields[index] = { episodeNo: '', episodeTitle: '', file: null, videoTitle: '' };
        const fileInput = fileInputRef.current;
        if (fileInput) {
            fileInput.value = null;
            fileInput.removeAttribute('disabled');
        }
        setFileInputKey((prevKey) => prevKey + 1);
        setUploadProgress(updatedUploadProgress);
        setUploadSuccess(updatedUploadSuccess);
        setInputFields(updatedInputFields);
        setVideoTitle('');
    };



    console.log(uploadSuccess)



    const handleAnalyzeClick = () => {
        if (contentType === "tv-show" || contentType === "web-series") {
            for (const inputField of inputFields) {
                if (inputField.episodeNo === '' || inputField.episodeNo === undefined) {
                    setAnalyzeClicked(true);
                    return;
                }
            }
            setAnalyseModal(true);
            setIsDefault(false);
            setAnalyzeClicked(false);
        } else {
            setAnalyseModal(true);
            setIsDefault(false);
        }
    }


    const customStyles = {
        menu: (provided, state) => ({
            ...provided,
            // maxHeight: '250px',
            // overflowY: 'hidden',
            zIndex: 50
            
        }),
       
    
    };

    const handleAddRow = (inputIndex) => {
        const newRowId = inputFields[inputIndex].rows.length + 1;
        const newRow = {
            id: newRowId,
            startTime: { hour: '00', minute: '00', second: '00' },
            endTime: { endHour: '00', endMinute: '00', endSecond: '00' },
            brandCategory: '',
            vppPrice: ''
        };

        const updatedFields = [...inputFields];
        updatedFields[inputIndex].rows.push(newRow);
        setInputFields(updatedFields);
    };

    const handleRemoveRow = (inputIndex, rowId) => {
        const updatedFields = [...inputFields];
        updatedFields[inputIndex].rows = updatedFields[inputIndex].rows.filter(row => row.id !== rowId);
        setInputFields(updatedFields);
    };

    const handleChangeHour = (selectedOption, inputIndex, rowId) => {
        const updatedFields = [...inputFields];
        updatedFields[inputIndex].rows = updatedFields[inputIndex].rows.map(row => {
            if (row.id === rowId) {
                return {
                    ...row,
                    startTime: {
                        ...row.startTime,
                        hour: selectedOption ? selectedOption.value : ''
                    }
                };
            }
            return row;
        });
        setInputFields(updatedFields);
    };

    const handleChangeMinute = (selectedOption, inputIndex, rowId) => {
        const updatedFields = [...inputFields];
        updatedFields[inputIndex].rows = updatedFields[inputIndex].rows.map(row => {
            if (row.id === rowId) {
                return {
                    ...row,
                    startTime: {
                        ...row.startTime,
                        minute: selectedOption ? selectedOption.value : ''
                    }
                };
            }
            return row;
        });
        setInputFields(updatedFields);
    };

    const handleChangeSecond = (selectedOption, inputIndex, rowId) => {
        const updatedFields = [...inputFields];
        updatedFields[inputIndex].rows = updatedFields[inputIndex].rows.map(row => {
            if (row.id === rowId) {
                return {
                    ...row,
                    startTime: {
                        ...row.startTime,
                        second: selectedOption ? selectedOption.value : ''
                    }
                };
            }
            return row;
        });
        setInputFields(updatedFields);
    };

    const handleChangeEndHour = (selectedOption, inputIndex, rowId) => {
        const updatedFields = [...inputFields];
        updatedFields[inputIndex].rows = updatedFields[inputIndex].rows.map(row => {
            if (row.id === rowId) {
                return {
                    ...row,
                    endTime: {
                        ...row.endTime,
                        endHour: selectedOption ? selectedOption.value : ''
                    }
                };
            }
            return row;
        });
        setInputFields(updatedFields);
    };

    const handleChangeEndMinute = (selectedOption, inputIndex, rowId) => {
        const updatedFields = [...inputFields];
        updatedFields[inputIndex].rows = updatedFields[inputIndex].rows.map(row => {
            if (row.id === rowId) {
                return {
                    ...row,
                    endTime: {
                        ...row.endTime,
                        endMinute: selectedOption ? selectedOption.value : ''
                    }
                };
            }
            return row;
        });
        setInputFields(updatedFields);
    };

    const handleChangeEndSecond = (selectedOption, inputIndex, rowId) => {
        const updatedFields = [...inputFields];
        updatedFields[inputIndex].rows = updatedFields[inputIndex].rows.map(row => {
            if (row.id === rowId) {
                return {
                    ...row,
                    endTime: {
                        ...row.endTime,
                        endSecond: selectedOption ? selectedOption.value : ''
                    }
                };
            }
            return row;
        });
        setInputFields(updatedFields);
    };

    const handleBrandCategoryChange = (selectedOption, inputIndex, rowId) => {
        const updatedFields = [...inputFields];
        updatedFields[inputIndex].rows = updatedFields[inputIndex].rows.map(row => {
            if (row.id === rowId) {
                return { ...row, brandCategory: selectedOption ? selectedOption.value : '' };
            }
            return row;
        });
        setInputFields(updatedFields);
    };

    const handleVppChange = (event, inputIndex, rowId) => {
        const value = parseFloat(event.target.value);
        if (!isNaN(value) && value >= 0) {
            const updatedFields = [...inputFields];
            updatedFields[inputIndex].rows = updatedFields[inputIndex].rows.map(row => {
                if (row.id === rowId) {
                    return { ...row, vppPrice: value };
                }
                return row;
            });
            setInputFields(updatedFields);
        }
    };

    const handleVppUpdate = async () => {
        const formData = {
            vppType: selectedVppType
        }
        try {
            const existingResponse = await awsApi.post('/getprojects', { id: projectId });
            if (existingResponse.status === 200) {
                const updatedProject = {
                    ...formData,
                };
                const response = await awsApi.post(
                    '/postproject',
                    { action: "update-metadata", id: projectId, updatedProject }
                );
                if (response.status === 200) {
                    console.log('Data updated successfully');
                } else {
                    console.log('Failed to update data:');
                }
            }
        } catch (error) {
            console.error('Error updating data:', error);
        }
    };

    const handleAnalize = async () => {
        dispatch(setShowLoader(true));
        let fields = inputFields;
        let fieldsWithS3Meta = inputFields.filter(field => field.s3Meta);

        if (fieldsWithS3Meta.length === 0) {
            dispatch(setShowLoader(false));
            return;
        }
        try {
            handleVppUpdate()
            const res = await awsApi.post('/postvideo', { projectId: projectId, videos: fieldsWithS3Meta });
            if (res.data.statusCode === 200) {
                console.log(res.data.body);
                setVideoDialog(true);
                setAnalyseModal(false);
                onClose();
                fetchDataForProjectUpload();
                fetchDraftProjects();
                handleShowSnackbar();

            }
        } catch (err) {
            console.log(err, "error");
        } finally {
            dispatch(setShowLoader(false));
        }
    };

    const windowHeight = window.innerHeight - 300;

    return (
        <>
            {analyseModal &&

                <DialogContent sx={{ width: '100%' }} >
                    <Typography variant="P22">
                        {selectedVppType === 'pre_approved_vpp' ? 'Are you sure you want to submit for Marketplace ?' : 'Are you sure you want to submit for AI Analysis?'}
                    </Typography>
                    <Grid item container display='flex' alignContent='center' justifyContent='center' mt={4} mb={2}>
                        <Button variant="B1" onClick={() => { handleAnalize() }} sx={{ marginRight: "7px" }}>
                            Submit
                        </Button>
                        <Button variant="B1" onClick={() => { setAnalyseModal(false); setIsDefault(true); }} sx={{ marginLeft: "7px" }}>Cancel</Button>
                    </Grid>
                </DialogContent>

            }

            {confirmModal &&

                <DialogContent sx={{ width: '100%' }} >
                    <Typography variant="P22">Are you sure you want to close?</Typography>
                    <Grid item container display='flex' alignContent='center' justifyContent='center' mt={4} mb={2}>
                        <Button variant="B1" onClick={() => { setConfirmModal(false); setVideoDialog(true); onClose() }} sx={{ marginRight: "7px" }}>Yes</Button>
                        <Button variant="B1" onClick={() => { setIsDefault(true); setConfirmModal(false) }} sx={{ marginLeft: "7px" }}>No</Button>
                    </Grid>
                </DialogContent>

            }
            {isDefault && (
                <div>
                    <Grid
                        item
                        xs={11.8}
                        container
                        spacing={1}
                        ml={1}
sx={{zIndex:0}}
                    >
                        <Grid item xs={12} spacing={1} container ml={0.5}>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                value={selectedVppType}
                                onChange={handleChange}
                                style={{ fontWeight: "bold" }}
                            >
                                <FormControlLabel
                                    value="new_vpp"
                                    control={<Radio style={{ color: selectedVppType === 'new_vpp' ? 'black' : 'inherit' }} />}
                                    // label="Ai Analyze"
                                    label={
                                        <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                                            Scene Finder AI (Beta)
                                        </Typography>
                                    }
                                />
                                <FormControlLabel
                                    value="pre_approved_vpp"
                                    control={<Radio style={{ color: selectedVppType === 'pre_approved_vpp' ? 'black' : 'inherit' }} />}
                                    // label="Manual Analyze"
                                    label={
                                        <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                                            Manual Scene Selection
                                        </Typography>
                                    }
                                />
                            </RadioGroup>
                        </Grid>

                        {/* <Grid item xs={12} spacing={1} mr={2} container>
                            {uploadProgress[0] === 100 &&
                                <><Grid item xs={12} mb={0.6}>
                                    <Typography variant="P22">
                                        {"Video Thumbnail"}
                                    </Typography>
                                </Grid>
                                    <Stack direction="row" spacing={2} alignItems="center">
                                        {uploadProgress.map((upload, index) => {
                                            if (upload === 100) {
                                                const correspondingFields = inputFields.filter((field, fieldIndex) => fieldIndex === index);

                                                return correspondingFields.map((field, fieldIndex) => (
                                                    <Grid key={fieldIndex} display='flex' flexDirection='column' alignItems='center'>
                                                        <Grid>
                                                            <ImageContainer>
                                                                <img src={Link} alt="Your Image" width="70px" height="70px" style={{ borderRadius: '10px', marginLeft: "10px" }} />
                                                            </ImageContainer>
                                                        </Grid>

                                                        <Typography ml={1.4} mt={0.5} variant='C11' color={'#344357'}>
                                                            {field.episodeNo}
                                                        </Typography>
                                                    </Grid>
                                                ));
                                            }
                                        })}

                                    </Stack>
                                </>}
                        </Grid> */}
                        {selectedVppType === 'pre_approved_vp' && (
                            <>
                                <Grid item xs={12} spacing={1} mr={2} container display='flex'>
                                    <Grid  >
                                        <Typography variant="P22">
                                            {"Upload Source Video"}
                                        </Typography>
                                    </Grid>
                                    <Grid ml={7}>
                                        <Switch onChange={setShowUpload} checked={showUpload} />
                                    </Grid>
                                </Grid>

                                {showUpload && (
                                    <Grid item xs={12} spacing={1} mr={2} container>

                                        <Grid item xs={12}>
                                            <div>
                                                <Input
                                                    type="file"
                                                    accept="video/*"
                                                    onChange={handleSourceSelect}
                                                    ref={inputFileRef}
                                                    style={{ display: 'none' }}
                                                    disabled={isUploaded}
                                                />
                                                <Label htmlFor="video-upload" style={{ margin: '0px', backgroundColor: isUploaded ? '#4caf50' : '#bfbfbf' }}>
                                                    <UploadButton
                                                        as="span"
                                                        startIcon={<RiVideoUploadFill style={{ fontSize: '23px', marginTop: '5px' }} />}
                                                        disabled={isUploaded}
                                                        onClick={() => inputFileRef.current.click()}
                                                    >
                                                        {isUploaded ? 'Uploaded' : 'Upload Source Video'}
                                                    </UploadButton>
                                                </Label>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} display='flex' mt={1}>
                                            <Grid xs={6}>
                                                <Typography variant="P22">
                                                    {"Start Time"}
                                                </Typography>
                                            </Grid>
                                            <Grid xs={6}>
                                                <Typography variant="P22">
                                                    {"End Time"}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} display='flex' >
                                            <Grid xs={6}>
                                                <Grid xs={6} display='flex'>
                                                    <Select
                                                        options={hourData}
                                                        styles={customStyles}
                                                        placeholder='HH'
                                                        onChange={handleChangeHour}

                                                        value={{ value: hour, label: hour }}
                                                    />
                                                    <Select
                                                        options={mintData}
                                                        styles={customStyles}
                                                        placeholder='MM'
                                                        onChange={handleChangeMinute}
                                                        value={{ value: minute, label: minute }}
                                                    />
                                                    <Select
                                                        options={secData}
                                                        styles={customStyles}
                                                        placeholder='SS'
                                                        onChange={handleChangeSecond}
                                                        value={{ value: second, label: second }}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid xs={6}>
                                                <Grid xs={7} display='flex'>
                                                    <Select
                                                        options={hourData}
                                                        styles={customStyles}
                                                        placeholder='HH'
                                                        onChange={handleChangeEndHour}
                                                        value={{ value: endHour, label: endHour }}
                                                    />
                                                    <Select
                                                        options={mintData}
                                                        styles={customStyles}
                                                        placeholder='MM'
                                                        onChange={handleChangeEndMinute}
                                                        value={{ value: endMinute, label: endMinute }}
                                                    />
                                                    <Select
                                                        options={secData}
                                                        styles={customStyles}
                                                        placeholder='SS'
                                                        onChange={handleChangeEndSecond}
                                                        value={{ value: endSecond, label: endSecond }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )}
                            </>
                        )}
                    </Grid>

                    <div style={{   maxHeight: windowHeight }}>


                        <Grid item xs={11.8} container spacing={1} mt={1} ml={1}>
                            {contentType === "tv-show" || contentType === "web-series" ? (
                                <>
                                    <Grid item xs={1.4} container spacing={2} style={{ height: '55px' }}>
                                        <Grid item xs={12}>
                                            <Typography variant="P22">
                                                {"*Episode No."}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6} container spacing={2} ml={0.5}>
                                        <Grid item xs={12}>
                                            <Typography variant="P22">
                                                {"Episode Title"}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </>
                            ) : (
                                <Grid item xs={8.8} container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography variant="P22">
                                            {"Video Title"}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            )}
                            <Grid item xs={3} container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="P22">
                                        {"Upload Video"}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>

                        {inputFields.map((inputField, index) => {
                            let color = "#E8E8E8";
                            if (uploadSuccess[index]) {
                                color = "#4caf50";
                            }
                            return (
                                <React.Fragment key={index}>
                                    <div style={{ background: "#ababab", borderRadius: "8px" }}>
                                        <Grid item xs={11.8} container spacing={1} mt={1} ml={1} mb={1}>

                                            {contentType === "tv-show" || contentType === "web-series" ? (
                                                <>
                                                    <Grid item xs={1.4} container spacing={2} style={{ height: '55px' }}>

                                                        <Grid item xs={12}>
                                                            <CustomTextfield
                                                                size="small"
                                                                placeholder="Enter number"
                                                                fullWidth
                                                                variant="outlined"
                                                                name="episodeNo"
                                                                value={inputField.episodeNo}
                                                                onChange={(event) => handleChangeInput(index, event)}
                                                                required
                                                            />
                                                            {analyzeClicked && !inputField.episodeTitle && <Typography variant='P23' className="error-message" >Episode No is required.</Typography>}
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={6} container spacing={2} ml={0.5}>

                                                        <Grid item xs={12}>
                                                            <CustomTextfield
                                                                size="small"
                                                                placeholder="Enter title"
                                                                fullWidth
                                                                variant="outlined"
                                                                name="episodeTitle"
                                                                value={inputField.episodeTitle}
                                                                onChange={(event) => handleChangeInput(index, event)}
                                                                required
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </>
                                            ) : (
                                                <Grid item xs={8.8} container spacing={2}>

                                                    <Grid item xs={12}>
                                                        <CustomTextfield
                                                            size="small"
                                                            placeholder="Enter number"
                                                            fullWidth
                                                            variant="outlined"
                                                            name="videoTitle"
                                                            value={inputField.videoTitle}
                                                            onChange={(event) => handleChangeInput(index, event)}
                                                        />

                                                    </Grid>
                                                </Grid>

                                            )}
                                            <Grid item xs={3} container spacing={2}>
                                                <Grid item xs={12}>
                                                    <div>
                                                        <>
                                                            <Input
                                                                key={fileInputKey}
                                                                accept="video/*"
                                                                id={`video-upload-${index}`}
                                                                multiple={false}
                                                                type="file"
                                                                onChange={(event) => handleFileSelect(event, index)}
                                                                ref={fileInputRef}
                                                                style={{ display: 'none' }}
                                                                disabled={uploadSuccess[index]}
                                                            />
                                                            <Label htmlFor={`video-upload-${index}`} style={{ margin: '0px', backgroundColor: color }}>
                                                                <UploadButton
                                                                    as="span"
                                                                    startIcon={<RiVideoUploadFill style={{ fontSize: '23px', marginTop: '5px' }} />}
                                                                    disabled={uploadSuccess[index]}
                                                                >
                                                                    {uploadSuccess[index] ? 'Uploaded' : 'Upload'}
                                                                </UploadButton>
                                                            </Label>
                                                        </>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                            {contentType === "tv-show" || contentType === "web-series" ? (
                                                <Grid item xs={1} container mr={1}>
                                                    <Grid item xs={12} >
                                                        {index === (inputFields.length - 1) ? (
                                                            <div style={{ display: "flex", width: "110px" }}>
                                                                <Action style={{ background: "Black" }} disabled={uploadProgress[uploadProgress.length - 1] === 0} onClick={handleAddFields}>
                                                                    <AiOutlinePlus />
                                                                </Action>
                                                                <Action style={{ background: "#E85347" }} onClick={() => { handleRemoveVideo(index); if (index > 0) handleRemoveFields(index); }}>
                                                                    <AiOutlineMinus />
                                                                </Action>
                                                            </div>
                                                        ) : (
                                                            <Action style={{ background: "#E85347" }} onClick={() => { handleRemoveVideo(index) }}>
                                                                <AiOutlineMinus />
                                                            </Action>
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            ) : (
                                                null
                                            )}
                                        </Grid >
                                        {selectedVppType === 'pre_approved_vpp' && (
                                            <Grid item xs={10.85} container spacing={1} ml={2} mt={1} >
                                                <Accordion style={{ backgroundColor: '#E8E8E8', color: 'Black', minWidth: "100%", marginBottom: "10px" ,
                                            pointerEvents: uploadSuccess[index] ? 'auto' : 'none', 
                                            opacity: uploadSuccess[index] ? 1 : 0.5
                                            }} >
                                                    <AccordionSummary
                                                        expandIcon={<ArrowDropDownIcon />}
                                                        aria-controls="panel2-content"
                                                        id="panel2-header"
                                                        style={{ borderBottom: "0.5px solid white" }}
                                                    >
                                                        <Typography>For Video No <span style={{ fontWeight: 'bold', color: 'blue' }}>{index + 1}</span> VPO Clip Duration</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails >
                                                        <Grid item xs={12} spacing={1} mr={2} container style={{ minWidth: "100%" }}>
                                                            <Grid item xs={12} display='flex' mt={1} >
                                                                <Grid xs={0.5}>
                                                                    <Typography variant="P22">
                                                                        {" S.No"}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid xs={3.2} >
                                                                    <Typography variant="P22">
                                                                        {"Start Time"}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid xs={3.2} >
                                                                    <Typography variant="P22">
                                                                        {"End Time"}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid xs={3.1} >
                                                                    <Typography variant="P22">
                                                                        {"Brand Categories"}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid xs={1.3} >
                                                                    <Typography variant="P22">
                                                                        {"VPO Price"}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid xs={0.7} >
                                                                    <Typography variant="P22">
                                                                        {"VPO"}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                            {inputField.rows && inputField.rows.map((row, inputIndex) => (
                                                                <Grid item xs={12} display='flex' >
                                                                    <Grid xs={0.5} display='flex' alignItems='center'>
                                                                        <Typography variant="P22" style={{ marginTop: "-5px" }}>
                                                                            {`${index + 1}.${String.fromCharCode(65 + inputIndex)}`}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid xs={3.2}>
                                                                        <Grid xs={12} display='flex'>

                                                                            <Select
                                                                                options={hourData}
                                                                                styles={customStyles}
                                                                                placeholder='HH'
                                                                                menuPlacement="auto"
                                                                                onChange={(selectedOption) => handleChangeHour(selectedOption, index, row.id)}
                                                                                value={row.startTime.hour ? { value: row.startTime.hour, label: row.startTime.hour } : null}
                                                                            />
                                                                            <Select
                                                                                options={mintData}
                                                                                styles={customStyles}
                                                                                placeholder='MM'
                                                                                menuPlacement="auto"
                                                                                onChange={(selectedOption) => handleChangeMinute(selectedOption, index, row.id)}
                                                                                value={row.startTime.minute ? { value: row.startTime.minute, label: row.startTime.minute } : null}
                                                                            />
                                                                            <Select
                                                                                options={secData}
                                                                                styles={customStyles}
                                                                                placeholder='SS'
                                                                                menuPlacement="auto"
                                                                                onChange={(selectedOption) => handleChangeSecond(selectedOption, index, row.id)}
                                                                                value={row.startTime.second ? { value: row.startTime.second, label: row.startTime.second } : null}
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid xs={3.2}>
                                                                        <Grid xs={12} display='flex' >
                                                                            <Select
                                                                                options={hourData}
                                                                                styles={customStyles}
                                                                                placeholder='HH'
                                                                                menuPlacement="auto"
                                                                                onChange={(selectedOption) => handleChangeEndHour(selectedOption, index, row.id)}
                                                                                value={row.endTime.endHour ? { value: row.endTime.endHour, label: row.endTime.endHour } : null}
                                                                            />
                                                                            <Select
                                                                                options={mintData}
                                                                                styles={customStyles}
                                                                                placeholder='MM'
                                                                                menuPlacement="auto"
                                                                                onChange={(selectedOption) => handleChangeEndMinute(selectedOption, index, row.id)}
                                                                                value={row.endTime.endMinute ? { value: row.endTime.endMinute, label: row.endTime.endMinute } : null}
                                                                            />
                                                                            <Select
                                                                                options={secData}
                                                                                styles={customStyles}
                                                                                placeholder='SS'
                                                                                menuPlacement="auto"
                                                                                onChange={(selectedOption) => handleChangeEndSecond(selectedOption, index, row.id)}
                                                                                value={row.endTime.endSecond ? { value: row.endTime.endSecond, label: row.endTime.endSecond } : null}
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid xs={3.1}>
                                                                        <Grid xs={11.3} display='flex' >
                                                                            <Select
                                                                                options={brandCategoryOptions}
                                                                                isMulti={false}
                                                                                onChange={(selectedOption) => handleBrandCategoryChange(selectedOption, index, row.id)}
                                                                                // value={row.brandCategory}
                                                                                value={brandCategoryOptions.find((option) => option.value === row.brandCategory)}
                                                                                menuPlacement="auto"
                                                                                placeholder='Select Brand ...'
                                                                                styles={{ minWidth: "100%", }}
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid xs={1.3}>
                                                                        <Grid xs={12} display='flex' >
                                                                            <CustomTextfield
                                                                                name="vppPrice"
                                                                                size="small"
                                                                                placeholder="Enter Price"
                                                                                fullWidth
                                                                                variant="outlined"
                                                                                type="number"
                                                                                value={row.vppPrice}
                                                                                onChange={(event) => handleVppChange(event, index, row.id)}
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid xs={0.7}>
                                                                        <Grid xs={12} display='flex' >
                                                                            <Action style={{ background: inputIndex === inputFields[index].rows.length - 1 ? "Black" : "#E85347" }} onClick={inputIndex === inputFields[index].rows.length - 1 ? () => handleAddRow(index) : () => handleRemoveRow(index, row.id)}>
                                                                                {inputIndex === inputFields[index].rows.length - 1 ? <AiOutlinePlus /> : <AiOutlineMinus />}
                                                                            </Action>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            ))}
                                                        </Grid>
                                                    </AccordionDetails>
                                                </Accordion>
                                            </Grid >
                                        )}
                                    </div>

                                </React.Fragment >
                            )
                        })}

                    </div>

                    <Grid item container display='flex' alignContent='center' justifyContent='center' mt={4} mb={2}>
                        <Button variant="B1" onClick={() => { handleAnalyzeClick() }} sx={{ marginRight: "7px", width: "fit-content" }}>
                            {selectedVppType === 'pre_approved_vpp' ? 'Send to MarketPlace' : 'Analyze'}
                        </Button>
                        <Button variant="B1" onClick={() => { handleBackButtonClick() }} sx={{ marginLeft: "7px" }}>Back</Button>
                    </Grid>

                    <Snackbar
                        open={snackbarOpen}
                        autoHideDuration={6000}
                        onClose={() => setSnackbarOpen(false)}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    >
                        <MuiAlert
                            elevation={6}
                            variant="filled"
                            onClose={() => setSnackbarOpen(false)}
                            severity="warning"
                            sx={{ color: '#fff', backgroundColor: '#ff9800' }}
                        >
                            {snackbarMessage}
                        </MuiAlert>
                    </Snackbar>
                </div >
            )}
            <Loader />
        </>
    );
}
