import React, { useState } from 'react'
import { Chip, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea, CardActions, Grid, } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { addProjectId } from '../../redux/projectSlice';
import { useDispatch } from "react-redux";
import defaultThumbnail from '../../assets/NoVideo.png'
import { generateThumbnailURL } from '../../Routes/apiConfig';

const ProjectCard = ({ projectTitle, season, episodes, genrec, pValue, NoOfVop, thumbnail, projectId, handelonClick,
    contentType, price, vppFreamNo, label, color, completeDate, viId, pageType, totalMarketClip }) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    // console.log(projectId, 'Project card id in vpp')

    let videoId;



    const thumbnailURL = generateThumbnailURL(viId) || defaultThumbnail;

    return (
        <Card sx={{
            minWidth: 300, maxWidth: 300, margin: "1rem",
        }}>
            <CardActionArea sx={{
                '&:focus': {
                    outline: 'none',
                }
            }}>
                <CardMedia
                    component="img"
                    height="140"
                    image={thumbnailURL}
                    alt="green iguana"
                    onClick={() => { handelonClick(projectId) }}
                />

            </CardActionArea>
            <CardContent>
                <Grid xs={12} display='flex' justifyContent='space-between' alignItems='center' mt={-0.5}>
                    <Typography variant='CHead' > {contentType}</Typography>
                </Grid>
                {projectTitle && (
                    <Grid xs={12} display='flex' justifyContent='flex-start' alignItems='center' mt={0.5}>
                        <Typography variant='CHead' style={{ fontSize: "15px" }}>Project Title :&nbsp;</Typography>
                        <Grid variant='GV'> {projectTitle.length > 15 ? `${projectTitle.substring(0, 15)}...` : projectTitle}</Grid>
                    </Grid>
                )}
                {pValue && (
                    <Grid xs={12} display='flex' justifyContent='flex-start' alignItems='center' mt={0.5}>
                        <Typography variant='CHead' style={{ fontSize: "15px" }}>Potential Value :&nbsp;</Typography>
                        <Grid variant='GV'>  ${pValue} </Grid>
                    </Grid>
                )}

                {price && (

                    <Grid xs={12} display='flex' justifyContent='flex-start' alignItems='center' mt={0.5}>
                        <Typography variant='CHead' style={{ fontSize: "15px" }}>Price:&nbsp;</Typography>
                        <Grid variant='GV'> ${price} </Grid>
                    </Grid>
                )}
                {completeDate && (

                    <Grid xs={12} display='flex' justifyContent='flex-start' alignItems='center' mt={0.5}>
                        <Typography variant='CHead' style={{ fontSize: "15px" }}>Complete Date :&nbsp;</Typography>
                        <Grid variant='GV'>  {completeDate} </Grid>
                    </Grid>
                )}
                {genrec && (
                    <Grid xs={12} display='flex' justifyContent='flex-start' alignItems='center' mt={0.8}>
                        <Typography
                            variant="P13"
                        >
                            Genre :
                        </Typography>
                        <Typography
                            variant="P14"
                        >
                            &nbsp; {genrec}
                        </Typography>
                    </Grid>
                )}
            </CardContent>
            <CardActions>


                <Grid container mt={-5} spacing={2} style={{ padding: '0.5rem' }}>
                    {season && (
                        <Grid item container xs={3}>
                            <Grid item xs={12}>
                                <Typography fontSize={'0.8rem'} fontWeight={'bold'}>
                                    {'Seasons'}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid item xs={12} mt={0.5}>
                                    <Typography style={{
                                        fontSize: '0.8rem',
                                        color: "#8094ae"
                                    }}>
                                        {season}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>)}
                    {episodes && (
                        <Grid item container xs={4}>
                            <Grid item xs={12}>
                                <Typography fontSize={'0.8rem'} fontWeight={'bold'}>
                                    {'Episodes'}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} mt={0.5}>
                                <Typography style={{
                                    fontSize: '0.8rem',
                                    color: "#8094ae"
                                }}>
                                    {episodes}
                                </Typography>
                            </Grid>
                        </Grid>)}
                    {NoOfVop && (
                        <Grid item container xs={5}>
                            <Grid item xs={12}>
                                <Typography fontSize={'0.9rem'} fontWeight={'bold'}>
                                    {'No of VPOs'}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} mt={0.5}>
                                <Typography style={{
                                    fontSize: '0.9rem',
                                    color: "#8094ae"
                                }}>
                                    {pageType === 'market' ? <>{totalMarketClip}</> : <>{NoOfVop}</>}
                                </Typography>
                            </Grid>
                        </Grid>)}
                    {vppFreamNo !== undefined && (
                        <Grid item container xs={5}>
                            <Grid item xs={12}>
                                <Typography fontSize={'0.8rem'} fontWeight={'bold'}>
                                    {'Campaign No'}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} mt={0.5}>
                                <Typography style={{
                                    fontSize: '0.8rem',
                                    color: "#8094ae"
                                }}>
                                    {vppFreamNo}
                                </Typography>
                            </Grid>
                        </Grid>
                    )}
                    {label && (
                        <Grid item container xs={6}>
                            <Grid item xs={12}>
                                <Typography fontSize={'0.8rem'} fontWeight={'bold'}>
                                    {'Status'}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} mt={0.5}>
                                <Typography style={{
                                    fontSize: '0.8rem',
                                    color: "#8094ae"
                                }}>
                                    <Chip
                                        label={label}
                                        color={color}
                                        variant="outlined"
                                        size="small"
                                        sx={{ width: '100px', height: '30px' }}
                                    />
                                </Typography>
                            </Grid>
                        </Grid>)}
                </Grid>
            </CardActions>

        </Card>


    )
}

export default ProjectCard
