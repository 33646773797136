import { useState } from "react";
import Logo from '../assets/Logo.png';
import { useNavigate } from 'react-router-dom';
import CustomTextfield from "../Components/CommonComponet/CustomTextfield";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { AppBar, Grid, Typography, Paper, Toolbar, Button, InputAdornment, IconButton, DialogActions } from "@mui/material";
import styled from "@emotion/styled";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import { GiFrankensteinCreature } from "react-icons/gi"
import { IoPricetagsOutline } from "react-icons/io5"
import { awsApiTwo } from "../Routes/Server";
import { IoArrowBackCircleOutline  } from "react-icons/io5";


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const Circle = styled(Grid)(({ isSelected }) => ({
    border: "1px solid #dbdfea",
    width: "35%",
    height: "9rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "15rem",
    fontSize: "38px",
    color: isSelected ? "white" : "#526484",
    background: isSelected ? "black" : "none",
    cursor: "pointer",
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other} fontWeight="bold" style={{ fontFamily: 'DM Sans' }}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

const Media = styled("img")({
    width: "300px",
    height: "180px",
});



const SignupPage = () => {
    const navigate = useNavigate();

    // console.log(location, "location");
    // const selectedCircle = location.state?.selectedCircle || null;

    const [showPassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState("");
    const [username, setusername] = useState("");
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    // const [companyName, setCompanyName] = useState("");
    // const [userType, setuserType] = useState("")
    const [signupError, setSignupError] = useState(null);
    const [approveDialogOpen, setApproveDialogOpen] = useState(false);
    const [monthlyViewers, setMonthlyViewers] = useState("")
    const [brand, setBrand] = useState("")
    const [otpValue, setOtpValue] = useState("");
    const [otp, setOtp] = useState(false);
    const [usernameError, setUsernameError] = useState(false);
    const [nameError, setNameError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [passwordErrors, setPasswordErrors] = useState({
        uppercase: false,
        specialChar: false,
        number: false,
        length: false,
    });
    const [passwordHelperText, setPasswordHelperText] = useState("");
    const [phoneError, setPhoneError] = useState(false);

    const [circle, setCircle] = useState();
    const [selectedCircle, setSelectedCircle] = useState();

    const validatePassword = (value) => {
        const errors = {
            uppercase: !/(?=.*[A-Z])/.test(value),
            specialChar: !/(?=.*[@$!%*?&])/.test(value),
            number: !/(?=.*\d)/.test(value),
            length: !/(?=.{8,})/.test(value),
        };
        setPasswordErrors(errors);
        return !Object.values(errors).some((error) => error);
    };

    const handleToggleShowPassword = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };

    const handlePasswordChange = (event) => {
        const passwordValue = event.target.value;
        setPassword(passwordValue);
        validatePassword(passwordValue);
    };

    const handleusernameChange = (event) => {
        setusername(event.target.value);
        setUsernameError(false);
    };

    const handleNameChange = (event) => {
        setName(event.target.value);
        setNameError(false);
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
        setEmailError(false);
    };

    // const handlephoneChange = (event) => {
    //     setPhone(event.target.value);
    //     setPhoneError(false);
    // };

    const handlephoneChange = (event) => {
        let phoneNumber = event.target.value;
        // Remove any non-digit characters
        phoneNumber = phoneNumber.replace(/\D/g, "");
        // Ensure the phone number starts with "+91"
        if (!phoneNumber.startsWith("91") && phoneNumber.length >= 10) {
            phoneNumber = "+91" + phoneNumber.slice(-10);
        }
        // Limit the total length to "+91" plus 10 digits
        phoneNumber = phoneNumber.slice(0, 13);

        setPhone(phoneNumber);
        setPhoneError(false);
    };

    const handleOtpSubmit = async () => {
        const data = {
            username: username,
            password: password,
            otp: otpValue.toString(),
        };
        try {
            let res = await awsApiTwo.post('/confirmsignup', data);
            if (res.data.statusCode === 200) {
                setOtp(false);
                setApproveDialogOpen(true);

                setTimeout(() => {
                    setApproveDialogOpen(false);
                    navigate('/Login');
                }, 7000);
            } else {
                const errorMessage = JSON.parse(res.data.body);
                setSignupError(errorMessage);
                const { error } = await res.json();
                console.log(error);
            }
        } catch (error) {
            console.log(error);
        }
    };


    const handleSubmit = async () => {

        const userType = selectedCircle === 'Creator' ? 'creator' : 'brand';
        console.log("userType", userType)
        if (!username || !name || !email || !phone) {

            setUsernameError(!username);
            setNameError(!name);
            setEmailError(!email);

            setPhoneError(!phone);
            return;
        }
        const isPasswordValid = validatePassword(password);
        if (!isPasswordValid) {
            setPasswordHelperText("Password does not meet the criteria.");
            return;
        }
        try {
            let postData = {
                username,
                name,
                email,
                password,
                phone,
                // companyName,
                userType
            };
            if (userType === 'brand') {
                postData.brand = brand;
            } else if (userType === 'creator') {
                postData.userType = userType;
                postData.monthlyViewers = monthlyViewers;
            }

            const response = await awsApiTwo.post('/signup', postData);
            // navigate('*');
            if (response.data.statusCode === 200) {
                setOtp(true);
                // navigate('/Login');
            } else {
                const errorMessage = JSON.parse(response.data.body);
                setSignupError(errorMessage);
            }
            // console.log(response.data);
        } catch (error) {
            alert(error.message);
            console.error(error);
        }
    };

    const navigateBack = () => {
        navigate('/Login');
    };

    console.log(signupError)

    return (
        <>
            {selectedCircle ? <>
                {otp && <BootstrapDialog onClose={() => { setOtp(false) }} aria-labelledby="customized-dialog-title" open={otp}>
                    <BootstrapDialogTitle id="customized-dialog-title" onClose={() => { setOtp(false) }}>
                        Enter OTP
                    </BootstrapDialogTitle>
                    <DialogContent dividers>
                        <Grid item xs={12} container spacing={1}>
                            <Grid item xs={12}>
                                <CustomTextfield
                                    size="small"
                                    id="otp-input"
                                    placeholder="Enter OTP"
                                    fullWidth
                                    variant="outlined"
                                    type={'text'}
                                    value={otpValue}
                                    onChange={(e) => { setOtpValue(e.target.value) }}
                                />
                            </Grid>
                            {signupError && (
                                <Typography variant={"body2"} color={"error"} textAlign={"center"} ml={1.5}>
                                    {signupError}
                                </Typography>
                            )}
                            <Grid variant='G16' item container>
                                <Button variant="B9" onClick={handleSubmit}>Resend OTP</Button>
                                <Button variant="B9" onClick={handleOtpSubmit}>
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </BootstrapDialog>
                }
                <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    style={{ minHeight: "100vh" }}
                >
                    <Grid
                        variant='Login'
                        // style={{maxHeight: gridMaxHeight}}  
                        // style={{ minHeight: "100vh" }} 
                        xs={12} sm={8} md={6} lg={4}
                    >

                        <AppBar variant="A2" position="static" color="transparent" elevation={0}>
                            <Toolbar variant="dense" >
                                <Typography >
                                    <Media src={Logo} alt="" />
                                </Typography>
                            </Toolbar>
                        </AppBar>

                        <Paper
                            variant={"outlined"}
                            sx={{
                                border: "1px solid #DBDFEA",
                                width: "30rem",
                                padding: "2rem",
                            }}
                        >
                            <Grid container spacing={2}>
                                
                                <Grid item xs={11} >
                                    <Typography
                                        variant={"h5"}
                                        fontSize={"1.3rem"}
                                        textAlign={"center"}
                                        fontWeight={"bold"}
                                        fontFamily={"DM Sans"}
                                        ml={2}
                                    >
                                        {"Sign-Up"}
                                    </Typography>
                                    <Typography
                                        variant={"subtitle2"}
                                        textAlign={"center"}
                                        color={"#526484"}
                                        mt={1}
                                        ml={2}
                                    >
                                        Create new Account as a {selectedCircle}
                                    </Typography>
                                    {signupError && (
                                        <Typography variant={"body2"} color={"error"} textAlign={"center"} mt={1} style={{ fontSize: '17px', fontWeight: 'bold' }} mb={-2}>
                                            {signupError}
                                        </Typography>
                                    )}
                                </Grid>
                                <Grid xs={1}  display='flex' justifyContent='flex-end' alignItems='flex-start' >
                                <IoArrowBackCircleOutline  onClick={() => {  setSelectedCircle(null) }} 
                                style={{cursor:'pointer',fontSize:"28px"}}/>

                                </Grid>
                                <Grid display="flex" justifyContent="space-around" xs={12} mt={4}>
                                    <Grid item xs={5.5} container spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography
                                                variant={"subtitle1"}
                                                fontWeight={"bold"}
                                                color={"#344357"}
                                            >
                                                {"User Name"}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <CustomTextfield
                                                size="small"
                                                placeholder="Enter user name"
                                                fullWidth
                                                variant="outlined"
                                                value={username}
                                                error={usernameError}
                                                helperText={usernameError && "User Name is required."}
                                                onChange={handleusernameChange}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={5.5} container spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography
                                                variant={"subtitle1"}
                                                fontWeight={"bold"}
                                                color={"#344357"}
                                            >
                                                {"Name"}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <CustomTextfield
                                                size="small"
                                                placeholder="Enter name"
                                                fullWidth
                                                variant="outlined"
                                                value={name}
                                                error={nameError}
                                                helperText={nameError && "Name is required."}
                                                onChange={handleNameChange}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid display="flex" justifyContent="space-around" xs={12} mt={4}>
                                    <Grid item xs={5.5} container spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography
                                                variant={"subtitle1"}
                                                fontWeight={"bold"}
                                                color={"#344357"}
                                            >
                                                {"Email I'd"}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <CustomTextfield
                                                size="small"
                                                placeholder="Enter email i'd"
                                                fullWidth
                                                variant="outlined"
                                                value={email}
                                                error={emailError}
                                                helperText={emailError && "Email is required."}
                                                onChange={handleEmailChange}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={5.5} container spacing={1} sx={{ height: "40px" }}>
                                        <Grid item xs={12}>
                                            <Typography
                                                variant={"subtitle1"}
                                                fontWeight={"bold"}
                                                color={"#344357"}
                                            >
                                                {"Password"}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <CustomTextfield
                                                size={"small"}
                                                id={"user-password-input"}
                                                placeholder={"Enter password"}
                                                fullWidth
                                                variant={"outlined"}
                                                type={showPassword ? "text" : "password"}
                                                onChange={handlePasswordChange}
                                                value={password}

                                                error={passwordErrors.length}
                                                helperText={passwordErrors.length && "Password must be at least 8 characters long."}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton onClick={handleToggleShowPassword}>
                                                                {showPassword ? (
                                                                    <VisibilityOff fontSize={"small"} sx={{ height: "100%" }} />
                                                                ) : (
                                                                    <Visibility fontSize={"small"} sx={{ height: "100%" }} />
                                                                )}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </Grid>
                                        <Typography
                                            variant='P23'
                                            ml={1}
                                            mt={0.1}
                                        >
                                            {passwordErrors.uppercase && "capital letter Missing."}
                                            {passwordErrors.specialChar && "special character Missing."}
                                            {passwordErrors.number && "number Missing."}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid display="flex" justifyContent="space-around" xs={12} mt={4}>
                                    <Grid item xs={11.5} container spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography
                                                variant={"subtitle1"}
                                                fontWeight={"bold"}
                                                color={"#344357"}
                                            >
                                                {"Mobile Number"}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <CustomTextfield
                                                size="small"
                                                placeholder="Enter mobile number"
                                                error={phoneError}
                                                helperText={phoneError && "Mobile Number is required."}
                                                fullWidth
                                                variant="outlined"
                                                value={phone}
                                                onChange={handlephoneChange}
                                            />
                                        </Grid>
                                    </Grid>


                                </Grid>

                                {/* <Grid display="flex" justifyContent="space-around" xs={12} mt={4}>
                            <Grid item xs={11.5} container spacing={1}>
                                <Grid item xs={12}>
                                    <Typography
                                        variant={"subtitle1"}
                                        fontWeight={"bold"}
                                        color={"#344357"}
                                    >
                                        {"Type of userType"}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Select
                                        displayEmpty
                                        size="small"
                                        placeholder="Enter name"
                                        fullWidth
                                        variant="outlined"
                                        sx={{ borderColor: '#dbdfea' }}
                                        value={userType}
                                        onChange={handleuserType}
                                    >
                                        <MenuItem value="" disabled>
                                            Select userType type
                                        </MenuItem>
                                        <MenuItem value="educator">Educator</MenuItem>
                                        <MenuItem value="blogger">Blogger</MenuItem>
                                        <MenuItem value="coach">Coach</MenuItem>
                                        <MenuItem value="author">Author</MenuItem>
                                    </Select>
                                </Grid>
                            </Grid>

                        </Grid> */}

                                {/* {selectedCircle === 'brand' && (
                            <Grid display="flex" xs={12} mt={4}>
                                <Grid item xs={5.5} container spacing={1} ml={1.5}>
                                    <Grid item xs={12}>
                                        <Typography
                                            variant={"subtitle1"}
                                            fontWeight={"bold"}
                                            color={"#344357"}
                                        >
                                            {"Brand Category"}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Select
                                            displayEmpty
                                            size="small"
                                            placeholder="Enter name"
                                            fullWidth
                                            variant="outlined"
                                            sx={{ borderColor: '#dbdfea' }}
                                            value={brand}
                                            onChange={handleBrand}
                                        >
                                            <MenuItem value="" disabled>
                                                Select brand category
                                            </MenuItem>
                                            <MenuItem value="corporate">Corporate </MenuItem>
                                            <MenuItem value="personal">Personal</MenuItem>
                                            <MenuItem value="product">Product</MenuItem>
                                            <MenuItem value="service">Service </MenuItem>
                                        </Select>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )} */}

                                <Grid item xs={12} display='flex' justifyContent="center" mt={1} mr={1}>
                                    <Button
                                        fullWidth
                                        type="submit"
                                        variant="B6"
                                        color="primary"
                                        size="large"

                                        onClick={handleSubmit}
                                    >
                                        Submit
                                    </Button>
                                </Grid>
                                <Grid item xs={12} mt={1}>
                                    <Typography
                                        variant={"subtitle2"}
                                        textAlign={"center"}
                                        color={"#526484"}
                                        style={{ fontSize: "17px" }}
                                    >Already a user. Please
                                        <span style={{ fontWeight: "bold", color: "black", cursor: "pointer" }} onClick={navigateBack}>{" login."}</span>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Dialog open={approveDialogOpen} onClose={() => setApproveDialogOpen(false)} >
                        <DialogTitle >{"Sing-Up success"}</DialogTitle>
                        <DialogContent >{"Your account has been created successfully. Kindly login to access the application and start exploring its features."}</DialogContent>
                        <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
                            <Button onClick={navigateBack} variant="outlined" color="success" sx={{ marginBottom: "7px" }}>
                                Login
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Grid>
            </> :
                // <SelectionPage />
                <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    style={{ minHeight: "100vh" }}
                >
                    <Grid variant='Login' xs={12} sm={8} md={6} lg={4}>

                        <AppBar variant="A2" position="static" color="transparent" elevation={0}>
                            <Toolbar variant="dense" >
                                <Typography >
                                    <Media src={Logo} alt="" />
                                </Typography>
                            </Toolbar>
                        </AppBar>

                        <Paper
                            variant={"outlined"}
                            sx={{
                                border: "1px solid #DBDFEA",
                                width: "30rem",
                                padding: "2rem",
                            }}
                        >
                            <Grid
                                container
                                spacing={2}
                            >
                                <Grid
                                    item
                                    xs={12}
                                >
                                    <Typography
                                        variant={"h5"}
                                        fontSize={"1.3rem"}
                                        textAlign={"center"}
                                        fontWeight={"bold"}
                                        fontFamily={"DM Sans"}
                                    >
                                        Get Started & Join Now !
                                    </Typography>
                                    <Typography
                                        variant={"subtitle2"}
                                        textAlign={"center"}
                                        color={"#526484"}
                                        mt={1}
                                    >
                                        In-Scene Advertising (VPP) Management Platfrom
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    mt={3}
                                    sx={{ display: 'flex', justifyContent: 'space-around' }}
                                >
                                    <Circle
                                        isSelected={circle === 'Creator'}
                                        onClick={() => setCircle('Creator')}
                                    >
                                        <GiFrankensteinCreature />
                                    </Circle>
                                    <Circle
                                        isSelected={circle === 'Brand'}
                                        onClick={() => setCircle('Brand')}
                                    >
                                        <IoPricetagsOutline />
                                    </Circle>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sx={{ display: 'flex', justifyContent: 'space-around' }}
                                >
                                    <Typography variant="C11" mr={0.6}>I'M A CREATOR</Typography>
                                    <Typography variant="C11" mr={0.6}>I'M A BRAND</Typography>
                                </Grid>

                                <Grid
                                    item
                                    xs={12}
                                >
                                    <Button
                                        fullWidth
                                        type="submit"
                                        variant="B6"
                                        color="primary"
                                        size="large"
                                        onClick={() => { setSelectedCircle(circle) }}
                                    >
                                        Continue
                                    </Button>

                                </Grid>
                                <Grid item xs={12} mt={1}>
                                    <Typography
                                        variant={"subtitle2"}
                                        textAlign={"center"}
                                        color={"#526484"}
                                        style={{ fontSize: "17px" }}
                                    >Already a user. Please
                                        <span style={{ fontWeight: "bold", color: "black", cursor: "pointer" }} onClick={navigateBack}>{" login."}</span>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>

                </Grid>
            }
        </>
    );
};

export default SignupPage;
