import CloseIcon from '@mui/icons-material/Close';
import { Card, CardMedia, Chip, Grid } from "@mui/material";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { addProjectId } from '../../redux/projectSlice';
import { useDispatch } from "react-redux";
import defaultThumbnail from '../../assets/NoVideo.png'
import { generateThumbnailURL } from '../../Routes/apiConfig';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));



const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 0.5 }} {...other} fontWeight="bold" style={{ fontFamily: "DM Sans" }}>
            <Typography variant='CHead' ml={2}>{children}</Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 2,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};



const SinglePlayer = (props) => {
    const { openPlayer, projectDetail, onClose, fromPage } = props;
    const navigate = useNavigate();
    const dispatch = useDispatch();


    // const projectIdForNavigate = projectId;


    // const handelNavigate = (id) => {
    //     localStorage.setItem('clickedProject', JSON.stringify(project));
    //     navigate('/VppMain');
    //     dispatch(addProjectId(id))
    // }

    if (!projectDetail) {
        return null;
    }

    const thumbnailURL = generateThumbnailURL(projectDetail.video[0].videoId);


    return (
        <div className='DialogContainer'>
            <BootstrapDialog
                onClose={onClose}
                aria-labelledby="customized-dialog-title"
                open={openPlayer}
                maxWidth="lg"
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={onClose} >
                    {projectDetail?.title}
                </BootstrapDialogTitle>
                <DialogContent dividers >
                    <Grid container spacing={2} >
                        <Grid variant="G12" item xs={4} ml={-2} >
                            <Grid item xs={12} sx={{ borderBottom: "1px solid #dbdfea" }}>
                                <Card sx={{ minWidth: 282, margin: "15px", marginTop: "0px" }}>
                                    <CardMedia
                                        component="img"
                                        height="160"
                                        image={thumbnailURL}
                                        alt="thumb"
                                    />
                                </Card>
                            </Grid>

                            <Grid xs={12} >
                                <Grid item xs={12} sx={{ margin: "15px", marginTop: "0px" }}>
                                    <Grid mt={1}>
                                        <Typography variant='CHead'>Project Title</Typography> - &nbsp;
                                        <Typography variant='GV'>
                                            {projectDetail?.title}
                                        </Typography>
                                    </Grid>
                                    <Grid mt={1}>
                                        <Typography variant='CHead'>Price</Typography> - &nbsp;
                                        <Typography variant='GV'>
                                            {projectDetail?.vppPrice}
                                        </Typography>
                                    </Grid>
                                    <Grid mt={1}>
                                        <Typography variant='CHead'>Genre</Typography> - &nbsp;
                                        {projectDetail?.genres.map((genre, index) => (
                                            <Typography variant='GV' key={index}>
                                                {genre}
                                                {index !== projectDetail.genres.length - 1 && ", "}
                                            </Typography>
                                        ))}
                                    </Grid>
                                    <Grid mt={1}>
                                        <Typography variant='CHead'>Placement</Typography> - &nbsp;
                                        <Typography variant='GV'></Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={8} >
                            <Grid item xs={12} >
                                <Card sx={{ maxWidth: "100%", marginLeft: "10px", display: "flex", justifyContent: "center" }}>
                                    <video
                                        width='100%'
                                        controls
                                        style={{ minWidth: 650, minHeight: 335, maxHeight: 335, background: "black" }}
                                    >
                                        <source
                                            src={projectDetail?.video[0]?.VideoUrl}
                                            type="video/mp4"
                                        />
                                        Your browser does not support the video tag.
                                    </video>
                                </Card>
                                <Grid variant="G13"
                                    item
                                    container
                                    sx={{ height: "40px" }}
                                >
                                    {fromPage === 'brand' && (
                                        <Button variant="B8" sx={{ marginRight: '15px' }}>
                                            Open In Gen AI Embedding
                                        </Button>
                                    )}

                                    <Button variant="B8" onClick={onClose} >Cancel</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                </DialogContent>
            </BootstrapDialog>
        </div>
    );
}

export default SinglePlayer;
