
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import SearchBar from './SearchBar';
import { Chip } from '@mui/material';
import { setShowLoader } from '../../redux/loaderSlice';
import RejectDialog from '../CommonComponet/RejectDialog';
import NoData from '../CommonComponet/NoData';
import { TfiMenuAlt } from 'react-icons/tfi';
import { BsGrid3X2GapFill } from 'react-icons/bs';
import ProjectCard from '../CommonComponet/ProjectCard';
import VppPlayerBrand from './VppPlayerBrand';
import { awsApiCamp, awsApi } from '../../Routes/Server';
import { useSelector } from 'react-redux';
import CustomizedTables from '../DashboardComponet/MuiTabel';
import { useNavigate } from 'react-router-dom';
import { BrandLogo } from '../../ThemeProvider/style';
import Loader from '../CommonComponet/Loader';
import CampaignsCard from '../CommonComponet/CampaignsCard';
import TrackDilogBrand from './TrackDilogBrand';

const DraftBrand = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userData = useSelector((state) => state.user.userLoginData);

    const [anchorEl, setAnchorEl] = useState(null);
    const [searchText, setSearchText] = useState('');
    const [rows, setRows] = useState([]);
    const [lastEvaluatedKey, setLastEvaluatedKey] = useState(undefined);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [openPlayer, setOpenPlayer] = useState(false);
    const [selectedProject, setSelectedProject] = useState(null);
    const [gridView, setGridView] = useState(false);
    const [listView, setListView] = useState(true);
    const [updateMessages, setUpdateMessages] = useState('')
    const [selectedApproveRow, setSelectedApproveRow] = useState(null);
    const [approveDialogOpen, setApproveDialogOpen] = useState(false);
    const [rejectMessage, setRejectMessage] = useState('');
    const [selectedImage, setSelectedImage] = useState(null);
    const [brandDetails, SetBrandDetails] = useState([])
    const [rejectDialogOpen, setRejectDialogOpen] = useState(false);
    const [brandDialogOpen, setBrandDialogOpen] = useState(false);
    const [actionValue, setAactionValue] = useState(1)
    const [trackCamp, setTrackCamp] = useState(false);


    useEffect(() => {
        async function fetchInProgress() {
            dispatch(setShowLoader(true));
            try {
                const res = await awsApiCamp.post("/getcampaign", {
                    pageSize: 10,
                    type: "draft",
                    page: 1,
                    lastEvaluatedKey: lastEvaluatedKey,
                });
                if (res.data.statusCode === 200) {
                    const data = JSON.parse(res.data.body);
                    setRows(data.items);
                    setLastEvaluatedKey(data.lastEvaluatedKey);
                    if (selectedProject && selectedProject.id) {
                        const updatedProject = data.items.find(item => item.id === selectedProject.id);
                        if (updatedProject) {
                            setSelectedProject(updatedProject);
                        }
                    }
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                dispatch(setShowLoader(false));
            }
        }
        fetchInProgress();
    }, [updateMessages]);

    const fetchImages = async () => {
        try {
            dispatch(setShowLoader(true));
            const payload = {
                assetAction: 'get-asset',
                brandId: userData.sub,
            };
            const response = await awsApi.post('/postproject', payload, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            const responseBody = JSON.parse(response.data.body);
            if (Array.isArray(responseBody)) {
                SetBrandDetails(responseBody);
            } else {
                console.error('Invalid response format. Expected an array.');
            }
            dispatch(setShowLoader(false));

        } catch (error) {
            console.error('Error fetching images:', error);
            dispatch(setShowLoader(false));
        }
    };

    useEffect(() => {
        fetchImages();
    }, []);


    const generateRandomText = () => {
        const randomText = Math.random().toString(36).substring(7);
        return randomText;
    };

    const handleUpdateMessages = () => {
        const randomText = generateRandomText();
        setUpdateMessages(randomText);
    };

    const handleSetRows = (updatedRows) => {
        setRows(updatedRows);
    };

    const handleOpenDialog = (rowData) => {
        setSelectedRow(rowData);
        setOpenDialog(true);
    };
    const handelOpenPlayer = (project) => {
        setSelectedProject(project);
        setOpenPlayer(true);
        setAactionValue(1)
    };

    const handelBarndedPlayer = (project) => {
        setSelectedProject(project);
        setOpenPlayer(true);
        setAactionValue(3)
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handelTrack = (project) => {
        setSelectedProject(project);
        setTrackCamp(true);

    };

    const handleDownload = (id) => {
        // console.log("Download project with ID:", id);
    };

    rows.forEach((row, index) => {
        row._index = index + 1;
    });

    const getValue = (row) => row._index;

    const columns = [
        {
            field: 'index',
            headerName: 'S.No',
            width: 60,
            valueGetter: (params) => params.row._index,
        },
        {
            field: "projectTitle",
            headerName: "Title",
            width: 300,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {/* <Img src={params.row.imgURL} alt="Project Title" /> */}
                    <Box sx={{ fontWeight: 'bold', color: 'black', cursor: "pointer" }} onClick={() => handelOpenPlayer(params.row)}>{params.row.clipSet[0].projectTitle}</Box>
                </Box>
            ),
        },
        {
            field: "Campaign Id", headerName: "Campaign Id", width: 150,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ fontWeight: 'normal', color: 'black' }}>{params.row.clipSet[0].VppFreamNo}</Box>
                </Box>
            ),
        },
        {
            field: "brandCat", headerName: "Brand Catagory", width: 150,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ fontWeight: 'normal', color: 'black' }}>{params.row.clipSet[0].VppFreamNo}</Box>
                </Box>
            ),
        }, {
            field: "currentTime", headerName: "Date Of Creation", width: 150,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ fontWeight: 'normal', color: 'black' }}>{params.row.clipSet[0].currentTime}</Box>
                </Box>
            ),
        },
        {
            field: "value", headerName: "Brand Creative", width: 150,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ fontWeight: 'normal', color: 'black' }}>{params.row.clipSet[0].VppFreamNo}</Box>
                </Box>
            ),
        },
        {
            field: "catagory",
            headerName: "Media Type",
            width: 170,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ fontWeight: 'normal', color: 'black' }}>{params.row.clipSet[0].catagory}</Box>
                </Box>
            ),
        },
        {
            field: "videoId", headerName: "Branded Sense", width: 150,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ fontWeight: 'normal', color: 'black' }}>{params.row.clipSet[0].VppFreamNo}</Box>
                </Box>
            ),
        },
        {
            field: "status",
            headerName: "Status",
            width: 200,
            renderCell: (params) => {
                const brandStatus = params.row.brandStatus;
                const creatorStatus = params.row.creatorStatus;

                let label = params.row.status.charAt(0).toUpperCase() + params.row.status.slice(1);
                let color = "secondary";

                if (brandStatus && creatorStatus) {
                    label = label.charAt(0).toUpperCase() + label.slice(1);
                    color = "success";
                } else if (!brandStatus && creatorStatus) {
                    label = "Your Approval Pending";
                    color = "warning";
                } else if (brandStatus && !creatorStatus) {
                    label = "Producer Approval Pending";
                    color = "warning";
                }

                return (
                    <Chip
                        label={label}
                        color={color}
                        variant="outlined"
                        size="small"
                        sx={{ width: '180px', height: '30px' }}
                    />
                );
            },
        },
        {
            field: "comment", headerName: "Comment", width: 300,
        },
        {
            field: 'id',
            headerName: 'Action',
            description: 'This column has a value getter and is not sortable.',
            sortable: false,
            width: 130,

        },
        // {
        //     field: "track", headerName: "Track", width: 300,
        // },
    ];

    const rowsPerPageOptions = [5, 10, 25];

    const handleSearchChange = (event) => {
        setSearchText(event.target.value)
    };

    console.log(brandDetails)


    const filteredData = rows
        .filter((item) => {
            const searchValue = searchText.toLowerCase();
            const rowData = [item.clipSet[0].VppId, item.clipSet[0].projectTitle, item.clipSet[0].category, item.clipSet[0].brandCat];
            return rowData.some((cell) => String(cell).toLowerCase().includes(searchValue));
        })
        .sort((a, b) => {
            const timestampA = parseInt(a.currentTime, 10);
            const timestampB = parseInt(b.currentTime, 10);
            return timestampB - timestampA;
        });

    console.log(filteredData)

    const decideColor = (row) => {
        const brandStatus = row.brandStatus;
        const creatorStatus = row.creatorStatus;
        let label = row.status.charAt(0).toUpperCase() + row.status.slice(1);
        let color = "secondary";
        if (brandStatus && creatorStatus) {
            label = label.charAt(0).toUpperCase() + label.slice(1);
            color = "success";
        } else if (!brandStatus && !creatorStatus) {
            label = "Waiting for your approval";
            color = "warning";
        } else if (brandStatus && !creatorStatus) {
            label = "Waiting for Producer's approval";
            color = "warning";
        }
        return { label, color };
    }

    const handelLoadData = () => {
        console.log('test 1')
        if (brandDialogOpen && selectedApproveRow && selectedApproveRow.brandLogo) {
            const selectedImageIndex = brandDetails.findIndex(
                (image) => image.Key === selectedApproveRow.brandLogo.key
            );
            if (selectedImageIndex !== -1) {
                console.log('test 5')
                setSelectedImage(brandDetails[selectedImageIndex]);
                console.log(brandDetails[selectedImageIndex])
            }
        }
    }

    useEffect(() => {
        handelLoadData();
    }, [brandDialogOpen, selectedApproveRow, brandDetails]);


    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleApproveClick = (row) => {
        setSelectedApproveRow(row);
        handleMenuClose();
        setApproveDialogOpen(true);
    };
    const handleBrandClick = (row) => {
        setSelectedApproveRow(row);
        setBrandDialogOpen(true);
    }

    const handleRejectDialog = (row) => {
        handleMenuClose();
        setSelectedApproveRow(row);
        setRejectMessage('');
        setRejectDialogOpen(true);
    };

    const handleUpdate = async (row) => {
        if (selectedImage !== null) {
            dispatch(setShowLoader(true));
            let updatedImage = {
                key: selectedImage.Key,
                value: selectedImage.Location,
                label: selectedImage.assetName
            }
            try {
                let updatedData = {
                    ...row,
                    brandLogo: updatedImage
                };

                const res = await awsApiCamp.post('/postcampaign', {
                    ...updatedData,
                });

                if (res.data.statusCode === 200) {
                    handleUpdateMessages();
                    setBrandDialogOpen(false)
                } else {
                    console.log('error')
                }
            } catch (error) {
                console.error('Error rejecting data:', error);
            } finally {
                dispatch(setShowLoader(false));
            }
        }
    }


    const handleApprove = async (row) => {
        dispatch(setShowLoader(true));
        try {
            const updatedClipSet = row.clipSet.map(clip => {
                return {
                    ...clip,
                };
            });
            const updatedRowData = {
                ...row,
                brandStatus: true,
                clipSet: updatedClipSet,
                status: "pending",
                type: 'in_progress'
            };

            const res = await awsApiCamp.post('/postcampaign', {
                ...updatedRowData,
            });


            if (res.data.statusCode === 200) {
                handleUpdateMessages()
                setApproveDialogOpen(false);
            }
        } catch (error) {
            console.error('Error approving data:', error);
        } finally {
            dispatch(setShowLoader(false));
        }
    };

    const handleReject = async (row) => {
        dispatch(setShowLoader(true));
        try {
            const updatedClipSet = row.clipSet.map(clip => {
                return {
                    ...clip,
                };
            });

            const updatedRowData = {
                ...row,
                brandStatus: false,
                clipSet: updatedClipSet,
                status: "reject",
                rejectBy: "Brand",
                type: "reject",
                rejectMassage: rejectMessage
            };

            const res = await awsApiCamp.post('/postcampaign', {
                ...updatedRowData,
            });

            if (res.data.statusCode === 200) {
                handleUpdateMessages()
                setRejectDialogOpen(false);
            }
        } catch (error) {
            console.error('Error rejecting data:', error);
        } finally {
            dispatch(setShowLoader(false));
        }
    };

    const handleComment = (row) => {
        const id = row.id;
        navigate(`/MessageBrand?id=${id}`);
    };


    return (

        <Grid xs={12}>
            <Grid xs={11.9} mt={1} display='flex' justifyContent='space-between' alignItems='center'>
                <Grid xs={2}>
                    <SearchBar onChange={handleSearchChange} />
                </Grid>
                <Grid xs={0.45} >
                    {listView === true ? (
                        <Button
                            variant="B5"
                            onClick={() => {
                                setGridView(true);
                                setListView(false);
                            }}
                        >
                            <BsGrid3X2GapFill />
                        </Button>
                    ) : (
                        <Button
                            variant="B5"
                            onClick={() => {
                                setGridView(false);
                                setListView(true);
                            }}
                        >
                            <TfiMenuAlt />
                        </Button>
                    )}
                </Grid>
            </Grid>

            {gridView && (
                <Grid xs={12} mt={3}>
                    <Grid xs={12} variant='G3'>
                        {filteredData.length === 0 ? (
                            <NoData />
                        ) : (
                            <Grid container item xs={12} spacing={3} style={{ margin: '0rem 1rem' }}>
                                {filteredData.map((project) => (
                                    <CampaignsCard
                                        key={project.projectId}
                                        contentType={project.clipSet[0].category}
                                        projectTitle={project.clipSet[0].projectTitle}
                                        campaignId={project.clipSet[0].VppName}
                                        brandCatagory={project.clipSet[0].brandCat}
                                        dateOfCreation={project.currentTime}
                                        brandLogo={project.brandLogo?.value}
                                        comment={project.clipSet[0]}
                                        handleBrandClick={() => handleBrandClick(project)}
                                        decideColor={() => decideColor(project)}
                                        handleComment={() => handleComment(project)}
                                        handelBarndedPlayer={() => handelBarndedPlayer(project)}
                                        handleApproveClick={()=>handleApproveClick(project)}
                                        handleRejectDialog={()=>handleRejectDialog(project)}
                                        price={project.clipSet[0].price}
                                        vppFreamNo={project.clipSet[0].VppFreamNo}
                                        thumbnail={project.clipSet[0].thumbnail}
                                        projectId={project.id}
                                        label={project.status.label}
                                        color={project.status.color}
                                        project={project}
                                        loger={'brand'}
                                        handelOpenPlayer={() => handelOpenPlayer(project)}
                                        viId={project.videoId}
                                        cardType={'brand'}
                                    />
                                ))
                                }
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            )}
            {listView && (
                <Grid xs={12} mt={3} mb={2}>
                    {filteredData.length === 0 ? (
                        <NoData />
                    ) : (
                        <>
                            <CustomizedTables
                                filterRow={filteredData}
                                columns={columns}
                                rowAttributes={[
                                    { name: "projectTitle", type: 'regular', child: "clipSet", newStyle: "pointer", fontWeight: "bold", action: handelOpenPlayer, TextDecoder: "underline", },
                                    { name: "VppName", type: 'regular', child: "clipSet", action: handelOpenPlayer },
                                    { name: "brandCat", type: 'chip', child: "clipSet", action: handelOpenPlayer },
                                    { name: "currentTime", type: 'regular', action: handelOpenPlayer, valueType: 'date' },
                                    { name: "value", type: 'image', objectChild: "brandLogo", action: handleBrandClick, loger: 'brand' },
                                    { name: "category", type: 'regular', child: "clipSet", action: handelOpenPlayer },
                                    { name: "videoId", type: 'thumbnail', action: handelBarndedPlayer },
                                    { name: "status", type: 'newChip', child: "clipSet", typeColorCallback: decideColor ,action: handelTrack},
                                    { name: "comment", type: 'regular', child: "clipSet", objectArray: "commentsBrand", newStyle: "pointer", color: "#0f87ff", TextDecoder: "underline", action: handleComment },
                                    {
                                        name: "id",
                                        type: 'quikAction',
                                        child: "clipSet",
                                        actions: [
                                            { label: 'Approve', buttonType: 'Approve', buttonColor: "#00ff0070", action: handleApproveClick, bType: "darft", newSet: 'branded' },
                                            { label: 'Reject', buttonType: 'Reject', buttonColor: "#ff000085", action: handleRejectDialog, bType: "darft", newSet: 'branded' },
                                        ],
                                    },
                                    // { name: "status", type: 'track', action: handelTrack },
                                ]}
                            />
                        </>
                    )}
                </Grid>
            )}
            <VppPlayerBrand
                setOpenPlayer={setOpenPlayer}
                openPlayer={openPlayer}
                videoData={filteredData}
                selectedProject={selectedProject}
                handleApproveClick={handleApproveClick}
                handleRejectDialog={handleRejectDialog}
                type={"brand"}
                pageType={'draft'}
                disableState={'draft'}
                handleComment={handleComment}
                handleUpdateMessages={handleUpdateMessages}
                actionValue={actionValue}
            />

            <Dialog open={approveDialogOpen} onClose={() => setApproveDialogOpen(false)} >
                <DialogTitle >{"Approve campaign"}</DialogTitle>
                <DialogContent >{"Are you sure you want to approve once approved cannot be cancelled"}</DialogContent>
                <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
                    <Button onClick={() => handleApprove(selectedApproveRow)} variant="outlined" color="success" sx={{ marginBottom: "7px" }}>
                        Approve
                    </Button>
                    <Button onClick={() => setApproveDialogOpen(false)} variant="outlined" color="error" sx={{ marginBottom: "7px" }}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <RejectDialog
                open={rejectDialogOpen}
                onClose={() => setRejectDialogOpen(false)}
                rejectMessage={rejectMessage}
                setRejectMessage={setRejectMessage}
                onRejectSubmit={() => handleReject(selectedApproveRow)}
            />
            <TrackDilogBrand
                setTrackCamp={setTrackCamp}
                trackCamp={trackCamp}
                selectedProject={selectedProject}
                type={"producer"}
                track={'draft'}
            />
            <Dialog
                fullWidth={true}
                maxWidth='lg'
                open={brandDialogOpen} onClose={() => setBrandDialogOpen(false)}
            >
                <DialogTitle style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant='h6'>Brand Logo</Typography>
                </DialogTitle>
                <DialogContent sx={{ display: "flex", marginTop: '-20px' }}>
                    {brandDetails &&
                        brandDetails.length > 0 &&
                        brandDetails.map((image, index) => (
                            <Grid item xs={2} key={index} variant='G24' sx={{
                                marginLeft: '10px',
                                border: "1px solid #c8c8c8",
                                backgroundColor: selectedImage === image ? '#c8c8c8' : 'white',
                                cursor: 'pointer'
                            }}
                                onClick={() => { setSelectedImage(image) }}>
                                {image.mimeType === 'application/json' ? (
                                    <div>JSON Thumbnail</div>
                                ) : (
                                    <img src={image.Location} alt={`Image ${index}`} style={{
                                        width: '90%',
                                        height: '150px',
                                        marginTop: '10px',
                                        borderRadius: "8px",
                                    }} />
                                )}
                                <Typography variant='CHead' style={{ marginTop: '4px', marginBottom: '4px' }}>{image.assetName.split('.')[0]}</Typography>
                            </Grid>
                        ))}
                </DialogContent>
                <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
                    <Button onClick={() => handleUpdate(selectedApproveRow)} variant="outlined" color="success" sx={{ marginBottom: "7px" }}>
                        Update
                    </Button>
                    <Button onClick={() => setBrandDialogOpen(false)} variant="outlined" color="error" sx={{ marginBottom: "7px" }}>
                        Close
                    </Button>
                </DialogActions>
                <Loader />
            </Dialog>
            <Loader />
        </Grid>

    );
};

export default DraftBrand;
