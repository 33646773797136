import React, { useState } from 'react';
import { Grid, Button, Typography, IconButton } from '@mui/material';
import ProjectCard from '../CommonComponet/ProjectCard';
import { TfiMenuAlt } from 'react-icons/tfi';
import { BsGrid3X2GapFill } from 'react-icons/bs';
import SearchFilter from './SearchFilter';
import SearchBar from '../CampaignsComponet/SearchBar';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { addProjectId } from '../../redux/projectSlice';
import { useDispatch } from "react-redux";
import NoData from '../CommonComponet/NoData';
import { setShowLoader } from '../../redux/loaderSlice';
import Loader from '../CommonComponet/Loader';
import Charts from '../VppComponet/Charts';
import CustomizedTables from '../DashboardComponet/MuiTabel';
import CustomTextfield from '../CommonComponet/CustomTextfield';
import { BsSearch } from 'react-icons/bs'


const Container = ({ data, columns, tabledata, setFilters, filters, filteredDataCount, totalVpoTotal, handelNavigateTabel }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [gridView, setGridView] = useState(true);
    const [listView, setListView] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedType, setSelectedType] = useState('all');
    const [searchQuery, setSearchQuery] = useState('');
    const [searchTextForApi, setSearchTextForApi] = useState('');


    if (!data) {
        return <div>Loading...</div>;
    }

    const videosPerPage = 10;
    const indexOfLastVideo = currentPage * videosPerPage;
    const indexOfFirstVideo = indexOfLastVideo - videosPerPage;
    const currentVideos = data.slice(indexOfFirstVideo, indexOfLastVideo);

    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(data.length / videosPerPage); i++) {
        pageNumbers.push(i);
    }

    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };


    const handelNavigate = (project) => {
        dispatch(setShowLoader(true));
        localStorage.removeItem('clickedProject');
        localStorage.setItem('clickedProject', JSON.stringify(project));
        navigate('/VppMainBrand');
        dispatch(addProjectId(project.projectId));
        dispatch(setShowLoader(false));
    };

    let filteredData = data;

    const projectTitles = filteredData.map(project => project.title);

    const handleSearchChange = (event) => {
        const searchValue = event.target.value
        setSearchTextForApi(searchValue);
    };

    const handleSearchClick = () => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            searchText: searchTextForApi.trim(),
        }));
    };

    const handleFilterChange = (filterName, filterValue) => {
        setFilters(prevFilters => ({
            ...prevFilters,
            [filterName]: filterValue
        }));
    };

    const getName = (type) => {
        if (type === 'movie') {
            return 'Movies';
        } else if (type === 'tv-show') {
            return 'TV Show';
        } else if (type === 'web-series') {
            return 'Web Series';
        } else if (type === 'music-video') {
            return 'Music Video';
        } else if (type === 'other') {
            return 'Other';
        }
        return '';
    };

    const ShowView = ({ type }) => {
        const filteredRows = filteredData.filter(
            (row) => row.contentType?.toUpperCase() === type.toUpperCase()
        );

        const filterTabelData = tabledata.filter(
            (row) => row.contentType?.toUpperCase() === type.toUpperCase()
        );

        const shouldDisplayPagination = filteredRows.length > 5;

        return (

            <Grid xs={12}>
                {gridView && (
                    <Grid xs={11.76} ml={2} display='flex' flexDirection='column' variant='G3' mt={1} mb={1}>
                        <Grid xs={12} sx={{ borderBottom: "1px solid #dbdfea", minHeight: "55px" }} display="flex" alignItems='center'>
                            <Typography ml={2} variant="P15">
                                {getName(type)}
                            </Typography>
                        </Grid>
                        <Grid xs={11} variant='G3'>
                            {filteredRows.length === 0 ? (
                                <NoData />
                            ) : (
                                <Grid container item xs={12} style={{ margin: '0rem 1rem' }}>
                                    {/* {gridView && */}
                                    {filteredRows
                                        .sort((a, b) => b.timestamp - a.timestamp)
                                        .map((project) => (
                                            <ProjectCard
                                                viId={project.video[0]?.videoId}
                                                key={project.projectId}
                                                projectTitle={project.title}
                                                season={project.season}
                                                episodes={project.episode}
                                                genres={project.genres.join(', ')}
                                                pValue={project.pValue}
                                                NoOfVop={project.totalVpo}
                                                totalMarketClip={project.totalMarketClip}
                                                thumbnail={project.imgURL}
                                                projectId={project.id}
                                                pageType={'market'}
                                                handelonClick={() => handelNavigate(project)}
                                                contentType={project.contentType}
                                            />
                                        ))
                                    }
                                </Grid>
                            )}

                            {shouldDisplayPagination && (
                                <Grid xs={11.7} ml={4}>
                                    <nav aria-label="Page navigation example">
                                        <ul className="pagination">
                                            <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                                <a
                                                    className="page-link"
                                                    href="#"
                                                    onClick={() => handlePageClick(currentPage - 1)}
                                                >
                                                    Previous
                                                </a>
                                            </li>
                                            {pageNumbers.map((number) => (
                                                <li
                                                    className={`page-item ${currentPage === number ? 'active' : ''}`}
                                                    key={number}
                                                >
                                                    <a
                                                        className="page-link"
                                                        href="#"
                                                        onClick={() => handlePageClick(number)}
                                                    >
                                                        {number}
                                                    </a>
                                                </li>
                                            ))}
                                            <li
                                                className={`page-item ${currentPage === pageNumbers.length ? 'disabled' : ''}`}
                                            >
                                                <a
                                                    className="page-link"
                                                    href="#"
                                                    onClick={() => handlePageClick(currentPage + 1)}
                                                >
                                                    Next
                                                </a>
                                            </li>
                                        </ul>
                                    </nav>
                                </Grid>
                            )}

                        </Grid>

                    </Grid>
                )}


                {listView && (
                    <Grid container item xs={11.55} ml={2} mt={3} mb={2}  >
                        <Grid xs={11.55}>
                            <Typography variant="P15" mb={2}>
                                {getName(type)}
                            </Typography>
                        </Grid>
                        {filteredRows.length === 0 ? (
                            <Grid xs={12} variant='G3' mt={2}>
                                <NoData />
                            </Grid>
                        ) : (
                            <Grid xs={12} >
                                <Grid xs={12} variant='G3'>
                                    {/* <DataGrid
                                        rows={filterTabelData}
                                        columns={columns}
                                        disableRowSelectionOnClick
                                        sx={{ color: '#526484', marginTop: '15px' }}
                                        pageSizeOptions={[5, 10, 100]}
                                        disableColumnMenu
                                    /> */}
                                    <div style={{ color: '#526484', marginTop: '15px' }}>
                                        <CustomizedTables
                                            filterRow={filterTabelData}
                                            columns={columns}
                                            rowAttributes={[
                                                { name: "title", type: 'regular', newStyle: "pointer", fontWeight: "bold", action: handelNavigateTabel },
                                                { name: "contentType", type: 'regular', },
                                                { name: "genres", type: 'regular', dataType: "arraySet" },
                                                { name: "totalVpo", type: 'regular' },
                                                { name: "releaseType", type: 'regular' },
                                            ]}
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        )}

                    </Grid>
                )}


            </Grid>
        );

    };

    let windowHeight;

    if (window.innerWidth < 1150) {
        windowHeight = "98vh";
    } else {
        windowHeight = window.innerHeight - 290;
    }

    return (
        <Grid variant="G1" container>

            <Grid xs={12} display='flex' justifyContent='center' alignItems='center' flexDirection='column'>
                <Grid xs={12} display='flex' container>
                    <Grid xs={3.3} display='flex' flexDirection='column'>
                        <Grid xs={12} mb={2}>
                            <Charts totalVpoTotal={totalVpoTotal} filteredDataCount={filteredDataCount} />
                        </Grid>
                        <Grid xs={12} ml={2} container >
                            <Grid xs={9.5} mt={-1}>
                                <Grid xs={12} display='flex'>
                                    <CustomTextfield
                                        size="small"
                                        id="user-email-input"
                                        placeholder="Search Here"
                                        onChange={handleSearchChange}
                                        fullWidth
                                        style={{ border: 'none' }}
                                    />
                                    <IconButton variant='IB1' onClick={handleSearchClick} style={{ marginTop: "-0.2px" }}><BsSearch /></IconButton>
                                </Grid>
                            </Grid>
                            <Grid xs={2} display="flex" justifyContent="flex-end" mt={-1} ml={2}>
                                {listView === true ? (
                                    <Button
                                        variant="B5"
                                        onClick={() => {
                                            setGridView(true);
                                            setListView(false);
                                        }}
                                    >
                                        <BsGrid3X2GapFill />
                                    </Button>
                                ) : (
                                    <Button
                                        variant="B5"
                                        onClick={() => {
                                            setGridView(false);
                                            setListView(true);
                                        }}
                                    >
                                        <TfiMenuAlt />
                                    </Button>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid xs={8.6} >
                        <SearchFilter
                            projectTitles={projectTitles}
                            setSelectedCountry={value => handleFilterChange('country', value)}
                            filters={filters}
                            setFilters={setFilters}
                            setSelectedProjectType={value => handleFilterChange('projectType', value)}
                            setSelectedGenre={value => handleFilterChange('genre', value)}
                            setSelectedBrandCategory={value => handleFilterChange('brandCategory', value)}
                            setSelectedSeason={value => handleFilterChange('season', value)}
                            setSelectedEpisode={value => handleFilterChange('episode', value)}
                        />
                    </Grid>
                </Grid>
            </Grid>



            <Grid container item xs={12} style={{ maxHeight: windowHeight, overflowY: 'scroll' }} mt={1} className='newContainermax'>
                {(selectedType === 'music-video' || selectedType === 'all') && (
                    <ShowView type="music-video" handelNavigate={handelNavigate} />
                )}
                {(selectedType === 'web-series' || selectedType === 'all') && (
                    <ShowView type="web-series" handelNavigate={handelNavigate} />
                )}
                {(selectedType === 'movie' || selectedType === 'all') && (
                    <ShowView type="movie" handelNavigate={handelNavigate} />
                )}
                {(selectedType === 'tv-show' || selectedType === 'all') && (
                    <ShowView type="tv-show" handelNavigate={handelNavigate} />
                )}


                {(selectedType === 'other' || selectedType === 'all') && (
                    <ShowView type="other" handelNavigate={handelNavigate} />
                )}
            </Grid>
            <Loader />
        </Grid>
    );
};

export default Container;

