import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  customFilteredData: [],
};

const videoSlice = createSlice({
  name: 'video',
  initialState,
  reducers: {
    setCustomFilteredData: (state, action) => {
      state.customFilteredData = action.payload;
    },
  },
});

export const { setCustomFilteredData } = videoSlice.actions;
export default videoSlice.reducer;
