
import React, { useState, useRef, useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import Details from './Details';
import axios from 'axios';
import CommentProducer from './CommentProducer';
import CommentBrand from './CommentBrand';
import { setShowLoader } from '../../redux/loaderSlice';
import { useDispatch } from "react-redux";
import { awsApiCamp } from '../../Routes/Server';
import { useSelector } from "react-redux";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate, useLocation } from 'react-router-dom';

const Container = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const vppById = useSelector(state => state.vpp.vppId);
    const [vppData, setVppData] = useState([]);
    const [selectedVpp, setSelectedVpp] = useState(null);
    const [lastEvaluatedKey, setLastEvaluatedKey] = useState(undefined);
    const [newMessage, setNewMessage] = useState('');
    const [updateMessages, setUpdateMessages] = useState('')
    const [updateData, setUpdateData] = useState('')
    const [newMessageB, setNewMessageB] = useState('');
    const [expanded, setExpanded] = useState(null);

    const fetchData = async () => {
        try {
            dispatch(setShowLoader(true));
            const res = await awsApiCamp.post("/getcampaign", {
                pageSize: 10,
                // type: "all",
                // page: 1,
                lastEvaluatedKey: lastEvaluatedKey,
            });
            if (res.data.statusCode === 200) {
                const data = JSON.parse(res.data.body);
                const newData = data.items
                setLastEvaluatedKey(data.lastEvaluatedKey);
                console.log('API Response:', data);
                console.log(newData)
                if (selectedVpp && selectedVpp.id) {
                    const updatedProject = newData.find(item => item.id === selectedVpp.id);
                    if (newData) {
                        setSelectedVpp(updatedProject);
                    }
                }
                const vppDataWithComment = newData.filter((vpp) => {
                    return (
                        vpp.clipSet &&
                        Array.isArray(vpp.clipSet) &&
                        vpp.clipSet.some((clip) => {
                            // Check if there are comments in either commentsProducer or commentsBrand
                            return (
                                (clip.commentsProducer && clip.commentsProducer.length > 0) ||
                                (clip.commentsBrand && clip.commentsBrand.length > 0)
                            );
                        })
                    );
                });
                console.log('Filtered Data:', vppDataWithComment);
                setVppData(vppDataWithComment);
                console.log(vppDataWithComment)
                if (selectedVpp && selectedVpp.id) {
                    const updatedProject = vppDataWithComment.find(item => item.id === selectedVpp.id);
                    if (vppDataWithComment) {
                        setSelectedVpp(updatedProject);
                    }
                }else if (!selectedVpp && vppDataWithComment.length > 0) {
                    setSelectedVpp(vppDataWithComment[0]);
                }
            }
            dispatch(setShowLoader(false));
        } catch (error) {
            console.error('Error fetching data:', error);
            console.error('Error stack trace:', error.stack);
            dispatch(setShowLoader(false));
        }
    };

    const generateRandomText = () => {
        const randomText = Math.random().toString(36).substring(7);
        return randomText;
    };

    const handleUpdateMessages = () => {
        console.log('handleUpdateMessages called');
        const randomText = generateRandomText();
        setUpdateMessages(randomText);
    };

    useEffect(() => {
        console.log(vppById);
        if (!vppById && vppData.length === 0) {
            fetchData();
        } else if (vppById) {
            const selectedVppById = vppData.find((vpp) => vpp.clipSet[0].VppId === vppById);
            if (selectedVppById) {
                setSelectedVpp(selectedVppById);
            }
        }
    }, [vppById, vppData, updateMessages]);

    useEffect(() => {
        fetchData();
    }, [updateMessages])


    const handleVppClick = (vpp) => {
        setSelectedVpp(vpp);
        navigate(`/MessageCreative?id=${vpp.id}`);
    };

    const handleUpdateSelectedVpp = (updatedVpp) => {
        setSelectedVpp(updatedVpp);
    };

    useEffect(() => {
        fetchData();
    }, [updateMessages]);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : null);
    };

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const idFromQuery = queryParams.get('id');
        if (!idFromQuery && vppData.length > 0) {
            const firstVppId = vppData[0].id;
            navigate(`/MessageCreative?id=${firstVppId}`);
        }

        if (idFromQuery && vppData.length > 0) {
            const vppWithId = vppData.find((vpp) => vpp.id === idFromQuery);
            if (vppWithId) {
                setSelectedVpp(vppWithId);
            } else {
                console.log('no id present in query');
            }
        } else {
            if (!selectedVpp && vppData.length > 0) {
                setSelectedVpp(vppData[0]);
            }
        }
    }, [location.search, vppData]);

    console.log('container', selectedVpp)

    let windowHeight;

    if (window.innerWidth < 1150) {
        windowHeight = "98vh";
    } else {
        windowHeight = window.innerHeight - 240;
    }
    return (
        <Grid container xs={12} variant="G19">
            <Grid item xs={1.5} style={{ border: '1px solid black', borderRadius: '8px' }}>
                <Grid container xs={12} variant="G20">
                    <Grid item xs={12} variant="G26" sx={{ background: 'black' }}>
                        <Typography variant="P21" mt={2} mb={2} style={{ color: 'white' }}>
                            VPO List
                        </Typography>
                    </Grid>
                    <Grid item xs={12} variant="G20" style={{ overflowY: 'auto', maxHeight: windowHeight, minHeight: windowHeight }} >
                        {vppData.map((vpp, index) => (
                            <Accordion
                                key={index}
                                expanded={expanded === `panel${index}`}
                                onChange={handleChange(`panel${index}`)}
                                onClick={() => handleVppClick(vpp)}
                                style={{
                                    cursor: 'pointer',
                                    backgroundColor: selectedVpp === vpp ? 'lightgray' : 'inherit',
                                    width: "95%",
                                    marginLeft: "2.5%",
                                    marginTop: "10px",
                                    border: "1px solid #d3d3d3"
                                }}

                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls={`panel${index}bh-content`}
                                    id={`panel${index}bh-header`}
                                >
                                    <Typography sx={{ width: '99%', flexShrink: 0, fontSize: '10px' }}>
                                        {vpp.clipSet[0].VppId}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid xs={12}>
                                        <>
                                            <Grid >
                                                <Typography variant='CHead'>Project Title : &nbsp;</Typography>
                                            </Grid>
                                            <Grid >
                                                {vpp.clipSet[0].projectTitle}
                                            </Grid>
                                        </>
                                        <>
                                            <Grid >
                                                <Typography variant='CHead'>Type of Content : &nbsp;</Typography>
                                            </Grid>
                                            <Grid >
                                                {vpp.clipSet[0].catagory ?? "N/A"}
                                            </Grid>
                                        </>
                                        <>
                                            <Grid >
                                                <Typography variant='CHead'>Brand Category : &nbsp;</Typography>
                                            </Grid>
                                            <Grid >
                                                {vpp.clipSet[0].brandCat}
                                            </Grid>
                                        </>
                                        <Grid container direction='row'>
                                            <Grid item xs={6}>
                                                <Grid variant='GH'>
                                                    <Typography variant='CHead'>Start Time  &nbsp;</Typography>
                                                </Grid>
                                                <Grid variant='GV'>
                                                    {vpp.clipSet[0].startTime}
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Grid variant='GH'>
                                                    <Typography variant='CHead'>End Time  &nbsp;</Typography>
                                                </Grid>
                                                <Grid variant='GV'>
                                                    {vpp.clipSet[0].endTime}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={2.60} style={{ border: '1px solid black', borderRadius: '8px' }}>
                <CommentProducer selectedVpp={selectedVpp} currentUser={"creative"} setNewMessage={setNewMessage} newMessage={newMessage}
                    onUpdateSelectedVpp={handleUpdateSelectedVpp} setUpdateMessages={setUpdateMessages} handleUpdateMessages={handleUpdateMessages} />
            </Grid>
            <Grid item xs={5.3} style={{ border: '1px solid black', borderRadius: '8px' }}>
                <Details selectedVpp={selectedVpp} handleUpdateMessages={handleUpdateMessages} />
            </Grid>
            <Grid item xs={2.60} style={{ border: '1px solid black', borderRadius: '8px' }}>
                <CommentBrand selectedVpp={selectedVpp} currentUser={"creative"} setNewMessage={setNewMessageB} newMessage={newMessageB}
                    onUpdateSelectedVpp={handleUpdateSelectedVpp} setUpdateMessages={setUpdateMessages} handleUpdateMessages={handleUpdateMessages} />
            </Grid>
        </Grid>
    );
};

export default Container;
