import React from 'react'
import { Grid } from '@mui/material'
import TopBar from '../Components/CampaignsComponet/TopBar'
import Active from '../Components/AdSteup/Active'

const AdSetup = () => {
    const ComponentName = 'Ad Setup (Active Campaigns)'

    return (
        <Grid container xs={12} mt={7}>
            <TopBar ComponentName={ComponentName} />
            <Grid container xs={12} variant='G1' mt={3} >
                <Grid xs={11.7} ml={2} container mt={2} >
                    < Active />
                </Grid>
            </Grid>

        </Grid>
    )
}

export default AdSetup
