import {  Grid, IconButton } from '@mui/material'
import React from 'react'
import CustomTextfield from '../CommonComponet/CustomTextfield'
import {BsSearch} from 'react-icons/bs'

const SearchBar = ({ onChange }) => {

    return (
        <Grid xs={12} display='flex'>
            <CustomTextfield
                size="small"
                id="user-email-input"
                placeholder="Search Here"
                onChange={onChange}
                fullWidth
                style={{border:'none'}}
            />
            {/* <IconButton variant='IB1' ><BsSearch /></IconButton> */}
        </Grid>
    )
}

export default SearchBar
