import React, { useEffect, useState } from 'react'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material';
import SearchBar from '../CampaignsComponet/SearchBar'
import { AiOutlineFilter } from 'react-icons/ai'
import { CardActionArea, CardActions } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { awsApiCamp } from '../../Routes/Server';
import { setShowLoader } from '../../redux/loaderSlice';
import { generateThumbnailURL } from '../../Routes/apiConfig';
import defaultThumbnail from '../../assets/NoVideo.png'
import { addProjectId } from '../../redux/projectSlice';
import SingleNewFilters from '../singleCameraCommon/SingleNewFilters';
import NoData from '../CommonComponet/NoData';
import EmbeddedPlayer from '../singleCameraCommon/EmbeddedPlayer';
import { v4 as uuidv4 } from 'uuid';

const Logo = 'https://aa-web-portal.s3.ap-south-1.amazonaws.com/video-asset/images/logoAnan.png';

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement('script');
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

const __DEV__ = document.domain === 'localhost';

const EmbeddedVideos = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = useSelector((state) => state.user.userLoginData);
  const [project, setProject] = useState([]);
  const [rows, setRows] = useState([]);
  const [openPlayer, setOpenPlayer] = useState(false);
  const [projectDetail, setProjectDetail] = useState();
  const [newState, setNewState] = useState(true);
  const [updateMessages, setUpdateMessages] = useState('')
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState(undefined);
  const [approveDialogOpen, setApproveDialogOpen] = useState(false);
  const [rejectDialogOpen, setRejectDialogOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);

  const generateRandomText = () => {
    const randomText = Math.random().toString(36).substring(7);
    return randomText;
  };

  const handleUpdateMessages = () => {
    const randomText = generateRandomText();
    setUpdateMessages(randomText);
  };

  const [filters, setFilters] = useState({
    country: '',
    projectType: [],
    genre: [],
    brandCategory: [],
    price: ''
  });

  useEffect(() => {
    async function fetchInProgress() {
      dispatch(setShowLoader(true));
      try {
        const res = await awsApiCamp.post("/getcampaign", {
          pageSize: 10,
          type: "in_Embedded",
          page: 1,
          lastEvaluatedKey: lastEvaluatedKey,
        });
        if (res.data.statusCode === 200) {
          const data = JSON.parse(res.data.body);
          const projectsData = data.items;
          setLastEvaluatedKey(data.lastEvaluatedKey);
          setProject(projectsData);
          setRows(projectsData);
          console.log('projectsData:', projectsData);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        dispatch(setShowLoader(false));
      }
    }

    fetchInProgress();
  }, [updateMessages, filters]);

  const filteredRows = project;

  const [isPagination, setIsPagination] = useState(true);

  const [filteredCurrentPage, setFilteredCurrentPage] = useState(1);
  const [filteredVideosPerPage, setFilteredVideosPerPage] = useState(5);

  useEffect(() => {
    const updateFilteredVideosPerPage = () => {
      if (window.innerWidth >= 1500) {
        setFilteredVideosPerPage(5);
      } else {
        setFilteredVideosPerPage(5);
      }
    };
    updateFilteredVideosPerPage();
    window.addEventListener('resize', updateFilteredVideosPerPage); // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', updateFilteredVideosPerPage);
    };
  }, []);

  const indexOfLastFilteredVideo = filteredCurrentPage * filteredVideosPerPage;
  const indexOfFirstFilteredVideo = indexOfLastFilteredVideo - filteredVideosPerPage;
  const currentFilteredVideos = filteredRows.slice(
    indexOfFirstFilteredVideo,
    indexOfLastFilteredVideo
  );

  const filteredPageNumbers = [];
  for (let i = 1; i <= Math.ceil(filteredRows.length / filteredVideosPerPage); i++) {
    filteredPageNumbers.push(i);
  }

  const handleFilteredPageClick = (pageNumber, event) => {
    if (event) {
      event.preventDefault();
    }
    setFilteredCurrentPage(pageNumber);
  };

  const handlePagination = () => {
    setFilteredCurrentPage(1)
    setIsPagination(!isPagination)
  }

  const handleOpenPlayer = (projectId) => {
    if (projectId) {
      setProjectDetail(projectId);
      setOpenPlayer(true);
    }
  };

  const handleApproveClick = (project) => {
    setSelectedRow(project);
    setApproveDialogOpen(true);
  };

  const handleRejectDialog = (project) => {
    setSelectedRow(project);
    setRejectDialogOpen(true);
  };

  const handelPaymentClick = (project) => {
    setSelectedRow(project);
    setShowConfirmPopup(true);
  };
  const handleCloseDialog = () => {
    setShowConfirmPopup(false);
  };

  const handleApprove = async (project) => {
    dispatch(setShowLoader(true));
    try {
      let updatedData;
      if (project.creatorStatus === true) {
        updatedData = {
          ...project,
          brandStatus: true,
          status: "approved",
        };
      } else {
        updatedData = {
          ...project,
          brandStatus: true,
        };
      }
      const res = await awsApiCamp.post('/postcampaign', {
        ...updatedData,
      });

      if (res.data.statusCode === 200) {
        handleUpdateMessages()
        setApproveDialogOpen(false);
      }
    } catch (error) {
      console.error('Error approving data:', error);
    } finally {
      dispatch(setShowLoader(false));
    }
  };

  const handleReject = async (project) => {
    dispatch(setShowLoader(true));
    try {
      let updatedData;
      if (project.creatorStatus === true) {
        updatedData = {
          ...project,
          currentStatus: "Reject"
        };
      } else {
        updatedData = {
          ...project,
          currentStatus: "brandReject"
        };
      }
      const res = await awsApiCamp.post('/postcampaign', {
        ...updatedData,
      });

      if (res.data.statusCode === 200) {
        handleUpdateMessages()
        setRejectDialogOpen(false);
      }
    } catch (error) {
      console.error('Error approving data:', error);
    } finally {
      dispatch(setShowLoader(false));
    }
  };



  const handleBuy = async (project, paymentDetails) => {
    const vppId = uuidv4();
    const vppClipUrl = project.clipSet[0]?.video[0]?.VideoUrl;
    const embeddedUrl = project.clipSet[0]?.video[0]?.embeddedUrl;
    const startTime = "00:00:00"
    const endTime = ""
    const status = "checked"
    const Duration = "00:00:00"
    const price = project.clipSet[0]?.vppPrice
    const projectTitle = project.clipSet[0]?.title
    const brandCat = ""
    const associationStatus = ""
    const videoId = project.clipSet[0]?.video[0]?.videoId
    const currentUnixTimestamp = Math.floor(new Date().getTime() / 1000);
    const messageId = uuidv4();
    const currentUnixTimestampB = Math.floor(new Date().getTime() / 1000);
    const messageIdb = uuidv4();
    const commentsProducer = [
      {
        meassage: "Campaigns Start",
        time: new Date().toISOString(),
        senderType: "producer",
        senderId: userData.sub,
        senderName: userData.name,
        meassageId: messageId,
        options: "",
        timeStamp: currentUnixTimestamp,
        rechiverType: "creative",
      },
    ]
    const commentsBrand = [
      {
        meassage: "Campaigns Start",
        time: new Date().toISOString(),
        senderType: "brand",
        senderId: userData.sub,
        senderName: userData.name,
        meassageId: messageIdb,
        options: "",
        timeStamp: currentUnixTimestampB,
        rechiverType: "creative",
      },
    ]
    const updatedClipSet = [
      {
        VppId: vppId,
        VppFreamNo: "1",
        VppClipUrl: vppClipUrl,
        embeddedUrl: embeddedUrl,
        startTime: startTime,
        endTime: endTime,
        status: status,
        Duration: Duration,
        price: price,
        projectTitle: projectTitle,
        brandCat: brandCat,
        associationStatus: associationStatus,
        videoId: videoId,
        commentsProducer: commentsProducer,
        commentsBrand: commentsBrand
      },
    ];

    const updatedData = {
      ...project,
      clipSet: updatedClipSet,
      status: 'pending',
      type: 'in_progress',
      creatorStatus: false,
      brandStatus: false,
      paymentDetails: paymentDetails
    };

    try {
      const res = await awsApiCamp.post('/postcampaign', {
        ...updatedData,
      });
      if (res.data.statusCode === 200) {
        handleUpdateMessages()
      }
    } catch (error) {
      console.error('Error buying data:', error);
    }
  }

  async function displayRazorpay() {
    handleCloseDialog()
    try {
      dispatch(setShowLoader(true));
      const totalSelectedPrice = selectedRow.clipSet[0]?.vppPrice;

      const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js');
      if (!res) {
        alert('Razorpay SDK failed to load. Are you online?');
        return;
      }

      const data = await fetch(`https://dev.optimus.anantadi.com/razorpay/${totalSelectedPrice}`, { method: 'POST' }).then((t) =>
        t.json()
      );
      console.log('Inside Try displayRazorpay 2');

      const options = {
        key: __DEV__ ? 'rzp_test_UEfYEtdBIyVOPB' : 'rzp_test_UEfYEtdBIyVOPB',
        currency: data.currency,
        amount: data.amount.toString(),
        order_id: data.id,
        name: 'Vpp Advertisement',
        description: 'Vpp Purchase is successfully done kindly wait for approval.',
        image: Logo,
        handler: async function (response) {
          if (response.razorpay_payment_id && response.razorpay_order_id && response.razorpay_signature) {
            const paymentDetails = {
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_order_id: response.razorpay_order_id,
              razorpay_signature: response.razorpay_signature,
              userName: userData.name,
              emailId: userData.email,
              totalPrice: totalSelectedPrice,
            };
            await handleBuy(selectedRow, paymentDetails);
          }
        },
        prefill: {
          email: userData.email,
          phone_number: userData.phone_number,
        },
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    } catch (error) {
      console.error('Error:', error);
      // alert('An error occurred during payment.');
    } finally {
      dispatch(setShowLoader(false));
    }
  }



  return (
    <Grid xs={12}>
      <Grid xs={12} display='flex' justifyContent='space-between' alignItems='center' >
        <SingleNewFilters
          filters={filters}
          setFilters={setFilters}
        />
      </Grid>
      <Grid xs={12} >
        <Grid container direction="column"  >
          <Grid
            container
            item
            xs={12}
            mb={2}
            ml={-2}
            spacing={1}
          >

            {!isPagination ?
              <>
                {filteredRows.length === 0
                  ? <NoData /> :
                  filteredRows.map((projectItem) => (
                    <Grid ml={2} mt={2}>
                      <Card sx={{ minWidth: 260, maxWidth: 260 }}>
                        <CardActionArea sx={{
                          '&:focus': {
                            outline: 'none',
                          }
                        }}>
                          <CardMedia
                            component="img"
                            height="140"
                            image={generateThumbnailURL(projectItem.clipSet[0]?.video[0]?.videoId) || defaultThumbnail}
                            alt="green iguana"
                            onClick={() => { handleOpenPlayer(projectItem) }}
                          />

                        </CardActionArea>
                        <CardContent>
                          <Grid xs={12} display='flex' justifyContent='space-between' alignItems='center' ml={-1} mt={-0.8}>
                            <Typography
                              variant='CHead'>
                              {projectItem.clipSet[0]?.contentType}
                            </Typography>
                          </Grid>
                          <Grid xs={12} display='flex' justifyContent='flex-start' alignItems='center' mt={0.5} ml={-1}>
                            <Typography variant='CHead' style={{ fontSize: "15px" }}>Project Title :&nbsp;</Typography>
                            <Typography
                              variant='GV'
                            >
                              &nbsp; {projectItem.clipSet[0]?.title}
                            </Typography>
                          </Grid>
                          <Grid xs={12} display='flex' justifyContent='flex-start' alignItems='center' mt={0.5} ml={-1} >
                            <Typography
                              variant='CHead'>
                              Price:-&nbsp;
                            </Typography>
                            <Typography variant='GV'>{projectItem.clipSet[0]?.vppPrice}</Typography>
                          </Grid>
                          <>
                            {
                              projectItem.creatorStatus && projectItem.brandStatus ? (
                                <Grid xs={12} display='flex' justifyContent='center' alignItems='center' mt={2} mb={-2}>
                                  <Button variant='B2S' style={{ background: "#6eff9f", height: '35px', width: "100%", color: 'black', marginLeft: "0px" }}
                                    onClick={() => handelPaymentClick(projectItem)}>
                                    Buy
                                  </Button>
                                </Grid>
                              ) : projectItem.brandStatus ? (
                                <Grid xs={12} display='flex' justifyContent='center' alignItems='center' mt={2} mb={-2}>
                                  {projectItem.currentStatus ? (
                                    <Button variant='B2S' style={{ background: "#ff8282", height: '35px', width: "100%", color: '#432800', marginLeft: "0px", fontSize: "13px" }} disabled>
                                      {projectItem.currentStatus === 'brandReject' ? 'Rejected by You' : 'Rejected by Producer'}
                                    </Button>
                                  ) : (
                                    <Button variant='B2S' style={{ background: "#f7940085", height: '35px', width: "100%", color: '#432800', marginLeft: "0px", fontSize: "13px" }} disabled>
                                      Waiting For Producer Approval
                                    </Button>
                                  )}
                                </Grid>
                              ) : (
                                projectItem.currentStatus ? (
                                  <Grid xs={12} display='flex' justifyContent='center' alignItems='center' mt={2} mb={-2}>
                                    <Button variant='B2S' style={{ background: "#ff8282", height: '35px', width: "100%", color: '#432800', marginLeft: "0px", fontSize: "13px" }} disabled>
                                      {projectItem.currentStatus === 'brandReject' ? 'Rejected by You' : 'Rejected by Producer'}
                                    </Button>
                                  </Grid>
                                ) : (
                                  <Grid display='flex' justifyContent='space-between' alignItems='center'>
                                    <Grid xs={5} display='flex' justifyContent='center' alignItems='center' mt={2} mb={-2}>
                                      <Button variant='B2S' style={{ background: "#ff8282", height: '35px', width: "100%", color: 'black', marginLeft: "0px" }}
                                        onClick={() => handleRejectDialog(projectItem)}>
                                        Reject
                                      </Button>
                                    </Grid>
                                    <Grid xs={6} display='flex' justifyContent='center' alignItems='center' mt={2} mb={-2}>
                                      <Button variant='B2S' style={{ background: "#6eff9f", height: '35px', width: "100%", color: 'black', marginLeft: "0px" }}
                                        onClick={() => handleApproveClick(projectItem)}
                                      >
                                        Approve
                                      </Button>
                                    </Grid>
                                  </Grid>
                                )
                              )
                            }
                          </>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))
                }
              </>
              :
              <>
                {currentFilteredVideos.length === 0
                  ? <NoData /> :
                  currentFilteredVideos.map((projectItem) => (
                    <Grid ml={2} mt={2}>
                      <Card sx={{ minWidth: 260, maxWidth: 260 }}>
                        <CardActionArea sx={{
                          '&:focus': {
                            outline: 'none',
                          }
                        }}>
                          <CardMedia
                            component="img"
                            height="140"
                            image={generateThumbnailURL(projectItem.clipSet[0]?.video[0]?.videoId) || defaultThumbnail}
                            alt="green iguana"
                            onClick={() => { handleOpenPlayer(projectItem) }}
                          />
                        </CardActionArea>
                        <CardContent >
                          <Grid xs={12} display='flex' justifyContent='space-between' alignItems='center' ml={-1} mt={-0.8}>
                            <Typography
                              variant='CHead'>
                              {projectItem.clipSet[0]?.title}
                            </Typography>
                          </Grid>
                          <Grid xs={12} display='flex' justifyContent='flex-start' alignItems='center' mt={0.5} ml={-1}>
                            <Typography variant='CHead' style={{ fontSize: "15px" }}>Project Title :</Typography>
                            <Typography
                              variant='GV'
                              style={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                display: 'inline-block',
                                maxWidth: '130px',
                              }}
                            >
                              &nbsp; {projectItem.clipSet[0]?.title}
                            </Typography>
                          </Grid>
                          <Grid xs={12} display='flex' justifyContent='flex-start' alignItems='center' mt={0.5} ml={-1} >
                            <Typography
                              variant='CHead'>
                              Price:-&nbsp;
                            </Typography>
                            <Typography variant='GV'>{projectItem.clipSet[0]?.vppPrice}</Typography>
                          </Grid>

                          <>
                            {
                              projectItem.creatorStatus && projectItem.brandStatus ? (
                                <Grid xs={12} display='flex' justifyContent='center' alignItems='center' mt={2} mb={-2}>
                                  <Button variant='B2S' style={{ background: "#6eff9f", height: '35px', width: "100%", color: 'black', marginLeft: "0px" }}
                                    onClick={() => handelPaymentClick(projectItem)}>
                                    Buy
                                  </Button>
                                </Grid>
                              ) : projectItem.brandStatus ? (
                                <Grid xs={12} display='flex' justifyContent='center' alignItems='center' mt={2} mb={-2}>
                                  {projectItem.currentStatus ? (
                                    <Button variant='B2S' style={{ background: "#ff8282", height: '35px', width: "100%", color: '#432800', marginLeft: "0px", fontSize: "13px" }} disabled>
                                      {projectItem.currentStatus === 'brandReject' ? 'Rejected by You' : 'Rejected by Producer'}
                                    </Button>
                                  ) : (
                                    <Button variant='B2S' style={{ background: "#f7940085", height: '35px', width: "100%", color: '#432800', marginLeft: "0px", fontSize: "13px" }} disabled>
                                      Waiting For Producer Approval
                                    </Button>
                                  )}
                                </Grid>
                              ) : (
                                projectItem.currentStatus ? (
                                  <Grid xs={12} display='flex' justifyContent='center' alignItems='center' mt={2} mb={-2}>
                                    <Button variant='B2S' style={{ background: "#ff8282", height: '35px', width: "100%", color: '#432800', marginLeft: "0px", fontSize: "13px" }} disabled>
                                      {projectItem.currentStatus === 'brandReject' ? 'Rejected by You' : 'Rejected by Producer'}
                                    </Button>
                                  </Grid>
                                ) : (
                                  <Grid display='flex' justifyContent='space-between' alignItems='center'>
                                    <Grid xs={5} display='flex' justifyContent='center' alignItems='center' mt={2} mb={-2}>
                                      <Button variant='B2S' style={{ background: "#ff8282", height: '35px', width: "100%", color: 'black', marginLeft: "0px" }}
                                        onClick={() => handleRejectDialog(projectItem)}>
                                        Reject
                                      </Button>
                                    </Grid>
                                    <Grid xs={6} display='flex' justifyContent='center' alignItems='center' mt={2} mb={-2}>
                                      <Button variant='B2S' style={{ background: "#6eff9f", height: '35px', width: "100%", color: 'black', marginLeft: "0px" }}
                                        onClick={() => handleApproveClick(projectItem)}
                                      >
                                        Approve
                                      </Button>
                                    </Grid>
                                  </Grid>
                                )
                              )
                            }
                          </>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))
                }
              </>}
          </Grid>
          {filteredRows.length > filteredVideosPerPage && (
            <Grid xs={11.7} ml={2} display='flex' justifyContent='center'>
              <nav aria-label="Page navigation example">
                <ul className="pagination">
                  <li
                    className={`page-item ${filteredCurrentPage === 1 ? 'disabled' : ''}`}
                  >
                    <a
                      className="page-link"
                      href="#"
                      onClick={() => handleFilteredPageClick(filteredCurrentPage - 1)}
                    >
                      Previous
                    </a>
                  </li>
                  {filteredPageNumbers.map((number) => (
                    <li
                      className={`page-item ${filteredCurrentPage === number ? 'active' : ''}`}
                      key={number}
                    >
                      <a
                        className="page-link"
                        href="#"
                        onClick={(event) => handleFilteredPageClick(number, event)}
                      >
                        {number}
                      </a>
                    </li>
                  ))}
                  <li
                    className={`page-item ${filteredCurrentPage === filteredPageNumbers.length
                      ? 'disabled'
                      : ''}`}
                  >
                    <a
                      className="page-link"
                      href="#"
                      onClick={() => handleFilteredPageClick(filteredCurrentPage + 1)}
                    >
                      Next
                    </a>
                  </li>
                </ul>
              </nav>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Dialog open={approveDialogOpen} onClose={() => setApproveDialogOpen(false)} >
        <DialogContent style={{ fontSize: "19px" }}>{"Are you sure you want to approve once approved cannot be cancelled."}</DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
          <Button onClick={() => handleApprove(selectedRow)} variant="outlined" color="success" sx={{ marginBottom: "7px" }}>
            Approve
          </Button>
          <Button onClick={() => setApproveDialogOpen(false)} variant="outlined" color="error" sx={{ marginBottom: "7px" }}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={rejectDialogOpen} onClose={() => setRejectDialogOpen(false)} >
        <DialogTitle >{"Approve campaign"}</DialogTitle>
        <DialogContent >{"Are you sure you want to approve once approved cannot be cancelled"}</DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
          <Button onClick={() => handleReject(selectedRow)} variant="outlined" color="success" sx={{ marginBottom: "7px" }}>
            Reject
          </Button>
          <Button onClick={() => setRejectDialogOpen(false)} variant="outlined" color="error" sx={{ marginBottom: "7px" }}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <EmbeddedPlayer
        projectDetail={projectDetail}
        onClose={() => setOpenPlayer(false)}
        openPlayer={openPlayer}
        fromPage={'brand'}
        handleApproveClick={handleApproveClick}
        handleRejectDialog={handleRejectDialog}
      />
      <Dialog
        open={showConfirmPopup}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Are you sure you want to purchase these?"}</DialogTitle>

        <DialogActions>
          <Button onClick={() => handleCloseDialog()} variant="outlined" color="error">
            Disagree
          </Button>
          <Button onClick={displayRazorpay} autoFocus variant="outlined" color="success">
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  )
}

export default EmbeddedVideos
