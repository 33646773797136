import CloseIcon from '@mui/icons-material/Close';
import { Card, CardMedia, Grid } from "@mui/material";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { addProjectId } from '../../redux/projectSlice';
import { useDispatch } from "react-redux";
import PlayerTab from '../CampaignsComponet/PlayerTab';
import defaultThumbnail from '../../assets/NoVideo.png'
import Chip from '@mui/material/Chip';
import PlayerTabCreative from '../CampaignsComponetCreative/PlayerTabCreative';
import { generateThumbnailURL } from '../../Routes/apiConfig';
import jsPDF from 'jspdf';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
        <DialogTitle sx={{ m: 0, p: 0.5 }} {...other} fontWeight="bold" style={{ fontFamily: "DM Sans" }}>
            <Typography variant='CHead' ml={2}>{children}</Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 2,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

const CreativeVppUpload = ({ openPlayer, setOpenPlayer, selectedProject, handleUpdateMessages, handleComment, pageType ,actionValue}) => {

    const reportTemplateRef = useRef(null);

    const getStatusLabelAndColor = () => {
        const brandStatus = selectedProject?.brandStatus;
        const creatorStatus = selectedProject?.creatorStatus;
        const status = selectedProject?.status;

        let label = status.charAt(0).toUpperCase() + status.slice(1);
        let color = "secondary";

        if (brandStatus && creatorStatus) {
            label = label.charAt(0).toUpperCase() + label.slice(1);
            color = "success";
        } else if (!brandStatus && creatorStatus) {
            label = "Brand Approval Pending";
            color = "warning";
        } else if (brandStatus && !creatorStatus) {
            label = "Producer Approval Pending";
            color = "warning";
        }

        return { label, color };
    };

    const handleClose = () => {
        setOpenPlayer(false);
        handleUpdateMessages()
    };

    if (!selectedProject) {
        return null;
    }

    // console.log('selectedProject', selectedProject)
    const url = selectedProject?.videoId;
    const thumbnailURL = generateThumbnailURL(url) || defaultThumbnail;
    const { label, color } = getStatusLabelAndColor();



    const handleGeneratePdf = () => {
        const doc = new jsPDF({
            format: 'a4',
            unit: 'px',
        });

        doc.setFont('Inter-Regular', 'normal');

        doc.html(reportTemplateRef.current, {
            async callback(doc) {
                await doc.save('document');
            },
        });
    };

    const calculateTimeDifference = (startTime, endTime) => {
        const parseTime = (time) => {
            const [hours, minutes, seconds] = time.split(':').map(Number);
            return hours * 3600 + minutes * 60 + seconds;
        };
        const startSeconds = parseTime(startTime);
        const endSeconds = parseTime(endTime);
        return endSeconds - startSeconds;
    };

    const startTime = selectedProject?.clipSet[0].startTime;
    const endTime = selectedProject?.clipSet[0].endTime;

    const differenceInSeconds = calculateTimeDifference(startTime, endTime);

    console.log('CreativeVppUpload', selectedProject)

    return (
        <div>
            <div>
                <BootstrapDialog
                    onClose={handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={openPlayer}
                    maxWidth="lg"
                >
                    <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose} >
                        {selectedProject?.clipSet[0].projectTitle}
                    </BootstrapDialogTitle>
                    <DialogContent dividers >
                        <Grid container spacing={2} sx={{ minWidth: 1000, maxWidth: 1000 }}>
                            <Grid variant="G12" item xs={4} ml={-2}>
                                <Grid item xs={12} sx={{ borderBottom: "1px solid #dbdfea" }}>
                                    <Card sx={{ maxWidth: '100%', margin: "15px", marginTop: "0px" }}>
                                        <CardMedia
                                            component="img"
                                            height="160"
                                            image={thumbnailURL}
                                            alt="thumb"
                                        />
                                    </Card>
                                </Grid>
                                <Grid xs={12} ref={reportTemplateRef}>
                                    <Grid item xs={12} sx={{ margin: "15px", marginTop: "0px" }}>
                                        <Grid mt={1}>
                                            <Typography variant='CHead'>Project Title</Typography> - &nbsp;
                                            <Typography variant='GV'>{selectedProject?.clipSet[0].projectTitle}</Typography>
                                        </Grid>
                                        <Grid mt={1}>
                                            <Typography variant='CHead'>Status</Typography> - &nbsp;
                                            <Typography variant='GV'><Chip
                                                label={label}
                                                color={color}
                                                variant="outlined"
                                                size="small"
                                                sx={{ width: '180px', height: '25px' }}
                                            /></Typography>
                                        </Grid>
                                        <Grid container direction='row'>
                                            <Grid item xs={6}>
                                                <Grid variant='GH'>
                                                    <Typography variant='CHead'>Start Time</Typography>
                                                </Grid>
                                                <Grid variant='GV'>
                                                    {selectedProject?.clipSet[0].startTime}
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={6}>

                                                <Grid variant='GH'>
                                                    <Typography variant='CHead'>End Time</Typography>
                                                </Grid>
                                                <Grid variant='GV'>
                                                    {selectedProject?.clipSet[0].endTime}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid mt={1}>
                                            <Typography variant='CHead'>Catagory</Typography> - &nbsp;
                                            <Typography variant='GV'>{selectedProject?.clipSet[0].category}</Typography>
                                        </Grid>
                                        <Grid mt={1}>
                                            <Typography variant='CHead'>Brand Catagory</Typography> - &nbsp;
                                            <Typography variant='GV'>{selectedProject?.clipSet[0].brandCat}</Typography>
                                        </Grid>
                                        <Grid mt={1}>
                                            <Typography variant='CHead'>Embedded Ad Inventory Seconds</Typography> - &nbsp;
                                            <Typography variant='GV'>{differenceInSeconds}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={8} >
                                <Grid item xs={12} >
                                    <PlayerTabCreative
                                        pageType={pageType}
                                        VppClipUrl={selectedProject?.clipSet[0].VppClipUrl}
                                        startTime={selectedProject?.clipSet[0].startTime}
                                        selectedProject={selectedProject}
                                        handleClose={handleClose}
                                        label={label}
                                        handleGeneratePdf={handleGeneratePdf}
                                        handleUpdateMessages={handleUpdateMessages}
                                        handleComment={handleComment}
                                        actionValue={actionValue}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                    </DialogContent>
                </BootstrapDialog>
            </div>
        </div>
    )
}

export default CreativeVppUpload
