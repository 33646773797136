import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  appType: "",
};

const AppTypeSlice = createSlice({
  name: "Application",
  initialState,
  reducers: {
    addAppType: (state, action) => {
      state.appType = action.payload;
    },
  },
});

export const { addAppType } = AppTypeSlice.actions;

export default AppTypeSlice.reducer;


