import React, { useState } from 'react';
import { Grid, Paper, Typography, Button } from "@mui/material";
import CommonTable from '../CommonComponet/CommonTable';
import { TfiMenuAlt } from 'react-icons/tfi';
import { BsGrid3X2GapFill } from 'react-icons/bs';
import ListCard from './../CommonComponet/ListCard';
import MultiVideoUpload from '../AddProject/MultiVideoUpload';
import VideoPlayer from '../CommonComponet/VideoPlayer';
import { BootstrapDialog, BootstrapDialogTitle } from '../AddProject/StepOne'
import DialogContent from '@mui/material/DialogContent';
import { generateThumbnailURL } from '../../Routes/apiConfig';

const SubContainer = ({ name, videos, genre, season, id, project, projectTitle, contentType }) => {

    const [gridView, setGridView] = useState(true);
    const [listView, setListView] = useState(false);
    const [openPlayer, setOpenPlayer] = useState(false);
    const [uploadOpen, setUploadOpen] = useState(false);
    const [projectDetail, setProjectDetail] = useState();
    const [videoId, setVideoId] = useState('')
    const [selectedEpisodeId, setSelectedEpisodeId] = useState(null);


    const columns = [
        'S.No',
        'videoTitle',
        'Genre',
        'No of VPOs',
        'Season',
        'Episode No',
        '', ''
    ];

    const getDataByContentType = (contentType) => {
        return videos.map((video, index) => {
            const rowData = [
                index + 1,
                // thumbnailURL,
                video.videoTitle,
                genre,


            ];

            if (contentType === 'web-series' || contentType === 'tv-show') {
                rowData.push(video.episodeNo, video.season);
            } else {
                rowData.push('', '');
            }

            return {
                id: video.videoId,
                cells: rowData,
            };
        });
    };


    const data = getDataByContentType(name);

    const [currentPage, setCurrentPage] = useState(1);
    const videosPerPage = 10;

    const indexOfLastVideo = currentPage * videosPerPage;
    const indexOfFirstVideo = indexOfLastVideo - videosPerPage;
    const currentVideos = data.slice(indexOfFirstVideo, indexOfLastVideo);

    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(data.length / videosPerPage); i++) {
        pageNumbers.push(i);
    }

    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    const shouldDisplayPagination = data.length > 6;

    const [vId, setVId] = useState('')

    const handleOpenPlayer = (videoId) => {
        // console.log("VideoId clicked:", videoId);
        const clickedVideo = videos.find((video) => video.videoId === videoId);
        if (clickedVideo) {
            setProjectDetail(clickedVideo);
            setOpenPlayer(true);
            setVId(videoId)
        }
    };

    const thumbnailURL = generateThumbnailURL(videoId);

    return (
        <Grid xs={12} mb={2}>
            <Paper variant="Box1">
                <Grid xs={12} variant="G11">
                    <Typography ml={2} variant='Head'>
                        Video's ({videos.length})
                    </Typography>
                    <Grid ml={-2}>

                        <Button variant='B1' onClick={() => { setUploadOpen(true) }} sx={{ marginRight: "15px" }}>Add Videos</Button>

                        {/* {listView === true ? (
                            <Button
                                variant="B5"
                                onClick={() => {
                                    setGridView(true);
                                    setListView(false);
                                }}
                            >
                                <BsGrid3X2GapFill />
                            </Button>
                        ) : (
                            <Button
                                variant="B5"
                                onClick={() => {
                                    setGridView(false);
                                    setListView(true);
                                }}
                            >
                                <TfiMenuAlt />
                            </Button>
                        )} */}
                    </Grid>

                </Grid>
                {gridView && (
                    <Grid container direction="column">
                        <Grid
                            container
                            item
                            xs={12}
                            spacing={3}
                            style={{ margin: '0rem 1rem' }}
                        >
                            {videos.map((project) => (
                                <ListCard
                                    setVideoId={setVideoId}
                                    category={project.contentType}
                                    title={project.videoTitle || project.episodeTitle}
                                    description={project.description}
                                    thumbnail={project.videoThumbnail}
                                    seasonCount={project.season}
                                    episodeCount={project.episodeNo}
                                    time={project.time}
                                    contentId={project.videoId}
                                    genres={genre}
                                    page={'viewProject'}
                                   
                                    videoSubId={project.videoId}
                                    key={project.videoId}
                                    totalVpo={project.vpoNo}
                                    onClick={() => handleOpenPlayer(project.videoId)}
                                />

                            ))}
                        </Grid>
                        {shouldDisplayPagination && (
                            <Grid xs={11.7} ml={4}>
                                <nav aria-label="Page navigation example">
                                    <ul className="pagination">
                                        <li
                                            className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}
                                        >
                                            <a className="page-link" href="#" onClick={() => handlePageClick(currentPage - 1)} >
                                                Previous </a>
                                        </li>
                                        {pageNumbers.map((number) => (
                                            <li
                                                className={`page-item ${currentPage === number ? 'active' : ''}`}
                                                key={number}
                                            >
                                                <a className="page-link" href="#" onClick={() => handlePageClick(number)} >
                                                    {number}
                                                </a>
                                            </li>
                                        ))}
                                        <li
                                            className={`page-item ${currentPage === pageNumbers.length
                                                ? 'disabled'
                                                : ''}`}
                                        >
                                            <a className="page-link" href="#" onClick={() => handlePageClick(currentPage + 1)} >
                                                Next
                                            </a>
                                        </li>
                                    </ul>
                                </nav>
                            </Grid>
                        )}
                    </Grid>
                )
                }
                {
                    listView && (
                        <Grid container item xs={11.55} ml={4} mt={2}>
                            <CommonTable
                                data={data}
                                columns={columns}
                                rowsPerPageOptions={[videosPerPage]}
                                thumbnailURL={thumbnailURL}
                            />
                        </Grid>
                    )
                }
            </Paper >
            {uploadOpen &&
                <BootstrapDialog
                    onClose={() => { setUploadOpen(false) }}
                    aria-labelledby="customized-dialog-title"
                    open={uploadOpen}
                    maxWidth="xxl"
                >
                    <BootstrapDialogTitle id="customized-dialog-title" onClose={() => { setUploadOpen(false) }} >
                        Add Video
                    </BootstrapDialogTitle>
                    <DialogContent dividers sx={{ width: '70vw', padding: "24px !important" }} >
                        <MultiVideoUpload onClose={() => { setUploadOpen(false) }} setAnalysis={() => { setUploadOpen(false) }} setVideoDialog={setUploadOpen} projectId={id}
                            contentType={contentType} projectTitle={projectTitle}
                        />
                    </DialogContent>
                </BootstrapDialog>
            }

            <VideoPlayer
                project={project}
                setOpenPlayer={setOpenPlayer}
                openPlayer={openPlayer}
                projectDetail={projectDetail}
                projectId={id}
                contentType={contentType}
                projectTitle={projectTitle}
                onClose={() => setOpenPlayer(false)}
                vId={vId}
                EpisodesNo={videos.length}
            />

        </Grid >
    );
};

export default SubContainer;
