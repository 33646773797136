import React, { useState } from 'react'
import { Grid, Button } from '@mui/material'
import Draft from '../CampaignsComponet/Draft'
import Active from '../AdSteup/Active'
import InProgress from '../CampaignsComponet/InProgress'
import Rejected from '../CampaignsComponet/Rejected'

const TabHeader = () => {
    const [activeButton, setActiveButton] = useState(1);


    const handleButtonClick = (buttonIndex) => {
        setActiveButton(buttonIndex);
    };


    return (
        <Grid xs={11.78}>
            <Grid xs={11.7} ml={2} variant='G21' >
                <Button variant='Tab'
                    sx={{
                        marginLeft: '6px', borderLeft: '1px solid black',
                        color: activeButton === 1 ? 'white' : 'black',
                        background: activeButton === 1 ? 'black' : 'white',
                    }} onClick={() => handleButtonClick(1)}
                >Draft</Button>

                <Button variant='Tab'
                    sx={{
                        color: activeButton === 2 ? 'white' : 'black',
                        background: activeButton === 2 ? 'black' : 'white',
                    }} onClick={() => handleButtonClick(2)}
                >In Progress</Button>

                {/* <Button variant='Tab'
                    sx={{
                        color: activeButton === 3 ? 'white' : 'black',
                        background: activeButton === 3 ? 'black' : 'white',
                    }} onClick={() => handleButtonClick(3)}
                >Active</Button> */}

                <Button variant='Tab'
                    sx={{
                        color: activeButton === 4 ? 'white' : 'black',
                        background: activeButton === 4 ? 'black' : 'white',
                    }} onClick={() => handleButtonClick(4)}
                >Rejected</Button>

            </Grid>
            <Grid xs={11.7} ml={2} container mt={2} >
                {activeButton === 1 &&

                    <Draft />
                }
                {activeButton === 2 &&

                    < InProgress />

                }
                {/* {activeButton === 3 &&

                    < Active />

                } */}
                {activeButton === 4 &&

                    <Rejected />

                }
            </Grid>
        </Grid>
    )
}

export default TabHeader
