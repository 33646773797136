import MessageCreative from "../Pages/MessageCreative";
import GallaryChat from "../Pages/GallaryChat";
import CampaignsCreative from "../Pages/CampaignsCreative";
import Profile from '../Components/ProfileComponet/Profile'
import { ImBook } from 'react-icons/im'
import { SiWechat } from 'react-icons/si'
import { TfiGallery } from 'react-icons/tfi'
import VpoMarket from "../Pages/VpoMarket";
import { FaVideo } from "react-icons/fa";
import VpoMarketView from '../Components/VpoMarket/VpoMarketView'

const RoutesCreative = [
    {
        name: "VpoMarket",
        path: "/VpoMarket",
        component: VpoMarket,
        icon: <FaVideo /> 
    },
    {  
        name: "Campaigns",
        path: "/CampaignsCreative",
        component: CampaignsCreative,
        icon: <ImBook />
    },
    
    {
        name: "Gallery",
        path: "/GallaryChat",
        component: GallaryChat,
        icon: <TfiGallery />
    },    
    {
        name: "Message",
        path: "/MessageCreative",
        component: MessageCreative,
        icon: <SiWechat />
    },
    {
        path: "/Profile",
        name: "",
        component: Profile,
    },
    {
        path: "/VpoMarketView",
        name: "",
        component: VpoMarketView,
    },
];

export default RoutesCreative;
