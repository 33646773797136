
import React from 'react'
import { Grid } from '@mui/material'
import TopBar from '../Components/CampaignsComponet/TopBar'
import TabHeader from '../Components/CampaignsComponet/TabHeader'

const Campaigns = () => {
  const ComponentName = 'Campaigns'

  return (
    <Grid container xs={12} mt={7}>
      <TopBar ComponentName={ComponentName} />
      <Grid container xs={12} variant='G1' mt={3} >
        <TabHeader />
      </Grid>
    </Grid>
  )
}

export default Campaigns
