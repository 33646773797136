import React, { useEffect, useRef, useState } from 'react';
import { Card, CardMedia, Grid, Button } from "@mui/material";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { LuCopy } from "react-icons/lu";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { FaDownload } from "react-icons/fa6";
import ManifestConditionData from '../../xmlFi/ManifestCondition'
import ManifestConditionDataVp from '../../xmlFi/ManifestConditionVp'
import Vast from '../../xmlFi/Vast'
import VastVp from '../../xmlFi/VastVp'
import SignalProcessing from '../../xmlFi/SignalProcessing'
import SignalProcessingVp from '../../xmlFi/SignalProcessingVp'
import { FaCheckDouble } from "react-icons/fa6";
import Tooltip from '@mui/material/Tooltip';
import CardContent from '@mui/material/CardContent';
import { CardActionArea } from '@mui/material';


const Documents = ({ selectedProject }) => {
    const [copied, setCopied] = useState(false);

    const handleCopy = (text) => {
        navigator.clipboard.writeText(text);
        setCopied(true);
    };

    const [activeTab, setActiveTab] = useState(1);
    const [selectedItem, setSelectedItem] = useState(null);
    const [xmlContent, setXmlContent] = useState("");

    const vppType = "Without source";

    const handleButtonClick = (buttonIndex) => {
        setActiveTab(buttonIndex);
    };

    // useEffect(() => {
    //     setSelectedItem('ManifestCondition')
    // }, []);

    const handleDownload = (fileName) => {
        let fileContent;
        switch (fileName) {
            case 'ManifestCondition':
                fileContent = ManifestConditionData;
                break;
            case 'SignalProcessing':
                fileContent = SignalProcessing;
                break;
            case 'Vast':
                fileContent = Vast;
                break;
            default:
                fileContent = "";
        }
        const blob = new Blob([fileContent], { type: 'text/xml' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${fileName}.xml`;
        a.click();
        URL.revokeObjectURL(url);
    };

    // const handleActiveTab = (fileName) => {
    //     setSelectedItem(fileName);
    //     let fileContent;
    //     switch (fileName) {
    //         case 'ManifestCondition':
    //             fileContent = ManifestConditionData;
    //             break;
    //         case 'SignalProcessing':
    //             fileContent = SignalProcessing;
    //             break;
    //         case 'Vast':
    //             fileContent = Vast;
    //             break;
    //         default:
    //             fileContent = "";
    //     }
    //     setCopied(false)
    //     setXmlContent(fileContent);
    // };

    const handleActiveTab = (fileName) => {
        setSelectedItem(fileName);
        let fileContent;
        switch (fileName) {
            case 'ManifestCondition':
                // Check if projectId and vppId match specific values
                if ((selectedProject?.projectId === "d66957a2-c0ac-4a3a-9e46-f51522ff5be0" &&
                    selectedProject?.clipSet.length > 0 &&
                    (selectedProject?.clipSet[0]?.VppId === "Qh8zmo4B45lqvD3FfhOY" || selectedProject?.clipSet[0]?.VppId === "8CEvmo4Bs3-8W0Ym9m-P"))) {
                    fileContent = ManifestConditionDataVp;
                } else {
                    fileContent = ManifestConditionData;
                }
                break;
            case 'SignalProcessing':
                // Similar checks for SignalProcessing
                if ((selectedProject?.projectId === "d66957a2-c0ac-4a3a-9e46-f51522ff5be0" &&
                    selectedProject?.clipSet.length > 0 &&
                    (selectedProject?.clipSet[0]?.VppId === "Qh8zmo4B45lqvD3FfhOY" || selectedProject?.clipSet[0]?.VppId === "8CEvmo4Bs3-8W0Ym9m-P"))) {
                    fileContent = SignalProcessingVp;
                } else {
                    fileContent = SignalProcessing;
                }
                break;
            case 'Vast':
                // Similar checks for Vast
                if ((selectedProject?.projectId === "d66957a2-c0ac-4a3a-9e46-f51522ff5be0" &&
                    selectedProject?.clipSet.length > 0 &&
                    (selectedProject?.clipSet[0]?.VppId === "Qh8zmo4B45lqvD3FfhOY" || selectedProject?.clipSet[0]?.VppId === "8CEvmo4Bs3-8W0Ym9m-P"))) {
                    fileContent = VastVp;
                } else {
                    fileContent = Vast;
                }
                break;
            default:
                fileContent = "";
        }
        setCopied(false);
        setXmlContent(fileContent);
    };


    useEffect(() => {
        handleActiveTab('ManifestCondition')
    }, []);

    console.log(selectedProject)

    const ManifestConditionContent = (
        <div >
            <Card sx={{ maxWidth: 345 }}>
                <CardActionArea>
                    <CardMedia
                        component="img"
                        height="140"
                        image="https://cdn-useast1.kapwing.com/static/gpI-video-editor-poster.webp"
                        alt="green iguana"
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                            Lizard
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            Lizards are a widespread group of squamate reptiles, with over 6,000
                            species, ranging across all continents except Antarctica
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
        </div>
    );

    const SignalProcessingContent = (
        <div >
            <Card sx={{ maxWidth: 345 }}>
                <CardActionArea>
                    <CardMedia
                        component="img"
                        height="140"
                        image="https://www.shutterstock.com/shutterstock/videos/1093044315/thumb/1.jpg?ip=x480"
                        alt="green iguana"
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                            Lizard
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            Lizards are a widespread group of squamate reptiles, with over 6,000
                            species, ranging across all continents except Antarctica
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
        </div>
    );

    const VastContent = (
        <div >
            <Card sx={{ maxWidth: 345 }}>
                <CardActionArea>
                    <CardMedia
                        component="img"
                        height="140"
                        image="https://ma-hub.imgix.net/wp-images/2019/10/18195807/5-Stages-of-Video-Editing.jpg"
                        alt="green iguana"
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                            Lizard
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            Lizards are a widespread group of squamate reptiles, with over 6,000
                            species, ranging across all continents except Antarctica
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
        </div>
    );




    return (
        <Grid container spacing={2} sx={{ minWidth: 1200, maxWidth: 1200 }} display='flex' ml={0.5} mt={1} justifyContent='space-between'>
            <Grid xs={3.9} style={{ minHeight: "582px", borderRadius: "8px", background: "white", border: "1px solid #e0e0e0" }}>
                <Grid xs={12} style={{ borderBottom: "1px solid #e0e0e0" }} >
                    <Button variant='Tab'
                        sx={{
                            borderLeft: '1px solid #7a7a7a',
                            color: activeTab === 1 ? 'white' : '#7a7a7a',
                            background: activeTab === 1 ? '#7a7a7a' : 'white',
                            msTransform: 'skewX(0deg)',
                            webkitTransform: 'skewX(0deg)',
                            transform: 'skewX(0deg)', '&:hover': {
                                background: '#7a7a7a',
                                color: 'white',
                            },
                        }} onClick={() => handleButtonClick(1)}
                    >AWS</Button>

                    <Button variant='Tab'
                        sx={{
                            color: activeTab === 2 ? 'white' : '#7a7a7a',
                            background: activeTab === 2 ? '#7a7a7a' : 'white', msTransform: 'skewX(0deg)',
                            webkitTransform: 'skewX(0deg)',
                            transform: 'skewX(0deg)', '&:hover': {
                                background: '#7a7a7a',
                                color: 'white',
                            },
                        }} onClick={() => handleButtonClick(2)}
                        disabled>GCP</Button>

                    <Button variant='Tab'
                        sx={{
                            color: activeTab === 3 ? 'white' : '#7a7a7a',
                            background: activeTab === 3 ? '#7a7a7a' : 'white', msTransform: 'skewX(0deg)',
                            webkitTransform: 'skewX(0deg)',
                            transform: 'skewX(0deg)', '&:hover': {
                                background: '#7a7a7a',
                                color: 'white',
                            },
                        }} onClick={() => handleButtonClick(3)}
                        disabled>Azure</Button>
                </Grid>
                {activeTab === 1 &&
                    (
                        <Grid xs={12}>


                            <Grid xs={11} ml={2} mr={2} mt={1} mb={1} style={{ background: selectedItem === 'ManifestCondition' ? "black" : "white", borderRadius: "10px", height: "35px", border: "1px solid #e0e0e0", cursor: "pointer" }}
                                display='flex' justifyContent='space-between' alignItems='center' onClick={() => handleActiveTab('ManifestCondition')}>
                                <Typography
                                    ml={2} style={{ color: selectedItem === 'ManifestCondition' ? 'white' : 'black' }}
                                >
                                    ManifestCondition XML&nbsp;&nbsp;
                                    <Tooltip title={ManifestConditionContent} arrow placement="right-start">
                                        (i)
                                    </Tooltip>
                                </Typography>


                                <Typography onClick={() => handleDownload("ManifestCondition")} mr={2} style={{ color: selectedItem === 'ManifestCondition' ? 'white' : 'black', cursor: "pointer" }}>
                                    <FaDownload />
                                </Typography>
                            </Grid>

                            <Grid xs={11} ml={2} mr={2} mt={1} mb={1} style={{ background: selectedItem === 'SignalProcessing' ? "black" : "white", borderRadius: "10px", height: "35px", border: "1px solid #e0e0e0", cursor: "pointer" }}
                                display='flex' justifyContent='space-between' alignItems='center' onClick={() => handleActiveTab('SignalProcessing')}>
                                <Typography ml={2} style={{ color: selectedItem === 'SignalProcessing' ? 'white' : 'black' }} >
                                    SignalProcessing XML&nbsp;&nbsp;
                                    <Tooltip title={SignalProcessingContent} arrow placement="right-start">
                                        (i)
                                    </Tooltip>
                                </Typography>
                                <Typography onClick={() => handleDownload("SignalProcessing")} mr={2} style={{ color: selectedItem === 'SignalProcessing' ? 'white' : 'black', cursor: "pointer" }}>
                                    <FaDownload />
                                </Typography>
                            </Grid>

                            <Grid xs={11} ml={2} mr={2} mt={1} mb={1} style={{ background: selectedItem === 'Vast' ? "black" : "white", borderRadius: "10px", height: "35px", border: "1px solid #e0e0e0", cursor: "pointer" }}
                                display='flex' justifyContent='space-between' alignItems='center' onClick={() => handleActiveTab('Vast')}>
                                <Typography ml={2} style={{ color: selectedItem === 'Vast' ? 'white' : 'black' }} >
                                    VAST XML&nbsp;&nbsp;
                                    <Tooltip title={VastContent} arrow placement="right-start">
                                        (i)
                                    </Tooltip>
                                </Typography>
                                <Typography onClick={() => handleDownload("Vast")} mr={2} style={{ color: selectedItem === 'Vast' ? 'white' : 'black', cursor: "pointer" }}>
                                    <FaDownload />
                                </Typography>
                            </Grid>

                        </Grid>
                    )
                }



            </Grid>

            <Grid xs={7.9} style={{ minHeight: "582px", borderRadius: "8px", background: "#2f2f2fa3" }}>
                <Grid xs={12} style={{ height: "35px", background: "#2f2f2f", borderTopLeftRadius: "8px", borderTopRightRadius: "8px" }} display='flex' justifyContent='space-between' alignItems='center' >
                    <Grid>
                        <Typography onClick={() => { handleCopy(xmlContent) }} style={{ color: "#a4a4a4", marginLeft: "7px" }} >Code</Typography>
                    </Grid>
                    <Grid display='flex'>
                        {/* {copied && <span style={{ color: "green", marginRight: "10px" }}>Copied!</span>} */}
                        <Typography onClick={() => { handleCopy(xmlContent) }} style={{ color: "#a4a4a4", marginRight: "17px", cursor: "pointer" }} >
                            {copied === false ? <LuCopy style={{ marginRight: "4px" }} /> :
                                <FaCheckDouble style={{ marginRight: "4px", color: "green", }} />
                            }
                            Copy</Typography>
                    </Grid>
                </Grid>

                <Grid xs={12} sx={{ color: "white", minHeight: "530px", maxHeight: "530px" }} ml={1} mb={1} >
                    {/* <Typography>
                        <Typography>{xmlContent}</Typography>
                    </Typography> */}
                    <pre style={{ minHeight: "535px", maxHeight: "535px", marginRight: "8px" }}>
                        <code>
                            {xmlContent}
                        </code>
                    </pre>
                </Grid>
            </Grid>

        </Grid>
    )
}

export default Documents



{/* <Grid xs={12} style={{ borderBottom: "1px solid #e0e0e0" }} >
<Button variant='Tab'
    sx={{
        borderLeft: '1px solid #7a7a7a',
        color: activeTab === 1 ? 'white' : '#7a7a7a',
        background: activeTab === 1 ? '#7a7a7a' : 'white',
        msTransform: 'skewX(0deg)',
        webkitTransform: 'skewX(0deg)',
        transform: 'skewX(0deg)', '&:hover': {
            background: '#7a7a7a',
            color: 'white',
        },
    }} onClick={() => handleButtonClick(1)}
>AWS</Button>

<Button variant='Tab'
    sx={{
        color: activeTab === 2 ? 'white' : '#7a7a7a',
        background: activeTab === 2 ? '#7a7a7a' : 'white', msTransform: 'skewX(0deg)',
        webkitTransform: 'skewX(0deg)',
        transform: 'skewX(0deg)', '&:hover': {
            background: '#7a7a7a',
            color: 'white',
        },
    }} onClick={() => handleButtonClick(2)}
    disabled>GCP</Button>

<Button variant='Tab'
    sx={{
        color: activeTab === 3 ? 'white' : '#7a7a7a',
        background: activeTab === 3 ? '#7a7a7a' : 'white', msTransform: 'skewX(0deg)',
        webkitTransform: 'skewX(0deg)',
        transform: 'skewX(0deg)', '&:hover': {
            background: '#7a7a7a',
            color: 'white',
        },
    }} onClick={() => handleButtonClick(3)}
    disabled>Azure</Button>
</Grid>
{activeTab === 1 &&
} */}