
import { Box, Button, Grid, Hidden, DialogActions } from '@mui/material';
import React, { useEffect, useState } from 'react';
// import { useHistory } from 'react-router-dom';
import SearchBar from './SearchBar';
import CommonTable from '../CommonComponet/CommonTable';
import { Chip, IconButton, Dialog, DialogTitle, DialogContent } from '@mui/material';
import { IoMdArrowBack } from 'react-icons/io'
import { MoreHoriz } from '@mui/icons-material';
import { Img, Tabel } from './../../ThemeProvider/style';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { useDispatch } from "react-redux";
import { setShowLoader } from '../../redux/loaderSlice';
import NoData from '../CommonComponet/NoData';
import ProjectCard from '../CommonComponet/ProjectCard';
import { TfiMenuAlt } from 'react-icons/tfi';
import { BsGrid3X2GapFill } from 'react-icons/bs';
import VppPlayer from '../CommonComponet/VppPlayer';
import { ToggleButton, ToggleButtonGroup, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Accordion, AccordionSummary, Typography, AccordionDetails } from '@mui/material';
import { Popover, List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import BasicTable, { PerPageView } from '../CommonComponet/BasicTable';
import ApproveDialog from './ApproveDialog';
import CommentDialog from '../CommonComponet/CommentDialog';
import RejectDialog from '../CommonComponet/RejectDialog';
import { v4 as uuidv4 } from 'uuid';
import { awsApiCamp } from '../../Routes/Server';
import { useSelector } from 'react-redux';
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu';
import CustomizedTables from '../DashboardComponet/MuiTabel';
import { LiaWindowClose } from "react-icons/lia";
import { RiCheckboxLine } from "react-icons/ri";
import { useNavigate } from 'react-router-dom';
import Loader from '../CommonComponet/Loader';
import CampaignsCard from '../CommonComponet/CampaignsCard';
import TrackDilog from './TrackDilog';



const InProgress = () => {
    const dispatch = useDispatch();
    // const history = useHistory();
    const navigate = useNavigate();
    const userData = useSelector((state) => state.user.userLoginData);

    const [searchText, setSearchText] = useState('');
    const [rows, setRows] = useState([]);
    const [lastEvaluatedKey, setLastEvaluatedKey] = useState(undefined);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [gridView, setGridView] = useState(false);
    const [listView, setListView] = useState(true);
    const [openPlayer, setOpenPlayer] = useState(false);
    const [selectedProject, setSelectedProject] = useState(null);
    const [updateMessages, setUpdateMessages] = useState('')
    const [selectedApproveRow, setSelectedApproveRow] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [approveDialogOpen, setApproveDialogOpen] = useState(false);
    const [rejectMessage, setRejectMessage] = useState('');
    const [rejectDialogOpen, setRejectDialogOpen] = useState(false);
    const [brandDialogOpen, setBrandDialogOpen] = useState(false);
    const [actionValue, setAactionValue] = useState(1)
    const [trackCamp, setTrackCamp] = useState(false);

    const generateRandomText = () => {
        const randomText = Math.random().toString(36).substring(7);
        return randomText;
    };

    const handleUpdateMessages = () => {
        const randomText = generateRandomText();
        setUpdateMessages(randomText);
    };

    useEffect(() => {
        async function fetchInProgress() {
            dispatch(setShowLoader(true));

            try {
                const res = await awsApiCamp.post("/getcampaign", {
                    pageSize: 10,
                    type: "in_progress",
                    page: 1,
                    lastEvaluatedKey: lastEvaluatedKey,
                });
                if (res.data.statusCode === 200) {
                    const data = JSON.parse(res.data.body);
                    setRows(data.items);
                    setLastEvaluatedKey(data.lastEvaluatedKey);
                    if (selectedProject && selectedProject.id) {
                        const updatedProject = data.items.find(item => item.id === selectedProject.id);
                        if (updatedProject) {
                            setSelectedProject(updatedProject);
                        }
                    }
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                dispatch(setShowLoader(false));
            }
        }

        fetchInProgress();
    }, [updateMessages]);

    // console.log('rows', rows);

    const handleSetRows = (updatedRows) => {
        setRows(updatedRows);
    };

    const handleOpenDialog = (rowData) => {
        setSelectedRow(rowData);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handelTrack = (project) => {
        setSelectedProject(project);
        setTrackCamp(true);

    };

    const handleDownload = (id) => {
        // console.log("Download project with ID:", id);
    };
    rows.forEach((row, index) => {
        row._index = index + 1;
    });

    const getValue = (row) => row._index;

    // console.log("row", rows)

    const columns = [
        {
            field: 'index',
            headerName: 'S.No',
            width: 60,
            valueGetter: (params) => params.row._index,
        },
        {
            field: "projectTitle",
            headerName: "Title",
            width: 300,
            renderCell: (params) => (

                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {/* <Img src={params.row.thumbnail} alt="Project Title" onClick={() => handelOpenPlayer(params.row)} /> */}
                    <Box sx={{ fontWeight: 'bold', color: 'black', cursor: "pointer" }} onClick={() => handelOpenPlayer(params.row)}>{params.row.clipSet[0].projectTitle}</Box>
                </Box>
            ),
        },
        {
            field: "Campaign Id", headerName: "Campaign Id", width: 150,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ fontWeight: 'normal', color: 'black' }}>{params.row.clipSet[0].VppFreamNo}</Box>
                </Box>
            ),
        },

        {
            field: "brandCat", headerName: "Brand Catagory", width: 150,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ fontWeight: 'normal', color: 'black' }}>{params.row.clipSet[0].VppFreamNo}</Box>
                </Box>
            ),
        },
        {
            field: "currentTime", headerName: "Date Of Creation", width: 150,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ fontWeight: 'normal', color: 'black' }}>{params.row.clipSet[0].currentTime}</Box>
                </Box>
            ),
        },
        {
            field: "catagory",
            headerName: "Media Type",
            width: 165,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ fontWeight: 'normal', color: 'black' }}>{params.row.clipSet[0].catagory}</Box>
                </Box>
            ),
        },
        {
            field: "videoId", headerName: "Branded Sense", width: 150,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ fontWeight: 'normal', color: 'black' }}>{params.row.clipSet[0].VppFreamNo}</Box>
                </Box>
            ),
        },
        {
            field: "value",
            headerName: "Brand Creative",
            width: 175,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ fontWeight: 'normal', color: 'black' }}> {params.row.paymentDetails?.userName || 'N/A'}</Box>
                </Box>
            ),
        },
        {
            field: "status",
            headerName: "Status",
            width: 200,
            renderCell: (params) => {
                const brandStatus = params.row.brandStatus;
                const creatorStatus = params.row.creatorStatus;

                let label = params.row.status.charAt(0).toUpperCase() + params.row.status.slice(1);
                let color = "secondary";
                if (brandStatus && creatorStatus) {
                    label = label.charAt(0).toUpperCase() + label.slice(1);
                    color = "success";
                } else if (!brandStatus && creatorStatus) {
                    label = "Brand Approval Pending";
                    color = "warning";
                } else if (brandStatus && !creatorStatus) {
                    label = "Your Approval Pending";
                    color = "warning";
                }
                return (
                    <Chip
                        label={label}
                        color={color}
                        variant="outlined"
                        size="small"
                        sx={{ width: '180px', height: '30px' }}
                    />
                );
            },
        },
        { field: "comment", headerName: "Comment", width: 300 },
        {
            field: 'id',
            headerName: 'Action',
            description: 'This column has a value getter and is not sortable.',
            sortable: false,
            width: 130,
        },
        // {
        //     field: "track", headerName: "Track", width: 300,
        // },
    ];


    const handleSearchChange = (event) => {
        setSearchText(event.target.value)
    };

    const handleBrandClick = (row) => {
        setSelectedApproveRow(row);
        setBrandDialogOpen(true);
    }




    const filteredData = rows
        .filter((item) => {
            const searchValue = searchText.toLowerCase();
            const rowData = [item.clipSet[0].VppId, item.clipSet[0].projectTitle, item.clipSet[0].category, item.clipSet[0].brandCat];
            return rowData.some((cell) => String(cell).toLowerCase().includes(searchValue));
        })
        .sort((a, b) => {
            const timestampA = parseInt(a.currentTime, 10);
            const timestampB = parseInt(b.currentTime, 10);
            return timestampB - timestampA; // Sort in descending order (newest first)
        });


    const handelOpenPlayer = (project) => {
        setSelectedProject(project);
        setOpenPlayer(true);
        setAactionValue(1)
    };

    const handelBarndedPlayer = (project) => {
        setSelectedProject(project);
        setOpenPlayer(true);
        setAactionValue(3)
    };

    console.log(filteredData)


    const decideColor = (row) => {
        const brandStatus = row.brandStatus;
        const creatorStatus = row.creatorStatus;
        let label = row.status.charAt(0).toUpperCase() + row.status.slice(1);
        let color = "secondary";
        if (brandStatus && creatorStatus) {
            label = label.charAt(0).toUpperCase() + label.slice(1);
            color = "success";
        } else if (!brandStatus && creatorStatus) {
            label = "Waiting for Advertiser's approval";
            color = "warning";
        } else if (brandStatus && !creatorStatus) {
            label = "Waiting for your approval";
            color = "warning";
        } else if (!brandStatus && !creatorStatus) {
            label = "Both Approval Pending";
            color = "secondary";
        }
        return { label, color };
    }

    // const handleApprove =()=>{
    //     console.log("handle Approve clicked")
    // }

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleApproveClick = (row) => {
        setSelectedApproveRow(row);
        handleMenuClose();
        setApproveDialogOpen(true);
    };

    const handleRejectDialog = (row) => {
        handleMenuClose();
        setSelectedApproveRow(row);
        setRejectMessage('');
        setRejectDialogOpen(true);
    };



    const handleApprove = async ({ liveDate, completeDate, row }) => {
        dispatch(setShowLoader(true));
        try {

            const updatedClipSet = row.clipSet.map(clip => {
                return {
                    ...clip,
                };
            });
            const updatedRowData = {
                ...row,
                liveDate,
                completeDate,
                creatorStatus: true,
                status: "Starting Soon",
                clipSet: updatedClipSet,
                type: 'active'
            };

            const res = await awsApiCamp.post('/postcampaign', {
                ...updatedRowData,
            });

            if (res.data.statusCode === 200) {
                handleUpdateMessages()
                setApproveDialogOpen(false);
            }
        } catch (error) {
            console.error('Error approving data:', error);
        } finally {
            dispatch(setShowLoader(false));
        }
    };

    const handleReject = async (row) => {
        console.log('handleReject called with row:', row);
        dispatch(setShowLoader(true));
        try {
            if (!row || !row.clipSet) {
                console.error('Row or clipSet is undefined or null');
                return;
            }
            const updatedClipSet = row.clipSet.map((clip) => {
                if (clip) {
                    return {
                        ...clip,
                    };
                }
                return null;
            });

            console.log('Updated clipSet:', updatedClipSet);
            const updatedRowData = {
                ...row,
                creatorStatus: false,
                clipSet: updatedClipSet,
                status: "reject",
                rejectBy: "Producer",
                type: "reject",
                rejectMassage: rejectMessage
            };
            console.log('Before Axios request', updatedRowData);
            const res = await awsApiCamp.post('/postcampaign', {
                ...updatedRowData,
            });
            console.log('After Axios request', res);
            if (res.data.statusCode === 200) {
                handleUpdateMessages()
                setRejectDialogOpen(false);
            }
        } catch (error) {
            console.error('Error rejecting data:', error);
        } finally {
            dispatch(setShowLoader(false));
        }
    };


    // const IconComponent = ({ type }) => {
    //     switch (type) {
    //         case 'Approve':
    //             return <RiCheckboxLine />;
    //         case 'Reject':
    //             return <LiaWindowClose />;
    //         // Add more cases for other buttonTypes
    //         default:
    //             return null;
    //     }
    // };

    const handleComment = (row) => {
        const id = row.id;
        // history.push(`/Message?id=${id}`);
        navigate(`/Message?id=${id}`);
    };

    console.log('In progress SP', selectedProject)


    return (

        <Grid xs={12} >
            <Grid xs={11.9} mt={1} display='flex' justifyContent='space-between' alignItems='center' >
                <Grid xs={2}>
                    <SearchBar onChange={handleSearchChange} />
                </Grid>
                <Grid xs={0.45} >
                    {listView === true ? (
                        <Button
                            variant="B5"
                            onClick={() => {
                                setGridView(true);
                                setListView(false);
                            }}
                        >
                            <BsGrid3X2GapFill />
                        </Button>
                    ) : (
                        <Button
                            variant="B5"
                            onClick={() => {
                                setGridView(false);
                                setListView(true);
                            }}
                        >
                            <TfiMenuAlt />
                        </Button>
                    )}
                </Grid>
            </Grid>

            {gridView && (
                <Grid xs={12} mt={3}>
                    <Grid xs={12} variant='G3'>
                        {filteredData.length === 0 ? (
                            <NoData />
                        ) : (
                            <Grid container item xs={12} spacing={3} style={{ margin: '0rem 1rem' }}>
                                {filteredData.map((project) => (
                                    <CampaignsCard
                                        key={project.projectId}
                                        contentType={project.clipSet[0].category}
                                        projectTitle={project.clipSet[0].projectTitle}
                                        campaignId={project.clipSet[0].VppName}
                                        brandCatagory={project.clipSet[0].brandCat}
                                        dateOfCreation={project.currentTime}
                                        brandLogo={project.brandLogo?.value}
                                        comment={project.clipSet[0]}
                                        handleBrandClick={() => handleBrandClick(project)}
                                        decideColor={() => decideColor(project)}
                                        handleComment={() => handleComment(project)}
                                        handelBarndedPlayer={() => handelBarndedPlayer(project)}
                                        handleApproveClick={()=>handleApproveClick(project)}
                                        handleRejectDialog={()=>handleRejectDialog(project)}
                                        price={project.clipSet[0].price}
                                        vppFreamNo={project.clipSet[0].VppFreamNo}
                                        thumbnail={project.clipSet[0].thumbnail}
                                        projectId={project.id}
                                        label={project.status.label}
                                        color={project.status.color}
                                        project={project}
                                        handelOpenPlayer={() => handelOpenPlayer(project)}
                                        viId={project.videoId}
                                        cardType={'other'}
                                    />
                                ))
                                }
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            )}
            {listView && (
                <Grid xs={12} mt={3} mb={2}>
                    {filteredData.length === 0 ? (
                        <NoData />
                    ) : (
                        <>
                            {/* <DataGrid
                                rows={filteredData}
                                columns={columns}
                                disableRowSelectionOnClick
                                sx={{ color: '#526484', minHeight: "38.5vh" }}
                                initialState={{
                                    ...rows.initialState,
                                    pagination: { paginationModel: { pageSize: 5 } },
                                }}
                                pageSizeOptions={[5, 10, 25]} disableColumnMenu
                            /> */}
                            <CustomizedTables
                                filterRow={filteredData}
                                columns={columns}
                                rowAttributes={[
                                    { name: "projectTitle", type: 'regular', child: "clipSet", newStyle: "pointer", fontWeight: "bold", action: handelOpenPlayer, TextDecoder: "underline", },
                                    { name: "VppName", type: 'regular', child: "clipSet", action: handelOpenPlayer, },
                                    { name: "brandCat", type: 'chip', child: "clipSet", action: handelOpenPlayer, },
                                    { name: "currentTime", type: 'regular', action: handelOpenPlayer, valueType: 'date' },
                                    { name: "category", type: 'regular', child: "clipSet", action: handelOpenPlayer, },
                                    { name: "videoId", type: 'thumbnail', action: handelBarndedPlayer },
                                    { name: "value", type: 'image', objectChild: "brandLogo", action: handleBrandClick },
                                    { name: "status", type: 'newChip', child: "clipSet", typeColorCallback: decideColor ,action: handelTrack},
                                    {
                                        name: "comment", type: 'regular', child: "clipSet", objectArray: "commentsProducer", newStyle: "pointer",
                                        color: "#0f87ff", TextDecoder: "underline", action: handleComment
                                    },
                                    {
                                        name: "id",
                                        type: 'quikAction',
                                        child: "clipSet",
                                        actions: [
                                            { label: 'Approve', buttonType: 'Approve', buttonColor: "#00ff0070", action: handleApproveClick },
                                            { label: 'Reject', buttonType: 'Reject', buttonColor: "#ff000085", action: handleRejectDialog },
                                        ],
                                    },
                                    // { name: "status", type: 'track', action: handelTrack },

                                ]}
                            />
                        </>
                    )}
                </Grid>
            )}

            <ApproveDialog
                open={approveDialogOpen}
                onClose={() => setApproveDialogOpen(false)}
                onApprove={(liveDate, completeDate) => handleApprove({ liveDate, completeDate, row: selectedApproveRow })}
            />

            <RejectDialog
                open={rejectDialogOpen}
                onClose={() => setRejectDialogOpen(false)}
                rejectMessage={rejectMessage}
                setRejectMessage={setRejectMessage}
                onRejectSubmit={() => handleReject(selectedApproveRow)}
            />
            <VppPlayer
                setOpenPlayer={setOpenPlayer}
                openPlayer={openPlayer}
                videoData={filteredData}
                selectedProject={selectedProject}
                handleApproveClick={handleApproveClick}
                handleRejectDialog={handleRejectDialog}
                type={"producer"}
                handleComment={handleComment}
                handleUpdateMessages={handleUpdateMessages}
                actionValue={actionValue}
            />
            <TrackDilog
                setTrackCamp={setTrackCamp}
                trackCamp={trackCamp}
                selectedProject={selectedProject}
                type={"producer"}
                track={'draft'}
            />
            <Dialog
                fullWidth={true}
                maxWidth='xs'
                open={brandDialogOpen} onClose={() => setBrandDialogOpen(false)}
            >
                <DialogTitle style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant='h6'>Brand Logo</Typography>
                </DialogTitle>
                <DialogContent sx={{ display: "flex", justifyContent: 'center', alignItems: 'center' }}>
                    <img src={selectedApproveRow?.brandLogo?.value || 'https://www.ultimatesource.toys/wp-content/uploads/2013/11/dummy-image-landscape-1.jpg'} style={{
                        width: '300px',
                        height: '300px',
                        marginTop: '10px',
                        borderRadius: "8px",
                    }} />
                </DialogContent>
                <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
                    <Button onClick={() => setBrandDialogOpen(false)} variant="outlined" color="error" sx={{ marginBottom: "7px" }}>
                        Close
                    </Button>
                </DialogActions>
                <Loader />
            </Dialog>
        </Grid>



    );
};

export default InProgress;


