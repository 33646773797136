import DashboardBrand from "../Pages/DashboardBrand";
import MarketPlace from "../Pages/MarketPlace";
import CampaignsBrand from "../Pages/CampaignsBrand";
import VppMainBrand from "../Components/MraketPlace/VppMainBrand";
import Gallary from "../Pages/Gallary";
import MessageBrand from "../Pages/MessageBrand";
import { TbPlayerTrackNextFilled } from 'react-icons/tb'
import { SiWechat } from 'react-icons/si'
import { TfiGallery } from 'react-icons/tfi'
import { ImBook } from 'react-icons/im'
import { HiSquaresPlus } from 'react-icons/hi2'
import Profile from '../Components/ProfileComponet/Profile'
import { IoCameraReverse } from "react-icons/io5";
import SingleCamera from "../Pages/SingleCamera";
// import SingleShotView from "../Components/SingleCameraComponet/SingleShotView";

const routesBrand = [
    {
        name: "Dashboard",
        path: "/DashboardBrand",
        component: DashboardBrand,
        icon: <HiSquaresPlus />
    },

    {
        name: "MarketPlace",
        path: "/MarketPlace",
        component: MarketPlace,
        icon: <TbPlayerTrackNextFilled />
    },
    {
        name: "Campaigns",
        path: "/CampaignsBrand",
        component: CampaignsBrand,
        icon: <ImBook />
    },
    // {
    //     name: "Single Camera",
    //     path: "/SingleCamera",
    //     component: SingleCamera,
    //     icon: <IoCameraReverse />
    // },
    {
        name: "Message",
        path: "/MessageBrand",
        component: MessageBrand,
        icon: <SiWechat />
    },
    {
        name: "Gallery",
        path: "/Gallary",
        component: Gallary,
        icon: <TfiGallery />
    },

    {
        path: "/VppMainBrand",
        name: "",
        component: VppMainBrand,
    },
    // {
    //     path: "/SingleShotView",
    //     name: "",
    //     component: SingleShotView,
    // },
   
    {
        path: "/Profile",
        name: "",
        component: Profile,
    },
];

export default routesBrand;
