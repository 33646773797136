// import { Grid, Typography } from '@mui/material';
// import React, { useState, useEffect } from 'react';
// import { RiUploadCloud2Fill } from 'react-icons/ri';
// import axios from 'axios';
// import { useDispatch } from "react-redux";
// import { setShowLoader } from '../../redux/loaderSlice';
// import { BrandLogo, UplaodButton } from '../../ThemeProvider/style';
// import { v4 as uuidv4 } from 'uuid';
// import { optimusApi, awsApi } from '../../Routes/Server';
// import { useSelector } from 'react-redux';

// const Container = () => {
//     const dispatch = useDispatch();
//     const [images, setImages] = useState([]);
//     const userData = useSelector((state) => state.user.userLoginData);



//     const handleImageUpload = async (files) => {
//         console.log(files)
//         const formData = new FormData();
//         const brandId = userData.sub;
//         for (let i = 0; i < files.length; i++) {
//             formData.append('file', files[i]);
//         }
//         formData.append('brandId', brandId);
//         try {
//             dispatch(setShowLoader(true));
//             const response = await optimusApi.post('/upload-brand-asset', formData, {
//                 headers: {
//                     'Content-Type': 'multipart/form-data',
//                 },
//             });
//             console.log('Upload response:', response.data);
//             setImages(response.data);

//             dispatch(setShowLoader(false));

//         } catch (error) {
//             console.error('Error uploading images:', error);
//             dispatch(setShowLoader(false));
//         }
//     };

//     useEffect(() => {
//         const fetchImages = async () => {
//             try {
//                 dispatch(setShowLoader(true));
//                 const payload = {
//                     assetAction: 'get-asset',
//                     brandId: userData.sub,
//                 };
//                 const response = await awsApi.post('/postproject', payload, {
//                     headers: {
//                         'Content-Type': 'application/json',
//                     },
//                 });


//                 // Check if the body property is present
//                 const responseBody = JSON.parse(response.data.body);

//                 // Ensure that the parsed response body is an array before setting it
//                 if (Array.isArray(responseBody)) {
//                     setImages(responseBody);
//                 } else {
//                     console.error('Invalid response format. Expected an array.');
//                 }
//                 console.log('Raw Response:', responseBody);
//                 dispatch(setShowLoader(false));
//             } catch (error) {
//                 console.error('Error fetching images:', error);
//                 dispatch(setShowLoader(false));
//             }
//         };
//         fetchImages();
//     }, [userData.sub, dispatch]);

//     return (
//         <Grid container >
//             <Grid item xs={2} variant='G24' >
//                 <UplaodButton
//                     component="label"
//                     style={{ marginBottom: 10 }}
//                 >
//                     <RiUploadCloud2Fill style={{ fontSize: '178px' }} />
//                     <input
//                         type="file"
//                         style={{ display: 'none' }}
//                         multiple
//                         onChange={(e) => handleImageUpload(e.target.files)}
//                     />
//                 </UplaodButton>
//                 <Typography variant='P15' >Upload Image</Typography>
//             </Grid>
//             {/* {images && images.length && images.map((image, index) => (
//                 <Grid item xs={2} key={index} variant='G24'>

//                     <BrandLogo src={image.url} alt={`Image ${index}`} />

//                 </Grid>
//             ))} */}

//             {images &&
//                 images.length > 0 &&
//                 images.map((image, index) => (
//                     <Grid item xs={2} key={index} variant='G24'>
//                         {image.mimeType === 'application/json' ? (
//                             <div>JSON Thumbnail</div>
//                         ) : (
//                             <BrandLogo src={image.Location} alt={`Image ${index}`} />
//                         )}
//                     </Grid>
//                 ))}
//         </Grid>
//     );
// };

// export default Container;



import { Grid, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { RiUploadCloud2Fill } from 'react-icons/ri';
import axios from 'axios';
import { useDispatch } from "react-redux";
import { setShowLoader } from '../../redux/loaderSlice';
import { BrandLogo, UplaodButton } from '../../ThemeProvider/style';
import { v4 as uuidv4 } from 'uuid';
import { optimusApi, awsApi } from '../../Routes/Server';
import { useSelector } from 'react-redux';

const Container = () => {
    const dispatch = useDispatch();
    const [images, setImages] = useState([]);
    const userData = useSelector((state) => state.user.userLoginData);

    const fetchImages = async () => {
        try {
            dispatch(setShowLoader(true));
            const payload = {
                assetAction: 'get-asset',
                brandId: userData.sub,
            };
            const response = await awsApi.post('/postproject', payload, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });


            // Check if the body property is present
            const responseBody = JSON.parse(response.data.body);

            // Ensure that the parsed response body is an array before setting it
            if (Array.isArray(responseBody)) {
                setImages(responseBody);
            } else {
                console.error('Invalid response format. Expected an array.');
            }
            console.log('Raw Response:', responseBody);
            dispatch(setShowLoader(false));

        } catch (error) {
            console.error('Error fetching images:', error);
            dispatch(setShowLoader(false));
        }
    };

    const handleImageUpload = async (files) => {
        console.log(files)
        const formData = new FormData();
        const brandId = userData.sub;
        for (let i = 0; i < files.length; i++) {
            formData.append('file', files[i]);
        }
        formData.append('id', brandId);
        formData.append('uploadType', "asset-upload");
        try {
            dispatch(setShowLoader(true));
            const response = await optimusApi.post('/upload-asset', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            console.log('Upload response:', response.data);
            setImages(response.data);
            setTimeout(fetchImages, 100);
            dispatch(setShowLoader(false));

        } catch (error) {
            console.error('Error uploading images:', error);
            dispatch(setShowLoader(false));
        }
    };

    useEffect(() => {

        fetchImages();
    }, [userData.sub, dispatch]);

    return (
        <Grid container >
            <Grid item xs={2} variant='G24' >
                <UplaodButton
                    component="label"
                    style={{ marginBottom: 10 }}
                >
                    <RiUploadCloud2Fill style={{ fontSize: '178px' }} />
                    <input
                        type="file"
                        style={{ display: 'none' }}
                        multiple
                        onChange={(e) => handleImageUpload(e.target.files)}
                    />
                </UplaodButton>
                <Typography variant='P15' >Upload Image</Typography>
            </Grid>
            {/* {images && images.length && images.map((image, index) => (
                <Grid item xs={2} key={index} variant='G24'>

                    <BrandLogo src={image.url} alt={`Image ${index}`} />

                </Grid>
            ))} */}

            {images &&
                images.length > 0 &&
                images.map((image, index) => (
                    <Grid item xs={2} key={index} variant='G24'>
                        {image.mimeType === 'application/json' ? (
                            <div>JSON Thumbnail</div>
                        ) : (
                            <BrandLogo src={image.Location} alt={`Image ${index}`} />
                        )}
                        <Typography variant='CHead' style={{marginTop:'4px',marginBottom:'4px'}}>{image.assetName.split('.')[0]}</Typography>
                    </Grid>
                ))}
        </Grid>
    );
};

export default Container;

