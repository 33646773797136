import React, { useEffect, useState } from 'react';
import { Grid, Paper, Typography, Button, Stack, Box, Chip } from "@mui/material";
import { TfiMenuAlt } from 'react-icons/tfi';
import { BsGrid3X2GapFill } from 'react-icons/bs';
import ListCard from '../CommonComponet/ListCard';
import CommonTable from '../CommonComponet/CommonTable';
import { addProjectId } from '../../redux/projectSlice';
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { setShowLoader } from '../../redux/loaderSlice';
import NoData from '../CommonComponet/NoData';
import Loader from '../CommonComponet/Loader';
import { DataGrid } from '@mui/x-data-grid';
import defaultThumbnail from '../../assets/NoVideo.png'
import { Action, Img } from '../../ThemeProvider/style';
import { generateThumbnailURL } from '../../Routes/apiConfig';
import CustomizedTables from '../DashboardComponet/MuiTabel';

const Container = ({ selectedType, setSelectedType, project, setProject, rows, setRows }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [gridView, setGridView] = useState(true);
    const [listView, setListView] = useState(false);



    // console.log('rows', rows)
    // const columns = [
    //     'S.No',
    //     'Title',
    //     'Genre',
    //     'Description',
    //     'No of VPOs',
    //     'Episode No',
    //     'Season',
    // ];

    if (rows) {
        rows.forEach((row, index) => {
            row._index = index + 1;
        });
    }



    const columns = [
        {
            field: 'index',
            headerName: 'S.No',
            width: 60,
            valueGetter: (params) => params.row._index,
        },
        {
            field: "title",
            headerName: "Title",
            width: 350, sortable: false,
            renderCell: (params) => {
                let videoId;
                if (params.row.video && params.row.video.length > 0) {
                    videoId = params.row.video[0].videoId;
                } else {
                    videoId = null;
                }
                const thumbnailURL = generateThumbnailURL(videoId);
                return (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>

                        <Img src={thumbnailURL} alt="Project Title" onClick={() => handleNavigate(params.row.id)} />
                        <Box sx={{ fontWeight: 'bold', color: 'black', cursor: "pointer" }} onClick={() => handleNavigate(params.row.id)}>{params.row.title}</Box>
                    </Box>
                );
            },
        },
        {
            field: "contentType", headerName: "Content Type", width: 150, sortable: false,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ fontWeight: 'normal', color: 'black' }}>{params.row.contentType}</Box>
                </Box>
            ),
        },
        {
            field: "totalVpo",
            headerName: "No of VPOs", sortable: false,
            width: 165,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ fontWeight: 'normal', color: 'black' }}>{params.row.totalVpo}</Box>
                </Box>
            ),
        },
        {
            field: "releasePlatform",
            headerName: "Release Platform", sortable: false,
            width: 250,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ fontWeight: 'normal', color: 'black' }}>{params.row.releasePlatform.join(', ') || 'N/A'}</Box>
                </Box>
            ),
        }, {
            field: "genres",
            headerName: "Genres", sortable: false,
            width: 280,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ fontWeight: 'normal', color: 'black' }}>{params.row.genres.join(', ') || 'N/A'}</Box>
                </Box>
            ),
        },
        {
            field: "",
            headerName: "Status", sortable: false,
            width: 200,
            renderCell: (params) => {
                const checkProcessingStatus = () => {
                    if (params.row?.video && params.row.video.length > 0) {
                        const allStarted = params.row.video.every(video => video.processingStatus === 'STARTED');
                        const allCompleted = params.row.video.every(video => video.processingStatus === 'COMPLETED');

                        if (allStarted) {
                            return (
                                <Chip variant='MC1' label="Processing" color="warning" />
                            );
                        } else if (allCompleted) {
                            return (
                                <Chip variant='MC1' label="Analyzed" color="success" />
                            );
                        }
                    }
                    return null;
                };

                return checkProcessingStatus();
            },
        },
    ];

    // console.log(project, "getProject");

    const getDataByContentType = (contentType) => {
        return project
            .filter((row) => row.contentType?.toUpperCase() === contentType.toUpperCase())
            .map((project, index) => {
                const rowData = [
                    index + 1,
                    project.title,
                    project.genre,
                    project.description,
                    project.NoOfVOP,
                ];

                if (contentType === 'web-series' || contentType === 'tv-show') {
                    rowData.push(project.episode, project.season);
                } else {
                    rowData.push('', '');
                }

                return {
                    id: project.id,
                    cells: rowData,
                };
            });
    };

    const data = getDataByContentType(selectedType);

    const [currentPage, setCurrentPage] = useState(1);
    const videosPerPage = 10;

    const indexOfLastVideo = currentPage * videosPerPage;
    const indexOfFirstVideo = indexOfLastVideo - videosPerPage;
    const currentVideos = data.slice(indexOfFirstVideo, indexOfLastVideo);

    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(data.length / videosPerPage); i++) {
        pageNumbers.push(i);
    }

    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    const shouldDisplayPagination = data.length > 6;
    // console.log(data, 'shouldDisplayPagination');

    const getName = (type) => {
        if (type === 'movie') {
            return 'Movies';
        } else if (type === 'tv-show') {
            return 'TV Show';
        } else if (type === 'web-series') {
            return 'Web Series';
        } else if (type === 'music-video') {
            return 'Music Video';
        } else if (type === 'other') {
            return 'Other';
        } else if (type === 'podcast') {
            return 'Podcast';
        } else if (type === 'you-tube') {
            return 'You Tube';
        }
        return '';
    };

    const handleNavigate = (id) => {
        navigate('/ViewProject');
        dispatch(addProjectId(id))
    };

    const handleNavigateTabel = (row) => {
        const id = row.id || row
        navigate('/ViewProject');
        dispatch(addProjectId(id))
    };

    // const decideColor = (row) => {

    //     let label = "";
    //     let color = "";

    //     if (row?.video && row.video.length > 0) {
    //         const allStarted = row.video.every(video => video.processingStatus === 'STARTED');
    //         const allCompleted = row.video.every(video => video.processingStatus === 'COMPLETED');
    //         if (allStarted) {
    //             label = "Processing";
    //             color = "warning";
    //         } else if (allCompleted) {
    //             label = "Analyzed";
    //             color = "success";
    //         }
    //     }
    //     return { label, color };

    // };
    const decideColor = (row) => {
        if (row?.video && row.video.length > 0) {
            const allStarted = row.video.every(video => video.processingStatus === 'STARTED');
            const allCompleted = row.video.every(video => video.processingStatus === 'COMPLETED');
            let label = "Not Described";
            let color = "secondary";
            if (allStarted) {
                label = "Processing";
                color = "warning";
            } else if (allCompleted) {
                label = "Analyzed";
                color = "success";
            }
            return { label, color };
        } else {
            return null
        }

    }




    const ShowView = ({ type }) => {
        const filteredRows = project.filter((row) => row.contentType?.toUpperCase() === getName(type).toUpperCase() || row.contentType?.toUpperCase() === type.toUpperCase()
        );

        const [isPagination, setIsPagination] = useState(true);

        const [filteredCurrentPage, setFilteredCurrentPage] = useState(1);
        const [filteredVideosPerPage, setFilteredVideosPerPage] = useState(5);

        useEffect(() => {
            const updateFilteredVideosPerPage = () => {
                if (window.innerWidth >= 1500) {
                    setFilteredVideosPerPage(5);
                } else {
                    setFilteredVideosPerPage(5);
                }
            };
            updateFilteredVideosPerPage();
            window.addEventListener('resize', updateFilteredVideosPerPage); // Clean up the event listener when the component unmounts
            return () => {
                window.removeEventListener('resize', updateFilteredVideosPerPage);
            };
        }, []);

        const indexOfLastFilteredVideo = filteredCurrentPage * filteredVideosPerPage;
        const indexOfFirstFilteredVideo = indexOfLastFilteredVideo - filteredVideosPerPage;
        const currentFilteredVideos = filteredRows.slice(
            indexOfFirstFilteredVideo,
            indexOfLastFilteredVideo
        );

        const filteredPageNumbers = [];
        for (let i = 1; i <= Math.ceil(filteredRows.length / filteredVideosPerPage); i++) {
            filteredPageNumbers.push(i);
        }

        // const handleFilteredPageClick = (pageNumber) => {
        //     setFilteredCurrentPage(pageNumber);
        // };
        const handleFilteredPageClick = (pageNumber, event) => {
            if (event) {
                event.preventDefault();
            }
            setFilteredCurrentPage(pageNumber);
        };


        const handlePagination = () => {
            setFilteredCurrentPage(1)
            setIsPagination(!isPagination)
            // if (filteredVideosPerPage === filteredRows.length) {
            //     setFilteredVideosPerPage(VideosPerPage)
            // } else {
            //     setFilteredVideosPerPage(filteredRows.length)
            // }
        }



        return (
            <Grid xs={12}>
                <Paper variant="Box1">
                    <Grid xs={12} variant="G11">
                        <Typography ml={2} variant='Head'>
                            {getName(type)}
                        </Typography>
                        <Stack direction={'row'} spacing={2} mr={3}>
                            {gridView &&
                                <Button variant='B1' onClick={handlePagination}>View All</Button>
                            }

                            {listView === true ? (
                                <Button
                                    ml={2}
                                    variant="B5"
                                    onClick={() => {
                                        setGridView(true);
                                        setListView(false);
                                    }}
                                >
                                    <BsGrid3X2GapFill />
                                </Button>
                            ) : (
                                <Button
                                    ml={2}
                                    variant="B5"
                                    onClick={() => {
                                        setGridView(false);
                                        setListView(true);
                                    }}
                                >
                                    <TfiMenuAlt />
                                </Button>
                            )}
                        </Stack>
                    </Grid>
                    {gridView && (
                        <Grid container direction="column">
                            <Grid
                                container
                                item
                                xs={12}
                                spacing={3}
                                style={{ margin: '0rem 1rem' }}
                            >
                                {!isPagination ?
                                    <>
                                        {filteredRows.length === 0
                                            ? <NoData /> :
                                            filteredRows.map((project) => (
                                                <ListCard
                                                    project={project}
                                                    category={project.contentType}
                                                    title={project.title}
                                                    description={project.description}
                                                    thumbnail={project.imgURL}
                                                    seasonCount={project.Season}
                                                    episodeCount={project.episode}
                                                    time={project.time}
                                                    contentId={project.id}
                                                    genres={project.genres}
                                                    key={project.id}
                                                    page={'project'}
                                                    totalVpo={project.totalVpo}
                                                    onClick={handleNavigate}

                                                />
                                            ))
                                        }
                                    </>
                                    :
                                    <>
                                        {currentFilteredVideos.length === 0
                                            ? <NoData /> :
                                            currentFilteredVideos.map((project) => (
                                                <ListCard
                                                    project={project}
                                                    category={project.contentType}
                                                    title={project.title}
                                                    description={project.description}
                                                    thumbnail={project.imgURL}
                                                    seasonCount={project.Season}
                                                    episodeCount={project.episode}
                                                    time={project.time}
                                                    contentId={project.id}
                                                    genres={project.genres}
                                                    key={project.id}
                                                    totalVpo={project.totalVpo}
                                                    onClick={handleNavigate}
                                                />
                                            ))
                                        }
                                    </>}

                            </Grid>
                            {/* {isPagination && filteredRows.length !== 0 && ( */}
                            {filteredRows.length > filteredVideosPerPage && (
                                <Grid xs={11.7} ml={2} display='flex' justifyContent='center'>
                                    <nav aria-label="Page navigation example">
                                        <ul className="pagination">
                                            <li
                                                className={`page-item ${filteredCurrentPage === 1 ? 'disabled' : ''}`}
                                            >
                                                <a
                                                    className="page-link"
                                                    href="#"
                                                    onClick={() => handleFilteredPageClick(filteredCurrentPage - 1)}
                                                >
                                                    Previous
                                                </a>
                                            </li>
                                            {filteredPageNumbers.map((number) => (
                                                <li
                                                    className={`page-item ${filteredCurrentPage === number ? 'active' : ''}`}
                                                    key={number}
                                                >
                                                    <a
                                                        className="page-link"
                                                        href="#"
                                                        onClick={(event) => handleFilteredPageClick(number, event)}
                                                    >
                                                        {number}
                                                    </a>
                                                </li>
                                            ))}
                                            <li
                                                className={`page-item ${filteredCurrentPage === filteredPageNumbers.length
                                                    ? 'disabled'
                                                    : ''}`}
                                            >
                                                <a
                                                    className="page-link"
                                                    href="#"
                                                    onClick={() => handleFilteredPageClick(filteredCurrentPage + 1)}
                                                >
                                                    Next
                                                </a>
                                            </li>
                                        </ul>
                                    </nav>
                                </Grid>
                            )}
                        </Grid>
                    )}

                    {listView && (
                        <Grid container
                            item
                            xs={11.55} ml={4} mt={2}>
                            {currentFilteredVideos.length === 0 ? (
                                <NoData />
                            ) : (
                                <div style={{ marginBottom: "15px", width: "100%" }}>
                                    {/* <DataGrid
                                        rows={filteredRows}
                                        columns={columns}
                                        disableRowSelectionOnClick
                                        sx={{ color: '#526484', minHeight: "38.5vh", }}
                                        initialState={{
                                            ...filteredRows.initialState,
                                            pagination: { paginationModel: { pageSize: 5 } },
                                        }} disableColumnMenu
                                        pageSizeOptions={[5, 10, 25]}
                                    /> */}
                                    <CustomizedTables
                                        filterRow={filteredRows}
                                        columns={columns}
                                        rowAttributes={[
                                            { name: "title", type: 'regular', newStyle: "pointer", fontWeight: "bold", action: handleNavigateTabel },
                                            { name: "contentType", type: 'regular', },
                                            { name: "totalVpo", type: 'regular' },
                                            { name: "releasePlatform", type: 'regular', dataType: "arraySet" },
                                            { name: "genres", type: 'regular', dataType: "arraySet" },
                                            { name: "status", type: 'newChip', typeColorCallback: decideColor },
                                        ]}
                                    />
                                </div>

                            )}
                        </Grid>
                    )}
                </Paper>
            </Grid>
        );
    };

    return (
        <Grid container xs={12} >
            <Grid container item xs={12}>
                {(selectedType === 'music-video' || selectedType === 'all') && (
                    <ShowView type="music-video" />
                )}
                {(selectedType === 'web-series' || selectedType === 'all') && (
                    <ShowView type="web-series" />
                )}
                {(selectedType === 'movie' || selectedType === 'all') && (
                    <ShowView type="movie" />
                )}
                {(selectedType === 'tv-show' || selectedType === 'all') && (
                    <ShowView type="tv-show" />
                )}


                {(selectedType === 'podcast' || selectedType === 'all') && (
                    <ShowView type="podcast" />
                )}
                {(selectedType === 'you-tube' || selectedType === 'all') && (
                    <ShowView type="you-tube" />
                )}
                {(selectedType === 'other' || selectedType === 'all') && (
                    <ShowView type="other" />
                )}

            </Grid>
            <Loader />
        </Grid>
    );
};

export default Container;
