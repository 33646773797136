import React, { useEffect, useState } from 'react';
import { Grid, Button, Typography, Paper, Box, Card, Dialog, DialogActions, DialogContent, DialogTitle, } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import NoData from './../CommonComponet/NoData';
import { NoDataUpload } from '../../ThemeProvider/style';
import uplodIcon from '../../assets/uplodIcon.jpg'
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import Loader from '../CommonComponet/Loader'
import { setShowLoader } from '../../redux/loaderSlice';
import { optimusApi } from '../../Routes/Server';

const Details = ({ selectedVpp, handleUpdateMessages }) => {
  const dispatch = useDispatch()
  const [selectedScene, setSelectedScene] = useState('Original');
  const [isOriginalSceneVisible, setIsOriginalSceneVisible] = useState(true);
  const [videoLink, setVideoLink] = useState('')

  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [videoDetails, setVideoDetails] = useState(null);

  const handleSceneChange = (scene) => {
    setSelectedScene(scene);
    setIsOriginalSceneVisible(scene === 'Original');
  };


  console.log('details', selectedVpp)

  const campaignId = selectedVpp?.id

  let type;

  if (selectedScene === 'Unbranded') {
    type = 'unbranded';
  } else if (selectedScene === 'Branded') {
    type = 'branded';
  }

  useEffect(() => {
    if (selectedVpp && selectedVpp.clipSet && selectedVpp.clipSet.length > 0) {
      if (selectedScene === 'Original') {
        setVideoLink(selectedVpp.clipSet[0]?.VppClipUrl || '');
      } else if (selectedScene === 'Unbranded') {
        setVideoLink(selectedVpp.clipSet[0]?.unbranded?.Location || '');
      } else if (selectedScene === 'Branded') {
        setVideoLink(selectedVpp.clipSet[0]?.branded?.Location || '');
      }
    }
  }, [selectedScene, selectedVpp]);

  console.log('selectedScene', selectedScene)
  console.log('videoLink', videoLink)

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file && file.type.includes('video')) {
      setSelectedFile(file);
      setUploadSuccess(false);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'video/*',
    maxFiles: 1,
  });

  console.log(type)

  const uploadFunctionUnbrand = async (file) => {
    const formData = new FormData();
    formData.append('file', file);
    const localFileIndex = uuidv4();
    formData.append('uploadType', `campaign-${type}-asset-upload`);
    formData.append('id', campaignId);
    try {
      dispatch(setShowLoader(true));
      const response = await optimusApi.post('/upload-asset', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      const videoDetails = {
        videoId: localFileIndex,
        videoUrl: response.data.Location,
        s3Meta: response.data,
      };
      setUploadSuccess(true);
      setVideoDetails(videoDetails);
    } catch (error) {
      console.error('Upload error', error);
    } finally {
      dispatch(setShowLoader(false));
    }
  }

  const handleUpload = () => {
    dispatch(setShowLoader(true));
    if (selectedFile) {
      const uploadPromise = type === 'unbranded' ? uploadFunctionUnbrand(selectedFile) : uploadFunctionUnbrand(selectedFile);
      uploadPromise
        .then(() => {
          setUploadSuccess(true);
          setSelectedFile(null);
        })
        .catch((error) => {
          console.error('Error uploading file:', error);
        });
    }
    dispatch(setShowLoader(false));
  };

  // useEffect(async () => {
  //   if (uploadSuccess === true) {
  //     try {
  //       await handleUpdateMessages();
  //       setUploadSuccess(false);
  //     } catch (error) {
  //       console.error("Error updating messages:", error);
  //     }
  //   }
  // }, [uploadSuccess]);




  return (
    <Grid xs={12} container>
      <Grid item xs={12} container variant="G26">
        <Grid
          item
          xs={6}
          variant="G26"
          onClick={() => handleSceneChange('Original')}
          style={{
            cursor: 'pointer',
            backgroundColor: selectedScene === 'Original' ? 'black' : 'transparent',
          }}
        >
          <Typography variant="P21" mb={2} mt={2} sx={{ color: selectedScene === 'Original' ? 'white' : 'black' }}>
            Original Scene
          </Typography>
        </Grid>
        {/* <Grid
          item
          xs={4}
          variant="G26"
          onClick={() => handleSceneChange('Unbranded')}
          style={{
            cursor: 'pointer',
            backgroundColor: selectedScene === 'Unbranded' ? 'black' : 'transparent',
          }}
        >
          <Typography variant="P21" mb={2} mt={2} sx={{ color: selectedScene === 'Unbranded' ? 'white' : 'black' }}>
            Unbranded Scene
          </Typography>
        </Grid> */}
        <Grid
          item
          xs={6}
          variant="G26"
          onClick={() => handleSceneChange('Branded')}
          style={{
            cursor: 'pointer',
            backgroundColor: selectedScene === 'Branded' ? 'black' : 'transparent',
          }}
        >
          <Typography variant="P21" mb={2} mt={2} sx={{ color: selectedScene === 'Branded' ? 'white' : 'black' }}>
            Branded Scene
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} >
        <div
          style={{
            width: "99%",
            height: "99%",
            marginLeft: '0.5%',
            marginTop: "1%",
            display: (isOriginalSceneVisible && (selectedScene === 'Original' || selectedScene === 'Unbranded')) || (!isOriginalSceneVisible && (selectedScene === 'Branded' || selectedScene === 'Unbranded')) ? 'block' : 'none',
            opacity: (selectedScene === 'Original' || selectedScene === 'Unbranded' || selectedScene === 'Branded') ? 1 : 0,
            transition: 'opacity 0.3s ease',
          }}
        >
          {videoLink ? (
            <video controls width="100%" style={{ objectFit: "cover", minHeight: "49vh", maxHeight: "70vh", background: "black" }} key={videoLink}>
              <source src={videoLink} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          ) : (
            <>
              {uploadSuccess ? (
                <Grid xs={12} style={{ background: 'white', border: "none" }} alignItems="center" justifyContent="center" variant="G2">
                  <Typography variant="P15" >Thank you for uploading video!</Typography>
                </Grid>
              ) : (
                <>
                  <Grid xs={12} display='flex' justifyContent='center' alignItems='center' flexDirection='column' mt={4}>
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <Paper
                        elevation={1}
                        sx={{
                          border: "4px dashed #dfdfdf",
                          cursor: "pointer",
                          background: 'white',
                          textAlign: 'center',
                          width: "340px",
                          height: "240px",
                          p: 2,
                        }}
                      >
                        {selectedFile ? (
                          <>
                            <video width='300px' height='150px' controls src={URL.createObjectURL(selectedFile)} style={{ marginBottom: '10px' }} />
                            <Typography variant="P12">{selectedFile.name}</Typography>
                          </>
                        ) : (
                          <Grid display='flex' flexDirection='column' xs={12} justifyContent='center' alignItems='center'>
                            <NoDataUpload src={uplodIcon} alt="No Data" />
                            <Typography variant='P12'>Drag & drop a video file here</Typography>
                            <Typography variant=''>or click to select one</Typography>
                          </Grid>
                        )}
                      </Paper>
                    </div>
                    <Box mt={3} textAlign="center">
                      <Button variant="B10" onClick={handleUpload}>
                        Upload Video
                      </Button>
                    </Box>
                  </Grid>
                  <Grid variant="G13" item container sx={{ height: "55px" }}>
                    {/* Additional content for the grid if needed */}
                  </Grid>
                </>
              )}
            </>
          )}

          <Loader />
        </div>

      </Grid>
      <Loader />
    </Grid>
  );
};

export default Details;


// {selectedVpp.clipSet[0][`${selectedScene === 'Original' ? 'VppClipUrl' : (selectedScene === 'Branded' ? 'newEmbeddedClipUrl' : 'preApprovedClipUrl')}`]}