import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    vppId: "",
};

const vppIdSlice = createSlice({
  name: "vpp",
  initialState,
  reducers: {
    addvppId: (state, action) => {
      state.vppId = action.payload;
    },
  },
});

export const { addvppId } = vppIdSlice.actions;

export default vppIdSlice.reducer;


