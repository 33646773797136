import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface LoginState {
    videoId: string;

}

const initialState: LoginState = {
    videoId: '',
};

const videoIdSlice = createSlice({
    name: 'videoId',
    initialState,
    reducers: {
        addVideoId: (state, action: PayloadAction<string>) => {
            state.videoId = action.payload;
        },
    },
});

export const { addVideoId } = videoIdSlice.actions;

export default videoIdSlice.reducer;

