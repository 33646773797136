import React, { useEffect, useState } from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useSelector } from 'react-redux';

const Loader = () => {
    const showLoader = useSelector((state) => state.loader?.showLoader);

    return (
        <>
            <Backdrop
                sx={{
                    opacity: 0.5,
                    color: '#fff',
                    background:"#4742422b",
                    zIndex: (theme) => theme.zIndex.drawer + 3,
                }}

                open={showLoader}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    )
}

export default Loader
