import CloseIcon from '@mui/icons-material/Close';
import { Card, CardMedia, Grid } from "@mui/material";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { addProjectId } from '../../redux/projectSlice';
import { useDispatch } from "react-redux";
import PlayerTab from '../CampaignsComponet/PlayerTab';
import defaultThumbnail from '../../assets/NoVideo.png'
import Chip from '@mui/material/Chip';
import { generateThumbnailURL } from '../../Routes/apiConfig';
import EmbeddedPlayerTab from './EmbeddedPlayerTab';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
        <DialogTitle sx={{ m: 0, p: 0.5 }} {...other} fontWeight="bold" style={{ fontFamily: "DM Sans" }}>
            <Typography variant='CHead' ml={2}>{children}</Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 2,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};


const EmbeddedPlayer = (props) => {
    const { openPlayer, projectDetail, onClose, fromPage, handleApproveClick, handleRejectDialog } = props;
    const navigate = useNavigate();
    const dispatch = useDispatch();


    if (!projectDetail) {
        return null;
    }

    const thumbnailURL = generateThumbnailURL(projectDetail.clipSet[0]?.video[0]?.videoId);

    console.log(projectDetail)

    return (
        <div>
            <BootstrapDialog
                onClose={onClose}
                aria-labelledby="customized-dialog-title"
                open={openPlayer}
                maxWidth="lg"
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={onClose} >
                    {projectDetail?.title}
                </BootstrapDialogTitle>
                <DialogContent dividers >
                    <Grid container spacing={2} sx={{ minWidth: 1000, maxWidth: 1000 }}>
                        <Grid variant="G12" item xs={4} ml={-2}>
                            <Grid item xs={12} sx={{ borderBottom: "1px solid #dbdfea" }}>
                                <Card sx={{ minWidth: 282, margin: "15px", marginTop: "0px" }}>
                                    <CardMedia
                                        component="img"
                                        height="160"
                                        image={thumbnailURL}
                                        alt="thumb"
                                    />
                                </Card>
                            </Grid>
                            <Grid xs={12} >
                                <Grid item xs={12} sx={{ margin: "15px", marginTop: "0px" }}>
                                    <Grid mt={1}>
                                        <Typography variant='CHead'>Project Title</Typography> - &nbsp;
                                        <Typography variant='GV'>
                                            {projectDetail?.clipSet[0]?.title}
                                        </Typography>
                                    </Grid>
                                    <Grid mt={1}>
                                        <Typography variant='CHead'>Price</Typography> - &nbsp;
                                        <Typography variant='GV'>
                                            {projectDetail?.clipSet[0]?.vppPrice}
                                        </Typography>
                                    </Grid>
                                    <Grid mt={1}>
                                        <Typography variant='CHead'>Genre</Typography> - &nbsp;
                                        {projectDetail?.clipSet[0]?.genres.map((genre, index) => (
                                            <Typography variant='GV' key={index}>
                                                {genre}
                                                {index !== projectDetail.clipSet[0].genres.length - 1 && ", "}
                                            </Typography>
                                        ))}
                                    </Grid>
                                    <Grid mt={1}>
                                        <Typography variant='CHead'>Platform</Typography> - &nbsp;
                                        {projectDetail?.clipSet[0]?.releasePlatform.map((releasePlatform, index) => (
                                            <Typography variant='GV' key={index}>
                                                {releasePlatform}
                                                {index !== projectDetail.clipSet[0]?.releasePlatform.length - 1 && ", "}
                                            </Typography>
                                        ))}
                                    </Grid>
                                    <Grid mt={1}>
                                        <Typography variant='CHead'>Views</Typography> - &nbsp;
                                        <Typography variant='GV'>
                                            {projectDetail?.clipSet[0]?.averageViewPerMonth}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={8} >
                            <Grid item xs={12} >
                                <EmbeddedPlayerTab
                                    OriginalUrl={projectDetail?.clipSet[0]?.video[0]?.VideoUrl}
                                    EmbeddedUrl={projectDetail?.clipSet[0]?.embeddedVideoUrl}
                                />
                                <Grid xs={12} display='flex' justifyContent='flex-end'>
                                    <Grid variant="G13"
                                        item
                                        container
                                        sx={{ height: "40px" }}
                                        xs={5}
                                        mb={1}
                                        mt={-1}
                                    >
                                        <>
                                            {fromPage === 'brand' ? (
                                                <>
                                                    {
                                                        projectDetail?.creatorStatus && projectDetail?.brandStatus ? (
                                                            <Grid xs={12} display='flex' justifyContent='center' alignItems='center' mt={2} mb={-2}>
                                                                <Button variant='B2S' style={{ background: "#6eff9f", height: '35px', width: "100%", color: 'black', marginLeft: "0px" }}>
                                                                    Buy
                                                                </Button>
                                                            </Grid>
                                                        ) : projectDetail?.brandStatus ? (
                                                            <Grid xs={12} display='flex' justifyContent='center' alignItems='center' mt={2} mb={-2}>
                                                                {projectDetail?.currentStatus ? (
                                                                    <Button variant='B2S' style={{ background: "#ff8282", height: '35px', width: "100%", color: '#432800', marginLeft: "0px", fontSize: "13px" }} disabled>
                                                                        {projectDetail?.currentStatus === 'brandReject' ? 'Rejected by You' : 'Rejected by Producer'}
                                                                    </Button>
                                                                ) : (
                                                                    <Button variant='B2S' style={{ background: "#f7940085", height: '35px', width: "100%", color: '#432800', marginLeft: "0px", fontSize: "13px" }} disabled>
                                                                        Waiting For Producer Approval
                                                                    </Button>
                                                                )}
                                                            </Grid>
                                                        ) : (
                                                            projectDetail?.currentStatus ? (
                                                                <Grid xs={12} display='flex' justifyContent='center' alignItems='center' mt={2} mb={-2}>
                                                                    <Button variant='B2S' style={{ background: "#ff8282", height: '35px', width: "100%", color: '#432800', marginLeft: "0px", fontSize: "13px" }} disabled>
                                                                        {projectDetail?.currentStatus === 'brandReject' ? 'Rejected by You' : 'Rejected by Producer'}
                                                                    </Button>
                                                                </Grid>
                                                            ) : (
                                                                <Grid display='flex' justifyContent='space-between' alignItems='center'>
                                                                    <Grid xs={5} display='flex' justifyContent='center' alignItems='center' mt={2} mb={-2}>
                                                                        <Button variant='B2S' style={{ background: "#ff8282", height: '35px', width: "100%", color: 'black', marginLeft: "0px" }}
                                                                            onClick={() => handleRejectDialog(projectDetail)}>
                                                                            Reject
                                                                        </Button>
                                                                    </Grid>
                                                                    <Grid xs={6} display='flex' justifyContent='center' alignItems='center' mt={2} mb={-2}>
                                                                        <Button variant='B2S' style={{ background: "#6eff9f", height: '35px', width: "100%", color: 'black', marginLeft: "0px" }}
                                                                            onClick={() => handleApproveClick(projectDetail)}
                                                                        >
                                                                            Approve
                                                                        </Button>
                                                                    </Grid>
                                                                </Grid>
                                                            )
                                                        )
                                                    }
                                                </>
                                            ) : (
                                                <>
                                                    {
                                                        projectDetail?.creatorStatus && projectDetail?.brandStatus ? (
                                                            <Grid xs={12} display='flex' justifyContent='center' alignItems='center' mt={2} mb={-2} >
                                                                <Button variant='B2S' style={{ background: "#6eff9f", height: '35px', width: "100%", color: 'black', marginLeft: "0px" }} disabled>
                                                                    Approved
                                                                </Button>
                                                            </Grid>
                                                        ) :
                                                            projectDetail?.creatorStatus ? (
                                                                <Grid xs={12} display='flex' justifyContent='center' alignItems='center' mt={2} mb={-2}>
                                                                    {projectDetail?.currentStatus ? (
                                                                        <Button variant='B2S' style={{ background: "#ff8282", height: '35px', width: "100%", color: '#432800', marginLeft: "0px", fontSize: "13px" }} disabled>
                                                                            {projectDetail?.currentStatus === 'producerReject' ? 'Rejected by You' : 'Rejected by Brand'}
                                                                        </Button>
                                                                    ) : (
                                                                        <Button variant='B2S' style={{ background: "#f7940085", height: '35px', width: "100%", color: '#432800', marginLeft: "0px", fontSize: "13px" }} disabled>
                                                                            Waiting For Brand Approval
                                                                        </Button>
                                                                    )}
                                                                </Grid>
                                                            ) : (
                                                                projectDetail?.currentStatus ? (
                                                                    <Grid xs={12} display='flex' justifyContent='center' alignItems='center' mt={2} mb={-2}>
                                                                        <Button variant='B2S' style={{ background: "#ff8282", height: '35px', width: "100%", color: '#432800', marginLeft: "0px", fontSize: "13px" }} disabled>
                                                                            {projectDetail?.currentStatus === 'producerReject' ? 'Rejected by You' : 'Rejected by Brand'}
                                                                        </Button>
                                                                    </Grid>
                                                                ) : (
                                                                    <Grid display='flex' justifyContent='space-between' alignItems='center'>
                                                                        <Grid xs={5} display='flex' justifyContent='center' alignItems='center' mt={2} mb={-2}>
                                                                            <Button variant='B2S' style={{ background: "#ff8282", height: '35px', width: "100%", color: 'black', marginLeft: "0px" }}
                                                                                onClick={() => handleRejectDialog(projectDetail)}>
                                                                                Reject
                                                                            </Button>
                                                                        </Grid>
                                                                        <Grid xs={6} display='flex' justifyContent='center' alignItems='center' mt={2} mb={-2}>
                                                                            <Button variant='B2S' style={{ background: "#6eff9f", height: '35px', width: "100%", color: 'black', marginLeft: "0px" }}
                                                                                onClick={() => handleApproveClick(projectDetail)}
                                                                            >
                                                                                Approve
                                                                            </Button>
                                                                        </Grid>
                                                                    </Grid>
                                                                )
                                                            )
                                                    }
                                                </>
                                            )}
                                        </>
                                    </Grid>
                                </Grid>

                            </Grid>
                        </Grid>
                    </Grid>

                </DialogContent>
            </BootstrapDialog>
        </div>
    );
}

export default EmbeddedPlayer;
