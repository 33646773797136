import React, { useEffect, useRef, useState } from 'react';
import { Table, TableBody, TableCell, TableHead, Chip, TableRow, Grid, Button, IconButton, Typography, Badge, } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { BsPlayCircle } from 'react-icons/bs'
import { HiOutlinePause } from 'react-icons/hi'
import CustomTextfield from '../CommonComponet/CustomTextfield';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch, useSelector } from "react-redux";
import { setShowLoader } from '../../redux/loaderSlice';
import { v4 as uuidv4 } from 'uuid';
import Loader from '../CommonComponet/Loader';
import { awsApi, awsApiCamp } from '../../Routes/Server';
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { styled } from '@mui/material/styles';
import { TbScanEye } from "react-icons/tb";
import Preview from './Preview';

const Logo = 'https://aa-web-portal.s3.ap-south-1.amazonaws.com/video-asset/images/logoAnan.png';

function loadScript(src) {
    return new Promise((resolve) => {
        const script = document.createElement('script');
        script.src = src;
        script.onload = () => {
            resolve(true);
        };
        script.onerror = () => {
            resolve(false);
        };
        document.body.appendChild(script);
    });
}

const __DEV__ = document.domain === 'localhost';

function parseTimeToSeconds(timeString) {
    const [hours, minutes, seconds] = timeString.split(':').map(Number);
    return hours * 3600 + minutes * 60 + seconds;
}


const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: -3,
        top: 13,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
    },
}));

const VppTabel = ({ clipsData, setClipData, selectedProjectType, projectTitle, brandCat, catagory, projectId, selectedVppType, videoId, setUpdateMessages, invokeVideoDetails }) => {
    const dispatch = useDispatch();
    const videoRef = useRef(null);

    const rowsPerPageOptions = [3, 4];

    const userData = useSelector((state) => state.user.userLoginData);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [totalPrice, setTotalPrice] = useState(10);
    const [afterUpdate, setAfterUpdate] = useState([])
    const [selectedClipData, setSelectedClipData] = useState(null);

    const [selectedClip, setSelectedClip] = useState(
        clipsData && clipsData.VppClips && clipsData.VppClips.length > 0
            ? clipsData.VppClips[0].VppClipUrl
            : ''
    );

    console.log(clipsData)

    const [currentPlayingIndex, setCurrentPlayingIndex] = useState(null);
    const [showConfirmPopup, setShowConfirmPopup] = useState(false);
    const [inputPrice, setInputPrice] = useState('');
    const [videoID, setVideoID] = useState(clipsData.VideoId)
    const [userDetails, setUserDetails] = useState({ name: '', email: '', phone_number: '' });
    const [previewDiloge, setPreviewDiloge] = useState(false)


    useEffect(() => {
        setRowsPerPage(5)
    }, [rowsPerPage]);

    useEffect(() => {
        const storedUserData = localStorage.getItem('userData');
        if (storedUserData) {
            const userData = JSON.parse(storedUserData);
            const { name, email, phone_number } = userData;
            const newUserDetails = {
                name: name || 'Paras',
                email: email || 'parasjnagid@gmail.com',
                phone_number: phone_number || '+918565859649',
            };
            setUserDetails(newUserDetails);
        }
    }, []);

    const handlePlayClick = (vppId, index) => {
        let currentClipInfo = "";
        clipsData.VppClips.forEach(element => {
            if (element.VppId === vppId) {
                currentClipInfo = element;
            }
        });
        const startTime = parseTimeToSeconds(currentClipInfo.startTime);
        const endTime = parseTimeToSeconds(currentClipInfo.endTime);
        videoRef.current.currentTime = startTime;
        if (currentPlayingIndex === index) {
            videoRef.current.pause();
            setCurrentPlayingIndex(null);
        } else {
            videoRef.current.play();
            setCurrentPlayingIndex(index);

            const timeUpdateHandler = () => {
                if (videoRef.current.currentTime >= endTime) {
                    videoRef.current.pause();
                    setCurrentPlayingIndex(null);
                }
            };
            videoRef.current.addEventListener('timeupdate', timeUpdateHandler);
            const endedHandler = () => {
                videoRef.current.removeEventListener('timeupdate', timeUpdateHandler);
            };

            const pauseHandler = () => {
                videoRef.current.removeEventListener('timeupdate', timeUpdateHandler);
            };

            videoRef.current.addEventListener('ended', endedHandler);
            videoRef.current.addEventListener('pause', pauseHandler);
        }
    };

    const handleCloseDialog = () => {
        setShowConfirmPopup(false);
    };


    const handleBrandCheckboxChange = (vppId) => {
        if (selectedRows.includes(vppId)) {
            setSelectedRows(selectedRows.filter(id => id !== vppId));
        } else {
            setSelectedRows([...selectedRows, vppId]);
        }
    };

    function calculateTotalPrice(selectedRows, clipsData) {
        let totalPrice = 0;
        for (const vppId of selectedRows) {
            const selectedClip = clipsData.VppClips.find(clip => clip.VppId === vppId);
            if (selectedClip && selectedClip.price) {
                totalPrice += parseFloat(selectedClip.price);
            }
        }
        return totalPrice;
    }

    useEffect(() => {
        const initialTotalPrice = calculateTotalPrice(selectedRows, clipsData);
        setTotalPrice(initialTotalPrice);
    }, [selectedRows, clipsData]);

    // const handleConfirmUpdate = async () => {
    //     try {

    //         selectedRows.forEach(vppId => {
    //             const payload = {
    //                 action: 'available-for-brand',
    //                 projectId: projectId,
    //                 videoId: videoId,
    //                 // vppId: vppId,
    //                 clipSet: [{
    //                     "VppId": vppId,
    //                     price: inputPrice,
    //                     status: 'checked',
    //                     associationStatus: "checked",
    //                     brandStatus: 'unChecked'
    //                 }]
    //             };
    //             awsApi.post('/postvideo', payload)
    //                 .then(response => {
    //                     console.log(`Updated data for VPP Frame No ${vppId}:`, response.data);
    //                 })
    //                 .catch(error => {
    //                     console.error(`Error updating data for VPP Frame No ${vppId}:`, error);
    //                 });
    //         });

    //         setShowConfirmPopup(false);
    //         setSelectedRows([]);
    //     } catch (error) {
    //         console.error('An error occurred:', error);
    //     }
    // };

    const fetchData = async () => {
        dispatch(setShowLoader(true));
        try {
            const response = await invokeVideoDetails(selectedProjectType, projectId, videoId);
            if (response.data.body && response.data.body) {
                setClipData(response.data.body);
            } else {
                setClipData([]);
            }
            dispatch(setShowLoader(false));
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    console.log(selectedRows)

    const handleConfirmClick = async (paymentDetails) => {
        try {
            dispatch(setShowLoader(true));
            const currentUnixTimestamp = Math.floor(new Date().getTime() / 1000);
            const messageId = uuidv4();
            const currentUnixTimestampB = Math.floor(new Date().getTime() / 1000);
            const messageIdb = uuidv4();
            const currentDate = new Date().toISOString();
            const currentTime = currentUnixTimestamp;

            const updatedData = clipsData.VppClips.map((clip) => {

                if (selectedRows.includes(clip.VppId)) {
                    const projectTitleFirstWord = clip.projectTitle.split(' ')[0];
                    const vppIdFirstThreeChars = clip.VppId.substring(0, 3);
                    const vppName = `${projectTitleFirstWord}_${vppIdFirstThreeChars}`;

                    return {
                        ...clip,
                        commentsProducer: [
                            {
                                meassage: "Campaigns Start",
                                time: new Date().toISOString(),
                                senderType: "producer",
                                senderId: userData.sub,
                                senderName: userData.name,
                                meassageId: messageId,
                                options: "",
                                timeStamp: currentUnixTimestamp,
                                rechiverType: "creative",
                            },
                        ],
                        commentsBrand: [
                            {
                                meassage: "Campaigns Start",
                                time: new Date().toISOString(),
                                senderType: "brand",
                                senderId: userData.sub,
                                senderName: userData.name,
                                meassageId: messageIdb,
                                options: "",
                                timeStamp: currentUnixTimestampB,
                                rechiverType: "creative",
                            },
                        ],
                        category: selectedProjectType,
                        brandedStatus: 'pending',
                        unbrandedStatus: 'pending',
                        VppName: vppName,
                    };
                }
                return clip;
            });
            console.log(updatedData)
            const selectedClipData = updatedData.filter((clip) => selectedRows.includes(clip.VppId));
            const campaignObjects = selectedClipData.map((clip) => ({
                id: uuidv4(),
                clipSet: [{
                    ...clip
                }],
                status: "pending",
                type: "draft",
                brandVideoStatus: false,
                creatorStatus: false,
                brandStatus: false,
                videoId: videoId,
                projectId: projectId,
                paymentDetails: {
                    ...paymentDetails,
                    totalPrice: clip.price
                },
                vppType: selectedVppType,
                currentDate: currentDate,
                currentTime: currentTime,
            }));
            for (const campaignObject of campaignObjects) {
                console.log(JSON.stringify(campaignObject, null, 2));
                const res = await awsApiCamp.post('/postcampaign', campaignObject);

                if (res.data.statusCode === 200) {
                    fetchData()
                    setAfterUpdate(selectedRows);
                    setSelectedRows([]);
                }

            }

            handleCloseDialog();
        } catch (error) {
            console.error('Error updating data:', error);
        } finally {
            dispatch(setShowLoader(false));
        }
    };

    async function displayRazorpay() {
        console.log("totalPrice", totalPrice.toFixed(2))
        handleCloseDialog()
        try {
            dispatch(setShowLoader(true));
            const totalSelectedPrice = calculateTotalPrice(selectedRows, clipsData);

            const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js');
            if (!res) {
                alert('Razorpay SDK failed to load. Are you online?');
                return;
            }

            const data = await fetch(`http://localhost:3009/razorpay/${totalPrice.toFixed(2)}`, { method: 'POST' }).then((t) =>
                t.json()
            );

            // const data = await fetch(`https://dev.optimus.anantadi.com/razorpay/${totalPrice.toFixed(2)}`, { method: 'POST' }).then((t) =>
            //     t.json()
            // );


            const options = {
                key: __DEV__ ? 'rzp_test_UEfYEtdBIyVOPB' : 'rzp_test_UEfYEtdBIyVOPB',
                // key: __DEV__ ? 'rzp_test_SVocaSpaJBKbjH' : 'PRODUCTION_KEY',
                currency: data.currency,
                amount: data.amount.toString(),
                order_id: data.id,
                name: 'Vpp Advertisement',
                description: 'Vpp Purchase is successfully done kindly wait for approval.',
                image: Logo,
                handler: async function (response) {
                    if (response.razorpay_payment_id && response.razorpay_order_id && response.razorpay_signature) {
                        const paymentDetails = {
                            razorpay_payment_id: response.razorpay_payment_id,
                            razorpay_order_id: response.razorpay_order_id,
                            razorpay_signature: response.razorpay_signature,
                            userName: userDetails.name,
                            emailId: userDetails.email,
                            totalPrice: totalSelectedPrice,
                        };
                        await handleConfirmClick(paymentDetails);
                    }
                },
                prefill: {
                    email: userDetails.email,
                    phone_number: userDetails.phone_number,
                },
            };

            const paymentObject = new window.Razorpay(options);
            paymentObject.open();
        } catch (error) {
            console.error('Error:', error);
            // alert('An error occurred during payment.');
        } finally {
            dispatch(setShowLoader(false));
        }
    }

    if (!clipsData || !clipsData.VppClips) {
        return <div>Loading data...</div>;
    }

    const data = clipsData.VppClips;
    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    const pageData = data.slice(startIndex, endIndex);

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, pageData.length);

    const totalPages = Math.ceil(data.length / rowsPerPage);
    const pageNumbers = Array.from({ length: totalPages }, (_, index) => index + 1);

    const handlePageClick = (pageNumber) => {
        setPage(pageNumber);
        setCurrentPlayingIndex(null);
    };

    const maxPagesToShow = 5;
    const showEllipsis = totalPages > maxPagesToShow;


    const renderPageNumbers = () => {
        const pageNumbers = [];

        if (!showEllipsis) {
            // Show all pages if there are 5 or fewer pages
            for (let i = 1; i <= totalPages; i++) {
                pageNumbers.push(i);
            }
        } else {
            const visiblePages = [];

            // Determine the range of visible pages
            const startPage = Math.max(1, Math.min(page - 1, totalPages - maxPagesToShow + 1));
            const endPage = Math.min(startPage + maxPagesToShow - 1, totalPages);

            // Show the current page centered in the visible range
            const centeredPage = Math.min(startPage + Math.floor((endPage - startPage) / 2), totalPages);

            // Show ellipsis if the current page is not at the beginning
            if (startPage > 1) {
                visiblePages.push(1);
                if (startPage > 2) {
                    visiblePages.push(null); // Ellipsis
                }
            }

            // Show visible pages
            for (let i = startPage; i <= endPage; i++) {
                visiblePages.push(i);
            }

            // Show ellipsis if the current page is not at the end
            if (endPage < totalPages) {
                if (endPage < totalPages - 1) {
                    visiblePages.push(null); // Ellipsis
                }
                visiblePages.push(totalPages);
            }

            pageNumbers.push(...visiblePages);
        }

        return pageNumbers.map((number, index) => (
            <li key={index} className={`page-item ${page === number - 1 ? 'active' : ''}`}>
                {number === null ? (
                    <span className="page-link">...</span>
                ) : (
                    <a className="page-link" href="#" onClick={() => handlePageClick(number - 1)}>
                        {number}
                    </a>
                )}
            </li>
        ));
    };

    const NoDataFound = selectedRows.length === 0

    const videoDatabru = {
        "VppId": "8CEvmo4Bs3-8W0Ym9m-P",
        "VppFreamNo": 0,
        "VppClipUrl": "https://vi-video-collector-01.s3.ap-south-1.amazonaws.com/27+-+33+seconds_anantadi.mp4",
        "startTime": "0:0:27",
        "endTime": "0:0:33",
        "status": "checked",
        "Duration": "8",
        "price": "2000",
        "projectTitle": "Chaand Baaliyan",
        "brandCat": "Food and Beverages",
        "associationStatus": "fea8f11e-8639-4b70-84f9-06bd996115b0",
        "videoId": "069b74b4-ecd9-4c55-9a41-9d2c3b160a68",
        "label": "Coffee",
        "labelInsight": {
            "Categories": [
                {
                    "Name": "Food and Beverage"
                }
            ],
            "Confidence": 99.92768859863281,
            "Parents": [
                {
                    "Name": "Beverage"
                }
            ],
            "Aliases": [],
            "Name": "Coffee"
        }
    };

    const videoDataCoke = {
        "VppId": "Qh8zmo4B45lqvD3FfhOY",
        "VppFreamNo": 3,
        "VppClipUrl": "https://vi-video-collector-01.s3.ap-south-1.amazonaws.com/47+-+55+seconds_anantadi.mp4",
        "startTime": "0:0:47",
        "endTime": "0:0:55",
        "status": "checked",
        "Duration": "16",
        "price": "3000",
        "projectTitle": "Chaand Baaliyan",
        "brandCat": "Signage and Billboard",
        "associationStatus": "eb27afc1-4ddf-425a-a224-4be732de3c03",
        "videoId": "069b74b4-ecd9-4c55-9a41-9d2c3b160a68",
        "label": "Coffee",
        "labelInsight": {
            "Categories": [
                {
                    "Name": "Food and Beverage"
                }
            ],
            "Confidence": 99.92768859863281,
            "Parents": [
                {
                    "Name": "Beverage"
                }
            ],
            "Aliases": [],
            "Name": "Coffee"
        }
    };

    const videoDataSwiggy = {
        "VppId": "Qh8zmo4B45lqvD3Ffhuio",
        "VppFreamNo": 3,
        "VppClipUrl": "https://vi-video-collector-01.s3.ap-south-1.amazonaws.com/47+-+55+seconds_anantadi.mp4",
        "startTime": "0:0:00",
        "endTime": "0:0:08",
        // "status": "checked",
        "Duration": "8",
        "price": "3000",
        "projectTitle": "Chaand Baaliyan",
        "brandCat": "Signage and Billboard",
        "associationStatus": "eb27afc1-4ddf-425a-a224-4be732de3c03",
        "videoId": "069b74b4-ecd9-4c55-9a41-9d2c3b160a68",
        "label": "Coffee",
        "labelInsight": {
            "Categories": [
                {
                    "Name": "Food and Beverage"
                }
            ],
            "Confidence": 99.92768859863281,
            "Parents": [
                {
                    "Name": "Beverage"
                }
            ],
            "Aliases": [],
            "Name": "Coffee"
        }
    };

    const dummyData = {
        "VppId": "4B45lqvD3Fudyd",
        "VppFreamNo": 1,
        "VppClipUrl": "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
        "startTime": "0:1:24",
        "endTime": "0:1:26",
        "status": "checked",
        "Duration": "16",
        "price": "25",
        "projectTitle": "Gaadi Kaali",
        "brandCat": "Clothing and Fashion",
        "associationStatus": "",
        "videoId": "e5b6eecb-eedd-48d4-90dd-2afb5a38f297",
        "label": "Party",
        "labelInsight": {
            "Aliases": [],
            "Categories": [{
                "Name": "Events and Attractions"
            }],
            "Confidence": 92.23836517333984,
            "Name": "Party",
            "Parents": [{
                "Name": "Fun"
            }]
        }
    };

    const handlePreviewDialog = (clip) => {
        if (clip.videoId === videoDatabru.videoId && clip.VppId === videoDatabru.VppId) {
            setSelectedClipData(videoDatabru);
        } else if (clip.videoId === videoDataCoke.videoId && clip.VppId === videoDataCoke.VppId) {
            setSelectedClipData(videoDataCoke);
        } else if (clip.videoId === videoDataSwiggy.videoId && clip.VppId === videoDataSwiggy.VppId) {
            setSelectedClipData(videoDataSwiggy);
        } else {
            setSelectedClipData(dummyData);
        }
        setPreviewDiloge(true);
    };


    console.log(selectedClipData)

    return (
        <>
            <Grid xs={12} mt={1} variant="G2C" display='flex' style={{ height: '400px' }} mb={1}>
                <Grid xs={7} style={{ height: '400px', borderTopRightRadius: "0px", borderBottomRightRadius: "0px", borderRight: "1px solid #e0e0e0" }}>
                    <Grid xs={12} sx={{ width: '100%' }}>
                        <Grid sx={{ minHeight: "320px", maxHeight: "320px" }}>
                            <Table variant="T1" size="small" aria-label="a dense table">
                                <TableHead sx={{ height: "55px" }}>
                                    <TableRow>
                                        <TableCell variant="TH1">VPO No</TableCell>
                                        <TableCell variant="TH1">Brand Categories</TableCell>
                                        <TableCell variant="TH1">Start Time</TableCell>
                                        <TableCell variant="TH1">Seconds</TableCell>
                                        <TableCell variant="TH1">Price</TableCell>
                                        <TableCell variant="TH1">Play</TableCell>
                                        <TableCell variant="TH1">Purchase</TableCell>
                                        <TableCell variant="TH1">VPO Preview</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {pageData &&
                                        pageData.map((clip, index) => (
                                            <TableRow key={clip.VppId} sx={{ maxHeight: "30px" }}>
                                                <TableCell>{clip.VppId.substring(0, 3)}</TableCell>
                                                <TableCell >
                                                    <Chip label={clip.brandCat} size="small" variant="outlined" />
                                                </TableCell>
                                                <TableCell>{clip.startTime}</TableCell>
                                                <TableCell>{clip.Duration}</TableCell>
                                                <TableCell>{clip.price !== '' ? clip.price : '-'}</TableCell>

                                                <TableCell>
                                                    <IconButton
                                                        variant="IB0"
                                                        onClick={() => handlePlayClick(clip.VppId, index)}
                                                    >
                                                        {currentPlayingIndex === index ? <HiOutlinePause /> : <BsPlayCircle />}
                                                    </IconButton>
                                                </TableCell>
                                                <TableCell>
                                                    {/* <Checkbox
                                                        onChange={() => handleBrandCheckboxChange(clip.VppId)}
                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                        color="success"
                                                        disabled={(clip.associationStatus && clip.associationStatus !== "" || afterUpdate.includes(clip.VppId)) ? true : false}
                                                        checked={(clip.associationStatus && clip.associationStatus !== "") ? true : selectedRows.includes(clip.VppId)}
                                                    /> */}
                                                    <Checkbox
                                                        onChange={() => handleBrandCheckboxChange(clip.VppId)}
                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                        color="success"
                                                        disabled={(clip.associationStatus && clip.associationStatus !== "" || afterUpdate.includes(clip.VppId)) ? true : false}
                                                        checked={
                                                            (clip.associationStatus && clip.associationStatus !== "" || afterUpdate.includes(clip.VppId))
                                                                ? true
                                                                : selectedRows.includes(clip.VppId)
                                                        }
                                                    />

                                                </TableCell>
                                                <TableCell>
                                                    <TbScanEye style={{ color: "black", fontSize: "24px", cursor: "pointer", marginLeft: "15px" }} onClick={() => handlePreviewDialog(clip)} />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </Grid>
                        <Grid variant="G22" xs={12}  >
                            <Grid xs={7.7} display="flex" justifyContent="space-between">
                                {/* <nav className="mt-3 ml-3">
                                    <ul className="pagination">
                                        <li className={`page-item ${page === 0 ? 'disabled' : ''}`}>
                                            <a className="page-link" href="#" onClick={() => handlePageClick(page - 1)}>
                                                {"Previous"}
                                            </a>
                                        </li>
                                        {pageNumbers.map((number) => (
                                            <li className={`page-item ${page === number - 1 ? 'active' : ''}`} key={number}>
                                                <a className="page-link" href="#" onClick={() => handlePageClick(number - 1)}>
                                                    {number}
                                                </a>
                                            </li>
                                        ))}
                                        <li className={`page-item ${page === totalPages - 1 ? 'disabled' : ''}`}>
                                            <a className="page-link" href="#" onClick={() => handlePageClick(page + 1)}>
                                                {"Next"}
                                            </a>
                                        </li>
                                    </ul>
                                </nav> */}
                                <nav className="mt-3 ml-3">
                                    <ul className="pagination">
                                        <li className={`page-item ${page === 0 ? 'disabled' : ''}`}>
                                            <a className="page-link" href="#" onClick={() => handlePageClick(page - 1)}>
                                                {"Previous"}
                                            </a>
                                        </li>
                                        {renderPageNumbers()}
                                        <li className={`page-item ${page === totalPages - 1 ? 'disabled' : ''}`}>
                                            <a className="page-link" href="#" onClick={() => handlePageClick(page + 1)}>
                                                {"Next"}
                                            </a>
                                        </li>
                                    </ul>
                                </nav>
                            </Grid>
                            <Grid xs={4} mr={1.1} display='flex' justifyContent='flex-end' alignItems='center' >
                                <Button
                                    variant="B3C"
                                    disabled={selectedRows.length === 0}
                                    sx={{ background: NoDataFound ? "#00000066" : 'black' }}
                                    onClick={() => setShowConfirmPopup(true)}
                                >
                                    Available for Buy Now
                                    <IconButton aria-label="cart">
                                        <StyledBadge badgeContent={selectedRows.length > 0 && `${selectedRows.length}`} color="success" >
                                            <ShoppingCartIcon style={{ color: "white", fontSize: "27px" }} />
                                        </StyledBadge>
                                    </IconButton>
                                </Button>
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>

                <Grid xs={5} style={{ minHeight: '400px' }} alignItems="center" >
                    <Grid xs={12} variant="G2" style={{ minHeight: '400px', borderTopLeftRadius: "0px", borderBottomLeftRadius: "0px", }} alignItems="center" justifyContent='center'>
                        <video width="95%" height="94%" controls ref={videoRef} style={{ background: "black" }} controlsList="nodownload">
                            <source src={selectedClip} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </Grid>
                </Grid>


                <Dialog
                    open={showConfirmPopup}
                    onClose={handleCloseDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Are you sure you want to purchase these VPP ?"}</DialogTitle>

                    <DialogActions>
                        <Button onClick={() => handleCloseDialog()} variant="outlined" color="error">
                            Disagree
                        </Button>
                        <Button onClick={displayRazorpay} autoFocus variant="outlined" color="success">
                            Agree
                        </Button>
                    </DialogActions>
                </Dialog>

                <Preview
                    setPreviewDiloge={setPreviewDiloge}
                    previewDiloge={previewDiloge}
                    clipData={selectedClipData}
                />

            </Grid>
            <Loader />
        </>
    );
};

export default VppTabel;
