import { Visibility, VisibilityOff } from "@mui/icons-material";
import { AppBar, Button, Grid, IconButton, InputAdornment, Paper, styled, Toolbar, Typography, Snackbar } from "@mui/material";
import { useState } from "react";
import CustomTextfield from "../Components/CommonComponet/CustomTextfield";
import { FcGoogle } from 'react-icons/fc'
import { setAccessToken } from '../redux/loginSlice';
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import Logo from '../assets/Logo.png';
import { setShowLoader } from '../redux/loaderSlice';
import Loader from "../Components/CommonComponet/Loader";
import { awsApiTwo } from "../Routes/Server";

const Media = styled("img")({
    width: "250px",
    height: "140px",
});


const LoginPage = ({ setIsAuthenticated, setLoginTrue }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate();

    const [userName, SetUserName] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [userNameError, setUserNameError] = useState(false);
    const [passwordErrors, setPasswordErrors] = useState({
        uppercase: false,
        specialChar: false,
        number: false,
        length: false,
    });
    const [snackbarmessage, setSnackbarMessage] = useState();

    const handleToggleShowPassword = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };

    const handleUserNameChange = (event) => {
        SetUserName(event.target.value);
        setUserNameError(false);
    };

    const validatePassword = (value) => {
        const errors = {
            uppercase: !/(?=.*[A-Z])/.test(value),
            specialChar: !/(?=.*[@$!%*?&])/.test(value),
            number: !/(?=.*\d)/.test(value),
            length: !/(?=.{8,})/.test(value),
        };
        setPasswordErrors(errors);
        return !Object.values(errors).some((error) => error);
    };

    const handlePasswordChange = (event) => {
        const passwordValue = event.target.value;
        setPassword(passwordValue);
        // Auto-validate password on every change
        validatePassword(passwordValue);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        dispatch(setShowLoader(true));
        if (!userName || !validatePassword(password)) {
            setUserNameError(!userName);
            return;
        }
        try {
            const response = await awsApiTwo.post('/signin', {
                username: userName,
                password: password
            });

            if (response.data.statusCode === 200) {
                const body = response.data.body;
                const data = JSON.parse(body).data;
                localStorage.setItem('data', JSON.stringify(data));
                setIsAuthenticated(true);
                setSnackbarMessage("Success")
                dispatch(setAccessToken(data.AccessToken));
                dispatch(setShowLoader(false));
                // window.location.href = "/"
            } else if (response.data.statusCode === 400) {
                setSnackbarMessage(response.data.body);
                dispatch(setShowLoader(false));
            } else {
                dispatch(setShowLoader(false));
            }
        } catch (error) {
            console.error(error);
            alert('An error occurred during login');
            dispatch(setShowLoader(false));
        }
    };


    const handleNavigate = () => {
        navigate('/SignupPage');
    };



    return (
        <>
            {snackbarmessage && <Snackbar open={snackbarmessage} message={snackbarmessage} autoHideDuration={1000} />}
            <Grid
                container
                justifyContent="center"
                alignItems="center"
                style={{ minHeight: "100vh" }}
            >
                <Grid
                    variant='Login'
                    // style={{maxHeight: gridMaxHeight}}  
                    // style={{ minHeight: "100vh" }} 
                    xs={12} sm={8} md={6} lg={4}
                >
                    <AppBar variant="A2" position="static" color="transparent" elevation={0} >
                        <Toolbar variant="dense" >
                            <Typography >
                                <Media src={Logo} alt="" />
                            </Typography>

                        </Toolbar>
                    </AppBar>
                    {/* <Grid xs={12} display='flex' justifyContent='center' alignItems="center" style={{ height: "30px" }} mt={-5} mb={4}>
                    <Typography variant="P0">
                        ({selectedCircle})
                    </Typography>
                </Grid> */}
                    <Paper
                        variant={"outlined"}
                        sx={{
                            border: "1px solid #DBDFEA",
                            width: "30rem",
                            padding: "2rem",
                        }} mb={6}
                    >
                        <Grid
                            container
                            spacing={2}
                        >
                            <Grid
                                item
                                xs={12}
                            >
                                <Typography
                                    variant={"h5"}
                                    fontSize={"1.3rem"}
                                    textAlign={"center"}
                                    fontWeight={"bold"}
                                    fontFamily={"DM Sans"}
                                >
                                    {"Sign-In"}
                                </Typography>
                                <Typography
                                    variant={"subtitle2"}
                                    textAlign={"center"}
                                    color={"#526484"}
                                    mt={1}
                                >
                                    {"Access the anantadi panel using your email and passcode."}
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                container
                                spacing={1}
                            >
                                <Grid
                                    item
                                    xs={12}
                                >
                                    <Typography
                                        variant={"subtitle1"}
                                        fontWeight={"bold"}
                                        color={"#344357"}
                                    >
                                        {"User Name"}
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                >
                                    <CustomTextfield
                                        size={"small"}
                                        id={"user-email-input"}
                                        placeholder={"Enter user name"}
                                        fullWidth
                                        variant={"outlined"}
                                        onChange={handleUserNameChange}
                                        error={userNameError}
                                    />
                                </Grid>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                container
                                spacing={1}
                            >
                                <Grid
                                    item
                                    xs={12}
                                >
                                    <Typography
                                        variant={"subtitle1"}
                                        fontWeight={"bold"}
                                        color={"#344357"}
                                    >
                                        {"Password"}
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                >
                                    <CustomTextfield
                                        size={"small"}
                                        id={"user-password-input"}
                                        placeholder={"Enter password"}
                                        fullWidth
                                        variant={"outlined"}
                                        type={showPassword ? "text" : "password"}
                                        onChange={handlePasswordChange}
                                        error={passwordErrors.length}
                                        helperText={passwordErrors.length && "Password must be at least 8 characters long."}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton onClick={handleToggleShowPassword}>
                                                        {showPassword ? (
                                                            <VisibilityOff fontSize={"small"} sx={{ height: "100%" }} />
                                                        ) : (
                                                            <Visibility fontSize={"small"} sx={{ height: "100%" }} />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}

                                    />
                                </Grid>
                                <Typography
                                    variant='P23'
                                    ml={1}
                                    mt={0.1}
                                >
                                    {passwordErrors.uppercase && "Password must contain at least one capital letter."}
                                    {passwordErrors.specialChar && "Password must contain at least one special character."}
                                    {passwordErrors.number && "Password must contain at least one number."}
                                </Typography>
                            </Grid>

                            <Grid
                                item
                                xs={12}
                            >
                                <Button
                                    fullWidth
                                    type="submit"
                                    variant="B6"
                                    color="primary"
                                    size="large"
                                    onClick={handleSubmit}
                                >
                                    Sign in
                                </Button>

                                {/* <Button
                                fullWidth
                                type="submit"
                                variant="B7"
                                color="primary"
                                size="large"
                                onClick={handleSubmit}
                            >
                                <FcGoogle fontSize='large' style={{ background: 'white' }} />&nbsp;&nbsp; Login With Google
                            </Button> */}


                            </Grid>
                            <Grid
                                item
                                xs={12}
                            >
                                <Typography
                                    variant={"subtitle2"}
                                    textAlign={"center"}
                                    color={"#526484"}
                                    style={{fontSize:"17px"}}
                                >
                                    {"Don't have an account?"}
                                    <span style={{ fontWeight: "bold", color: "black", cursor: "pointer" }} onClick={handleNavigate}>{" Sign up"}</span>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Loader />
            </Grid>
        </>
    );
};

export default LoginPage;
