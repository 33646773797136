import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import CustomTextfield from './CustomTextfield';
import { Grid, Typography } from '@mui/material';

const RejectDialog = ({ open, onClose, rejectMessage, setRejectMessage, onRejectSubmit }) => {
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Reject Message</DialogTitle>
            <DialogContent>
                <Grid item xs={12} container  mb={2} >
                    <Grid item xs={12} >
                        <Typography variant="P22" >
                            {"Reject Message"}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} mt={0.4}
                    >
                        <CustomTextfield
                            name="reject Message"
                            // size="small"
                            multiline
                            rows={4}
                            placeholder="Reason for Rejection"
                            fullWidth
                            variant="outlined"
                            value={rejectMessage}
                            onChange={(e) => setRejectMessage(e.target.value)}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions variant="DA3" sx={{ marginBottom: "15px",marginTop:"-15px" }}>
                <Button variant='B2S' onClick={onClose}>Cancel</Button>
                <Button variant='B2S' onClick={onRejectSubmit}>Reject</Button>
            </DialogActions>
        </Dialog>
    );
};

export default RejectDialog;
