import React, { useEffect, useRef, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import Loader from '../CommonComponet/Loader'
import { setShowLoader } from '../../redux/loaderSlice';
import { optimusApi } from '../../Routes/Server';
import { Card, CardMedia, Grid, Box, Paper } from "@mui/material";
import Button from '@mui/material/Button';
import { useDropzone } from 'react-dropzone';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { addProjectId } from '../../redux/projectSlice';
import { useDispatch } from "react-redux";
import { brandCategoryOptions } from '../CommonComponet/docsData';
import Select from "react-select";
import { NoDataUpload } from '../../ThemeProvider/style';
import uplodIcon from '../../assets/uplodIcon.jpg'
import { v4 as uuidv4 } from 'uuid';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
        <DialogTitle sx={{ m: 0, p: 0.5 }} {...other} fontWeight="bold" style={{ fontFamily: "DM Sans" }}>
            <Typography variant='CHead' ml={2}>{children}</Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 2,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};



const UploadEditVideo = (props) => {
    const { previewDiloge, setPreviewDiloge, clipData } = props;
    const dispatch = useDispatch();

    const [selectedFile, setSelectedFile] = useState(null);
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const [videoDetails, setVideoDetails] = useState(null);
    const [updateVppVideo, setUpdateVppVideo] = useState(false)

    const handleClose = () => {
        setPreviewDiloge(false);
    };

    const [brandCategory, setBrandCategory] = useState(null);

    useEffect(() => {
        if (clipData) {
            const matchingOption = brandCategoryOptions.find(option => option.label === clipData.brandCat);
            if (matchingOption) {
                setBrandCategory(matchingOption);
            }
        }
    }, [clipData]);

    const handleBrandCategoryChange = (selectedOption) => {
        setBrandCategory(selectedOption);
    };


    const onDrop = (acceptedFiles) => {
        const file = acceptedFiles[0];
        if (file && file.type.includes('video')) {
            setSelectedFile(file);
            setUploadSuccess(false);
        }
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: 'video/*',
        maxFiles: 1,
    });

    const uploadFunctionUnbrand = async (file) => {
        const formData = new FormData();
        formData.append('file', file);
        const localFileIndex = uuidv4();
        formData.append('uploadType');

        formData.append('brandCategory', brandCategory)

        try {
            dispatch(setShowLoader(true));
            const response = await optimusApi.post('/upload-asset', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            const videoDetails = {
                videoId: localFileIndex,
                videoUrl: response.data.Location,
                s3Meta: response.data,
            };
            // await handleUpdateMessages()
            setVideoDetails(videoDetails);
            setUpdateVppVideo(true)
            setUploadSuccess(true)
        } catch (error) {
            console.error('Upload error', error);
        } finally {
            dispatch(setShowLoader(false));
        }
    }

    const handleUpload = () => {
        dispatch(setShowLoader(true));
        if (selectedFile) {
            // const uploadPromise = type === 'unbranded' ? uploadFunctionUnbrand(selectedFile) : uploadFunctionUnbrand(selectedFile);
            const uploadPromise = uploadFunctionUnbrand(selectedFile)
            uploadPromise
                .then(() => {
                    setUploadSuccess(true);
                    setSelectedFile(null);
                    setUpdateVppVideo(true)
                    // handleUpdateMessages()

                })
                .catch((error) => {
                    console.error('Error uploading file:', error);
                });
        }
        dispatch(setShowLoader(false));
    };

    return (
        <div>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={previewDiloge}
                maxWidth="md"
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose} >
                    Preview Scenes
                </BootstrapDialogTitle>
                <DialogContent dividers >
                    <Grid container spacing={2} sx={{ minWidth: 800, maxWidth: 800 }} display='flex' flexDirection='column' ml={1}>
                        <Grid xs={12} mt={2}>
                            <Select
                                options={brandCategoryOptions}
                                value={brandCategory}
                                onChange={handleBrandCategoryChange}
                                placeholder={'Select Brand Category'}
                                isClearable
                                isSearchable
                            />
                        </Grid>
                        <Grid xs={12} mt={2}
                            variant="G2"
                            style={{ minHeight: '383px', background: '#e3edff' }}
                            alignItems="center"
                            justifyContent="center"
                        >
                            {/* <video  controls style={{borderRadius:"7px",width:"100%",height:"auto"}}>
                                <source src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4" type="video/mp4" />
                            </video> */}
                            <Box>
                                <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <Paper
                                        elevation={1}
                                        sx={{
                                            border: "4px dashed #dfdfdf",
                                            cursor: "pointer",
                                            background: 'white',
                                            textAlign: 'center',
                                            width: "340px",
                                            height: "240px",
                                            p: 2,
                                        }}
                                    >
                                        {selectedFile ? (
                                            <>
                                                <video width='300px' height='150px' controls src={URL.createObjectURL(selectedFile)} style={{ marginBottom: '10px' }} />
                                                <Typography variant="P12">{selectedFile.name}</Typography>
                                            </>
                                        ) : (
                                            <Grid display='flex' flexDirection='column' xs={12} justifyContent='center' alignItems='center'>
                                                <NoDataUpload src={uplodIcon} alt="No Data" />
                                                <Typography variant='P12'>Drag & drop a video file here</Typography>
                                                <Typography variant=''>or click to select one</Typography>
                                            </Grid>
                                        )}
                                    </Paper>
                                </div>
                                <Box mt={3} textAlign="center">
                                    <Button variant="B10" onClick={handleUpload}>
                                        Upload Video
                                    </Button>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>

                </DialogContent>
            </BootstrapDialog>
        </div>
    );
}

export default UploadEditVideo;
