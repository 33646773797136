import './App.css';
import axios from 'axios';
import { useEffect, useState } from "react";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./ThemeProvider/Theme";
import SideBar from './Components/Sidebar';
import { Box } from "@mui/material";
import { Route, Routes, useNavigate } from "react-router-dom";
import Login from './Pages/Login'
import SignupPage from './Pages/SignupPage'
import routes from './Routes/Routes';
import routesBrand from './Routes/RoutesBrand';
import SelectionPage from "./Pages/SelectionPage";
import { useDispatch, useSelector } from 'react-redux';
import Loader from './Components/CommonComponet/Loader';
import { setShowLoader } from './redux/loaderSlice';
import routesCreative from './Routes/RoutesCreative';
import { awsApiTwo } from './Routes/Server';


function App() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [userRoutes, setUserRoutes] = useState([]);
  const [userType, setUserType] = useState('')
  const [loginTrue, setLoginTrue] = useState(false);

  const dataType = useSelector(state => state.login.accessToken);

  useEffect(() => {
    const fetchLoginData = async () => {
      try {
        dispatch(setShowLoader(true));

        const data = dataType;
        if (data) {
          const headers = {
            "Content-Type": "application/json;charset=utf-8",
            authtoken: `${data}`
          };
          const response = await awsApiTwo.get('/getuser', { headers });
          if (response.data.statusCode === 200) {
            const user = JSON.parse(response.data.body);
            const userData = user.UserAttributes.reduce((acc, attribute) => {
              acc[attribute.Name] = attribute.Value;
              return acc;
            }, {});
            userData.userName = user.Username;
            if (userData['custom:userType'] === 'creator' || userData['custom:userType'] === 'Creator') {
              setUserRoutes(routes);
              navigate("/Dashboard");
            }
            else if (userData['custom:userType'] === 'Brand' || userData['custom:userType'] === 'brand') {
              setUserRoutes(routesBrand);
              navigate("/DashboardBrand");
            } else if (userData['custom:userType'] === 'Creative' || userData['custom:userType'] === 'creative') {
              setUserRoutes(routesCreative);
              navigate("/VpoMarket");
            }

            localStorage.setItem("user", JSON.stringify(userData));
          } else {
            setIsAuthenticated(false);
          }
        } else {
          setIsAuthenticated(false);
        }
      } catch (error) {
        console.log(error);
      } finally {
        dispatch(setShowLoader(false));
      }
    };
    
    fetchLoginData();
  }, [dataType]);

  // console.log(userType, "this is user Type")

  return (
    <div
     className="container" style={{
      width: "100%",
      height: '100%',
      display: isAuthenticated ? 'flex' : '',
      background: "#eff4fd" ,
    }}>
      <ThemeProvider theme={theme}>
        {/* {console.log(userType)} */}
        {isAuthenticated === true ? (
          <>
            <SideBar routes={userRoutes} setIsAuthenticated={setIsAuthenticated} setUserType={setUserType} />
            <Box
              component="main"
              sx={{ flexGrow: 1, p: 0,pt:3, }}
            >
              <Routes>
                {userRoutes.map((route, index) => (
                  <Route
                    key={index}
                    path={route.path}
                    element={<route.component />}
                  />
                ))}
              </Routes>
            </Box>
          </>
        ) : (
          <Routes>
            <Route path="/Login" element={<Login setIsAuthenticated={setIsAuthenticated} />} />
            <Route path="/" element={<SignupPage />} />
            <Route path="/Login" element={<Login setIsAuthenticated={setIsAuthenticated} setLoginTrue={setLoginTrue} />} />
            {/* <Route path="*" element={<Login setIsAuthenticated={setIsAuthenticated} setLoginTrue={setLoginTrue} />} /> */}
            <Route path="*" element={<SignupPage setIsAuthenticated={setIsAuthenticated} setLoginTrue={setLoginTrue} />} />
          </Routes>
        )}
        <Loader />
      </ThemeProvider>
    </div>
  );
}

export default App;
