import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  showLoader: false,
};

const loaderSlice = createSlice({
  name: 'loader',
  initialState,
  reducers: {
    setShowLoader: (state, action) => {
      state.showLoader = action.payload
    },
  },
});

export const { setShowLoader } = loaderSlice.actions;
export default loaderSlice.reducer;
