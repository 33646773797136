
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    showAnalysis: false,
};

const analysisSlice = createSlice({
    name: 'analysis',
    initialState,
    reducers: {
        setShowAnalysis: (state, action) => {
            state.showAnalysis = action.payload;
        },
    },
});

export const { setShowAnalysis } = analysisSlice.actions;
export default analysisSlice.reducer;