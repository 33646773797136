import React, { useState } from 'react';
import { Button, Grid } from '@mui/material';
import CustomTextfield from '../CommonComponet/CustomTextfield';
import { awsApi } from '../../Routes/Server';

const EditMode = ({ projectById, setDeatilsView, setEditView, project, updatedData }) => {

    const [formData, setFormData] = useState({
        stage: project.stage || '',
        distributionChannel: project.distributionChannel || '',
        posters: project.posters || '',
        director: project.director || '',
        presentation: project.presentation || '',
        producer: project.producer || '',
        teaser: project.teaser || '',
        script: project.script || '',
        trailer: project.trailer || '',
        casts: project.casts || '',
    });

    const handelUpdateView = () => {
        setEditView(false);
        updatedData();
    }

    const handleSubmit = async () => {
        try {
            const existingResponse = await awsApi.post('/getprojects', { id: projectById });
            if (existingResponse.status === 200) {
                // const existingData = existingResponse.data;
                // console.log(existingData);
                const updatedProject = {
                    ...formData,
                };
                const response = await awsApi.post(
                    '/postproject',
                    { action: "update-metadata", id: projectById, updatedProject }
                );

                if (response.status === 200) {
                    // console.log('Data updated successfully:', response.data);
                    setDeatilsView(true);
                    handelUpdateView();
                } else {
                    console.log('Failed to update data:', response.statusText);
                }
            }
        } catch (error) {
            console.error('Error updating data:', error);
        }
    };




    return (
        <>
            <Grid xs={12} variant='Table'>
                <Grid variant='body' xs={5.8} >
                    <Grid variant='GL' item xs={6} ml={2}>
                        Stage
                    </Grid>
                    <Grid variant='GR' item xs={6}>
                        <CustomTextfield
                            name="title"
                            size="small"
                            placeholder="Enter Stage"
                            fullWidth
                            variant="outlined"
                            value={formData.stage}
                            onChange={(e) => setFormData({ ...formData, stage: e.target.value })}
                        />
                    </Grid>
                </Grid>
                <Grid xs={0.4}></Grid>
                <Grid variant='body' xs={5.8} >
                    <Grid variant='GL' item xs={6} ml={2}>
                        Distribution Channel
                    </Grid>
                    <Grid variant='GR' item xs={6}>
                        <CustomTextfield
                            name="title"
                            size="small"
                            placeholder="Enter Distribution Channel"
                            fullWidth
                            variant="outlined"
                            value={formData.distributionChannel}
                            onChange={(e) =>
                                setFormData({ ...formData, distributionChannel: e.target.value })
                            }

                        />
                    </Grid>
                </Grid>

            </Grid>

            <Grid variant='Table' xs={12}>
                <Grid variant='body' xs={5.8} >
                    <Grid variant='GL' item xs={6} ml={2}>
                        Trailer
                    </Grid>
                    <Grid variant='GR' item xs={6}>
                        <CustomTextfield
                            name="title"
                            size="small"
                            placeholder="Enter Trailer"
                            fullWidth
                            variant="outlined"
                            value={formData.trailer}
                            onChange={(e) => setFormData({ ...formData, trailer: e.target.value })}
                        />
                    </Grid>
                </Grid>
                <Grid xs={0.4}></Grid>
                <Grid variant='body' xs={5.8} >
                    <Grid variant='GL' item xs={6} ml={2}>
                        Director
                    </Grid>
                    <Grid variant='GR' item xs={6}>
                        <CustomTextfield
                            name="title"
                            size="small"
                            placeholder="Enter Director"
                            fullWidth
                            variant="outlined"
                            value={formData.director}
                            onChange={(e) => setFormData({ ...formData, director: e.target.value })}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid variant='Table' xs={12}>
                <Grid variant='body' xs={5.8} >
                    <Grid variant='GL' item xs={6} ml={2}>
                        Presentation
                    </Grid>
                    <Grid variant='GR' item xs={6}>
                        <CustomTextfield
                            name="title"
                            size="small"
                            placeholder="Enter Presentation"
                            fullWidth
                            variant="outlined"
                            value={formData.presentation}
                            onChange={(e) => setFormData({ ...formData, presentation: e.target.value })}
                        />
                    </Grid>
                </Grid>
                <Grid xs={0.4}></Grid>
                <Grid variant='body' xs={5.8} >
                    <Grid variant='GL' item xs={6} ml={2}>
                        Producer
                    </Grid>
                    <Grid variant='GR' item xs={6}>
                        <CustomTextfield
                            name="title"
                            size="small"
                            placeholder="Enter Producer"
                            fullWidth
                            variant="outlined"
                            value={formData.producer}
                            onChange={(e) => setFormData({ ...formData, producer: e.target.value })}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid variant='Table' xs={12}>
                <Grid variant='body' xs={5.8} >
                    <Grid variant='GL' item xs={6} ml={2}>
                        Teaser
                    </Grid>
                    <Grid variant='GR' item xs={6}>
                        <CustomTextfield
                            name="title"
                            size="small"
                            placeholder="Enter Teaser"
                            fullWidth
                            variant="outlined"
                            value={formData.teaser}
                            onChange={(e) => setFormData({ ...formData, teaser: e.target.value })}
                        />
                    </Grid>
                </Grid>
                <Grid xs={0.4}></Grid>
                <Grid variant='body' xs={5.8} >
                    <Grid variant='GL' item xs={6} ml={2}>
                        Casts
                    </Grid>
                    <Grid variant='GR' item xs={6}>
                        <CustomTextfield
                            name="title"
                            size="small"
                            placeholder="Enter Casts"
                            fullWidth
                            variant="outlined"
                            value={formData.casts}
                            onChange={(e) => setFormData({ ...formData, casts: e.target.value })}
                        />
                    </Grid>
                </Grid>
            </Grid>
           

            <Grid xs={12} display='flex' justifyContent='center' alignItems='center' mt={3} style={{ maxHeight: "60px" }}>
                <Button variant='B2S' onClick={() => { setDeatilsView(true); setEditView(false); }}>Cancel</Button>
                <Button variant='B2S' onClick={handleSubmit}>Update</Button>
            </Grid>
        </>
    )
}

export default EditMode
