import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import TopBar from '../../Components/ProjectComponet/TopBar';
import DraftListCon from '../../Components/ProjectComponet/DraftListCon';
import { useDispatch } from "react-redux";
import { setShowLoader } from '../../redux/loaderSlice';
import { awsApi } from "../../Routes/Server";

const DraftList = () => {
  const dispatch = useDispatch();
  const ComponentName = 'Draft List'

  const [rows, setRows] = useState([]);

  useEffect(() => {
    async function getProjects() {
      dispatch(setShowLoader(true));
      try {
        const res = await awsApi.post('/getprojects', { type: "draft" });
        // console.log(res.data, "draft Project");

        if (res.data.statusCode === 200) {
          const draftProjects = JSON.parse(res.data.body);
          setRows(draftProjects);
        }
      } catch (e) {
        console.log(e, "error");
      } finally {
        dispatch(setShowLoader(false));
      }
    }

    getProjects();
  }, []);

  const fetchDraftProjects = async () => {
    dispatch(setShowLoader(true));
    try {
      const response = await awsApi.post('/getprojects', { type: "draft" })
      if (response.data.statusCode === 200) {
        const jsonData = JSON.parse(response.data.body);
        const projectsData = jsonData.filter(item => item.type === "draft");
        setRows(projectsData);
      }
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(setShowLoader(false));
    }
  };

  return (
    <Grid container xs={12} mt={7}>
      <TopBar ComponentName={ComponentName} fetchDraftProjects={fetchDraftProjects} />

      <DraftListCon setRows={setRows} rows={rows} fetchDraftProjects={fetchDraftProjects}/>

    </Grid>
  )

}

export default DraftList
