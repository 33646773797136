import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  projectId: "",
};

const ProjectSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    addProjectId: (state, action) => {
      state.projectId = action.payload;
    },
  },
});

export const { addProjectId } = ProjectSlice.actions;

export default ProjectSlice.reducer;


