import { Grid, Typography } from '@mui/material'
import React from 'react'
import CircleChart from '../DashboardComponet/CircleChart'
import GraphChart from '../DashboardComponet/GraphChart'

const ChartsBrand = ({data ,pageType}) => {
    return (
        <Grid xs={11.77} ml={2} display='flex' justifyContent='space-between' alignItems='center' spacing={2} mt={3} >
            <Grid xs={5.9} variant='G3' display='flex'>
                <Grid flexDirection='column' display='flex' ml={2} mt={2} mb={2} xs={11.6} justifyContent='center' alignItems='center'>
                    <Typography variant='C1'>{pageType === 'producer' ? 'Total Revenue' : 'Total Expense'}</Typography>
                    <Grid xs={12} display='flex' justifyContent='center' mt={-4} style={{maxWidth:"100%" ,minWidth:"100%"}}>
                        <GraphChart pageType={'producer'} data={data}/>
                    </Grid>
                </Grid>
            </Grid>
            <Grid xs={5.9} variant='G3' >
                <Grid flexDirection='column' display='flex' ml={2} mt={2} mb={2} xs={11.6} justifyContent='center' alignItems='center'>
                    <Typography variant='C1'>Total Campaigns</Typography>
                    <Grid mt={-4} xs={12} display='flex' justifyContent='center' style={{maxWidth:"100%" ,minWidth:"100%"}}>
                        <CircleChart data={data}/>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default ChartsBrand
