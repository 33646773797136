import React from 'react'
import { Table, TableBody, TableCell, TableHead, TableRow, Grid } from '@mui/material';
import NoData from '../CommonComponet/NoData';

const NewNoData = ({ state }) => {
    return (
        <Grid xs={12} mt={1} variant="G2C" display='flex' style={{ height: '400px' }} mb={1}>
            <Grid xs={6} style={{ height: '400px', borderTopRightRadius: "0px", borderBottomRightRadius: "0px", borderRight: "1px solid #e0e0e0" }}>
                <Grid xs={12} sx={{ width: '100%' }}>
                    <Grid sx={{ minHeight: "320px", maxHeight: "320px" }}>
                        <Table variant="T1" size="small" aria-label="a dense table">
                            <TableHead sx={{ height: "55px" }}>
                                <TableRow >
                                    <TableCell variant="TH1">VPO No</TableCell>
                                    <TableCell variant="TH1">Brand Categories</TableCell>
                                    <TableCell variant="TH1">Start Time</TableCell>
                                    <TableCell variant="TH1">Seconds</TableCell>
                                    <TableCell variant="TH1">Price</TableCell>
                                    <TableCell variant="TH1">Play</TableCell>
                                    <TableCell variant="TH1">{state}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableCell align='center' colSpan={7}>
                                    <NoData />
                                </TableCell>

                            </TableBody>
                        </Table>
                    </Grid>
                </Grid>
            </Grid>
            <Grid xs={6} style={{ minHeight: '400px' }} alignItems="center" >
                <Grid xs={12} variant="G2" style={{ minHeight: '400px', borderTopLeftRadius: "0px", borderBottomLeftRadius: "0px", }} alignItems="center" justifyContent='center'>
                    <img
                        src="https://www.rrcampus.com/images/no-video.jpg"
                        alt="No Video Found"
                        style={{ width: "100%", height: "100%", objectFit: "cover", background: "black" }}
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default NewNoData