import { Grid, Typography } from '@mui/material'
import React from 'react'

const Charts = ({totalVpoTotal,filteredDataCount}) => {
    return (
        <Grid xs={11.76} ml={2} display='flex' justifyContent='space-between' alignItems='center' spacing={2} mt={1.5} >
            <Grid xs={5.9} variant='G3' >
                <Grid flexDirection='column' display='flex'  mt={2.4} mb={2.4} xs={12} justifyContent='center' alignItems="center">
                    <Typography variant='C1' style={{fontSize:"15px"}}>No of VPO Inventories</Typography>
                    <Typography variant='C2' style={{fontSize:"15px"}}>{totalVpoTotal}</Typography>
                </Grid>
            </Grid>
            {/* <Grid xs={3.9} variant='G3' >
                <Grid flexDirection='column' display='flex' ml={2} mt={2} mb={2} xs={12} justifyContent='center' alignItems="center">
                    <Typography variant='C1'>No of Seconds of VPP</Typography>
                    <Typography variant='C2'>359</Typography>
                </Grid>
            </Grid> */}
            <Grid xs={5.9} variant='G3' >
                <Grid flexDirection='column' display='flex'  mt={2.4} mb={2.4} xs={12} justifyContent='center' alignItems="center">
                    <Typography variant='C1' style={{fontSize:"15px"}}>No of Content</Typography>
                    <Typography variant='C2' style={{fontSize:"15px"}}>{filteredDataCount}</Typography>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Charts
