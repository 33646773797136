import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, Button, DialogActions, Grid, Typography } from '@mui/material';
import CustomTextfield from '../CommonComponet/CustomTextfield';

const ApproveDialog = ({ open, onClose, onApprove, row }) => {
    const [liveDate, setLiveDate] = useState('');
    const [completeDate, setCompleteDate] = useState('');

    const handleApprove = () => {
        if (liveDate && completeDate) {
            onApprove({ liveDate, completeDate, row });
            onClose();
        }
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Enter Live Date and Complete Date</DialogTitle>
            <DialogContent>
                <Grid item xs={12} container mb={2} >
                    <Grid item xs={12} >
                        <Typography variant="P22" >
                            {"Live Date"}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} mt={0.4}
                    >
                        <CustomTextfield
                            name="liveDate"
                            size="small"
                            placeholder="Live Date"
                            fullWidth
                            variant="outlined"
                            type="date"
                            value={liveDate}
                            onChange={(e) => setLiveDate(e.target.value)}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} container mb={2} >
                    <Grid item xs={12} >
                        <Typography variant="P22" >
                            {"Complete Date"}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} mt={0.4}
                    >
                        <CustomTextfield
                            name="completeDate"
                            size="small"
                            placeholder="Complete Date"
                            fullWidth
                            variant="outlined"
                            type="date"
                            value={completeDate}
                            onChange={(e) => setCompleteDate(e.target.value)}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions variant="DA3" sx={{ marginBottom: "15px", marginTop: "-15px" }}>
                <Button variant='B2S' onClick={handleApprove}>Submit</Button>
                <Button variant='B2S' onClick={onClose}>Cancel</Button>

            </DialogActions>
        </Dialog>
    );
};

export default ApproveDialog;
