import React, { useEffect, useRef, useState } from 'react';
import { Grid } from "@mui/material";

function parseTimeToSeconds(timeString) {
    const [hours, minutes, seconds] = timeString.split(':').map(Number);
    return hours * 3600 + minutes * 60 + seconds;
}

const VideoSection = ({linkOne,likTwo, startTime, endTime,}) => {

    const videoRefOne = useRef(null);
    const videoRefTwo = useRef(null);
    const [currentPlayingIndex, setCurrentPlayingIndex] = useState(null);

    useEffect(() => {
        if (linkOne && startTime && endTime && videoRefOne.current) {
            const startTimeInSeconds = parseTimeToSeconds(startTime);
            const endTimeInSeconds = parseTimeToSeconds(endTime);
            const timeUpdateHandler = () => {
                if (videoRefOne.current && videoRefOne.current.currentTime >= endTimeInSeconds) {
                    videoRefOne.current.pause();
                    setCurrentPlayingIndex(null);
                }
            };
            const endedHandler = () => {
                if (videoRefOne.current) {
                    videoRefOne.current.removeEventListener('timeupdate', timeUpdateHandler);
                }
            };
            const pauseHandler = () => {
                if (videoRefOne.current) {
                    videoRefOne.current.removeEventListener('timeupdate', timeUpdateHandler);
                }
            };
            videoRefOne.current.addEventListener('timeupdate', timeUpdateHandler);
            videoRefOne.current.addEventListener('ended', endedHandler);
            videoRefOne.current.addEventListener('pause', pauseHandler);
            videoRefOne.current.currentTime = startTimeInSeconds;

            if (currentPlayingIndex === null) {
                // videoRefOne.current.play();
                setCurrentPlayingIndex(1);
            } else {
                videoRefOne.current.pause();
                setCurrentPlayingIndex(null);
            }
        }
    }, [linkOne]);

    useEffect(() => {
        if (likTwo && startTime && endTime && videoRefTwo.current) {
            const startTimeInSeconds = parseTimeToSeconds(startTime);
            const endTimeInSeconds = parseTimeToSeconds(endTime);
            const timeUpdateHandler = () => {
                if (videoRefTwo.current && videoRefTwo.current.currentTime >= endTimeInSeconds) {
                    videoRefTwo.current.pause();
                    setCurrentPlayingIndex(null);
                }
            };
            const endedHandler = () => {
                if (videoRefTwo.current) {
                    videoRefTwo.current.removeEventListener('timeupdate', timeUpdateHandler);
                }
            };
            const pauseHandler = () => {
                if (videoRefTwo.current) {
                    videoRefTwo.current.removeEventListener('timeupdate', timeUpdateHandler);
                }
            };
            videoRefTwo.current.addEventListener('timeupdate', timeUpdateHandler);
            videoRefTwo.current.addEventListener('ended', endedHandler);
            videoRefTwo.current.addEventListener('pause', pauseHandler);
            videoRefTwo.current.currentTime = startTimeInSeconds;

            if (currentPlayingIndex === null) {
                // videoRefTwo.current.play();
                setCurrentPlayingIndex(1);
            } else {
                videoRefTwo.current.pause();
                setCurrentPlayingIndex(null);
            }
        }
    }, [likTwo]);

    return (
        <Grid xs={11.8} style={{ background: "#eff4fd", border: "1px solid #c8c8c8", borderRadius: "12px" }} display='flex' justifyContent='space-around' alignItems='center' mt={1}>
            <Grid xs={5.9} mt={1}>
                <video width='100%' controls ref={videoRefOne} style={{ minWidth: '100%', minHeight: 335, maxHeight: 335, background: "black", borderRadius: "12px" }} >
                    <source src={linkOne} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </Grid>
            <Grid xs={5.9} mt={1}>
                <video width='100%' controls ref={videoRefTwo} muted  style={{ minWidth: '100%', minHeight: 335, maxHeight: 335, background: "black", borderRadius: "12px" }}>
                    <source src={likTwo} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </Grid>

        </Grid>
    )
}

export default VideoSection
